import React from 'react';
import {Typography} from '@material-ui/core';
import CustomTable from '../../common/CustomTable';
import FileAttachment from '../../common/FileAttachment';

export default function LabReportCard(props) {
  const event = props.event;

  const reportColumns = [
    {
      id: 'description',
      label: 'Description',
      minWidth: 190,
      align: 'left',
      format: value => value.toLocaleString(),
    },
    {
      id: 'value',
      label: 'Result',
      minWidth: 70,
      align: 'center',
      format: value => value.toLocaleString(),
    },
    {
      id: 'referenceRange',
      label: 'Reference Range',
      minWidth: 70,
      align: 'right',
      format: value => value.toFixed(2),
    },
  ];

  if(event.eventType==='INVSTR'){
  return (
    <>
      <Typography variant="body2" color="textPrimary" component="p">
        {`Test Name:${event.description.testname}`}
      </Typography>
      <br />
      {event.description.results ? (
        <CustomTable columns={reportColumns} rows={event.description.results} />
      ) : null}

      {event.description.documents
        ? event.description.documents.map((v, i) => (
            <FileAttachment attachment={v} />
          ))
        : null}
    </>
  );
        }

        if(event.eventType==='PTDOC'){
  return (
    <>
      {event.description.documents
        ? event.description.documents.map((v, i) => (
            <FileAttachment attachment={v} />
          ))
        : null}
    </>
  );
        }
        return null;
}
