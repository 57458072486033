import React, {useState} from 'react';
import {Container, Card, CardContent, Avatar} from '@material-ui/core';
import useSelectLanguageHook from './UseSelectLanguageHook';
import useStyles from '../Style';
import Title from '../common/Title';
import { saveIsLanguageSelected, savePreferedLanguage } from '../localstorage/LocalStorage';
import { languages, languagesList } from 'components/src/constants/languages';

export default function SelectLanguage(props) {
  
  const classes = useStyles();

  const [selectLang, setSelectLang] = useState(null)

  useSelectLanguageHook(
    selectLang
  );

  function setLangDetails(lang) {
    saveIsLanguageSelected(true)
    savePreferedLanguage(lang)
    setSelectLang(lang)
  }

  let langDetails = null
  if(languagesList) {
    langDetails = Object.keys(languagesList).map(lang => {
      let langClass = classes.languageCard
      if(lang === props.lang && props.screen !== 'Init') {
        langClass = classes.selectedLanguageCard
      }
      return (
        <Card className={classes.langBtnWrap} onClick={() => setLangDetails(lang)}>
          <button className={langClass}>
            <CardContent className={classes.langContent}>
              {languagesList[lang]}
            </CardContent>
          </button>
        </Card>
      )
    })
  }

  return (
    <Container>
      <div className={classes.paper}>
        <Title
          header={languages[props.lang].selectLanguage}
        />
        <div className={classes.langWrapper}>
          {langDetails}
        </div>
        {/* <button className={classes.langBtnWrap} onClick={()=>props.onSelect()}>
            <CardContent className={classes.langContent}>
             {languages[props.lang].continue}
            </CardContent>
          </button> */}
      </div>
    </Container>
  );
}
