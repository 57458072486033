import React from 'react';
import {AppStyle} from 'components/AppStyle';
import {paymentConstants} from 'components/src/redux/config';
// params :   {
//     name: user.patientName,
//     description: selectedEvent.packageName,
//     order_id: orderDetails.orderUUID,
//     contact: user.phoneNumber.mobileNumber,
//   }

const paymentHandler = (amount, responseHandler, params) => {
  const payment_amount = Number(amount * 100).toFixed(0);
  const options = {
    key: paymentConstants.PAYMENT_GATEWAY_KEY,
    amount: payment_amount * 1,
    name: params.name,
    currency: params?.currencyCode ? params?.currencyCode : 'INR',
    description: params.description,
    order_id: params.order_id,
    image: params.imageurl,
    prefill: {
      name: 'Razorpay Software',
      contact: params.contact,
    },
    theme: {
      color: params.paymentBackgroundColor
        ? params.paymentBackgroundColor
        : AppStyle.headerBackgroundColor,
    },
    handler(response) {
      responseHandler(response);
    },

    modal: {
      ondismiss: function() {
        responseHandler();
      },
    },
  };
  const rzp1 = new window.Razorpay(options);

  rzp1.open();
};

export default paymentHandler;
