import React, {useState,useEffect} from 'react';
import useStyles from './Style';
import {Grid, Button, Zoom, Box, FormHelperText} from '@material-ui/core';
import Title from '../common/Title';
import {genderList, isValidDob} from 'components/src/commonutil/CommonUtil';
import PatientName from './PatientName';
import PatientAddress from './PatientAddress';
import CustomButtonGroup from '../common/CustomButtonGroup';
import CustomDatepicker from '../common/CustomDatepicker';
import {registrationTitleColor} from './ConstStyle';
import { languages } from 'components/src/constants/languages';
import { FormControl, Select, InputLabel, MenuItem } from '@material-ui/core';
import WebAnalyticsUtils  from '../../WebAnalytics';
import { eventConstants } from 'components/src/constants/firebaseAnalyticsEventConstant';
import { getHospitalCodeFromParams } from '../localstorage/LocalStorage';
import { getKinesisWebEventVO } from '../../utils/KinesisWebUtil';
import { actionType, eventScreenName, eventType } from 'components/src/constants/KinesisConstants';
import { useDispatch } from 'react-redux';
import { kinesisUIEventBeforeLogin } from 'components/src/redux/kinesisReducer/kinesisActions';

function Profile(props) {
  const classes = useStyles();
  const [dob, setDob] = useState(new Date());
  const [gender, setGender] = useState(genderList[0]);
  const [patientName, setPatientName] = useState();
  const [address, setAddress] = useState();
  const [hospitalCd, setHospitalCd] = useState("Select Hospital Branch");
  const [showBranchSelection, setShowBranchSelection] = useState(false);
  const [showBranchSelectionError, setShowBranchSelectionError] = useState(
    null,
  );
const [showDOBSelectionError, setShowDOBSelectionError] = useState(
    false
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if(props.branches && props.branches.length > 1){
      let selBranch = getHospitalCodeFromParams();
      if(selBranch){
      setHospitalCd(selBranch);
      }
      let defaultH = props.branches.find(d => d.defaultHospitalRegistrationFlg)
      if(!defaultH){
        defaultH = props.branches[0]
      }
      setShowBranchSelection(true)
    }else if(props.branches && props.branches.length==1){
      setHospitalCd(props.branches[0].hospitalCd)
    }
  },[props.branches])

  const handleDateChange=(selectedDob)=>{
    setDob(selectedDob);
    if(showDOBSelectionError){
      setShowDOBSelectionError(false)
    }
  }
  
  const handleOnClick = () => {
    if (dob && isValidDob(dob) && gender && patientName && address  && hospitalCd !== "Select Hospital Branch") {
      let eventVO = getKinesisWebEventVO(eventScreenName.patientProfileScreen, eventType.newUserLogin, "button_saveProfile", '' , actionType.onClick, '', '');
      dispatch(kinesisUIEventBeforeLogin(eventVO, 'web'));
      const profileData = {dob, gender, patientName, address};
        profileData.hospitalCode = hospitalCd
        let analyticsProfileData = JSON.stringify(profileData)
        try{
        WebAnalyticsUtils.trackEvent(eventConstants.KYC, {...profileData})
        }catch(e){

        }
        props.registerUser(profileData);
    } else if(dob && !isValidDob(dob)){
    if(!showDOBSelectionError){
      setShowDOBSelectionError(true);
    }
    }
    else {
      props.setErrorMsg(languages[props.lang].enterAllFields);
      if(hospitalCd === 'Select Hospital Branch'){
      setShowBranchSelectionError(true);
      }
      WebAnalyticsUtils.trackEvent(eventConstants.KYC_FAILED, {errorMsg: languages[props.lang].enterAllFields})

    }
  };

  return (
    <>
      <Title
        header={languages[props.lang].completeProfile}
        subHeader={`for ${'+'}${props.userNumber.countryCode}${
          props.userNumber.mobileNumber
        }`}
        style={registrationTitleColor}
      />

      <Zoom in={true} style={{transitionDelay: '0ms'}}>
        <form noValidate className={classes.form}>
        <Box boxShadow={3}>
        
          
            <Grid container spacing={3} className={classes.registrationGrid}>

            {showBranchSelection ? (
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <InputLabel required >Select Branch</InputLabel>
                <Select 
                 value={hospitalCd}
                 onChange = { (e) => {
                  WebAnalyticsUtils.trackEvent(eventConstants.SELECT_HOSPITAL_BRANCH, {branch:e.target.value})
                   setHospitalCd(e.target.value)
                 }}>
                   <MenuItem value="Select Hospital Branch">Select Hospital Branch</MenuItem>
                  {props.branches.map(d => (
                    <MenuItem value={d.hospitalCd} key={d}>
                      {d.hospitalNm}
                    </MenuItem>
                  ))}
                </Select>
                {showBranchSelectionError ? (
                  <FormHelperText style={{color: 'red'}}>
                    Hospital Branch is Required .
                  </FormHelperText>
                ) : null}
              </FormControl>
            </Grid>) : null}

              <PatientName setPatientName={setPatientName} lang={props.lang} />
              <Grid item container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <CustomDatepicker
                    label={languages[props.lang].dob}
                    handleDateChange={handleDateChange}
                  />
                   {showDOBSelectionError ? (
                  <FormHelperText style={{color: 'red'}}>
                    {languages[props.lang].invalidDob}
                  </FormHelperText>
                ) : null}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomButtonGroup
                    containerStyle={classes.genderSection}
                    labelStyle={classes.genderLabel}
                    buttonGroupLabel={languages[props.lang].gender}
                    buttonGroupStyle={classes.buttonGroup}
                    buttonGroupName={languages[props.lang].genderSelection}
                    items={genderList}
                    handleSelect={setGender}
                  />
                </Grid>
              </Grid>
              <PatientAddress
                setAddress={setAddress}
                error={props.addressError}
                lang={props.lang}
                branches={props.branches} 
                selectedBranch={hospitalCd}
              />
              <Grid item xs={12} style={{textAlign: 'center'}}>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={e => handleOnClick()}>
                  {languages[props.lang].save}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Zoom>
    </>
  );
}
export default Profile;
