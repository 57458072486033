import React, {useState, useEffect} from 'react';
import useStyles from './Style';
import {Container, Card, CardContent, Avatar, Button} from '@material-ui/core';
import CustomContainer from '../common/CustomContainer';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import HospitalLogo from '../common/HospitalLogo';
import Title from '../common/Title';
import PhoneInputComponent from '../common/PhoneInputComponent';
import ReCAPTCHA from 'react-google-recaptcha';
import useSignupHook from './UseSignupHook';
import SignupOtp from './SignupOtp';
import {languages, languagesList} from 'components/src/constants/languages';
import SelectLanguage from '../SelectLanguage/SelectLanguage';
import OtpHelpImage from '../common/OtpHelpImage';
import appleLogo2 from '../../assets/zomato-apple.png';
import androidLogo2 from '../../assets/zomato-android.png';
import WebAnalyticsUtils from '../../WebAnalytics';
import {eventConstants} from 'components/src/constants/firebaseAnalyticsEventConstant';
import {hospitalWebsiteLink, subHeading} from 'components/src/redux/config';
import { getKinesisWebEventVO } from '../../utils/KinesisWebUtil';
import { actionType, eventScreenName, eventType } from 'components/src/constants/KinesisConstants';
import { useDispatch } from 'react-redux';
import { kinesisUIEventBeforeLogin } from 'components/src/redux/kinesisReducer/kinesisActions';


function Signup(props) {
  const classes = useStyles();
  const [phoneNo, setPhoneNo] = useState();
  const [countryCode, setCountryCode] = useState();
  const [checkbox, setCheckbox] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const [appUrl, setAppUrl] = useState({});
  const [displaySendOtp, setDisplaySendOtp] = useState(true);

  var deviceInfo = {
    platform: navigator.platform,
    userAgent: navigator.userAgent,
    appVersion: navigator.appVersion,
    vendor: navigator.vendor,
  };
  const dispatch = useDispatch();
  useSignupHook(setOpenBackdrop, setErrorMsg, setAppUrl, props.history);

  const handlePhoneNumberChange = (countryCode, mobileNo) => {
    setPhoneNo(mobileNo);
    setCountryCode(countryCode);
    setCheckbox(false);
    setDisplaySendOtp(true);
  };

const onClickMobileAppUrl =(e,actionOnComponentName, url) =>{
  let componentValue = {};
      componentValue.downloadtUrl = url;
  let eventVO = getKinesisWebEventVO(eventScreenName.patientSignupScreen, eventType.download, actionOnComponentName, JSON.stringify(componentValue) , actionType.onClick, '', '');
  dispatch(kinesisUIEventBeforeLogin(eventVO, 'web'));
}

  const handleSendOtpClick = (actionOnComponentName) => {
    if (phoneNo && phoneNo.trim().length > 0) {
      setDisplaySendOtp(false);
      WebAnalyticsUtils.trackEvent(eventConstants.SEND_OTP, {phoneNo});
      WebAnalyticsUtils.onLogin(deviceInfo);
      let eventVO = getKinesisWebEventVO(eventScreenName.patientSignupScreen, eventType.loginAttempt, actionOnComponentName, '' , actionType.onClick, '', '');
      dispatch(kinesisUIEventBeforeLogin(eventVO, 'web'));
    }
  };
  function handleCaptchaChange(value) {
    setCheckbox(true);
  }
  function handleCaptchaExpiry() {
    setCheckbox(false);
  }

  function handleSubcomponentError(errorMessage) {
    setErrorMsg(errorMessage);
  }

  let screenContent = null;
  if (props.langSelected) {
    screenContent = (
      <Container>
        <div className={classes.paper}>
          <Title
            header={languages[props.lang].enterMobile}
            subHeader={languages[props.lang][subHeading]}
          />

          <Card className={classes.signupCard}>
            {checkbox ? <OtpHelpImage style={classes.otpImageStyle} /> : null}
            <CardContent>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <PhoneInputComponent
                disabled={errorMsg ? true : false}
                onChange={handlePhoneNumberChange}
              />
              {displaySendOtp ? (
                <div className={classes.phoneNumberContainerStyle}>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    className={classes.sendOtpButton}
                    onClick={()=>handleSendOtpClick("button_sendOtp")}
                    disabled={props.disabled}>
                    Send OTP
                  </Button>
                </div>
              ) : null}
              {!displaySendOtp && !checkbox ? (
                <div className={classes.captchaDivStyle}>
                  <ReCAPTCHA
                    sitekey="6LcBCtIUAAAAAK7I7Jwp9iqsYJ9JsPzGVGWl-S6h"
                    onChange={e => handleCaptchaChange(e)}
                    onExpired={handleCaptchaExpiry}
                  />
                </div>
              ) : null}
              {checkbox ? (
                <SignupOtp
                  countryCode={countryCode}
                  phoneNo={phoneNo}
                  openBackdrop={setOpenBackdrop}
                />
              ) : null}
            </CardContent>
          </Card>
        </div>
      </Container>
    );
  } else {
    screenContent = <SelectLanguage lang={props.lang} screen={'Init'} />;
  }

  return (
    <CustomContainer
      openBackdrop={openBackdrop}
      displayDialog={errorMsg ? true : false}
      dialogTitle={'Error'}
      dialogMessage={errorMsg}
      dialogType={'error'}
      footerStyle={{color: '#ffffff'}}>
      <div className={classes.logoDiv}>
        <HospitalLogo style={classes.imageStyle} />
        {screenContent}
        {appUrl ? (
          <div className={classes.appUrlContainer}>
            {appUrl.ios ? (
              <div onClick={(e)=> onClickMobileAppUrl(e, "button_appStore", appUrl.ios)}>
                <a href={`${appUrl.ios}`} target="_blank">
                  <img
                    src={appleLogo2}
                    alt="mobile_logo"
                    className={[classes.appleLogo]}
                  />
                </a>
              </div>
            ) : null}
            {appUrl.android ? (
              <div onClick={(e)=> onClickMobileAppUrl(e, "button_playStore", appUrl.android)} >
                <a href={`${appUrl.android}`} target="_blank">
                  <img
                    src={androidLogo2}
                    alt="mobile_logo"
                    className={classes.appleLogo}
                  />
                </a>{' '}
              </div>
            ) : null}
          </div>
        ) : null}
        {hospitalWebsiteLink && hospitalWebsiteLink.length > 0 ? (
          <div className={classes.policyContainer}>
            <div>
              {`Privacy policy, Terms & Conditions, Refunds & Cancellations as per the `}
              <a
                className={classes.hyperLinkWhite}
                href={`${hospitalWebsiteLink}`}
                target="_blank">
                hospital
              </a>
              {` norms.`}
            </div>
          </div>
        ) : null}
      </div>
    </CustomContainer>
  );
}
export default Signup;
