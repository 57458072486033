import {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import moment from 'moment';
import {checkForUpdates} from 'components/src/redux/homescreenreducer/HomeScreenAction';

export default function useDataSyncHook(setUpdates) {
  const dispatch = useDispatch();
  const dataSynced = useSelector(state => state.home.dataSync);
  const dataSyncInit = useSelector(state => state.home.dataSyncInit);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(checkForUpdates());
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!dataSyncInit && Object.keys(dataSynced).length) {
      setUpdates(dataSynced.updateCount);
    }
  }, [dataSyncInit]);
}
