import {paymentConstant} from './../ReduxConstant';
import {authorizedHttp} from './../axiosApi';
import {showErrorToast} from '../errorhandler/ErrorHandleReducer';

export function intiatePaymentDetailsSave() {
  return {
    type: paymentConstant.PAYMENT_DETAILS_SAVE_INTITATED,
  };
}

export function paymentDetailsSaveSuccess(data) {
  return {
    type: paymentConstant.PAYMENT_DETAILS_SAVE_SUCCESS,
    data,
  };
}

export function paymentDetailsSaveFailue(data) {
  return {
    type: paymentConstant.PAYMENT_DETAILS_SAVE_FAILURE,
    data,
  };
}

export function intiateCreateOrder(payload) {
  return {
    type: paymentConstant.ORDER_CREATE_INITIATED,
    payload,
  };
}

export function createOrderSuccess(data) {
  return {
    type: paymentConstant.ORDER_CREATE_SUCCESS,
    data,
  };
}

export function createOrderFailue(data) {
  return {
    type: paymentConstant.ORDER_CREATE_FAILURE,
    data,
  };
}

export function createOrder(data) {
  let errorMessage =
    'Service is down. Please contact support@thehealthscore.com or try again later.';
  return dispatch => {
    dispatch(intiateCreateOrder(data.billItemCd));
    return authorizedHttp
      .post('api/createOrder', data)
      .then(response => {
        if (response.data.status) {
          dispatch(createOrderSuccess(response.data));
        } else {
          dispatch(createOrderFailue({message: errorMessage, statusCode: 409}));
           dispatch(showErrorToast(response.data.errors));
        }
      })
      .catch(error => {
        dispatch(createOrderFailue(error));
        dispatch(showErrorToast(error));
      });
  };
}

export function addPaymentDetails(data) {
  return dispatch => {
    dispatch(intiatePaymentDetailsSave());
    authorizedHttp
      .post('api/addPaymentDetails', data)
      .then(response => {
        dispatch(paymentDetailsSaveSuccess(response.data));
      })
      .catch(error => {
        dispatch(paymentDetailsSaveFailue(error));
        dispatch(showErrorToast(error));
      });
  };
}
