import React, {useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
// import Search from '@material-ui/icons/Search';
import DehazeIcon from '@material-ui/icons/Dehaze';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Translate from '@material-ui/icons/Translate';
import Cancel from '@material-ui/icons/Cancel';
import useStyles from './Style';
import homeScreenHeaderImage from 'components/assets/homescreenHeader.png';
import useMenubarHook from './UseMenubarHook';
import Typography from '@material-ui/core/Typography';
import { Hosp_Name } from 'components/src/redux/config';
import { languages } from 'components/src/constants/languages';
import { Modal } from '@material-ui/core';
import SelectLanguage from '../SelectLanguage/SelectLanguage';
import WebAnalyticsUtils from '../../WebAnalytics';
import { eventConstants } from 'components/src/constants/firebaseAnalyticsEventConstant';

export default function MenuBar(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [logout, setLogout] = useState();
  const [username, setUsername] = useState();
  const [open, setOpen] = useState(false);

  const isMenuOpen = Boolean(anchorEl);

  useMenubarHook(logout, setUsername, props.history);

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = menuItem => {
    setAnchorEl(null);
    if (menuItem === 'logout') {
      WebAnalyticsUtils.trackEvent(eventConstants.LOGOUT, {})
      setLogout(true);
    }
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      id={menuId}
      keepMounted
      transformOrigin={{vertical: 'top', horizontal: 'right'}}
      open={isMenuOpen}
      className={classes.menu}
      onClose={handleMenuClose}>
        <MenuItem
          onClick={() => handleOpen()}
        >
          {languages[props.lang].sideChangeLanguage}
        </MenuItem>
        <MenuItem
          onClick={e => {
            handleMenuClose('logout');
          }}>
          {languages[props.lang].logout}
        </MenuItem>
    </Menu>
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const languageModal = (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.modalWrap}>
        <SelectLanguage lang={props.lang} onSelect={()=>{handleClose();handleMenuClose();}}/>
        <button className={classes.modalClose} onClick={() => {handleClose();handleMenuClose();}}>
          <Cancel className={classes.modalCloseIcon} />
        </button>
      </div>
    </Modal>
  )

  return (
    /*  <div className={classes.grow}> */
    <>
      <AppBar
        position="fixed"
        className={props.class ? props.class : classes.menuBar}>
        <Toolbar>
          {props.home ? (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={props.handleSideSheetToggle}
              className={classes.menuButton}>
              <DehazeIcon />
            </IconButton>
          ) : null}
          {/*  {props.home ? (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={props.handleDrawerToggle}
              className={classes.menuButton}>
              <MenuIcon />
            </IconButton>
          ) : null} */}
          <div className={classes.divImageHeader}>
            <img
              src={homeScreenHeaderImage}
              alt="header"
              className={classes.homeScreenHeaderImage}></img>
              <p className={classes.LogoText}>{Hosp_Name}</p>
          </div>
          <div className={classes.grow}></div>

          {username ? <Typography variant="h6">{username}</Typography> : null}
          <IconButton
            aria-label="account of current user"
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit">
            <AccountCircle />
          </IconButton>
        </Toolbar>
      </AppBar>
      {renderMenu}
      {languageModal}
    </>
    /*   </div> */
  );
}
