import React, { useState } from 'react';
import { Typography, Button } from '@material-ui/core'
import { languages } from 'components/src/constants/languages';
import homeStyles from '../Style';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';

export default function IndentCard({
    event,
    lang
}) {
    const eventDescripiton = event.description;
    const initialCount = 3;
    // If no indent items, IND event itself will not be created in DB from HS.
    const indentItems = JSON.parse(eventDescripiton.indentItems);
    const [isExpanded, setIsExpanded] = useState(false);
    const classes = homeStyles();

    const onLoadMore = () => {
        setIsExpanded(true);
    }

    function onReset() {
        setIsExpanded(false);
    }

    return (
        <div className={classes.indentCardBody}>
            <div className="refNoContainer" style={{ paddingBottom: 10, }}>
                <div className={classes.refNoText}>Ref No: {eventDescripiton.indentCode}</div>
            </div>

            <div className="indentItemsContainer">
                <div className={classes.indentCardItemsTableHeader}>
                    <span class={classes.indentItemsTableHeaderText}>{languages[lang].product}</span>
                    <span class={classes.indentItemsTableHeaderText}>{languages[lang].quantity}</span>
                </div>
                <div className={classes.seperatorContainer}>
                    <div className={classes.seperatorLine} />
                </div>
                {
                    indentItems.slice(0, isExpanded === false ? initialCount : undefined).map(item => {
                        return <div className={classes.indentItemContainer}>
                            <Typography 
                                className={classes.indentItemItemName} 
                                variant='body2' 
                                component={"p"} 
                                color="textPrimary"
                            >
                                {item.itemName}
                            </Typography>
                            <Typography
                                className={classes.indentItemItemQty}
                                variant='body2' 
                                component={"p"} 
                                color="textPrimary"
                            >
                                {item.quantity}
                            </Typography>
                        </div>
                    })
                }
            </div>

            <div className={classes.cardFooter}>
                {indentItems.length > initialCount && (isExpanded === false ? (
                    <div className={classes.loadMoreButtonContainer}>
                        <Button variant='outlined' onClick={onLoadMore}>
                            {languages[lang].loadMore}
                        </Button>
                    </div>
                ) : (
                    <div className={classes.loadMoreButtonContainer}>
                        <div className={classes.loadMoreButton} onClick={onReset}>
                            <p className={classes.loadMoreText}>
                                <KeyboardArrowUp />
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}