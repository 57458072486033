/* eslint-disable no-undef */
import { kinesisConstant } from '../ReduxConstant';
import { authorizedHttp, http, } from '../axiosApi';


export function kinesisUIEvent(event, appType) {
  return {
    types: [
      kinesisConstant.KINESIS_INIT,
      kinesisConstant.KINESIS_SUCCESS,
      kinesisConstant.KINESIS_FAIL,
    ],
    callAPI: () =>
      authorizedHttp.post(`api/saveKinesisUIEvent/${appType}`, event)
  };
}


export function kinesisUIEventBeforeLogin(event, appType) {

  return {
    types: [
      kinesisConstant.KINESIS_INIT,
      kinesisConstant.KINESIS_SUCCESS,
      kinesisConstant.KINESIS_FAIL,
    ],
    callAPI: () =>
      http.post(`/api/saveKinesisUIEventBeforeLogin/${appType}`, event)
  };
}
