import React from 'react';
import axios from 'axios';
import { slackDevChannel , loginConstants} from 'components/src/redux/config';
import errorImg from './assets/error.gif'
import errorImg1 from './assets/error1.jpeg'

export default class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
        hasErrorImg: '' ,
        errorMessage: '',

      };
      // if we need more device infomation. we can use bellow deviceInfo object things like that line number 28 same file.
      // let deviceInfo = {
      //   platform:  navigator.platform,
      //   userAgent: navigator.userAgent,
      //   appVersion: navigator.appVersion,
      //   vendor: navigator.vendor,
      //   opera: window.opera
      //  }
    }
    static getDerivedStateFromError(error) {
      try{
        if(window.localStorage){
         return {errorMessage:'Something went wrong.', hasErrorImg: errorImg};
        }
       }catch{
       return {errorMessage:`Our website doesn't support Incognito Or Private Mode ! Please use normal browser . . .` , hasErrorImg: errorImg1};
       }
    }

     componentDidCatch(error, info) {
      let componentStack = info.componentStack.split('in App')[0];
      let deviceInfo = JSON.stringify({userAgent:window.navigator.userAgent , vendor: navigator.vendor})
      if(process.env.REACT_APP_ENV === 'development') {
        this.setState({hasError: true});
      } else {
        async function slack (){
          let userName = `${loginConstants.CLIENT_ID}` 
          let data = {
             "text": `appBundleId=>${userName}\ndeviceInfo=>${deviceInfo}\nJsCrashError=>${error}\ncomponentStack=>${componentStack} `
          }
          await axios.post(slackDevChannel, JSON.stringify(data), {
            withCredentials: false,
            transformRequest: [(data, headers) => {
                delete headers.post["Content-Type"]
                return data
            }]
        })
        }
        slack();
      }
    }
  
    render() {
      if (this.state.errorMessage) {
        // You can render any custom fallback UI
          return (
            <>
               <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '10%' , width: '100%' }}>
                  <h1 style={{ margin: "auto"}} >{this.state.errorMessage}</h1>
                  <img style={{objectFit: 'contain', height:'60vh', width:'100%'}} src={this.state.hasErrorImg} alt=''></img>
              </div>
            </>
          )
      }
      return this.props.children;
    }
  }

  