import {credentialConstant} from '../ReduxConstant';
import {authorizedHttp} from '../axiosApi';

export function getApplicationCredentials() {
  return {
    // Types of actions to emit before and after
    types: [
      credentialConstant.CREDENTIAL_INIT,
      credentialConstant.CREDENTIAL_SUCCESS,
      credentialConstant.CREDENTIAL_FAILURE,
    ],

    callAPI: () => authorizedHttp.get(`api/getAwsCredentials`),
  };
}

export function saveApplicationCredentials(data) {
  return {
    type: credentialConstant.CREDENTIAL_SUCCESS,
    data: {data: data},
  };
}
