import { useEffect } from 'react';
import { getApplicationCredentials } from '../localstorage/LocalStorage';
import { getSignedUrl, downloadFileFromS3 } from 'components/src/aws/AwsUtil';
import fileDownload from 'js-file-download';

export default function useCustomFileViewerHook(
  awsCredentials,
  setAwsCredentials,
  setVisible,
  filePath,
  bucketName,
  setViewerSrc,
  fileType,
  fileName,
  close,
  resourceType,
) {
  useEffect(() => {
    if (!awsCredentials) {
      setAwsCredentials(getApplicationCredentials());
    } else {
      if (resourceType !== 'jpg') {
        downloadFileFromS3(
          filePath,
          bucketName,
          awsCredentials.accessKey,
          awsCredentials.secretKey,
        )
          .then(details => {
            if (details) {
              fileDownload(details, fileName);
              close();
            }
          })
          .catch(error => {
            console.log('error occured in get files method', error);
          });
      } else {
        setViewerSrc(
          getSignedUrl(
            filePath,
            bucketName,
            awsCredentials.accessKey,
            awsCredentials.secretKey,
          ),
        );
      }

      setVisible(true);
    }
  }, [awsCredentials]);
}
