import {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
  saveUserDetails,
  resetRegistrationResponse,
} from 'components/src/redux/registrationreducer/RegistrationAction';

import {getAllHospitalDetails} from 'components/src/redux/hospitalreducer/HospitalAction';

import {prefix} from '../../AppNavigator';
import {
  reloadPatientList,
  resetAddAction,
} from 'components/src/redux/homescreenreducer/HomeScreenAction';
import {saveUserProfile} from '../localstorage/LocalStorage';

const useRegisterHook = (
  userData,
  setUserData,
  setOpenBackdrop,
  setErrorMsg,
  history,
  cancel,
  setBranches,
) => {
  const dispatch = useDispatch();
  const [profileData, setProfileData] = useState();

  const registerInit = useSelector(
    state => state.registration.registerPatientIntiated,
  );
  const registerResponse = useSelector(
    state => state.registration.registerData,
  );

  const registerError = useSelector(state => state.registration.error);

  const hospitalBranches = useSelector(state => state.hospital.branches);

  useEffect(() => {
    if (!hospitalBranches) {
      dispatch(getAllHospitalDetails());
    }
  }, []);

  useEffect(() => {
    setBranches(hospitalBranches);
  }, [hospitalBranches]);

  useEffect(() => {
    if (!registerInit && Object.keys(registerError).length) {
      setErrorMsg(registerError.message);
    }
  }, [registerError]);

  useEffect(() => {
    if (userData && Object.keys(registerResponse).length == 0) {
      const reqData = populateRegistrationData();
      if (history) {
        setProfileData(reqData);
      }
      dispatch(saveUserDetails([reqData]));
    }
  }, [userData]);

  useEffect(() => {
    setOpenBackdrop(registerInit);
    if (!registerInit) {
      if (registerResponse && Object.keys(registerResponse).length) {
        dispatch(resetRegistrationResponse());

        if (history) {
          history.push(`${prefix}/home`);
          saveUserProfile(profileData);
          setProfileData('');
        } else {
          dispatch(reloadPatientList());
        }
        setUserData('');
      } else {
        setErrorMsg(registerError.message);
      }
    }
  }, [registerInit]);

  useEffect(() => {
    if (cancel) {
      dispatch(resetAddAction());
    }
  }, [cancel]);

  const populateRegistrationData = () => {
    let requestData = {};
    requestData.patientFirstNm = userData?.patientName?.firstName;
    requestData.patientLastNm = userData?.patientName?.lastName;
    requestData.patientNm = `${requestData?.patientFirstNm} ${
      requestData?.patientLastNm
    }`;
    requestData.birthDt = userData?.dob;
    requestData.gender = userData?.gender;
    requestData.patientRelation = userData?.relation;
    let contactDetails = {};

    contactDetails.patientMobileNo = userData?.patientMobileNo;
    contactDetails.patientAddrLine1 = userData?.address?.addresLineOne;
    contactDetails.cityName = userData?.address?.cityName;
    contactDetails.stateName = userData?.address?.stateName;
    contactDetails.countryName = userData?.address?.countryName;
    contactDetails.zipCode = userData?.address?.zipCode;
    requestData.contactDetails = contactDetails;
    requestData.hospitalCode = userData?.hospitalCode;
    return requestData;
  };
};

export default useRegisterHook;
