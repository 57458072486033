import {vitalConstant} from '../ReduxConstant';

const initialState = {
  vitalList: '',
  getVitalListInit: false,
  addVitalInit: false,
  addVitalResponse: {},
  error: {},
};

export default function vitalDetails(state = initialState, action) {
  switch (action.type) {
    case vitalConstant.GET_VITAL_FOR_HOSPITAL_INIT:
      return {
        ...state,
        vitalList: '',
        getVitalListInit: true,
        error: {},
      };
    case vitalConstant.GET_VITAL_FOR_HOSPITAL_SUCCESS:
      return {
        ...state,
        vitalList: action.data.data,
        getVitalListInit: false,
        error: {},
      };
    case vitalConstant.GET_VITAL_FOR_HOSPITAL_FAILURE:
      return {
        ...state,
        vitalList: '',
        getVitalListInit: false,
        error: action.data,
      };
    case vitalConstant.ADD_VITAL_INIT:
      return {
        ...state,
        addVitalResponse: {},
        addVitalInit: true,
        error: {},
      };
    case vitalConstant.ADD_VITAL_SUCCESS:
      return {
        ...state,
        addVitalResponse: action.data,
        addVitalInit: false,
        error: {},
      };
    case vitalConstant.ADD_VITAL_FAILURE:
      return {
        ...state,
        addVitalResponse: {},
        addVitalInit: false,
        error: action.data,
      };
    default:
      return state;
  }
}
