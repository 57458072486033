import React, {useEffect, useState} from 'react';
import {
  Container,
  CircularProgress,
  Grid,
  Select,
  MenuItem,
  Button,
  Box,
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import CustomAvatar from '../common/CustomAvatar';
import CustomContainer from '../common/CustomContainer';
import CustomTextfield from '../common/CustomTextfield';
import {registrationContainerColor, registrationTitleColor} from './ConstStyle';
import useStyles from './Style';
import MenuBar from '../menubar/MenuBar';
import Title from '../common/Title';
import CustomTypography from '../common/CustomTypography';
import useListPatientHook from './UseListPatientHook';
import {AppStyle} from 'components/AppStyle';
import {languages} from 'components/src/constants/languages';
import moment from 'moment';
import {prefix} from '../../AppNavigator';
import {regYearDropDown} from 'components/src/commonutil/CommonUtil';
import {getUserProfile} from '../localstorage/LocalStorage';
import WebAnalyticsUtils  from '../../WebAnalytics';
import { eventConstants } from 'components/src/constants/firebaseAnalyticsEventConstant';
import { actionType, eventScreenName, eventType } from 'components/src/constants/KinesisConstants';
import { getKinesisWebEventVO } from '../../utils/KinesisWebUtil';
import { kinesisUIEventBeforeLogin } from 'components/src/redux/kinesisReducer/kinesisActions';
import { useDispatch } from 'react-redux';

function ListPatient(props) {
  const classes = useStyles();
  const [loginFlow, setLoginFlow] = useState(
    props.location.pathname.includes('listPatient'),
  );

  const [patientList, setPatientList] = useState([]);

  const [progress, setProgress] = useState(true);

  const [cancel, setCancel] = useState(false);

  const [verifyUserIndex, setVerifyUserIndex] = useState([]);

  const [verifyUserData, setVerifyUserData] = useState({});
  const [hospitalPatientIDData, setHospitalPatientIDData] = useState([]);
  const [regYrPart, setRegYrPart] = useState([]);
  const [patientCdPart, setPatientCdPart] = useState([]);
  const [hasVerifiedUser, setHasVerifiedUser] = useState(false);
  const [hasUnVerifiedUser, setHasUnVerifiedUser] = useState(false);
  const [disabledBool, setDisabledBool] = useState(false);
  const years = regYearDropDown();
  useListPatientHook(
    patientList,
    setPatientList,
    setProgress,
    cancel,
    hospitalPatientIDData,
    setHospitalPatientIDData,
    regYrPart,
    setRegYrPart,
    patientCdPart,
    setPatientCdPart,
    hasVerifiedUser,
    setHasVerifiedUser,
    hasUnVerifiedUser,
    setHasUnVerifiedUser,
    verifyUserIndex,
    setVerifyUserIndex,
    verifyUserData,
    setVerifyUserData,
    props.history,
    loginFlow,
  );

  const dispatch = useDispatch();
  useEffect(()=>{
    let disableButton = patientList.find(e =>e.verified==true)
    if(disableButton){
      setDisabledBool(true)
    }
  },[props,patientList])

  const userProfile = getUserProfile();

  if (!patientList || progress) {
    return <CircularProgress />;
  }

  const handleClose = () => {
    if (props.history && userProfile && userProfile.appPatientId) {
      props.history.push(`${prefix}/home`);
      WebAnalyticsUtils.trackEvent(eventConstants.ALLREADY_REGISTAR_USER_CLICK_ON_CONTINUE, {appPatientId:userProfile.appPatientId})
    }
      //  WebAnalyticsUtils.trackEvent(eventConstants.ALLREADY_REGISTAR_USER_CLICK_ON_CANCLE, {})
    setCancel(true);
  };

  const onClickVerifyUser = props => {
    let patientCdArray = [];
    let index = props.index;
    if (props.data && props.data.hospitalPatientCode) {
      patientCdArray = props.data.hospitalPatientCode.split('/');
    }
    const dataProps = props.dataProps;
    var id = '';
    const ptCodePart = [...patientCdPart];
    const regYrState = [...regYrPart];
    if (patientCdArray && patientCdArray.length === 3) {
      id = patientCdArray[0] + '/';
    }
    if (ptCodePart && ptCodePart[index] && regYrState && regYrState[index]) {
      id = id + ptCodePart[index] + '/' + regYrState[index];
    } else {
      id = '';
    }
    if (id && id.trim().length > 0) {
      var patientData = {
        dimPatientId: props.data.dimPatientId,
        hospitalPatientCode: id,
      };
    if(!disabledBool){
      let componentValue = {};
      componentValue.patientCdPart = patientCdPart[0];
      let eventVO = getKinesisWebEventVO(eventScreenName.patientListPatienScreen, eventType.newUserLogin, "button_VerifyProfile", JSON.stringify(componentValue) , actionType.onClick, props.data.hospitalCode, props.data.hospitalPatientCode);
      dispatch(kinesisUIEventBeforeLogin(eventVO, 'web'));
    }
      let verifyUserSelectedIndex = [...verifyUserIndex];
      verifyUserSelectedIndex[index] = true;
      setVerifyUserIndex(verifyUserSelectedIndex);
      setVerifyUserData(patientData);
      WebAnalyticsUtils.trackEvent(eventConstants.VERIFY_NEW_PATIENT, {data:patientData})

    } else {
      alert(languages[dataProps.lang].enterPatientId);
    }
  };

  const saveYrPart = (selectedYr, index) => {
    const yrs = [...regYrPart];
    yrs[index] = selectedYr;
    setRegYrPart(yrs);
  };

  const savePatientCdPart = (patientCd, index) => {
    const newPatientCdPart = [...patientCdPart];
    newPatientCdPart[index] = patientCd;
    setPatientCdPart(newPatientCdPart);
  };

  const VerifyPatientComponent = props => {
    const data = props.data;
    const dataProps = props.dataProps;
    const index = props.index;
    let patientCdArray = [];
    if (data.hospitalPatientCode) {
      patientCdArray = data.hospitalPatientCode.split('/');
    }
    return (
      <div class={classes.colPtCodeView}>
        <CustomTypography>
          {languages[dataProps.lang].enterPatientCode}
        </CustomTypography>
        <div class={classes.ptCodeView}>
          <div>
            {patientCdArray && patientCdArray.length === 3 ? (
              <CustomTypography class={classes.hospCodeText}>
                {patientCdArray[0]}
              </CustomTypography>
            ) : null}
            {patientCdArray && patientCdArray.length === 3 ? (
              <CustomTypography class={classes.slashText}>
                {` `}/{` `}
              </CustomTypography>
            ) : null}
          </div>

          <div class={classes.flexPadd1}>
            <CustomTextfield
              class={classes.inputPtCode}
              value={patientCdPart[index] || ''}
              maxLength={5}
              type={'number'}
              id={'ptCode'}
              onTextBlur={t => savePatientCdPart(t, index)}
            />
          </div>
          <div class={classes.flex1}>
            <CustomTypography class={classes.slashText}>
              {` `}/{` `}
            </CustomTypography>

            <Select
              value={regYrPart[index] || new Date().getFullYear()}
              onChange={e => {
                saveYrPart(e.target.value, index);
              }}>
              {years.map((year, i) => (
                <MenuItem value={year} key={i}>
                  {year.toString()}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      </div>
    );
  };

  const CustomCard = props => {
    const data = props.data;
    const dataProps = props.dataProps;
    const index = props.index;
    let isVerified = false;
    if (data.appPatientId !== 0) {
      isVerified = true;
    }

    return (
      <>
        <div
          alignItems="flex-start"
          key={data.appPatientId}
          className={classes.inputPtCodeDiv}
          key={index}>
          <Box boxShadow={3} borderRadius={4}>
            <div class={classes.flexDiv}>
              <div class={classes.flex2}>
                <ListItemAvatar>
                  <CustomAvatar
                    class={classes.avatar}
                    username={true}
                    value={data.patientName}
                  />
                </ListItemAvatar>

                <div class={classes.ptNameDiv}>
                  <CustomTypography class={classes.primaryListItemText}>
                    {`${data.patientName}`}
                    <br />
                  </CustomTypography>
                </div>
              </div>
              <div>
                <Grid item xs={6}>
                  {isVerified ? (
                    <Button
                      type="button"
                      variant="contained"
                      disabled={isVerified}
                      style={{
                        marginLeft: '0',
                        borderColor: 'grey',
                        borderRadius: 5,
                        borderWidth: 1,
                        color: 'grey',
                        alignSelf: 'flex-end',
                      }}>
                      {languages[dataProps.lang].verified}
                    </Button>
                  ) : null}
                
                </Grid>
              </div>
            </div>
            <Divider />
            <div class={classes.cardItemBorder}>
              {isVerified ? (
                <Grid class={classes.verifyPatientDiv}>
                  <div class={classes.flexDiv2}>
                    <div class={classes.fieldDiv}>
                      <CustomTypography class={classes.labelStyle}>
                        {languages[dataProps.lang].dob}:<br />
                      </CustomTypography>
                      <CustomTypography class={classes.valueStyle}>
                        {moment(data.dob).format('DD-MMM-YYYY')}
                      </CustomTypography>
                    </div>
                    <div class={classes.fieldDiv2}>
                      <CustomTypography class={classes.labelStyle}>
                        {languages[dataProps.lang].gender}:<br />
                      </CustomTypography>
                      <div class={classes.rightDiv}>
                        <CustomTypography class={classes.valueStyle}>
                          {data.gender}
                        </CustomTypography>
                      </div>
                    </div>
                  </div>
                  <div class={classes.flexDiv2}>
                    <div class={classes.fieldDiv}>
                      <CustomTypography class={classes.labelStyle}>
                        {languages[dataProps.lang].mobile}:<br />
                      </CustomTypography>
                      <CustomTypography class={classes.valueStyle}>
                        {data?.phoneNumber?.countryCode}-
                        {data?.phoneNumber?.mobileNumber}
                      </CustomTypography>
                    </div>
                    <div class={classes.fieldDiv2}>
                      <CustomTypography class={classes.labelStyle}>
                        {languages[dataProps.lang].hospitalPatientCode}:
                        <br />
                      </CustomTypography>
                      <div class={classes.rightDiv}>
                        <CustomTypography class={classes.valueStyle}>
                          {data.hospitalPatientCode}
                        </CustomTypography>
                      </div>
                    </div>
                  </div>
                </Grid>
              ) : null}

              {!isVerified ? (
                <VerifyPatientComponent
                  data={props.data}
                  dataProps={props.dataProps}
                  index={props.index}
                />
              ) : null}
                {!isVerified ? (
                    <Button
                      type="button"
                      variant="contained"
                      style={{
                        margin: 'auto',
                        marginBottom:'10px',
                        borderColor: 'white',
                        backgroundColor: '#f04343',
                        color: '#ffffff',
                      }}
                      onClick={e => onClickVerifyUser(props)}>
                      {languages[dataProps.lang].verify}
                    </Button>
                  ) : null}
            </div>
          </Box>
        </div>
      </>
    );
  };

  return (
    <>
      {loginFlow ? <MenuBar history={props.history} lang={props.lang} /> : null}

      <CustomContainer
        openBackdrop={false}
        displayDialog={false}
        dialogTitle={'Error'}
        dialogMessage={''}
        dialogType={'error'}
        style={registrationContainerColor}
        footerStyle={{color: AppStyle.primaryColor}}>
        <div
          class={
            !loginFlow ? classes.containerStyle : classes.loginContainerStyle
          }
        />
        <Container maxWidth="sm">
          <Title
            header={
              patientList.length > 1
                ? `There are ${
                    patientList.length
                  } patients with the same mobile number.`
                : `There is ${
                    patientList.length
                  } patient with this mobile number.`
            }
            subHeader={
              hasUnVerifiedUser > 0
                ? languages[props.lang].enterPatientCodeToVerify
                : ''
            }
            style={registrationTitleColor}
          />
          {hasUnVerifiedUser > 0 ? (
            <div class={classes.ptNameDiv}>
              <CustomTypography class={classes.secondaryListItemText}>
                {languages[props.lang].callHospitalNote}
              </CustomTypography>
            </div>
          ) : null}
          {patientList.map((element, index) => (
            <>
              <CustomCard
                data={element}
                key={index}
                index={index}
                dataProps={props}
              />
            </>
          ))}
              {!disabledBool ?
              <CustomTypography class={classes.warningText}>
              {languages[props.lang].verifiedToContinue}
            </CustomTypography>
            :null
              }
              
          {userProfile && userProfile.appPatientId ? (
            <Grid item xs={6} class={classes.backButton}>
              <Button
                disabled={!disabledBool?true:false}
                type="button"
                variant="contained"
                style={{
                  marginLeft: '0',
                  backgroundColor: !disabledBool?'#3g3g3g' :AppStyle.primaryColor,
                  color: !disabledBool?'#333':'#ffffff',
                }}
                onClick={e => handleClose(e)}>
                {loginFlow
                  ? languages[props.lang].continue
                  : languages[props.lang].close
                  }
              </Button>
            </Grid>
          ) : null}
        </Container>
      </CustomContainer>
    </>
  );
}
export default ListPatient;
