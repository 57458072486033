import {useEffect} from 'react';
import {saveAddAction} from 'components/src/redux/homescreenreducer/HomeScreenAction';
import {useSelector, useDispatch} from 'react-redux';

export default function useTabFabHook(actionCode, setActionCode) {
  const dispatch = useDispatch();
  const savedAction = useSelector(state => state.home.addAction);

  useEffect(() => {
    if (actionCode) {
      dispatch(saveAddAction(actionCode));
    }
  }, [actionCode]);

  useEffect(() => {
    if (!savedAction) {
      setActionCode(savedAction);
    }
  }, [savedAction]);
}
