import { subscriptionConstant, COMMON_ERROR } from './../ReduxConstant';
import { authorizedHttp } from './../axiosApi';
import { showErrorToast } from '../errorhandler/ErrorHandleReducer';

export function initiateSubscribedUserRequest() {
  return {
    type: subscriptionConstant.SUBSCRIBED_USER_REQUEST_INITIATED,
  };
}

export function subscribedUserSuccess(data) {
  return {
    type: subscriptionConstant.SUBSCRIBED_USER_REQUEST_SUCCESS,
    data,
  };
}

export function subscribedUserFailure(data) {
  return {
    type: subscriptionConstant.SUBSCRIBED_USER_REQUEST_FAILURE,
    data,
  };
}

export function userVerificationInitiated(data) {
  return {
    type: subscriptionConstant.USER_VERIFICATION_INITIATED,
    data,
  };
}

export function userVerificationSuccess(data) {
  return {
    type: subscriptionConstant.USER_VERIFICATION_SUCCESS,
    data,
  };
}

export function userVerificationFailure(data) {
  return {
    type: subscriptionConstant.USER_VERIFICATION_FAILURE,
    data,
  };
}

export function intiateBillingPkgRequest() {
  return {
    type: subscriptionConstant.BILLING_PACKAGE_REQUEST_INITIATED,
  };
}

export function billingPkgRequestSuccess(data) {
  return {
    type: subscriptionConstant.BILLING_PACKAGE_REQUEST_SUCCESS,
    data,
  };
}

export function billingPkgRequestFailue(data) {
  return {
    type: subscriptionConstant.BILLING_PACKAGE_REQUEST_FAILURE,
    data,
  };
}

export function resetCommonError() {
  return {
    type: COMMON_ERROR,
    data: {},
  };
}
export function getSubscribedUserListFromDeviceStorage(data) {
  return dispatch => {
    dispatch(subscribedUserSuccess(JSON.parse(data)));
  };
}

export function resetUserList() {
  return {
    type: subscriptionConstant.SUBSCRIBED_USER_LIST_RESET,
  };
}

export function getSubscribedUserList() {
  return dispatch => {
    dispatch(initiateSubscribedUserRequest());
    authorizedHttp
      .get('api/getPatientDetailsForUsername')
      .then(response => {
        if (response.data.status) {
          dispatch(subscribedUserSuccess(response.data.data));
        } else {
          dispatch(subscribedUserFailure(response.data.error));
        }
      })
      .catch(error => {
        dispatch(subscribedUserFailure(error));
        dispatch(showErrorToast(error));
      });
  };
}

export function getBillingPackages(data, user) {
  return dispatch => {
    dispatch(intiateBillingPkgRequest());
    let apiUrl = `api/getBillingPackages?rateCategory=${data}`;
    if (user && user.appPatientId) {
      apiUrl = apiUrl + `&appPatientId=${user.appPatientId}`;
    }
    authorizedHttp
      .get(apiUrl)
      .then(response => {
        dispatch(billingPkgRequestSuccess(response.data));
      })
      .catch(error => {
        dispatch(billingPkgRequestFailue(error));
        dispatch(showErrorToast(error));
      });
  };
}

export function verifyUser(data) {
  return dispatch => {
    dispatch(userVerificationInitiated(data));
    dispatch(resetCommonError());
    authorizedHttp
      .post(`api/verifyPatient`, data)
      .then(response => {
        if (response.data.status) {
          dispatch(userVerificationSuccess(response.data));
          dispatch(showErrorToast(response.data.errors));
        } else {
          dispatch(userVerificationFailure(data));
          dispatch(showErrorToast(response.data.errors));
        }
      })
      .catch(error => {
        dispatch(userVerificationFailure(data));
        dispatch(showErrorToast(error));
      });
  };
}

function intiatewebSubsribeRequest(data) {
  return {
    type: subscriptionConstant.WEB_SUBSCRIBE_REQUEST_INITIATED,
    data,
  };
}

function webSubsribeSucess(data) {
  return {
    type: subscriptionConstant.WEB_SUBSCRIBE_REQUEST_SUCCESS,
    data,
  };
}

function webSubsribeError() {
  return {
    type: subscriptionConstant.WEB_SUBSCRIBE_REQUEST_ERROR,
  };
}

export function webSubsribeUser(data) {
  return dispatch => {
    dispatch(intiatewebSubsribeRequest(data));
    authorizedHttp
      .post(`api/subscribePackage`, data)
      .then(response => {
        dispatch(webSubsribeSucess(response.data));
      })
      .catch(error => {
        dispatch(webSubsribeError());
      });
  };
}
