export const languagesList = {
  eng: `English`,
  hindi: `हिंदी`,
  kannada: `ಕನ್ನಡ`,
  tamil: `தமிழ்`,
  telugu: `తెలుగు`,
};

export const languages = {
  eng: {
    /**Start screen */
    errOccured: `An error occurred`,

    /**Signup screen */
    accessRecords: `to tele consult, access medical records, health videos and more`,
    mobileNumber: `Mobile Number`,
    enterMobile: `Enter your mobile number`,
    enterOtp: `Enter OTP`,
    resendOtp: `Resend OTP`,
    sendOtp: `Verify with OTP`,
    resendOtpByWhatsApp: `Resend OTP By WhatsApp`,
    getOtpByVoiceCall: `Get OTP by voice call`,
    agree: `By entering, I agree to these`,
    terms: `Terms and conditions`,
    loggedOutWarning: `You will be logged out of the previously configured device`,
    unableToVerifyMobile: `Unable to verify mobile number,please try again later`,
    invalidOtp: `Invalid Otp`,
    selectLanguage: `Select your preferred language to continue`,
    getStarted: `Enter patient’s registered mobile number`,
    waitingForAutoReadOTP: `Waiting to auto-read OTP `,

    /**Subscription screen */
    payLater: `Pay Later`,
    canNotSubscribe: `We are sorry we cannot subscribe you right now. Please try again later`,
    noPackage: `No Packages are available`,
    oneTimeFee: `One Time Fee`,
    monthlyFee: `Monthly Renewal Fee`,
    choosePackage: `Choose Care Package`,

    /**Patient registration screen */
    patientRegistered: `Patient(s) successfully registered.`,
    enterAllFields: `Please enter all mandatory fields`,
    registerPatient: `Register New Patient(s)`,
    patientDetails: `Provide patient details`,
    addPatient: `ADD ANOTHER PATIENT`,
    register: `Register`,
    registerLater: `Register Later`,
    tellUs: `Tell us about yourself`,
    otherPatientRegister: `You'll be able to register other patients later`,

    /**List Patient screen */
    verified: `Verified`,
    verify: 'Verify',
    enterPatientId: `Please enter the hospital patient Id to verify the user.`,
    enterPatientCode: `Enter hospital patient code`,
    dob: `Date of Birth`,
    gender: `Gender`,
    mobile: `Mobile`,
    hospitalPatientCode: `HospitalPatientCode`,
    enterPatientCodeToVerify: `Please enter patient code to verify and add`,
    callHospitalNote: `NOTE : If you don’t know your patient code, please call the hospital for help.`,
    verifiedToContinue: `Atleast one patient must be verified to continue.`,

    /**Home screen */
    subscribeFirst: `Please subscribe first`,
    medications: `MY RECORDS`,
    myHealthRecords: `My Health Records`,
    addVitals: `MY VITALS`,
    myAppts: `MY APPOINTMENTS`,
    scanPay: `SCAN & PAY`,
    callDoctor: `Call Doctor`,
    virtualLibrary: `VIRTUAL LIBRARY`,
    billsAndPayments: `BILLS & PAYMENTS`,

    /**Verify user and complete profile screen */
    verificationSuccess: `User Verification is Successful.`,
    unableToVerifyUser: `Unable to verify the user.Please try later.`,
    unableToUpdateProfile: `Unable to update the profile.Please try later.`,

    /**Add Vitals screen */
    canNotSaveVitals: `Cannot save vital values with more than three digits. Please correct and try submitting again.`,
    bloodPressure: `Blood Pressure`,
    noVitalsConfigured: `No vitals configured for the hospital`,
    back: `BACK`,
    next: `Next`,
    cancel: `CANCEL`,
    submit: `SUBMIT`,

    /**Medication screen */
    selectUserToViewMedication: `Please select a user to view their medication details`,
    frequency: `Frequency`,
    home: `HOME`,
    printPrescription: `PRINT PRESCRIPTIONS`,
    currentMedication: `Current Medications`,
    currentMedicationCap: `CURRENT MEDICATION`,
    noRecordFound: `No record found...`,

    /**Bill screen */
    selectUserToViewBill: `Please select a user to view their bill details`,
    selectUserToScanQR: `Please select a user to scan a QR code`,
    itemName: `Item Name`,
    qty: `Qty`,
    amount: `Amount`,
    viewBill: `View Bill Details`,
    billReceived: `Bill Received`,
    payNow: `PAY NOW`,
    makePayment: `MAKE PAYMENT`,
    product: `Product`,
    quantity: `Quantity`,
    loadMore: `Load more...`,


    /**Consult doctor screen */
    callPermission: `CALL PERMISSION`,
    allowPhone: `Allow the app to make a phone call.`,
    ok: `OK`,
    giveCallPermission: `You need to give call permission to make a phone call.`,
    consultationCharge: `Consultation charge`,
    payCall: `PAY & CALL`,
    noConsultationAvailable: `No consultation Available.`,

    /**Prescription screen */
    noPrescriptionAvailable: `No Prescription available`,
    prescriptionHistory: `Prescription History`,
    visitDate: `Visit Date`,
    diagnosis: `Diagnosis`,
    prescription: `Prescription`,
    advice: `Advice`,

    /**Help screen */
    help: `Help`,
    contactForHelp: `Contact us if you need help`,
    email: `Email`,
    phoneNumber: `Phone Number`,
    address: `Address`,

    /**Sidebar */
    sideBarDashboard: `Dashboard`,
    sideBarregisterPatient: `Register New Patient`,
    sideLinkDependents: `Link Dependents`,
    sideChangeLanguage: `Change Language`,
    sideTerms: `Terms of Use`,
    sidePrivacyPolicy: `Privacy Policy`,
    sideHelp: `Help`,
    viewPackages: `Purchase Care Package`,
    tellAFriend: `Tell a friend`,
    rateUs: `Rate Us`,

    /**Advice Card */
    viewPdf: `Click here to view the shared pdf.`,
    documentShared: `Document shared`,
    viewImage: `Click here to view the shared image.`,
    viewFile: `Click here to view the shared file.`,
    /**web */
    logout: `Logout`,
    continue: 'Continue',
    allMembers: `All Members`,
    notSubscribed: `Not Subscribed`,
    processingPayment: `Processing Payment`,
    poweredby: `Powered by`,
    updatesAreAvailable: `Updates are available`,
    refreshToSeeTheChanges: `refresh to see the changes.`,
    webHome: `HOME`,
    webAppointments: `MY APPOINTMENTS`,
    webMedications: `MY MEDICATIONS`,
    // webLabTests: `DOCUMENTS & REPORTS`,
    webRecords: `MY RECORDS`,
    webBills: `BILLS & PAYMENTS`,
    subscribeToUnlock: `Subscribe to a package to unlock this`,
    enterAllFields: `Please enter all mandatory fields`,
    completeProfile: `Complete Profile`,
    genderSelection: `Gender Selection`,
    addressLine1: `Address Line 1`,
    zipcode: `Zipcode`,
    searchByZipcode: `Search by Zipcode`,
    selectValidAddress: `Please select a valid address`,
    save: `SAVE`,
    needInfoToProvideBestCare: `We need all your contact information to provide the best care`,
    invalidDob: `Please select the correct date of birth`,
    useMyNumber: `Use My Number`,
    firstName: `First Name`,
    lastName: `Last Name`,
    relation: `Relation`,
    registerCap: `REGISTER`,
    completeVerification: `Complete Verification`,
    selectPlan: `Select Plan`,
    subscriptionSuccessful: `Subscription Successful`,
    useMyDetails: `Use My Contact Details`,
    oneTimeMembership: `One time Membership`,
    rs: `Rs`,
    close: `Close`,
    viewMorePlans: `View More Plans`,
    joinCall: `Join Call`,
    testCall: `Test Call`,
    novitalsconfigured: `No vitals configured for the hospital`,
    selectUserToViewMedication: `Please select a user to view their medication details`,
    selectUserToViewAppointment: `Please select a user to view their appointment details`,
    selectUserToViewRecord: `Please select a user to view their records details`,
    selectUserToViewBill: `Please select a user to view their bills and payments details`,
    formNameStrength: `Form/Name/Strength`,
    from: `From`,
    to: `To`,
    specialInstruction: `Special Instruction`,
    noMedications: `No Medications`,
    accessMedicalRecords: `to access medical records and wellness portal`,
    accessMedicalRecordsUoh: `to connect your child with Unit of Hope doctors and therapists. Not for adults.`,

    /**Force update */
    updateToLatest: `To use this app, update to the latest version`,
    updateNow: `Update Now`,

    myProfile: `My Profile`,
  },
  hindi: {
    /**Start screen */
    errOccured: `एक त्रुटि पाई गई`,

    /**Signup screen */
    accessRecords: `से परामर्श करने के लिए, चिकित्सा रिकॉर्ड, स्वास्थ्य वीडियो और अधिक का उपयोग करें`,
    mobileNumber: `मोबाइल नंबर`,
    enterMobile: `अपना मोबाइल संख्या दर्ज करे`,
    enterOtp: `OTP दर्ज करें`,
    resendOtp: `ओटीपी पुनः भेजें`,
    sendOtp: `OTP भेजें`,
    resendOtpByWhatsApp: `व्हाट्सएप द्वारा ओटीपी फिर से भेजें`,
    getOtpByVoiceCall: `फोन कॉल द्वारा ओटीपी प्राप्त करें`,
    agree: `प्रवेश करके, मैं इनसे सहमत हूँ`,
    terms: `नियम और शर्तें`,
    loggedOutWarning: `आपको पहले से कॉन्फ़िगर डिवाइस से लॉग आउट किया जाएगा`,
    unableToVerifyMobile: `मोबाइल नंबर सत्यापित करने में असमर्थ, कृपया बाद में पुनः प्रयास करें`,
    invalidOtp: `अमान्य ओटीपी`,
    selectLanguage: `अपनी पूर्वनिर्धारित भाषा का चयन करें`,
    getStarted: `रोगी का पंजीकृत मोबाइल नंबर दर्ज करें`,
    waitingForAutoReadOTP: `ओटीपी को ऑटो-रीड करने की प्रतीक्षा कर रहा है`,

    /**Subscription screen */
    payLater: `बाद में भुगतान करें`,
    canNotSubscribe: `हमें खेद है कि हम अभी आपकी सदस्यता नहीं ले सकते। बाद में पुन: प्रयास करें`,
    noPackage: `कोई पैकेज उपलब्ध नहीं हैं`,
    oneTimeFee: `बस एकबार दिए जाना वाला शुल्क`,
    monthlyFee: `मासिक नवीकरण शुल्क`,
    choosePackage: `केयर पैकेज चुनें`,

    /**Patient registration screen */
    patientRegistered: `रोगी (ओं) को सफलतापूर्वक पंजीकृत किया गया।`,
    enterAllFields: `कृपया सभी अनिवार्य फ़ील्ड दर्ज करें`,
    registerPatient: `रोगी को पंजीकृत करें`,
    patientDetails: `रोगी विवरण प्रदान करें`,
    addPatient: `एक और मरीज जोड़ें`,
    register: `रजिस्टर करें`,
    registerLater: `बाद में दर्ज करें`,
    tellUs: `आप अपने बारे में बताओ`,
    otherPatientRegister: `आप बाद में अन्य रोगियों को पंजीकृत करने में सक्षम होंगे`,

    /**List Patient screen */
    verified: `सत्यापित`,
    verify: 'सत्यापित करें',
    enterPatientId: `उपयोगकर्ता को सत्यापित करने के लिए कृपया अस्पताल के मरीज की आईडी दर्ज करें।`,
    enterPatientCode: `अस्पताल का मरीज कोड डालें`,
    dob: `जन्म की तारीख`,
    gender: `लिंग`,
    mobile: `मोबाइल`,
    hospitalPatientCode: `अस्पताल रोगी कोड`,
    enterPatientCodeToVerify: `कृपया सत्यापित करने और जोड़ने के लिए रोगी कोड दर्ज करें`,
    callHospitalNote: `नोट: यदि आपको अपना रोगी कोड पता नहीं है, तो कृपया मदद के लिए अस्पताल को कॉल करें।`,
    verifiedToContinue: `जारी रखने के लिए कम से कम एक रोगी को सत्यापित किया जाना चाहिए।`,

    /**Home screen */
    subscribeFirst: `कृपया पहले सदस्यता लें`,
    medications: `मेरा स्वास्थ्य रिकॉर्ड`,
    myHealthRecords: `मेरा स्वास्थ्य रिकॉर्ड`,
    addVitals: `मेरे विटल`,
    myAppts: `मेरे APPOINTMENTS`,
    scanPay: `स्कैन और भुगतान`,
    callDoctor: `डॉक्टर को बुलाओ`,
    virtualLibrary: `ई-लाइब्रेरी`,
    billsAndPayments: `बिल और भुगतान`,

    /**Verify user and complete profile screen */
    verificationSuccess: `उपयोगकर्ता सत्यापन सफल है।`,
    unableToVerifyUser: `उपयोगकर्ता को सत्यापित करने में असमर्थ। कृपया बाद में प्रयास करें।`,
    unableToUpdateProfile: `प्रोफ़ाइल अपडेट करने में असमर्थ। कृपया बाद में प्रयास करें।`,

    /**Add Vitals screen */
    canNotSaveVitals: `तीन से अधिक अंकों के साथ महत्वपूर्ण मूल्यों को नहीं बचा सकता है। कृपया सही करें और फिर से सबमिट करने का प्रयास करें।`,
    bloodPressure: `Blood Pressure`,
    noVitalsConfigured: `अस्पताल के लिए कोई विटालस कॉन्फ़िगर नहीं किया गया है`,
    back: `वापस`,
    next: `अगला`,
    cancel: `रद्द करना`,
    submit: `प्रस्तुत`,

    /**Medication screen */
    selectUserToViewMedication: `कृपया उनके दवा विवरण देखने के लिए एक उपयोगकर्ता का चयन करें`,
    frequency: `आवृत्ति`,
    home: `घर`,
    printPrescription: `प्रिंट प्रिस्क्रिप्शन`,
    currentMedication: `मौजूदा दवाएं`,
    currentMedicationCap: `वर्तमान दवा`,
    noRecordFound: `कोई रिकॉर्ड नहीं मिला...`,

    /**Bill screen */
    selectUserToScanQR: `कृपया QR कोड स्कैन करने के लिए एक उपयोगकर्ता का चयन करें`,
    itemName: `आइटम नाम`,
    qty: `मात्रा`,
    amount: `रकम`,
    viewBill: `बिल विवरण देखें`,
    billReceived: `बिल प्राप्त किया`,
    payNow: `अब भुगतान करें`,
    makePayment: `भुगतान करो`,
    product: `वस्तु`,
    quantity: `मात्रा`,
    loadMore: `और दिखाना...`,

    /**Consult doctor screen */
    callPermission: `कॉल पेमेंट`,
    allowPhone: `एप्लिकेशन को फ़ोन कॉल करने दें।`,
    ok: `ठीक है`,
    giveCallPermission: `आपको फ़ोन कॉल करने के लिए कॉल अनुमति देने की आवश्यकता है।`,
    consultationCharge: `परामर्श शुल्क`,
    payCall: `भुगतान और कॉल`,
    noConsultationAvailable: `कोई परामर्श उपलब्ध नहीं है।`,

    /**Prescription screen */
    noPrescriptionAvailable: `कोई प्रिस्क्रिप्शन उपलब्ध नहीं है`,
    prescriptionHistory: `प्रिस्क्रिप्शन हिस्ट्री`,
    visitDate: `दिनांक पर जाएँ`,
    diagnosis: `निदान`,
    prescription: `पर्चे`,
    advice: `सलाह`,

    /**Help screen */
    help: `हाथ बटाना`,
    contactForHelp: `अगर आपको मदद चाहिए तो हमसे संपर्क करें`,
    email: `ईमेल`,
    phoneNumber: `फ़ोन नंबर`,
    address: `पता`,

    /**Sidebar */
    sideBarDashboard: `डैशबोर्ड`,
    sideBarregisterPatient: `रोगी को पंजीकृत करें`,
    sideLinkDependents: `लिंक आश्रित`,
    sideChangeLanguage: `भाषा बदलें`,
    sideTerms: `उपयोग की शर्तें`,
    sidePrivacyPolicy: `गोपनीयता नीति`,
    sideHelp: `हाथ बटाना`,
    viewPackages: `खरीद देखभाल पैकेज`,
    tellAFriend: `एक दोस्त को बताएं`,
    rateUs: `हमें रेट करें`,

    /**Advice Card */
    viewPdf: `साझा किए गए पीडीएफ को देखने के लिए यहां क्लिक करें।`,
    documentShared: `दस्तावेज़ साझा`,
    viewImage: `साझा की गई छवि देखने के लिए यहां क्लिक करें।`,
    viewFile: `साझा फ़ाइल देखने के लिए यहां क्लिक करें।`,
    /**web */
    logout: `लॉग आउट`,
    continue: 'जारी रखें',
    allMembers: `सभी सदस्य`,
    notSubscribed: `सब्स्क्राइब्ड नहीं`,
    processingPayment: `संसाधन संबंधी भुगतान`,
    poweredby: `द्वारा संचालित`,
    updatesAreAvailable: `अद्यतन उपलब्ध हैं`,
    refreshToSeeTheChanges: `परिवर्तनों को देखने के लिए ताज़ा करें।`,
    webHome: `घर`,
    webVitals: `मेरी नियुक्ति`,
    webMedications: `मेरे दवाओं`,
    webLabTests: `दस्तावेज़ & लैब टीईटीएस`,
    webDoctorNotes: `मेरा रिकॉर्ड`,
    webBills: `बिल और भुगतान`,
    subscribeToUnlock: `इसे अनलॉक करने के लिए पैकेज की सदस्यता लें`,
    enterAllFields: `कृपया सभी अनिवार्य फ़ील्ड दर्ज करें`,
    completeProfile: `पूरा प्रोफ़ाइल`,
    genderSelection: `लिंग चयन`,
    addressLine1: `पता पंक्ति 1`,
    zipcode: `ज़िप कोड`,
    searchByZipcode: `ज़िपकोड द्वारा खोजें`,
    selectValidAddress: `कृपया एक वैध पता चुनें`,
    save: `सहेजें`,
    needInfoToProvideBestCare: `सर्वोत्तम देखभाल प्रदान करने के लिए हमें आपकी सभी संपर्क जानकारी की आवश्यकता है`,
    invalidDob: `कृपया सही जन्मतिथि चुनें`,
    useMyNumber: `मेरे नंबर का उपयोग करें`,
    firstName: `नाम`,
    lastName: `उपनाम`,
    relation: `रिश्ता`,
    registerCap: `रजिस्टर करें`,
    completeVerification: `पूरा सत्यापन`,
    selectPlan: `प्लान का चयन करें`,
    subscriptionSuccessful: `सदस्यता सफल`,
    useMyDetails: `मेरे संपर्क विवरण का उपयोग करें`,
    oneTimeMembership: `एक बार की सदस्यता`,
    rs: `रुपये`,
    close: `बंद करे`,
    viewMorePlans: `अधिक योजनाओं को देखें`,
    joinCall: `कॉल ज्वाइन करें`,
    testCall: `टेस्ट कॉल`,
    novitalsconfigured: `अस्पताल के लिए कोई विटालस कॉन्फ़िगर नहीं किया गया है`,
    selectUserToViewMedication: `कृपया उनके दवा विवरण देखने के लिए एक उपयोगकर्ता का चयन करें`,
    selectUserToViewAppointment: `कृपया एक मरीज को उनकी नियुक्ति विवरण देखने के लिए चुनें`,
    formNameStrength: `फॉर्म / नाम / शक्ति`,
    from: `से`,
    to: `सेवा`,
    specialInstruction: `विशेष निर्देश`,
    noMedications: `कोई दवा नहीं`,
    accessMedicalRecords: `मेडिकल रिकॉर्ड और वेलनेस पोर्टल तक पहुँचने के लिए`,
    accessMedicalRecordsUoh: `अपने बच्चे को यूनिट ऑफ होप के डॉक्टरों और थेरेपिस्ट से जोड़ने के लिए। वयस्कों के लिए नहीं।`,
    /**Force update */
    updateToLatest: `इस एप्लिकेशन का उपयोग करने के लिए, नवीनतम संस्करण में अपडेट करें`,
    updateNow: `अभी अद्यतन करें`,

    myProfile: `मेरी प्रोफाइल`,
  },
  kannada: {
    /**Start screen */
    errOccured: `ಒಂದು ತಪ್ಪು ನಡೆದಿದೆ`,

    /**Signup screen */
    accessRecords: `ಟೆಲಿ ಸಮಾಲೋಚಿಸಲು, ವೈದ್ಯಕೀಯ ದಾಖಲೆಗಳು, ಆರೋಗ್ಯ ವೀಡಿಯೊಗಳು ಮತ್ತು ಹೆಚ್ಚಿನದನ್ನು ಪ್ರವೇಶಿಸಲು`,
    mobileNumber: `ಮೊಬೈಲ್ ನಂಬರ`,
    enterMobile: `ನಿಮ್ಮ ಮೊಬೈಲ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ`,
    enterOtp: `ಒಟಿಪಿ ನಮೂದಿಸಿ`,
    resendOtp: `ಒಟಿಪಿಯನ್ನು ಮರುಹೊಂದಿಸಿ`,
    sendOtp: `OTP ಕಳುಹಿಸಿ`,
    resendOtpByWhatsApp: `WhatsApp ಮೂಲಕ OTP ಅನ್ನು ಮರುಕಳುಹಿಸಿ`,
    getOtpByVoiceCall: `ಫೋನ್ ಕರೆ ಮೂಲಕ OTP ಪಡೆಯಿರಿ`,
    agree: `ಪ್ರವೇಶಿಸುವ ಮೂಲಕ, ನಾನು ಇವುಗಳನ್ನು ಒಪ್ಪುತ್ತೇನೆ`,
    terms: `ನಿಯಮಗಳು ಮತ್ತು ಷರತ್ತುಗಳು`,
    loggedOutWarning: `ಹಿಂದೆ ಕಾನ್ಫಿಗರ್ ಮಾಡಿದ ಸಾಧನದಿಂದ ನಿಮ್ಮನ್ನು ಲಾಗ್ out ಟ್ ಮಾಡಲಾಗುತ್ತದೆ`,
    unableToVerifyMobile: `ಮೊಬೈಲ್ ಸಂಖ್ಯೆಯನ್ನು ಪರಿಶೀಲಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ, ದಯವಿಟ್ಟು ನಂತರ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ`,
    invalidOtp: `ಅಮಾನ್ಯ Otp`,
    selectLanguage: `ನಿಮ್ಮ ಆದ್ಯತೆಯ ಭಾಷೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ`,
    getStarted: `ರೋಗಿಯ ನೋಂದಾಯಿತ ಮೊಬೈಲ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ`,
    waitingForAutoReadOTP: `OTP ಅನ್ನು ಸ್ವಯಂ ಓದಲು ನಿರೀಕ್ಷಿಸಲಾಗುತ್ತಿದೆ`,

    /**Subscription screen */
    payLater: `ನಂತರ ಪಾವತಿಸಿ`,
    canNotSubscribe: `ಕ್ಷಮಿಸಿ, ಇದೀಗ ನಾವು ನಿಮ್ಮನ್ನು ಚಂದಾದಾರರಾಗಲು ಸಾಧ್ಯವಿಲ್ಲ. ದಯವಿಟ್ಟು ನಂತರ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ`,
    noPackage: `ಯಾವುದೇ ಪ್ಯಾಕೇಜುಗಳು ಲಭ್ಯವಿಲ್ಲ`,
    oneTimeFee: `ಒಂದು ಬಾರಿ ಶುಲ್ಕ`,
    monthlyFee: `ಮಾಸಿಕ ನವೀಕರಣ ಶುಲ್ಕ`,
    choosePackage: `ಆರೈಕೆ ಪ್ಯಾಕೇಜ್ ಆಯ್ಕೆಮಾಡಿ`,

    /**Patient registration screen */
    patientRegistered: `ರೋಗಿ (ಗಳು) ಯಶಸ್ವಿಯಾಗಿ ನೋಂದಾಯಿಸಲಾಗಿದೆ.`,
    enterAllFields: `ದಯವಿಟ್ಟು ಎಲ್ಲಾ ಕಡ್ಡಾಯ ಕ್ಷೇತ್ರಗಳನ್ನು ನಮೂದಿಸಿ`,
    registerPatient: `ರೋಗಿಯನ್ನು (ಗಳನ್ನು) ನೋಂದಾಯಿಸಿ`,
    patientDetails: `ರೋಗಿಯ ವಿವರಗಳನ್ನು ಒದಗಿಸಿ`,
    addPatient: `ಮತ್ತೊಂದು ರೋಗಿಯನ್ನು ಸೇರಿಸಿ`,
    register: `ನೋಂದಣಿ`,
    registerLater: `ಆನಂತರ ನೊಂದಾಯಿಸಿಕೊಳ್ಳಿ`,
    tellUs: `ನಿನ್ನ ಬಗ್ಗೆ ನಮಗೆ ತಿಳಿಸು`,
    otherPatientRegister: `ನೀವು ನಂತರ ಇತರ ರೋಗಿಗಳನ್ನು ನೋಂದಾಯಿಸಲು ಸಾಧ್ಯವಾಗುತ್ತದೆ`,

    /**List Patient screen */
    verified: `ಪರಿಶೀಲಿಸಲಾಗಿದೆ`,
    verify: 'ಪರಿಶೀಲಿಸಿ',
    enterPatientId: `ಬಳಕೆದಾರರನ್ನು ಪರಿಶೀಲಿಸಲು ದಯವಿಟ್ಟು ಆಸ್ಪತ್ರೆಯ ರೋಗಿಯ ಐಡಿಯನ್ನು ನಮೂದಿಸಿ.`,
    enterPatientCode: `ಆಸ್ಪತ್ರೆಯ ರೋಗಿಗಳ ಕೋಡ್ ನಮೂದಿಸಿ`,
    dob: `ಹುಟ್ತಿದ ದಿನ`,
    gender: `ಲಿಂಗ`,
    mobile: `ಮೊಬೈಲ್`,
    hospitalPatientCode: `ಆಸ್ಪತ್ರೆ ರೋಗಿಗಳ ಕೋಡ್`,
    enterPatientCodeToVerify: `ಪರಿಶೀಲಿಸಲು ಮತ್ತು ಸೇರಿಸಲು ದಯವಿಟ್ಟು ರೋಗಿಯ ಕೋಡ್ ನಮೂದಿಸಿ`,
    callHospitalNote: `ಸೂಚನೆ: ನಿಮ್ಮ ರೋಗಿಯ ಕೋಡ್ ನಿಮಗೆ ತಿಳಿದಿಲ್ಲದಿದ್ದರೆ, ದಯವಿಟ್ಟು ಸಹಾಯಕ್ಕಾಗಿ ಆಸ್ಪತ್ರೆಗೆ ಕರೆ ಮಾಡಿ.`,
    verifiedToContinue: `ಮುಂದುವರೆಯಲು ಕನಿಷ್ಠ ಒಬ್ಬ ರೋಗಿಯನ್ನು ಪರಿಶೀಲಿಸಬೇಕು.`,

    /**Home screen */
    subscribeFirst: `ದಯವಿಟ್ಟು ಮೊದಲು ಚಂದಾದಾರರಾಗಿ`,
    medications: `ನನ್ನ ಆರೋಗ್ಯ ದಾಖಲೆಗಳು`,
    myHealthRecords: `ನನ್ನ ಆರೋಗ್ಯ ದಾಖಲೆಗಳು`,
    addVitals: `ನನ್ನ ಜೀವಾಣುಗಳು`,
    myAppts: `ನನ್ನ APPOINTMENTS`,
    scanPay: `ಸ್ಕ್ಯಾನ್ ಮತ್ತು ಪೇ`,
    callDoctor: `ವೈದ್ಯರನ್ನು ಕರೆ ಮಾಡಿ`,
    virtualLibrary: `ಇ-ಲೈಬ್ರರಿ`,
    billsAndPayments: `ಬಿಲ್‌ಗಳು & ಪಾವತಿಗಳು`,

    /**Verify user and complete profile screen */
    verificationSuccess: `ಬಳಕೆದಾರರ ಪರಿಶೀಲನೆ ಯಶಸ್ವಿಯಾಗಿದೆ.`,
    unableToVerifyUser: `ಬಳಕೆದಾರರನ್ನು ಪರಿಶೀಲಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ. ದಯವಿಟ್ಟು ನಂತರ ಪ್ರಯತ್ನಿಸಿ.`,
    unableToUpdateProfile: `ಪ್ರೊಫೈಲ್ ಅನ್ನು ನವೀಕರಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ. ದಯವಿಟ್ಟು ನಂತರ ಪ್ರಯತ್ನಿಸಿ.`,

    /**Add Vitals screen */
    canNotSaveVitals: `ಪ್ರಮುಖ ಮೌಲ್ಯಗಳನ್ನು ಮೂರು ಅಂಕೆಗಳಿಗಿಂತ ಹೆಚ್ಚು ಉಳಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ. ದಯವಿಟ್ಟು ಸರಿಪಡಿಸಿ ಮತ್ತು ಮತ್ತೆ ಸಲ್ಲಿಸಲು ಪ್ರಯತ್ನಿಸಿ.`,
    bloodPressure: `Blood Pressure`,
    noVitalsConfigured: `ಆಸ್ಪತ್ರೆಗೆ ಯಾವುದೇ ಜೀವಕೋಶಗಳನ್ನು ಕಾನ್ಫಿಗರ್ ಮಾಡಿಲ್ಲ`,
    back: `ಹಿಂತಿರುಗಿ`,
    next: `ಮುಂದೆ`,
    cancel: `ರದ್ದುಗೊಳಿಸಿ`,
    submit: `ಸಲ್ಲಿಸು`,

    /**Medication screen */
    selectUserToViewMedication: `ಅವರ ation ಷಧಿ ವಿವರಗಳನ್ನು ವೀಕ್ಷಿಸಲು ಬಳಕೆದಾರರನ್ನು ಆಯ್ಕೆ ಮಾಡಿ`,
    frequency: `ಆವರ್ತನ`,
    home: `ಮನೆ`,
    printPrescription: `ಮುದ್ರಣ ಮುದ್ರಣಗಳು`,
    currentMedication: `ಪ್ರಸ್ತುತ ಔಷಧಿಗಳು`,
    currentMedicationCap: `ಪ್ರಸ್ತುತ ವೈದ್ಯಕೀಯ`,
    noRecordFound: `ಯಾವುದೇ ದಾಖಲೆ ಕಂಡುಬಂದಿಲ್ಲ...`,

    /**Bill screen */
    selectUserToScanQR: `QR ಕೋಡ್ ಅನ್ನು ಸ್ಕ್ಯಾನ್ ಮಾಡಲು ದಯವಿಟ್ಟು ಬಳಕೆದಾರರನ್ನು ಆಯ್ಕೆ ಮಾಡಿ`,
    itemName: `ವಸ್ತುವಿನ ಹೆಸರು`,
    qty: `ಕ್ಯೂಟಿ`,
    amount: `ಮೊತ್ತ`,
    viewBill: `ಬಿಲ್ ವಿವರಗಳನ್ನು ವೀಕ್ಷಿಸಿ`,
    billReceived: `ಬಿಲ್ ಸ್ವೀಕರಿಸಲಾಗಿದೆ`,
    payNow: `ಈಗ ಪಾವತಿಸಿ`,
    makePayment: `ಪಾವತಿ ಮಾಡಿ`,
    product: `ಉತ್ಪನ್ನ`,
    quantity: `ಪ್ರಮಾಣ`,
    loadMore: `ಹೆಚ್ಚು ಲೋಡ್ ಮಾಡಿ...`,

    /**Consult doctor screen */
    callPermission: `ಕರೆ ಅನುಮತಿ`,
    allowPhone: `ಫೋನ್ ಮಾಡಲು ಅಪ್ಲಿಕೇಶನ್ ಅನ್ನು ಅನುಮತಿಸಿ`,
    ok: `ಸರಿ`,
    giveCallPermission: `ಫೋನ್ ಮಾಡಲು ನೀವು ಕರೆ ಅನುಮತಿ ನೀಡಬೇಕಾಗಿದೆ.`,
    consultationCharge: `ಸಮಾಲೋಚನೆ ಶುಲ್ಕ`,
    payCall: `ಪಾವತಿಸಿ ಮತ್ತು ಕರೆ ಮಾಡಿ`,
    noConsultationAvailable: `ಯಾವುದೇ ಸಮಾಲೋಚನೆ ಲಭ್ಯವಿಲ್ಲ.`,

    /**Prescription screen */
    noPrescriptionAvailable: `ಯಾವುದೇ ಪ್ರಿಸ್ಕ್ರಿಪ್ಷನ್ ಲಭ್ಯವಿಲ್ಲ`,
    prescriptionHistory: `ಪ್ರಿಸ್ಕ್ರಿಪ್ಷನ್ ಇತಿಹಾಸ`,
    visitDate: `ಭೇಟಿ ದಿನಾಂಕ`,
    diagnosis: `ರೋಗನಿರ್ಣಯ`,
    prescription: `ಪ್ರಿಸ್ಕ್ರಿಪ್ಷನ್`,
    advice: `ಸಲಹೆ`,

    /**Help screen */
    help: `ಸಹಾಯ`,
    contactForHelp: `ನಿಮಗೆ ಸಹಾಯ ಬೇಕಾದರೆ ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ`,
    email: `ಇಮೇಲ್`,
    phoneNumber: `ದೂರವಾಣಿ ಸಂಖ್ಯೆ`,
    address: `ವಿಳಾಸ`,

    /**Sidebar */
    sideBarDashboard: `ಡ್ಯಾಶ್‌ಬೋರ್ಡ್`,
    sideBarregisterPatient: `ರೋಗಿಯನ್ನು ನೋಂದಾಯಿಸಿ`,
    sideLinkDependents: `ಅವಲಂಬಿತರನ್ನು ಲಿಂಕ್ ಮಾಡಿ`,
    sideChangeLanguage: `ಭಾಷೆ ಬದಲಾಯಿಸಿ`,
    sideTerms: `ಬಳಕೆಯ ನಿಯಮಗಳು`,
    sidePrivacyPolicy: `ಗೌಪ್ಯತಾ ನೀತಿ`,
    sideHelp: `ಸಹಾಯ`,
    viewPackages: `ಆರೈಕೆ ಪ್ಯಾಕೇಜ್ ಖರೀದಿಸಿ`,
    tellAFriend: `ಸ್ನೇಹಿತನಿಗೆ ಹೇಳಿ`,
    rateUs: `ನಮಗೆ ರೇಟ್`,

    /**Advice Card */
    viewPdf: `ಹಂಚಿದ ಪಿಡಿಎಫ್ ವೀಕ್ಷಿಸಲು ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ.`,
    documentShared: `ಡಾಕ್ಯುಮೆಂಟ್ ಹಂಚಿಕೊಳ್ಳಲಾಗಿದೆ`,
    viewImage: `ಹಂಚಿದ ಚಿತ್ರವನ್ನು ವೀಕ್ಷಿಸಲು ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ.`,
    viewFile: `ಹಂಚಿದ ಫೈಲ್ ವೀಕ್ಷಿಸಲು ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ.`,
    /**web */
    logout: `ಲಾಗ್ ಔಟ್`,
    continue: 'ಮುಂದುವರಿಸಿ',
    allMembers: `ಎಲ್ಲಾ ಸದಸ್ಯರು`,
    notSubscribed: `ಚಂದಾದಾರರಾಗಿಲ್ಲ`,
    processingPayment: `ಪಾವತಿಯನ್ನು ಪ್ರಕ್ರಿಯೆಗೊಳಿಸಲಾಗುತ್ತಿದೆ`,
    poweredby: `ನಡೆಸುತ್ತಿದೆ`,
    updatesAreAvailable: `ನವೀಕರಣಗಳು ಲಭ್ಯವಿದೆ`,
    refreshToSeeTheChanges: `ಬದಲಾವಣೆಗಳನ್ನು ನೋಡಲು ರಿಫ್ರೆಶ್ ಮಾಡಿ.`,
    webHome: `ಮನೆ`,
    webVitals: `ನನ್ನ ನೇಮಕಾತಿ`,
    webMedications: `ನನ್ನ Ations ಷಧಿಗಳು`,
    webLabTests: `ದಾಖಲೆಗಳು & ಲ್ಯಾಬ್ ಪರೀಕ್ಷೆಗಳು`,
    webDoctorNotes: `ನನ್ನ ದಾಖಲೆ`,
    webBills: `ಬಿಲ್‌ಗಳು ಮತ್ತು ಪಾವತಿಗಳು`,
    subscribeToUnlock: `ಇದನ್ನು ಅನ್ಲಾಕ್ ಮಾಡಲು ಪ್ಯಾಕೇಜ್ಗೆ ಚಂದಾದಾರರಾಗಿ`,
    enterAllFields: `ದಯವಿಟ್ಟು ಎಲ್ಲಾ ಕಡ್ಡಾಯ ಕ್ಷೇತ್ರಗಳನ್ನು ನಮೂದಿಸಿ`,
    completeProfile: `ಸಂಪೂರ್ಣ ವಿವರ`,
    genderSelection: `ಲಿಂಗ ಆಯ್ಕೆ`,
    addressLine1: `ವಿಳಾಸ ಸಾಲು 1`,
    zipcode: `ಜಿಪ್‌ಕೋಡ್`,
    searchByZipcode: `ಪಿನ್‌ಕೋಡ್‌ನಿಂದ ಹುಡುಕಿ`,
    selectValidAddress: `ದಯವಿಟ್ಟು ಮಾನ್ಯವಾದ ವಿಳಾಸವನ್ನು ಆಯ್ಕೆಮಾಡಿ`,
    save: `ಉಳಿಸಿ`,
    needInfoToProvideBestCare: `ಉತ್ತಮ ಆರೈಕೆಯನ್ನು ಒದಗಿಸಲು ನಿಮ್ಮ ಎಲ್ಲಾ ಸಂಪರ್ಕ ಮಾಹಿತಿಗಳು ನಮಗೆ ಬೇಕು`,
    invalidDob: `ದಯವಿಟ್ಟು ಸರಿಯಾದ ಜನ್ಮ ದಿನಾಂಕವನ್ನು ಆಯ್ಕೆಮಾಡಿ`,
    useMyNumber: `ನನ್ನ ಸಂಖ್ಯೆಯನ್ನು ಬಳಸಿ`,
    firstName: `ಮೊದಲ ಹೆಸರು`,
    lastName: `ಕೊನೆಯ ಹೆಸರು`,
    relation: `ಸಂಬಂಧ`,
    registerCap: `ನೋಂದಣಿ`,
    completeVerification: `ಸಂಪೂರ್ಣ ಪರಿಶೀಲನೆ`,
    selectPlan: `ಯೋಜನೆ ಆಯ್ಕೆಮಾಡಿ`,
    subscriptionSuccessful: `ಚಂದಾದಾರಿಕೆ ಯಶಸ್ವಿಯಾಗಿದೆ`,
    useMyDetails: `ನನ್ನ ಸಂಪರ್ಕ ವಿವರಗಳನ್ನು ಬಳಸಿ`,
    oneTimeMembership: `ಒಂದು ಬಾರಿ ಸದಸ್ಯತ್ವ`,
    rs: `ರೂ`,
    close: `ಮುಚ್ಚಿ`,
    viewMorePlans: `ಇನ್ನಷ್ಟು ಯೋಜನೆಗಳನ್ನು ವೀಕ್ಷಿಸಿ`,
    joinCall: `ಕರೆಗೆ ಸೇರಿ`,
    testCall: `ಪರೀಕ್ಷಾ ಕರೆ`,
    novitalsconfigured: `ಆಸ್ಪತ್ರೆಗೆ ಯಾವುದೇ ಜೀವಕೋಶಗಳನ್ನು ಕಾನ್ಫಿಗರ್ ಮಾಡಿಲ್ಲ`,
    selectUserToViewMedication: `ಅವರ ation ಷಧಿ ವಿವರಗಳನ್ನು ವೀಕ್ಷಿಸಲು ಬಳಕೆದಾರರನ್ನು ಆಯ್ಕೆ ಮಾಡಿ`,
    selectUserToViewAppointment: `ಅವರ ಅಪಾಯಿಂಟ್‌ಮೆಂಟ್ ವಿವರಗಳನ್ನು ವೀಕ್ಷಿಸಲು ದಯವಿಟ್ಟು ರೋಗಿಯನ್ನು ಆಯ್ಕೆಮಾಡಿ`,
    formNameStrength: `ರೂಪ / ಹೆಸರು / ಸಾಮರ್ಥ್ಯ`,
    from: `ಇಂದ`,
    to: `ಗೆ`,
    specialInstruction: `ವಿಶೇಷ ಸೂಚನೆ`,
    noMedications: `Ations ಷಧಿಗಳಿಲ್ಲ`,
    accessMedicalRecords: `ವೈದ್ಯಕೀಯ ದಾಖಲೆಗಳು ಮತ್ತು ಕ್ಷೇಮ ಪೋರ್ಟಲ್ ಅನ್ನು ಪ್ರವೇಶಿಸಲು`,
    accessMedicalRecordsUoh: `ಯೂನಿಟ್ ಆಫ್ ಹೋಪ್ ವೈದ್ಯರು ಮತ್ತು ಚಿಕಿತ್ಸಕರ ಜೊತೆಗೆ ನಿಮ್ಮ ಮಗುವನ್ನು ಸಂಪರ್ಕಿಸಲು. ವಯಸ್ಕರಿಗೆ ಅಲ್ಲ.`,


    /**Force update */
    updateToLatest: `ಈ ಅಪ್ಲಿಕೇಶನ್ ಬಳಸಲು, ಇತ್ತೀಚಿನ ಆವೃತ್ತಿಗೆ ನವೀಕರಿಸಿ`,
    updateNow: `ಇದೀಗ ನವೀಕರಿಸಿ`,

    myProfile: `ಸ್ವ ಭೂಮಿಕೆ`,
  },
  tamil: {
    /**Start screen */
    errOccured: `பிழை ஏற்பட்டது`,

    /**Signup screen */
    accessRecords: `மருத்துவ பதிவுகளை அணுகவும் - உங்கள் அன்புக்குரியவர்களின்`,
    mobileNumber: `கைபேசி எண்`,
    enterMobile: `உங்களுடைய கைபேசி எண்ணை உள்ளிடவும்`,
    enterOtp: `OTP ஐ உள்ளிடவும்`,
    resendOtp: `OTP ஐ மீண்டும் அனுப்பு`,
    sendOtp: `OTP அனுப்பவும்`,
    resendOtpByWhatsApp: `WhatsApp மூலம் OTP ஐ மீண்டும் அனுப்பவும்`,
    getOtpByVoiceCall: `தொலைபேசி அழைப்பு மூலம் OTP பெறவும்`,
    agree: `நுழைவதன் மூலம், நான் இதை ஒப்புக்கொள்கிறேன்`,
    terms: `விதிமுறைகள் மற்றும் நிபந்தனைகள்`,
    loggedOutWarning: `முன்பு உள்ளமைக்கப்பட்ட சாதனத்திலிருந்து நீங்கள் வெளியேற்றப்படுவீர்கள்`,
    unableToVerifyMobile: `மொபைல் எண்ணை சரிபார்க்க முடியவில்லை, பின்னர் மீண்டும் முயற்சிக்கவும்`,
    invalidOtp: `தவறான Otp`,
    selectLanguage: `உங்கள் விருப்பமான மொழியைத் தேர்ந்தெடுக்கவும்`,
    getStarted: `நோயாளியின் பதிவு செய்யப்பட்ட மொபைல் எண்ணை உள்ளிடவும்`,
    waitingForAutoReadOTP: `OTP படிக்கக் காத்திருக்கிறது `,

    /**Subscription screen */
    payLater: `பின்னர் செலுத்துங்கள்`,
    canNotSubscribe: `மன்னிக்கவும், நாங்கள் இப்போது உங்களை குழுசேர முடியாது. பின்னர் மீண்டும் முயற்சிக்கவும்`,
    noPackage: `தொகுப்புகள் எதுவும் கிடைக்கவில்லை`,
    oneTimeFee: `ஒரு முறை கட்டணம்`,
    monthlyFee: `மாதாந்திர புதுப்பித்தல் கட்டணம்`,
    choosePackage: `பராமரிப்பு தொகுப்பைத் தேர்வுசெய்க`,

    /**Patient registration screen */
    patientRegistered: `நோயாளி (கள்) வெற்றிகரமாக பதிவு செய்யப்பட்டுள்ளனர்.`,
    enterAllFields: `அனைத்து கட்டாய புலங்களையும் உள்ளிடவும்`,
    registerPatient: `நோயாளி (களை) பதிவு செய்யுங்கள்`,
    patientDetails: `நோயாளியின் விவரங்களை வழங்கவும்`,
    addPatient: `மற்றொரு நோயாளியைச் சேர்க்கவும்`,
    register: `பதிவு`,
    registerLater: `பிறகு பதிவு செய்`,
    tellUs: `உங்களைப் பற்றி சொல்லுங்கள்`,
    otherPatientRegister: `நீங்கள் பிற நோயாளிகளை பின்னர் பதிவு செய்ய முடியும்`,

    /**List Patient screen */
    verified: `சரிபார்க்கப்பட்டது`,
    verify: 'சரிபார்க்கவும்',
    enterPatientId: `பயனரைச் சரிபார்க்க மருத்துவமனை நோயாளி ஐடியை உள்ளிடவும்.`,
    enterPatientCode: `மருத்துவமனை நோயாளி குறியீட்டை உள்ளிடவும்`,
    dob: `பிறந்த தேதி`,
    gender: `பாலினம்`,
    mobile: `கைபேசி`,
    hospitalPatientCode: `மருத்துவமனை நோயாளி குறியீடு`,
    enterPatientCodeToVerify: `சரிபார்க்க மற்றும் சேர்க்க நோயாளி குறியீட்டை உள்ளிடவும்`,
    callHospitalNote: `குறிப்பு: உங்கள் நோயாளி குறியீடு உங்களுக்குத் தெரியாவிட்டால், தயவுசெய்து உதவிக்கு மருத்துவமனைக்கு அழைக்கவும்.`,
    verifiedToContinue: `தொடர குறைந்தபட்சம் ஒரு நோயாளியாவது சரிபார்க்கப்பட வேண்டும்.`,

    /**Home screen */
    subscribeFirst: `முதலில் குழுசேரவும்`,
    medications: `எனது சுகாதார பதிவுகள்`,
    myHealthRecords: `எனது சுகாதார பதிவுகள்`,
    addVitals: `என் VITALS`,
    myAppts: `என் APPOINTMENTS`,
    scanPay: `ஸ்கேன் & பே`,
    callDoctor: `டாக்டரை அழைக்கவும்`,
    virtualLibrary: `மின் நூலகம்`,
    billsAndPayments: `பில்கள் & கொடுப்பனவுகள்`,

    /**Verify user and complete profile screen */
    verificationSuccess: `பயனர் சரிபார்ப்பு வெற்றிகரமாக உள்ளது.`,
    unableToVerifyUser: `பயனரைச் சரிபார்க்க முடியவில்லை. தயவுசெய்து பின்னர் முயற்சிக்கவும்.`,
    unableToUpdateProfile: `சுயவிவரத்தைப் புதுப்பிக்க முடியவில்லை. தயவுசெய்து பின்னர் முயற்சிக்கவும்.`,

    /**Add Vitals screen */
    canNotSaveVitals: `முக்கிய மதிப்புகளை மூன்று இலக்கங்களுக்கு மேல் சேமிக்க முடியாது. தயவுசெய்து சரிசெய்து மீண்டும் சமர்ப்பிக்க முயற்சிக்கவும்.`,
    bloodPressure: `Blood Pressure`,
    noVitalsConfigured: `மருத்துவமனைக்கு எந்த உயிரணுக்களும் கட்டமைக்கப்படவில்லை`,
    back: `மீண்டும்`,
    next: `அடுத்தது`,
    cancel: `ரத்துசெய்`,
    submit: `சமர்ப்பிக்கவும்`,

    /**Medication screen */
    selectUserToViewMedication: `அவர்களின் மருந்து விவரங்களைக் காண ஒரு பயனரைத் தேர்ந்தெடுக்கவும்`,
    frequency: `அதிர்வெண்`,
    home: `வீடு`,
    printPrescription: `அச்சிடுக`,
    currentMedication: `தற்போதைய மருந்துகள்`,
    currentMedicationCap: `தற்போதைய மருத்துவம்`,
    noRecordFound: `பதிவு எதுவும் கிடைக்கவில்லை...`,

    /**Bill screen */
    selectUserToScanQR: `QR குறியீட்டை ஸ்கேன் செய்ய பயனரைத் தேர்ந்தெடுக்கவும்`,
    itemName: `பொருளின் பெயர்`,
    qty: `அளவு`,
    amount: `தொகை`,
    viewBill: `தொகை`,
    billReceived: `பில் விவரங்களைக் காண்க`,
    payNow: `இப்போது செலுத்த`,
    makePayment: `பணம் கட்டு`,
    product: `பொருள்`,
    quantity: `அளவு`,
    loadMore: `மேலும் காட்ட...`,

    /**Consult doctor screen */
    callPermission: `அழைப்பு அனுமதி`,
    allowPhone: `தொலைபேசி அழைப்பைச் செய்ய பயன்பாட்டை அனுமதிக்கவும்.`,
    ok: `சரி`,
    giveCallPermission: `தொலைபேசி அழைப்புக்கு நீங்கள் அழைப்பு அனுமதி வழங்க வேண்டும்.`,
    consultationCharge: `ஆலோசனைக் கட்டணம்`,
    payCall: `பணம் செலுத்து & அழைக்கவும்`,
    noConsultationAvailable: `ஆலோசனை எதுவும் கிடைக்கவில்லை.`,

    /**Prescription screen */
    noPrescriptionAvailable: `மருந்து எதுவும் கிடைக்கவில்லை`,
    prescriptionHistory: `மருந்து வரலாறு`,
    visitDate: `வருகை தேதி`,
    diagnosis: `நோய் கண்டறிதல்`,
    prescription: `மருந்து`,
    advice: `ஆலோசனை`,

    /**Help screen */
    help: `உதவி`,
    contactForHelp: `உங்களுக்கு உதவி தேவைப்பட்டால் எங்களைத் தொடர்பு கொள்ளுங்கள்`,
    email: `மின்னஞ்சல்`,
    phoneNumber: `தொலைபேசி எண்`,
    address: `முகவரி`,

    /**Sidebar */
    sideBarDashboard: `டாஷ்போர்டு`,
    sideBarregisterPatient: `பதிவு நோயாளி`,
    sideLinkDependents: `இணைப்பு சார்ந்தவர்கள்`,
    sideChangeLanguage: `மொழியை மாற்றவும்`,
    sideTerms: `பயன்பாட்டு விதிமுறைகளை`,
    sidePrivacyPolicy: `தனியுரிமைக் கொள்கை`,
    sideHelp: `உதவி`,
    viewPackages: `பராமரிப்பு தொகுப்பு வாங்க`,
    tellAFriend: `ஒரு நண்பரகளிடம் சொல்`,
    rateUs: `எங்களை மதிப்பிடு`,

    /**Advice Card */
    viewPdf: `பகிரப்பட்ட பி.டி.எஃப் பார்க்க இங்கே கிளிக் செய்க.`,
    documentShared: `ஆவணம் பகிரப்பட்டது`,
    viewImage: `பகிரப்பட்ட படத்தைக் காண இங்கே கிளிக் செய்க.`,
    viewFile: `பகிரப்பட்ட கோப்பைக் காண இங்கே கிளிக் செய்க.`,
    /**web */
    logout: `வெளியேறு`,
    continue: 'தொடரவும்',
    allMembers: `அனைத்து உறுப்பினர்களும்`,
    notSubscribed: `குழுசேரவில்லை`,
    processingPayment: `கட்டணம் செலுத்துதல்`,
    poweredby: `மூலம் இயக்கப்படுகிறது`,
    updatesAreAvailable: `புதுப்பிப்புகள் கிடைக்கின்றன`,
    refreshToSeeTheChanges: `மாற்றங்களைக் காண புதுப்பிக்கவும்.`,
    webHome: `வீடு`,
    webVitals: `என் சந்திப்பு`,
    webMedications: `என் மருந்துகள்`,
    webLabTests: `ஆவணங்கள் & லேப் சோதனைகள்`,
    webDoctorNotes: `என் பதிவு`,
    webBills: `பில்கள் மற்றும் கொடுப்பனவுகள்`,
    subscribeToUnlock: `இதைத் திறக்க ஒரு தொகுப்புக்கு குழுசேரவும்`,
    enterAllFields: `அனைத்து கட்டாய புலங்களையும் உள்ளிடவும்`,
    completeProfile: `முழுமையான சுயவிவரம்`,
    genderSelection: `பாலின தேர்வு`,
    addressLine1: `முகவரி வரி 1`,
    zipcode: `ஜிப்கோட்`,
    searchByZipcode: `ஜிப்கோட் மூலம் தேடுங்கள்`,
    selectValidAddress: `சரியான முகவரியைத் தேர்ந்தெடுக்கவும்`,
    save: `சேமி`,
    needInfoToProvideBestCare: `சிறந்த கவனிப்பை வழங்க உங்கள் எல்லா தொடர்புத் தகவல்களும் எங்களுக்குத் தேவை`,
    invalidDob: `சரியான பிறந்த தேதியைத் தேர்ந்தெடுக்கவும்`,
    useMyNumber: `எனது எண்ணைப் பயன்படுத்தவும்`,
    firstName: `முதல் பெயர்`,
    lastName: `கடைசி பெயர்`,
    relation: `உறவு`,
    registerCap: `பதிவு`,
    completeVerification: `முழுமையான சரிபார்ப்பு`,
    selectPlan: `திட்டத்தைத் தேர்ந்தெடுக்கவும்`,
    subscriptionSuccessful: `சந்தா வெற்றிகரமாக`,
    useMyDetails: `எனது தொடர்பு விவரங்களைப் பயன்படுத்தவும்`,
    oneTimeMembership: `ஒரு முறை உறுப்பினர்`,
    rs: `ரூ`,
    close: `பக்கத்தை மூடு`,
    viewMorePlans: `மேலும் திட்டங்களைக் காண்க`,
    joinCall: `அழைப்பில் சேரவும்`,
    testCall: `சோதனை அழைப்பு`,
    novitalsconfigured: `மருத்துவமனைக்கு எந்த உயிரணுக்களும் கட்டமைக்கப்படவில்லை`,
    selectUserToViewMedication: `அவர்களின் மருந்து விவரங்களைக் காண ஒரு பயனரைத் தேர்ந்தெடுக்கவும்`,
    selectUserToViewAppointment: `நோயாளியின் சந்திப்பு விவரங்களைப் பார்க்க, ஒரு நோயாளியைத் தேர்ந்தெடுக்கவும்`,
    formNameStrength: `படிவம் / பெயர் / வலிமை`,
    from: `இருந்து`,
    to: `வரை`,
    specialInstruction: `சிறப்பு வழிமுறை`,
    noMedications: `மருந்துகள் இல்லை`,
    accessMedicalRecords: `மருத்துவ பதிவுகள் மற்றும் ஆரோக்கிய போர்ட்டலை அணுக`,
    accessMedicalRecordsUoh: `யூனிட் ஆஃப் ஹோப் மருத்துவர்கள் மற்றும் சிகிச்சையாளர்களுடன் உங்கள் குழந்தையை இணைக்க. பெரியவர்களுக்கு அல்ல.`,


    /**Force update */
    updateToLatest: `இந்த பயன்பாட்டைப் பயன்படுத்த, சமீபத்திய பதிப்பிற்கு புதுப்பிக்கவும்`,
    updateNow: `இப்பொழுது மேம்படுத்து`,

    myProfile: `என் சுயவிவரம்`,
  },
  telugu: {
    /**Start screen */
    errOccured: `లోపం సంభవించింది`,

    /**Signup screen */
    accessRecords: `టెలి సంప్రదింపులు, వైద్య రికార్డులు, ఆరోగ్య వీడియోలు మరియు మరెన్నో యాక్సెస్ చేయడానికి`,
    mobileNumber: `మొబైల్ సంఖ్య`,
    enterMobile: `మీ మొబైల్ నెంబర్ ను ఎంటర్ చేయండి`,
    enterOtp: `OTP ని నమోదు చేయండి`,
    resendOtp: `OTP ను మళ్ళీ పంపు`,
    sendOtp: `OTPని పంపండి`,
    resendOtpByWhatsApp: `వాట్సాప్ ద్వారా ఓటీపీని మళ్లీ పంపండి`,
    getOtpByVoiceCall: `ఫోన్ కాల్ ద్వారా OTP పొందండి`,
    agree: `ప్రవేశించడం ద్వారా, నేను వీటిని అంగీకరిస్తున్నాను`,
    terms: `నిబంధనలు మరియు షరతులు`,
    loggedOutWarning: `మీరు గతంలో కాన్ఫిగర్ చేసిన పరికరం నుండి లాగ్ అవుట్ అవుతారు`,
    unableToVerifyMobile: `మొబైల్ నంబర్‌ను ధృవీకరించడం సాధ్యం కాలేదు, దయచేసి తర్వాత మళ్లీ ప్రయత్నించండి`,
    invalidOtp: `చెల్లని Otp`,
    selectLanguage: `మీ ముందే నిర్వచించిన భాషను ఎంచుకోండి`,
    getStarted: `రోగి యొక్క రిజిస్టర్డ్ మొబైల్ నంబర్‌ను నమోదు చేయండి`,
    waitingForAutoReadOTP: `OTPని స్వయంచాలకంగా చదవడానికి వేచి ఉంది`,

    /**Subscription screen */
    payLater: `తరువాత చెల్లించు`,
    canNotSubscribe: `క్షమించండి, మేము ప్రస్తుతం మిమ్మల్ని చందా చేయలేము. దయచేసి తర్వాత మళ్లీ ప్రయత్నించండి`,
    noPackage: `ప్యాకేజీలు అందుబాటులో లేవు`,
    oneTimeFee: `వన్ టైమ్ ఫీజు`,
    monthlyFee: `నెలవారీ పునరుద్ధరణ రుసుము`,
    choosePackage: `సంరక్షణ ప్యాకేజీని ఎంచుకోండి`,

    /**Patient registration screen */
    patientRegistered: `రోగి (లు) విజయవంతంగా నమోదు చేయబడ్డారు.`,
    enterAllFields: `దయచేసి అన్ని తప్పనిసరి ఫీల్డ్‌లను నమోదు చేయండి`,
    registerPatient: `రోగి (ల) ను నమోదు చేయండి`,
    patientDetails: `రోగి వివరాలను అందించండి`,
    addPatient: `మరొక రోగిని జోడించండి`,
    register: `నమోదు చేయండి`,
    registerLater: `తరువాత నమోదు చేసుకోండి`,
    tellUs: `మీ గురించి చెప్పండి`,
    otherPatientRegister: `మీరు తరువాత ఇతర రోగులను నమోదు చేయగలరు`,

    /**List Patient screen */
    verified: `ధృవీకరించబడింది`,
    verify: 'ధృవీకరించండి',
    enterPatientId: `వినియోగదారుని ధృవీకరించడానికి దయచేసి ఆసుపత్రి రోగి ఐడిని నమోదు చేయండి.`,
    enterPatientCode: `ఆసుపత్రి రోగి కోడ్‌ను నమోదు చేయండి`,
    dob: `పుట్టిన తేది`,
    gender: `లింగం`,
    mobile: `మొబైల్`,
    hospitalPatientCode: `హాస్పిటల్ పేషెంట్ కోడ్`,
    enterPatientCodeToVerify: `ధృవీకరించడానికి మరియు జోడించడానికి దయచేసి రోగి కోడ్‌ను నమోదు చేయండి`,
    callHospitalNote: `గమనిక: మీ రోగి కోడ్ మీకు తెలియకపోతే, దయచేసి సహాయం కోసం ఆసుపత్రికి కాల్ చేయండి.`,
    verifiedToContinue: `కొనసాగించడానికి కనీసం ఒక రోగి ధృవీకరించబడాలి.`,

    /**Home screen */
    subscribeFirst: `దయచేసి మొదట సభ్యత్వాన్ని పొందండి`,
    medications: `నా ఆరోగ్య రికార్డులు`,
    myHealthRecords: `నా ఆరోగ్య రికార్డులు`,
    addVitals: `నా VITALS`,
    myAppts: `నా APPOINTMENTS`,
    scanPay: `స్కాన్ & పే`,
    callDoctor: `డాక్టర్‌కి కాల్ చేయండి`,
    virtualLibrary: `ఇ-లైబ్రరీ`,
    billsAndPayments: `బిల్లులు & చెల్లింపులు`,

    /**Verify user and complete profile screen */
    verificationSuccess: `వినియోగదారు ధృవీకరణ విజయవంతమైంది.`,
    unableToVerifyUser: `వినియోగదారుని ధృవీకరించడం సాధ్యం కాలేదు. దయచేసి తరువాత ప్రయత్నించండి.`,
    unableToUpdateProfile: `ప్రొఫైల్‌ను నవీకరించడం సాధ్యం కాలేదు. దయచేసి తరువాత ప్రయత్నించండి.`,

    /**Add Vitals screen */
    canNotSaveVitals: `ముఖ్యమైన విలువలను మూడు అంకెలకు మించి సేవ్ చేయలేరు. దయచేసి సరిచేసి మళ్ళీ సమర్పించడానికి ప్రయత్నించండి.`,
    bloodPressure: `Blood Pressure`,
    noVitalsConfigured: `ఆసుపత్రి కోసం ప్రాణాధారాలు కాన్ఫిగర్ చేయబడలేదు`,
    back: `తిరిగి`,
    next: `తరువాత`,
    cancel: `రద్దు చేయండి`,
    submit: `సమర్పించండి`,

    /**Medication screen */
    selectUserToViewMedication: `దయచేసి వారి ation షధ వివరాలను చూడటానికి వినియోగదారుని ఎంచుకోండి`,
    frequency: `తరచుదనం`,
    home: `హోమ్`,
    printPrescription: `ప్రింట్ ప్రిస్క్రిప్షన్లు`,
    currentMedication: `ప్రస్తుత మందులు`,
    currentMedicationCap: `ప్రస్తుత వైద్యం`,
    noRecordFound: `రికార్డు దొరకలేదు...`,

    /**Bill screen */
    selectUserToScanQR: `దయచేసి QR కోడ్‌ను స్కాన్ చేయడానికి వినియోగదారుని ఎంచుకోండి`,
    itemName: `వస్తువు పేరు`,
    qty: `పరిమాణం`,
    amount: `మొత్తం`,
    viewBill: `బిల్ వివరాలను చూడండి`,
    billReceived: `బిల్ అందుకుంది`,
    payNow: `ఇప్పుడు చెల్లించండి`,
    makePayment: `చెల్లింపు చేయండి`,
    product: `ఉత్పత్తి`,
    quantity: `పరిమాణం`,
    loadMore: `మరింత లోడ్ చేయండి...`,

    /**Consult doctor screen */
    callPermission: `కాల్ అనుమతి`,
    allowPhone: `ఫోన్ కాల్ చేయడానికి అనువర్తనాన్ని అనుమతించండి.`,
    ok: `అలాగే`,
    giveCallPermission: `ఫోన్ కాల్ చేయడానికి మీరు కాల్ అనుమతి ఇవ్వాలి.`,
    consultationCharge: `సంప్రదింపుల ఛార్జ్`,
    payCall: `చెల్లించండి & కాల్ చేయండి`,
    noConsultationAvailable: `సంప్రదింపులు అందుబాటులో లేవు.`,

    /**Prescription screen */
    noPrescriptionAvailable: `ప్రిస్క్రిప్షన్ అందుబాటులో లేదు`,
    prescriptionHistory: `ప్రిస్క్రిప్షన్ చరిత్ర`,
    visitDate: `సందర్శన తేదీ`,
    diagnosis: `రోగ నిర్ధారణ`,
    prescription: `ప్రిస్క్రిప్షన్`,
    advice: `సలహా`,

    /**Help screen */
    help: `సహాయం`,
    contactForHelp: `మీకు సహాయం అవసరమైతే మమ్మల్ని సంప్రదించండి`,
    email: `ఇమెయిల్`,
    phoneNumber: `ఫోను నంబరు`,
    address: `చిరునామా`,

    /**Sidebar */
    sideBarDashboard: `డాష్బోర్డ్`,
    sideBarregisterPatient: `రోగిని నమోదు చేయండి`,
    sideLinkDependents: `లింక్ డిపెండెంట్లు`,
    sideChangeLanguage: `భాష మార్చు`,
    sideTerms: `ఉపయోగ నిబంధనలు`,
    sidePrivacyPolicy: `గోప్యతా విధానం`,
    sideHelp: `సహాయం`,
    viewPackages: `సంరక్షణ ప్యాకేజీని కొనండి`,
    tellAFriend: `స్నేహితుడికి చెప్పండి`,
    rateUs: `మాకు రేట్`,

    /**Advice Card */
    viewPdf: `షేర్డ్ పిడిఎఫ్ చూడటానికి ఇక్కడ క్లిక్ చేయండి.`,
    documentShared: `పత్రం భాగస్వామ్యం చేయబడింది`,
    viewImage: `భాగస్వామ్య చిత్రాన్ని చూడటానికి ఇక్కడ క్లిక్ చేయండి.`,
    viewFile: `భాగస్వామ్య ఫైల్‌ను చూడటానికి ఇక్కడ క్లిక్ చేయండి.`,
    /**web */
    logout: `లాగ్ అవుట్`,
    continue: 'కొనసాగించండి',
    allMembers: `సభ్యులందరూ`,
    notSubscribed: `సభ్యత్వం పొందలేదు`,
    processingPayment: `చెల్లింపును ప్రాసెస్ చేస్తోంది`,
    poweredby: `ద్వారా ఆధారితం`,
    updatesAreAvailable: `నవీకరణలు అందుబాటులో ఉన్నాయి`,
    refreshToSeeTheChanges: `మార్పులను చూడటానికి రిఫ్రెష్ చేయండి.`,
    webHome: `హోమ్`,
    webVitals: `నా నియామకం`,
    webMedications: `నా మందులు`,
    webLabTests: `పత్రాలు & లాబ్ పరీక్షలు`,
    webDoctorNotes: `నా రికార్డ్`,
    webBills: `బిల్లులు మరియు చెల్లింపులు`,
    subscribeToUnlock: `దీన్ని అన్‌లాక్ చేయడానికి ప్యాకేజీకి సభ్యత్వాన్ని పొందండి`,
    enterAllFields: `దయచేసి అన్ని తప్పనిసరి ఫీల్డ్‌లను నమోదు చేయండి`,
    completeProfile: `పూర్తి ప్రొఫైల్`,
    genderSelection: `లింగ ఎంపిక`,
    addressLine1: `చిరునామా పంక్తి 1`,
    zipcode: `పిన్‌కోడ్`,
    searchByZipcode: `జిప్‌కోడ్ ద్వారా శోధించండి`,
    selectValidAddress: `దయచేసి చెల్లుబాటు అయ్యే చిరునామాను ఎంచుకోండి`,
    save: `సేవ్ చేయండి`,
    needInfoToProvideBestCare: `ఉత్తమ సంరక్షణను అందించడానికి మీ అన్ని సంప్రదింపు సమాచారం మాకు అవసరం`,
    invalidDob: `దయచేసి సరైన పుట్టిన తేదీని ఎంచుకోండి`,
    useMyNumber: `నా నంబర్ ఉపయోగించండి`,
    firstName: `మొదటి పేరు`,
    lastName: `చివరి పేరు`,
    relation: `సంబంధం`,
    registerCap: `రిజిస్టర్`,
    completeVerification: `పూర్తి ధృవీకరణ`,
    selectPlan: `ప్రణాళికను ఎంచుకోండి`,
    subscriptionSuccessful: `సభ్యత్వం విజయవంతమైంది`,
    useMyDetails: `నా సంప్రదింపు వివరాలను ఉపయోగించండి`,
    oneTimeMembership: `ఒక సారి సభ్యత్వం`,
    rs: `రూ`,
    close: `దగ్గరగా`,
    viewMorePlans: `మరిన్ని ప్రణాళికలను చూడండి`,
    joinCall: `కాల్‌లో చేరండి`,
    testCall: `టెస్ట్ కాల్`,
    novitalsconfigured: `ఆసుపత్రి కోసం ప్రాణాధారాలు కాన్ఫిగర్ చేయబడలేదు`,
    selectUserToViewMedication: `దయచేసి వారి ation షధ వివరాలను చూడటానికి వినియోగదారుని ఎంచుకోండి`,
    selectUserToViewAppointment: `దయచేసి వారి అపాయింట్‌మెంట్ వివరాలను వీక్షించడానికి రోగిని ఎంచుకోండి`,
    formNameStrength: `రూపం / పేరు / బలం`,
    from: `నుండి`,
    to: `కు`,
    specialInstruction: `ప్రత్యేక సూచన`,
    noMedications: `మందులు లేవు`,
    accessMedicalRecords: `వైద్య రికార్డులు మరియు వెల్నెస్ పోర్టల్ యాక్సెస్ చేయడానికి`,
    accessMedicalRecordsUoh: `యూనిట్ ఆఫ్ హోప్ డాక్టర్లు మరియు థెరపిస్ట్‌లతో మీ బిడ్డను కనెక్ట్ చేయడానికి. పెద్దలకు కాదు.`,


    /**Force update */
    updateToLatest: `ఈ అనువర్తనాన్ని ఉపయోగించడానికి, తాజా సంస్కరణకు నవీకరించండి`,
    updateNow: `ఇప్పుడే నవీకరించండి`,

    myProfile: `నా జీవన వివరణ`,
  },
};
