import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getSubscribedUserList } from 'components/src/redux/subscriptionreducer/SubscriptionAction';
import { getPatientProfileDetails } from 'components/src/redux/patientprofilereducer/PatientProfileAction';
import { reloadPatientList } from 'components/src/redux/homescreenreducer/HomeScreenAction';
import {
  saveSelectedUser,
  resetReload,
} from 'components/src/redux/homescreenreducer/HomeScreenAction';
import {
  saveUserProfile
} from '../../localstorage/LocalStorage';
import { kinesisUIEvent } from 'components/src/redux/kinesisReducer/kinesisActions';
import { getKinesisWebEventVO } from '../../../utils/KinesisWebUtil';
import { actionType, eventScreenName, eventType } from 'components/src/constants/KinesisConstants';

export default function usePatientListHook(
  setUserList,
  selectedUser,
  setProgress,
  setSelectedIndex,
  setSelectedUser,
  props
) {
  const dispatch = useDispatch();
  const userListInit = useSelector(
    state => state.subscription.subscribedUserRequestInitiated,
  );
  const userList = useSelector(state => state.subscription.data);

  const reloadData = useSelector(state => state.home.reloadData);
  const preSelectedUser = useSelector(state => state.home.selectedUser);

  const hospital = useSelector(state => state.hospital.hospital)

  const preSelectedUserId = Object.keys(preSelectedUser).length
    ? preSelectedUser.appPatientId
    : 0;
  const selectedUserId = Object.keys(selectedUser).length
    ? selectedUser.appPatientId
    : 0;

  const error = useSelector(state => state.home.error);

  const userProfileData = useSelector(state => state.profile.data);

  const profileRequestInit = useSelector(
    state => state.profile.profileRequestInit,
  );

  let reloadPatientDetails = () => setTimeout(() => { // return the timeoutID
    dispatch(reloadPatientList());
  }, 1000);

  let timer = null
  let counter = {}

  //  const reloadPatientDetails =  setTimeout(() => {
  //    dispatch(reloadPatientList());
  //  }, 300)

  useEffect(() => {
    dispatch(saveSelectedUser(selectedUser));
    if (selectedUser && selectedUser.hospitalPatientCode && selectedUser.appPatientId) {
      let componentValue = {};
      componentValue.appPatientId = selectedUser.appPatientId;
      let eventVO = getKinesisWebEventVO(eventScreenName.patientListMenu, eventType.login, "button_patientName", JSON.stringify(componentValue), actionType.onClick, selectedUser.hospitalCode, selectedUser.hospitalPatientCode);
      dispatch(kinesisUIEvent(eventVO, 'web'));
    }
  }, [selectedUser]);

  /*   useEffect(() => {
    if (preSelectedUser && preSelectedUser.appPatientId > 0) {
      if (!preSelectedUser.hospitalPatientCode && hospital.length > 0) {
        // console.log("selectedUserId", preSelectedUser, hospital)
        // console.log("selectedUserId")
        const selectedHospital = hospital.find(d => d.hospitalCd == preSelectedUser.hospitalCode);
        // console.log("selectedUserId1")
        if (selectedHospital.autoSubscribe) {
          // console.log("selectedUserId2")
          if (timer) {
            clearTimeout(timer);
          }
          // console.log("selectedUserId3")
          if (!counter[preSelectedUser.appPatientId] || counter[preSelectedUser.appPatientId] < 8) {
            timer = reloadPatientDetails();
            if (!counter[preSelectedUser.appPatientId]) {
              counter[preSelectedUser.appPatientId] = 0
            }
            counter[preSelectedUser.appPatientId] = counter[preSelectedUser.appPatientId] + 1
          }
        }
        // const userHospitalCode = hospitalCode;

      }
    }

  }, [preSelectedUser]);*/

  useEffect(() => {
    if (!profileRequestInit && Object.keys(userProfileData).length > 0) {
      saveUserProfile(userProfileData);
    }
  }, [profileRequestInit]);

  useEffect(() => {
    if (
      !userListInit &&
      userList &&
      userList.length == 0 &&
      Object.keys(error).length == 0
    ) {
      dispatch(getSubscribedUserList());
      dispatch(getPatientProfileDetails());
    }
    if (!userListInit && userList && userList.length > 0) {
      setUserList(userList);
      // console.log("preSelectedUser",preSelectedUser)
      if (!preSelectedUser || !preSelectedUser.appPatientId) {
        setSelectedUser(userList[0])
        // dispatch(saveSelectedUser(userList[0]));
        setSelectedIndex(0);
        props.setUserSelected(true);
      }
    }
    setProgress(false);
  }, [userListInit]);

  useEffect(() => {
    if (reloadData) {
      /* && !userListInit && Object.keys(error).length == 0 */
      setProgress(true);
      // setSelectedUser(preSelectedUser);
      dispatch(resetReload());
      dispatch(getSubscribedUserList());
      dispatch(getPatientProfileDetails());
    }
  }, [reloadData]);
}
