import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles, makeStyles} from '@material-ui/core/styles';

const useStylesBootstrap = makeStyles(theme => ({
  tooltip: {
    backgroundColor: 'transparent',
    color: theme.palette.error.main,
    fontFamily: 'OpenSans-Bold',
    fontSize:'14px'
  },
}));

function CustomToolTip(props) {
  const classes = props.type === 'error' ? useStylesBootstrap() : null;
  return (
    <Tooltip
      classes={classes}
      title={
        <React.Fragment>
          <em>{props.message}</em>
        </React.Fragment>
      }
      disableFocusListener={true}
      arrow
      disableFocusListener={true}
      disableHoverListener={true}
      disableTouchListener={true}
      placement="bottom"
      open={props.open}>
      {props.children}
    </Tooltip>
  );
}

export default CustomToolTip;
