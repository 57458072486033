import React, {useRef} from 'react';
import { Typography, IconButton, Grid, Link, Button } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CustomTable from '../../common/CustomTable';
import Collapse from '@material-ui/core/Collapse';
import homeStyles from '../Style';
import { useState } from 'react';
import clsx from 'clsx';
import useSideSheetHook from './UseSideSheetHook';
import LabReportCard from './LabReportCard';
import { AppStyle, showTestCallButton } from 'components/AppStyle';
import { getEventsByType, getMeetingUrl } from './eventUtils';
import Pay from '../../packagelist/Pay';
import { languages } from 'components/src/constants/languages';
import IndentCard from './IndentCard';
import AddToCalendar from 'react-add-to-calendar';
import { appointmentTypeFun } from 'components/src/constants/tabConstant';
import DateRangeIcon from '@material-ui/icons/DateRange';
import {HSMEETCHATBOT} from '../../../useAppHooks'
import { getHospitalAdd, getHospitalName, getpatientAdd } from 'components/src/commonutil';
function PackageDetails(packageDesc) {
  const createMarkup = htmlString => ({ __html: htmlString });
  const html = packageDesc.name;
  const num = html.replace(new RegExp('"', 'g'), '');

  return (
    <div>
      <Typography variant="body2" color="textPrimary" component="p">
        <div dangerouslySetInnerHTML={createMarkup(num)} />
      </Typography>
    </div>
  );
}




export default function EventCardDetails(props) {
  const { event, events, user, selectedHospital, patientContactDetails } = props;
  const billDetailColumns = [
    {
      id: 'itemName',
      label: 'Item Name',
      minWidth: 190,
      align: 'left',
      format: value => value.toLocaleString(),
    },
    {
      id: 'quantity',
      label: 'Quantity',
      minWidth: 70,
      align: 'center',
      format: value => value.toLocaleString(),
    },
    {
      id: 'billAmount',
      label: 'Amount',
      minWidth: 70,
      align: 'right',
      format: value => value.toFixed(2),
    },
  ];

  const classes = homeStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [action, setAction] = useState('');
  const [meetingLink, setMeetingLink] = useState('');
  const [testMeetingLink, setTestMeetingLink] = useState('');
  const myRefname= useRef(null);
  const containerRef= useRef(null);
  const [calenderDropDownOpen,setCalenderDropDownOpen]= useState(false);
  const [calenderStyle,setCalenderStyle]= useState({
    'position':'absolute'
  });

  useSideSheetHook(setAction, action);

  const handleOnClick = event => {
    setAction(event);
  };
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  function rebookAppointment(event){
    if(HSMEETCHATBOT){
      HSMEETCHATBOT.visibleBot("getAvailability","reBookAppointment",{'staffCode':event.description.doctorCode},null)
    }
  }
  
  
  const joinCall = url => {
    const data = {
      base_url: '',
      key: '',
      theme: AppStyle.bodybackground,
      verticalPosition: 'bottom',
      horizontalPosition: 'right',
      platform: 'web',
      appType: 'doctor',
      hideChat: false,
      fcmUrl: '',
    };

    var HSMEETCHATBOT = new hsChat(data);
    HSMEETCHATBOT.openVideo(url);
  };

  if (event.eventType == 'PYMSTS') {
    let currencySymbol = event.description.currencySymbol
      ? event.description.currencySymbol
      : 'Rs';
    if (event.description.paymentStatus === 'S') {
      return (
        <Typography
          variant="body2"
          color="textPrimary"
          component="p">{`Payment of ${currencySymbol} ${event.description.billAmount
            } received for ${event.description.orderItemName}`}</Typography>
      );
    }
    if (event.description.paymentStatus === 'P') {
      return (
        <Typography
          variant="body2"
          color="textPrimary"
          component="p">{`Payment of ${currencySymbol} ${event.description.billAmount
            } received for ${event.description.orderItemName
            }.Your transaction code is ${event.description.onlinePymCode
            } .It will reflect shortly on the hospital application.`}</Typography>
      );
    } else {
      return (
        <Typography
          variant="body2"
          color="textPrimary"
          component="p">{`Payment ${currencySymbol} ${event.description.billAmount
            } failed`}</Typography>
      );
    }
  }
  if (event.eventType === 'BIL') {
    const bilEvents = getEventsByType(events, event.eventType);

    let showPayNow = false;
    let { billItems, dueAmount } = event.description;
    billItems = JSON.parse(billItems);
    billItems = Object.values(billItems);
    let currencySymbol = event.description.currencySymbol
      ? event.description?.currencySymbol
      : 'Rs';

    if (
      bilEvents[0]?.description?.appBill === false &&
      bilEvents[0]?.description?.dueAmount <= 0
    ) {
      showPayNow = false;
    } else {
      if (
        bilEvents[0]?.eventId === event?.eventId &&
        user &&
        user?.appPatientId
      ) {
        showPayNow = true;
      }
    }

    return (
      <>
        <Typography variant="body2" color="textPrimary" component="p">{` ${event.description.patientName
          }, you have a bill ${event.description.billCode ? event.description.billCode : 'NDH/1/1'
          } for ${currencySymbol} ${event.description.billAmount
            ? Number(event.description.billAmount).toFixed(2)
            : 0
          }.Total amount to be paid ${currencySymbol} ${Number(
            event?.description?.dueAmount,
          ).toFixed(2)}`}</Typography>
        {showPayNow ? (
          <div dir="rtl">
            <Pay
              billData={event.description}
              billAmt={event.description.dueAmount}
              selectedEvent={user}
              onSucess={props.onSucess}
              paymentBackgroundColor={'grey'}
            />
          </div>
        ) : null}
        <div dir="rtl">
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more">
            <ExpandMoreIcon />
          </IconButton>
        </div>
        <Grid container>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CustomTable columns={billDetailColumns} rows={billItems} />
          </Collapse>
        </Grid>
      </>
    );
  }

  if (event.eventType === 'SUB') {
    return (
      <>
        <Typography variant="body2" color="textPrimary" component="p">
          Congratulations! {event.description.name} you are now subscribed for{' '}
          <Link onClick={handleExpandClick}>
            <span className={classes.congratulationCardText}>
              {event.description.packageName}
            </span>
          </Link>
        </Typography>
        <div dir="rtl">
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more">
            <ExpandMoreIcon />
          </IconButton>
        </div>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <div dir="rtl">
            <Button onClick={() => handleOnClick('SUB')} variant="text">
              <Typography variant="body2" color="textPrimary" component="p">
                <Link onClick={() => handleOnClick('SUB')}>
                  <span className={classes.congratulationCardText}>
                    {languages[props.lang].viewMorePlans}
                  </span>
                </Link>
              </Typography>
            </Button>
          </div>
          {event.description.packageDesc ? (
            <PackageDetails name={event.description.packageDesc} />
          ) : null}
        </Collapse>
      </>
    );
  }
  if (event.eventType == 'VTL') {
    return (
      <>
        {event.description.vitals.map(v => (
          <Typography variant="body2" color="textPrimary" component="p">{`${v.name
            } : ${v.value} ${v.unit ? v.unit : ''}`}</Typography>
        ))}
      </>
    );
  }
  if (event.eventType === 'CLNI') {
    return (
      <Typography variant="body2" color="textPrimary" component="p">
        {`${event.description.doctorName} says: `}
        {event.description.diagnosis ? (
          <p>
            <b>{languages[props.lang].diagnosis}:</b>
            {event.description.diagnosis}
          </p>
        ) : null}
        <p>
          {' '}
          <b>{languages[props.lang].advice}:</b> {event.description.advice}
        </p>
      </Typography>
    );
  }
  if (event.eventType === 'INVSTR' || event.eventType === 'PTDOC') {
    return <LabReportCard event={event} />;
  }
  if (event.eventType === 'APPT') {
    let {  message, url, title, startTime, EndTime, doctorName , appointmentType} = event.description;
    let appointmentTypeStr = appointmentTypeFun(appointmentType);
    let hospitalAdd = selectedHospital ? getHospitalAdd(selectedHospital) : '';
    let patientAdd = patientContactDetails ?  getpatientAdd(patientContactDetails) : '';
    let hospitalName = selectedHospital ? getHospitalName(selectedHospital) : '';
    let icon = { 'calendar-plus-o': 'left' };

    let addToCalenderevent={
      title:  appointmentType ? `${appointmentTypeStr} ${title}  ` : title ,
      description: meetingLink ? appointmentType && doctorName ? `${appointmentTypeStr} ${title} with ${doctorName} at ${hospitalName}  \n Join Link: ${meetingLink}`:'' : appointmentType && doctorName ? `${appointmentTypeStr} ${title} with ${doctorName} at ${hospitalAdd} ` :'',
      location: appointmentType === 'HOME_VISIT' ? patientAdd : meetingLink? meetingLink: hospitalAdd,
      startTime: typeof startTime === 'number'? startTime? new Date(startTime).toISOString() :'' : '',
      endTime: typeof EndTime === 'number'? EndTime? new Date(EndTime).toISOString() : '' : '',
    }
    let _addToCalender = null;
    if(addToCalenderevent.startTime < new Date().toISOString() || title == 'Appointment Cancelled'){
      _addToCalender = null;
    }else{
      _addToCalender = (
        <span ref={myRefname}  className={classes.addToCalender}>
          <AddToCalendar  buttonLabel={''} event={addToCalenderevent} buttonTemplate={icon} />
        </span>
      )
    }
  let showBookAgain = false
    if( (title == 'Appointment Cancelled') || (addToCalenderevent.startTime < new Date().toISOString() && event?.description?.doctorCode)){
      showBookAgain = true
    }

    
    let openMeetingSeparately = true;
    let _joinCall = null;
    let _testCall = null;
   
   
    if (openMeetingSeparately) {
      _joinCall = (
        <a className={classes.joinCall} href={meetingLink} target="_blank">
          {languages[props.lang].joinCall}
        </a>
      );
      _testCall = (
        <a className={classes.testCall} href={testMeetingLink} target="_blank">
          {languages[props.lang].testCall}
        </a>
      );
    } else {
      _joinCall = (
        <Button className={classes.joinCall} onClick={() => joinCall(url)}>
          {languages[props.lang].joinCall}
        </Button>
      );
      _testCall = (
        <Button className={classes.testCall} onClick={() => joinCall(url)}>
          {languages[props.lang].testCall}
        </Button>
      );
    }

    /**Only show join call button if isScheduled is true */
    if (url === '') {
      _joinCall = null;
      _testCall = null;
    }

    if (url && meetingLink === '') {
      getMeetingUrl(url)
        .then(r => {
          setMeetingLink(`${r.data}&language=${props.lang}`);
          setTestMeetingLink(`${r.data}&language=${props.lang}&preview=true`);
        })
        .catch(e => console.log('ee:', e));
    }

    

    return (
      <>
        <div className={classes.apptCardWrapParrent}   
        onClick={(e) => {
          if(calenderDropDownOpen){
            setCalenderDropDownOpen(false)
          }
        }}
        style={{minHeight:calenderDropDownOpen?'150px':'20px'}}
        >
    
          <div style={{height: 'auto'}}>
          <div
          className={classes.apptCardWrap}
          dangerouslySetInnerHTML={{ __html: message }}
          onClick={(e) =>{
            if(!calenderDropDownOpen){
               let top  = e.clientY - e.target.getBoundingClientRect().top + e.target.getBoundingClientRect().height
               let left  = e.clientX - e.target.parentElement.getBoundingClientRect().left - (e.clientX - e.target.getBoundingClientRect().left )
                setCalenderStyle({...calenderStyle,top:top+'px',left:left+'px'})
                  if("SPAN"==e.target?.tagName){
                   // eslint-disable-next-line no-unused-expressions
                   myRefname?.current?.querySelector(".react-add-to-calendar__button")?.click()
                   setTimeout(function(){
                    setCalenderDropDownOpen(true)
                   },0)
                  }
                }
          } }
        />
             <span style={calenderStyle}> {_addToCalender}</span>
          </div>
       
        </div>
        <div dir="ltr" className={classes.callBtnWrap}>
          {showTestCallButton && _testCall}
          {_joinCall}
        </div>
         <Grid container justify="flex-end">
          {showBookAgain ? (<Button
            color="primary"
            variant="contained" endIcon={<DateRangeIcon />}
            onClick={(e) => rebookAppointment(event)}
          >
            Book again
          </Button>) : null}
        </Grid> 
      </>
    );
  }
  if (event.eventType === 'IND') {
    return <IndentCard event={event} lang={props.lang} />;
  }
  return null;
}
