import {tabCdList} from '../../../../../../components/src/constants/tabConstant';
import {getEventDetailsForPatientByTabCd} from '../../../../../../components/src/redux/patienttabeventreducer/PatientTabEventAction';
import moment from 'moment';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {refreshHomescreen} from 'components/src/redux/homescreenreducer/Selector';

export default function useBillDetailHook(
  billCards,
  setBillCards,
  userId,
  setProgress,
  setShowpayNow,
  setPullRefresh,
  pullRefresh,
  subscriptionRenewalEvent,
  setSubscriptionRenewalEvent,
  setUserId,
  setSelectedUser,
  setLang,
  setPatientName,
  setBillEvent,
) {
  const dispatch = useDispatch();

  const patientTabEventDetails = useSelector(
    state => state?.patientTabEvent?.patientTabEvents,
  );
  
  const selUser = useSelector(state => state.home?.selectedUser);

  const showPaymentButton = useSelector(state =>
    state.home?.showPayButton ? state.home?.showPayButton : false,
  );
  const patientTabEventInit = useSelector(
    state => state.patientTabEvent?.PatientTabEventInit,
  );

  const billEvent = useSelector(
    state => state.patientTabEvent?.patientTabEvents,
  );
  const error = useSelector(state => state.patientTabEvent?.error);
  const isRefreshHomescreen = refreshHomescreen(useSelector(state => state));
  const refreshTab = useSelector(state => state.notification.refreshTab);

  useEffect(() => {
    if (patientTabEventDetails && patientTabEventDetails.length) {
      setBillEvent(() => patientTabEventDetails);
    }
  }, [patientTabEventDetails]);

  useEffect(() => {
    if (selUser) {
      setSelectedUser(selUser);
      setLang(selUser?.lang ? selUser.lang : 'eng');
      setUserId(
        selUser && Object.keys(selUser).length ? selUser.appPatientId : 0,
      );
      setPatientName(
        selUser && Object.keys(selUser).length ? selUser.patientName : '',
      );
    }
  }, [selUser]);

  useEffect(() => {
    if (selUser) {
      setSelectedUser(selUser);
      setLang(selUser?.lang ? selUser.lang : 'eng');
      setUserId(
        selUser && Object.keys(selUser).length ? selUser.appPatientId : 0,
      );
      setPatientName(
        selUser && Object.keys(selUser).length ? selUser.patientName : '',
      );
    }
  }, [selUser]);

  useEffect(() => {
    if (userId) {
      dispatch(
        getEventDetailsForPatientByTabCd(userId, tabCdList?.mybill?.code),
      );
    }
  }, [userId]);
  useEffect(() => {
    if (pullRefresh && userId) {
      setPullRefresh(false);
      dispatch(
        getEventDetailsForPatientByTabCd(userId, tabCdList?.mybill?.code),
      );
    }
  }, [pullRefresh,userId]);

  useEffect(() => {
    if (refreshTab && refreshTab?.code?.includes('MYBILL')) {
      setPullRefresh(true);
    }
  }, [refreshTab]);

  useEffect(() => {
    if (isRefreshHomescreen) {
      dispatch(setHomeScreenRefreshFlag(false));
      if (userId && !pullRefresh) {
        setPullRefresh(isRefreshHomescreen);
      }
    }
  }, [isRefreshHomescreen]);

  useEffect(() => {
    if (
      userId &&
      !patientTabEventInit &&
      billEvent &&
      billEvent.length > 0 &&
      billCards.length === 0
    ) {
      setSubscriptionRenewalEvent(filterSubscriptionRenewalEvent(billEvent));
      setBillCards(sortBillEvent(billEvent));
    }
  }, [billEvent]);

useEffect(()=>{
  setProgress(patientTabEventInit);
  if(!patientTabEventInit)
  {
    setBillEvent([]);
  }
},[]);

  useEffect(() => {
    if (patientTabEventDetails && patientTabEventDetails.length) {
      setBillEvent(patientTabEventDetails);
    }
  }, [patientTabEventInit, patientTabEventDetails]);

  useEffect(() => {
    setShowpayNow(showPaymentButton);
  }, [showPaymentButton]);

  const filterSubscriptionRenewalEvent = eventList => {
    let renewalEvent = eventList.filter(e => {
      return e.eventType === 'SUBR';
    });

    return renewalEvent ? renewalEvent : [];
  };

  const sortBillEvent = feedsList => {
    let newFeedList = [];
    feedsList.map(d => {
      let x = d;
      x.eventTime = d.description.billedTs
        ? moment(d.description.billedTs)
        : d.modifiedTs
        ? moment(d.modifiedTs)
        : moment(d.createdTs);
      newFeedList.push(x);
    });
    newFeedList.sort((a, b) => b.eventTime - a.eventTime);
    return newFeedList;
  };
}
