import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'components/src/redux/signupreducer/SignUpAction';
import { clearLocalStorage } from '../localstorage/LocalStorage';
import { prefix } from '../../AppNavigator';
import { getUserProfile } from '../localstorage/LocalStorage';

const useMenubarHook = (userLogout, setUserName, history) => {
  const dispatch = useDispatch();
  let userProfile = getUserProfile();
  const profileRequestInit = useSelector(
    state => state.profile.profileRequestInit,
  );
  const userProfileData = useSelector(state => state.profile.data);

  useEffect(() => {
    if (!profileRequestInit && Object.keys(userProfileData).length > 0) {
      userProfile = userProfileData;
    }
  }, [profileRequestInit]);

  useEffect(() => {
    if (userProfile) {
      setUserName(userProfile.patientNm);
    }
  }, [userProfile]);

  useEffect(() => {
    if (userLogout) {
      dispatch(logout(history, `${prefix}/`));
      // console.log('======came to UseMenubarHook.js===>')
      clearLocalStorage();
    }
  }, [userLogout]);
};
export default useMenubarHook;
