import React, {useRef, useState} from 'react';
import {
  Card,
  CardContent,
  Typography,
  Button,
  CardHeader,
  Container,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import UseCheckforSubscriptionHook from './UseCheckforSubscriptionHook';
import ErrorIcon from '@material-ui/icons/Error';

export default function CheckforSubscription(props) {
  const [error, setError] = useState(false);

  UseCheckforSubscriptionHook(setError);

  if (error) {
    return (
      <Card>
        <CardContent
          style={{textAlign: 'center', fontSize: '18px', color: 'red'}}>
          <div>
            <ErrorIcon style={{marginTop: '8px', display: 'inline-block'}} />{' '}
            We’re sorry we are unable to create your patient record.
            <br />
            Please make sure you are using your registered mobile number.
            <br />
            Please contact the hospital if you need any help.
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader
        title={'We are creating your record '}
        style={{textAlign: 'center'}}>
        {' '}
      </CardHeader>
      <CardContent style={{textAlign: 'center'}}>
        <div style={{marginBottom: '30px'}}>Please Wait... </div>
        <CircularProgress thickness={4} />
      </CardContent>
    </Card>
  );
}
