import AWS from 'aws-sdk';
import React from 'react';

function getS3Object(accessKey, secretKey) {
  const s3 = new AWS.S3({
    accessKeyId: accessKey,
    secretAccessKey: secretKey,
  });
  return s3;
}

export function getSignedUrl(path, bucketName, accessKey, secretKey) {
  const s3 = getS3Object(accessKey, secretKey);
  var params = { Bucket: bucketName, Key: path };
  var url = s3.getSignedUrl('getObject', params);
  return url;
}

export function getImageFromS3(path, bucketName, accessKey, secretKey) {
  try {
    const s3 = getS3Object(accessKey, secretKey);
    return new Promise((resolve, reject) => {
      s3.getObject(
        {
          Bucket: bucketName,
          Key: path,
        },
        (err, data) => {
          if (err) {
            reject('Error Getting Image Data : ', err);
          } else {
            resolve(
              `data:${data.ContentType};base64,${data.Body.toString('base64')}`,
            );
          }
        },
      );
    });
  } catch (error) {
    console.log('error occured at downloadImageFromS3=>', error);
  }
}

export function downloadFileFromS3(path, bucketName, accessKey, secretKey) {
  try {
    const s3 = getS3Object(accessKey, secretKey);
    return new Promise((resolve, reject) => {
      s3.getObject(
        {
          Bucket: bucketName,
          Key: path,
        },
        (err, data) => {
          if (err) {
            reject('Error Getting Image Data : ', err);
          } else {
            resolve(data.Body);
          }
        },
      );
    });
  } catch (error) {
    console.log('error occured at downloadImageFromS3=>', error);
  }
}
