import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getSubscribedUserList } from 'components/src/redux/subscriptionreducer/SubscriptionAction';
import { reloadPatientList } from 'components/src/redux/homescreenreducer/HomeScreenAction';
import {
    saveSelectedUser,
    resetReload,
} from 'components/src/redux/homescreenreducer/HomeScreenAction';
// import { reloadPatientList } from 'components/src/redux/homescreenreducer/HomeScreenAction';

let counter = {}
let timer = null



export default function UseCheckforSubscriptionHook( setError ) {
    const dispatch = useDispatch();

    let reloadPatientDetails = () => setTimeout(() => { // return the timeoutID
        dispatch(getSubscribedUserList());
    }, 1000);

    const userList = useSelector(state => state.subscription.data);


    const preSelectedUser = useSelector(state => state.home.selectedUser);
    const hospital = useSelector(state => state.hospital.hospital)

    useEffect(() => {
        if (!preSelectedUser.hospitalPatientCode && hospital.length > 0) {
            const newUser = userList.find(d => d.appPatientId == preSelectedUser.appPatientId);
            if (newUser?.hospitalPatientCode) {
               
                // dispatch(saveSelectedUser(newUser));
                dispatch(reloadPatientList(true))
            } else {
                const selectedHospital = hospital.find(d => d?.hospitalCd == preSelectedUser?.hospitalCode);
                
                if (selectedHospital.autoSubscribe) {
                    // console.log("333333")
                    if (timer) {
                        clearTimeout(timer);
                    }
                    console.log("selectedUserId3", preSelectedUser.appPatientId, counter)
                    if (!counter[preSelectedUser.appPatientId]) {
                        counter[preSelectedUser.appPatientId] = 0
                    }
                    if (counter[preSelectedUser.appPatientId] < 8) {
                        console.log("5555")
                        timer = reloadPatientDetails();

                        console.log("666666")

                        console.log(counter[preSelectedUser.appPatientId])
                        counter[preSelectedUser.appPatientId] = counter[preSelectedUser.appPatientId] + 1
                    }else{
                        setError(true)
                    }
                }
            }
        }
    }, [userList])
}