/*make the changes in ui/MyHSApp/packages/customer_setup/ for respective customer hospital cd
  the respective file is redux/config.js*/
export const AppStyle = {
  headerBackgroundColor: '#f5f5f5',
  primaryColor: '#EB6C35',
  secondaryColor: '#f1873b',
  bodybackground: 'linear-gradient(to bottom, #f1873b, #e13530)',
  header: 'linear-gradient(to bottom, #f1873b, #e13530)',
  labelColor: '#454545',
  loginMessageFontSize: 25,
  lottie: {
    width: 100,
    height: 100,
  },
};

export const mobileAppBackground = ['#f1873b', '#e13530'];

export const virtualLibraryBackground = ['#9eb529', '#4a7fc5'];
export const appThemeColorShades = {
  darker: '#c23530',
  darkest: '#a23530',
  dark: '#e23530',
  light: '#f54d48',
};


export const loginMessage = `Get started
absolutely free`

export const showPoweredBy = false;

export const appStoreAppId = '1582948513';

export const showTestCallButton = true;

export const showMedicationTab = true;