import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getAuthToken,
  getUserAuthToken,
} from 'components/src/redux/signupreducer/SignUpAction';
import {
  getHospitalAppUrl
} from 'components/src/redux/hospitalreducer/HospitalAction';
import { resetVerifyOtpData } from 'components/src/redux/otpreducer/OTPAction';
import Moment from 'moment';
import {
  getSavedUserAuthToken,
  saveUserAuthToken,
} from '../localstorage/LocalStorage';
import { prefix } from '../../AppNavigator';

const useSignupHook = (setOpenBackdrop, setErrorMsg, setAppUrl, history) => {
  const [login, setLogin] = useState(false);
  const dispatch = useDispatch();

  const isAppAuthIntiated = useSelector(
    state => state.signup.authRequestInitiated,
  );
  const appAuthToken = useSelector(state => state.signup.authToken);
  const isAppAuthenticated = Object.keys(appAuthToken).length > 0;

  const appAuthError = useSelector(state => state.signup.error);
  const otpData = useSelector(state => state.otp.verifyOtpData);
  const appUrl = useSelector(state => state.hospital.appUrl);

  const isOtpVerified = useSelector(state =>
    Object.keys(state.otp.verifyOtpData).length > 0 ? true : false,
  );

  const userAuthInit = useSelector(
    state => state.signup.signupRequestInitiated,
  );

  const userAuthToken = useSelector(state => state.signup.userAuthToken);

  useEffect(() => {
    setAppUrl(appUrl);
  }, [appUrl]);

  useEffect(() => {
    if (!isAppAuthenticated) {
      dispatch(getAuthToken(true));
    } else {
      dispatch(getHospitalAppUrl());
    }
  }, [isAppAuthenticated]);

  useEffect(() => {
    setOpenBackdrop(isAppAuthIntiated);
  }, [isAppAuthIntiated]);

  useEffect(() => {
    if (Object.keys(appAuthError).length > 0) {
      setErrorMsg(appAuthError.message);
    }
  }, [appAuthError]);

  useEffect(() => {
    if (isOtpVerified && !login) {
      const data = {};
      const date = Moment(otpData.createdTs);
      data.username = otpData.username;
      data.password = otpData.internalUserId + date.valueOf();
      dispatch(getUserAuthToken(data));
      setLogin(true);
      dispatch(resetVerifyOtpData());
    }
  }, [isOtpVerified]);

  useEffect(() => {
    setOpenBackdrop(userAuthInit);
    if (Object.keys(userAuthToken).length && !getSavedUserAuthToken()) {
      saveUserAuthToken(userAuthToken);
      window.location.href = prefix + '/';
    }
  }, [userAuthInit]);

};

export default useSignupHook;
