import React from 'react';
import CheckCircle from '@material-ui/icons/CheckCircle';
import HourglassFull from '@material-ui/icons/HourglassFull';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import Error from '@material-ui/icons/Error';
import { red } from '@material-ui/core/colors';
import { yellow } from '@material-ui/core/colors';

export const statusIcon = {
  S: (
    <CheckCircle style={{ color: '#6ab187', fontSize: 65, marginLeft: '38%' }} />
  ),
  F: <Error style={{ color: red[500], fontSize: 65, marginLeft: '38%' }} />,
  P: (
    <HourglassFull
      style={{ color: yellow[500], fontSize: 65, marginLeft: '38%' }}
    />
  ),
  U: (
    <FiberNewIcon
      style={{ color: yellow[500], fontSize: 65, marginLeft: '38%' }}
    />
  ),
};

export const cardHeaders = {
  REG: {
    title: 'Registration Successful',
  },
  BIL: {
    title: 'Bill received',
  },
  SUB: {
    title: 'Subscription',
  },
  PYMSTS: {
    title: 'Payment Status',
  },
  SUBR: {
    title: 'Subscription Renewal',
  },
  CLNI: {
    title: 'Doctor Advice',
  },
  VTL: {
    title: 'New Vitals added',
  },
  CURRENTMED: {
    title: 'Current Medications',
  },
  PREVMED: {
    title: 'Previous Medications',
  },
  INVSTR: {
    title: 'Lab Report',
  },
  APPT: {
    title: 'Appointment Confirmed',
  },
  PTDOC: {
    title: 'Document Shared',
  }
};
