import {showErrorToast} from './errorhandler/ErrorHandleReducer';

export default function callAPIMiddleware({dispatch, getState}) {
  return next => action => {
    const {types, callAPI} = action;
    if (!types) {
      // Normal action: pass it on
      return next(action);
    }
    if (
      !Array.isArray(types) ||
      types.length !== 3 ||
      !types.every(type => typeof type === 'string')
    ) {
      throw new Error('Expected an array of three string types.');
    }
    if (typeof callAPI !== 'function') {
      throw new Error('Expected callAPI to be a function.');
    }
    if (getState().error.info && getState().error.info.statusCode == 401) {
      return;
    }
    const [requestType, successType, failureType] = types;
    dispatch(
      Object.assign(
        {},
        {
          type: requestType,
        },
      ),
    );
    return callAPI()
      .then(response => {
        dispatch(Object.assign({}, {data: response.data, type: successType}));
      })
      .catch(error => {
        dispatch(
          Object.assign(
            {},
            {
              data: error,
              type: failureType,
            },
          ),
        );
        dispatch(showErrorToast(error));
      });
  };
}
