import { QuickEventConstant } from '../ReduxConstant';
import { authorizedHttp } from '../axiosApi';

export function getQuickActionDetailsForPatient(appPatientId, tabCode) {
  return {
    // Types of actions to emit before and after
    types: [
      QuickEventConstant.QUICK_EVENT_INIT,
      QuickEventConstant.QUICK_EVENT_SUCCESS,
      QuickEventConstant.QUICK_EVENT_FAILURE,
    ],

    callAPI: () =>
      authorizedHttp.get(`api/getQuickActionDetailsForPatient?appPatientId=${appPatientId}`),
  };
}

