// Import the functions you need from the SDKs you need

import { loginConstants } from 'components/src/redux/config';

// TODO: Add SDKs for Firebase products that you want to use
const firebase = require('firebase/app').default;
require('firebase/analytics');
require('firebase/remote-config');
import {
  getRefernce
} from './component/localstorage/LocalStorage';
export let remoteConfig = undefined;

export default class WebAnalyticsUtils {
  static chatbotApiVersion;
  static isAppIntialized = false ;
  static pendingEvents = [];

  static addpendingEvents(eventName , parameters){
    WebAnalyticsUtils.pendingEvents.push({eventName,parameters})
  }

  static loadPendingEvents(){
    if(WebAnalyticsUtils.pendingEvents && WebAnalyticsUtils.pendingEvents.length > 0){
      WebAnalyticsUtils.pendingEvents.forEach(d => {
        firebase.analytics().logEvent(d.eventName, d.parameters);
      })
    }
    WebAnalyticsUtils.pendingEvents = []
  }

  static  getReferenceLink(qp){
    if(qp && qp.reference){
      return qp.reference
    }
  }


  static intializeAnalytics(firebaseConfig) {
    const app = firebase.initializeApp(firebaseConfig);
    WebAnalyticsUtils.isAppIntialized = true
    remoteConfig = firebase.remoteConfig(app);
    WebAnalyticsUtils.loadPendingEvents();
    remoteConfig.settings = {
      minimumFetchIntervalMillis: -1,
    };
    WebAnalyticsUtils.getChatbotVersion().then(botApiVersion => {
      WebAnalyticsUtils.chatbotApiVersion = botApiVersion;
    });
  }

  static getChatbotApiVersion() {
    return WebAnalyticsUtils.chatbotApiVersion;
  }

  static async trackEvent(eventtName, data) {
    if(getRefernce()){
      data['reference'] = getRefernce()
    }
    firebase.analytics().logEvent(`${eventtName}`, {
      ...data
    });
  }

  static async getChatbotVersion() {
    if (remoteConfig) {
      let appId = '';
      appId = loginConstants.BUNDLE_ID.split('.')[3]
      remoteConfig.defaultConfig = {};
      return remoteConfig
        .fetchAndActivate()
        .then(activated => {
          return remoteConfig.getAll();
        })
        .then(remoteFlags => {
          const newFlags = {
          };

          for (const [key, config] of Object.entries(remoteFlags)) {
            newFlags[key] = config.asString();
          }
          const chatbotVersionJSON = JSON.parse(
            newFlags[`chatbot_api_version_${appId}`]
          );
          return chatbotVersionJSON.web ? chatbotVersionJSON.web : undefined;
        })
        .catch(error => console.error('getChatbotVersion===>', error));
    }
    else {
      console.log('remote config value====>', undefined);
    }
  }

  /**On login */
  static async onLogin(user) {
    console.log("deviceInfo", JSON.stringify(user))
    firebase.analytics().setUserProperties({ 'device_info': JSON.stringify(user) })
  }

  static contentViewAnalyticsEvent(url) {
    firebase.analytics().logEvent("ViewContentShared", { data: url });
  }

  static onPaymentFail(data) {
    firebase.analytics().logEvent('payment_failure', {
      ...data
    });
  }

  /**Custom event */
  static onPaymentSuccessful(data) {
    firebase.analytics().logEvent('payment_success', {
      ...data
    });
  }

  /**Track Screen */
  static trackScreen(screen) {
    firebase.analytics().logEvent('screen_view', {
      firebase_screen: screen,
      firebase_screen_class: screen,
    });
  }


  /* Query Parameter Json Object */
  static getCampaiganDetails(qp){
    console.log("getCampaiganDetails=====>",qp)
    if(qp && qp.utm_source  && qp.utm_medium && qp.utm_campaign ){
      let parameters = {
        source:qp.utm_source,
        medium:qp.utm_medium,
        campaign:qp.utm_campaign
      }
      if(qp.utm_term){
        parameters.term = qp.utm_term
      }
      if(qp.utm_content){
        parameters.content = qp.utm_content
      }
      console.log("Values=====>",qp)
      return parameters
    }
    return null

  }

  /* */
  static trackCampaiganDetails(parameters) {
    if(parameters){
      console.log("trackCampaiganDetails=====>",parameters)
      if(WebAnalyticsUtils.isAppIntialized){
    firebase.analytics().logEvent('campaign_details', parameters);
  }else{
    WebAnalyticsUtils.addpendingEvents('campaign_details', parameters)
  }
    }
  }


  /**On logout */
  static async logout() {
    // await firebaseWebAnalytics().resetAnalyticsData();
  }

  

}

