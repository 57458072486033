import {contentConstant} from './../ReduxConstant';

const initialState = {
  publicContent: [],
  publicContentInit: false,
  error: {},
  publicContentByTag: []
};

export default function homescreenDetails(state = initialState, action) {
  switch (action.type) {
    case contentConstant.GET_PUBLIC_CONTENT_INIT:
      return {
        ...state,
        publicContentInit: true,
        publicContent:[],
        error: {},
      };
    case contentConstant.GET_PUBLIC_CONTENT_SUCCESS:{
        return {
        ...state,
        publicContent: action.data.data,
        publicContentInit: false,
        error: {},
      };
    }
    case contentConstant.GET_PUBLIC_CONTENT_FAILURE:
      return {
        ...state,
        publicContentInit: false,
        error: action.data,
        publicContent:[],
      };
    case contentConstant.GET_PUBLIC_CONTENT_BY_TAG_INIT:
      return {
        ...state,
        publicContentInit: true,
        publicContentByTag: [],
        error: {},
      }
    case contentConstant.GET_PUBLIC_CONTENT_BY_TAG_SUCCESS:
      return {
        ...state,
        publicContentByTag: action.data.data,
        publicContentInit: false,
        error: {}
      };
    case contentConstant.GET_PUBLIC_CONTENT_BY_TAG_FAILURE:
      return {
        ...state,
        publicContentByTag: [],
        publicContentInit: false,
        error: action.data
      };

    
    default:
      return state;
  }
}
