export const tabCdList = {
  myappt: {code: 'MYAPPT', screen: 'MYAPPT'},
  mybill: {code: 'MYBILL', screen: 'BILL'},
  myhealth: {code: 'MYHEALTH', screen: 'MEDICATION'},
  patientlist: {code: 'PATIENTLIST', screen: 'HOME'},
  quickaction: {code: 'QUICKACTION', screen: 'HOME'},
  virlib: {code: 'VIRLIB', screen: 'CONTENT'},
};
export const appointmentTypeFun =(appointmentType)=> {
  let appointmentTypeStr = '';

  if(appointmentType === 'FOLLOW_UP'){
    appointmentTypeStr = 'Inperson'
  }else if (appointmentType === 'HOME_VISIT'){
    appointmentTypeStr = 'Home Visit'
  }else if (appointmentType === 'ONLINE_CONSULTATION'){
    appointmentTypeStr = 'Online Consultation'
  }
  return appointmentTypeStr;
}