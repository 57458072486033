import React, {useState, useEffect} from 'react';
import {Grid} from '@material-ui/core';
import CustomTextfield from '../common/CustomTextfield';
import { languages } from 'components/src/constants/languages';

function PatientName(props) {
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();

  useEffect(() => {
    const patientName = {firstName: firstName, lastName: lastName};
    props.setPatientName(patientName);
  }, [firstName, lastName]);

  return (
    <>
      <Grid item xs={6}>
        <CustomTextfield
          name={languages[props.lang].firstName}
          id={'firstName'}
          onTextBlur={setFirstName}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomTextfield
          name={languages[props.lang].lastName}
          id={'lastName'}
          onTextBlur={setLastName}
        />
      </Grid>
    </>
  );
}

export default PatientName;
