import React from 'react';
import useStyles from './Style';
import {Container, Backdrop, CircularProgress} from '@material-ui/core';
import CustomDialog from '../common/CustomDialog';
import Footer from './Footer';

function CustomContainer(props) {
  const classes = useStyles();
  return (
    <Container
      maxWidth={false}
      className={classes.containerStyle}
      style={props.style}>
      <Backdrop className={classes.backdrop} open={props.openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {props.displayDialog ? (
        <CustomDialog
          title={props.dialogTitle}
          content={props.dialogMessage}
          type={props.dialogType}
          onClose={props.onClose}
        />
      ) : null}
      <div>{props.children}</div>
      {/*  <Footer style={props.footerStyle} /> */}
    </Container>
  );
}

export default CustomContainer;
