import React from 'react';
import {Snackbar, SnackbarContent} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './Style';

export default function CustomSnackbar(props) {
  const classes = useStyles();

  const {action, message, open, type} = props;

  let typeClass = '';

  if (type === 'error') {
    typeClass = classes.errors;
  } else if (type === 'warning') {
    typeClass = classes.warnings;
  } else if (type === 'info') {
    typeClass = classes.infos;
  } else if (type === 'success') {
    typeClass = classes.successs;
  }

  return (
    <Snackbar
      open={open}
      onClose={e => action && action()}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}>
      <SnackbarContent
        message={message}
        className={typeClass}
        action={
          <React.Fragment>
            <IconButton
              aria-label="close"
              color="inherit"
              className={classes.close}
              onClick={e => action && action()}>
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }></SnackbarContent>
    </Snackbar>
  );
}
