import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
  getCurrentMedication,
  getPreviousMedication,
} from '../../../../../../components/src/redux/medicationreducer/MedicationAction';

export default function useMedicationTableHook(
  setCurrentMedication,
  setPreviousMedication,
  userId,
  setProgress,
) {
  const dispatch = useDispatch();

  const currentMedInit = useSelector(state => state.medication.currentMedInit);
  const currentMed = useSelector(state => state.medication.currentMed);
  const error = useSelector(state => state.medication.error);
  const previousMedInit = useSelector(
    state => state.medication.previousMedInit,
  );
  const previousMed = useSelector(state => state.medication.previousMed);

  useEffect(() => {
    if (userId) {
      dispatch(getCurrentMedication(userId));
      dispatch(getPreviousMedication(userId));
    }
  }, [userId]);

  useEffect(() => {
    setProgress(currentMedInit);
    if(currentMedInit){
      setCurrentMedication(null);
    }
    if (!currentMedInit && currentMed.length) {
      setCurrentMedication(createRows(currentMed));
    }
  }, [currentMedInit]);

  useEffect(() => {
    setProgress(previousMedInit);
    if(previousMedInit){
      setPreviousMedication(null);
    }
    if (!previousMedInit && previousMed.length) {
      setPreviousMedication(createRows(previousMed));
    }
  }, [previousMedInit]);

  const createRows = currentMed => {
    const rows = [];
    currentMed.forEach(element => {
      let row = createData(
        element.pharmaBrandNm,
        element.frequency,
        element.startDt,
        element.endDt,
        element.comments,
      );
      rows.push(row);
    });
    return rows;
  };

  const createData = (brand, frequency, startDt, endDt, comments) => {
    return {brand, frequency, startDt, endDt, comments};
  };
}
