/*make the changes in ui/MyHSApp/packages/customer_setup/ for respective customer hospital cd
  the respective file is redux/config.js*/
export const loginConstants = {
  MOBILE_PATIENT_APP_BASE_URL: 'https://patientapp.hsdevonline.com/HSPatient/',
  CLIENT_ID: "cadweb",
  BUNDLE_ID: 'com.csoft.myhealthscore.cad',
  MOBILE_CLIENT_ID: 'cadmobile'
};

export const paymentConstants = {
  PAYMENT_GATEWAY_KEY: 'rzp_test_NmX38K185mr9Y1',
};

export const googleTrackingId = {
  GA_ID: 'UA-158924043-1',
};

export const termsCondition =
  'https://healthscore.s3.amazonaws.com/policies/MyHSApp_Terms_of_Service.pdf';
export const privacyPolicy =
  'https://healthscore.s3.amazonaws.com/policies/MyHSApp_Privacy_Policy.pdf';

export const App_Title = 'Cadabams Consult';

export const Hosp_Name = 'Cadabams Consult';

export const hsChatbotUrl = 'https://hsbot.hsdevonline.com/index.js';

export const chatbotBaseUrl = 'https://hsdevonline.com';

export const chatbotHospital = 'CAD';

export const envPrefix = 'hs-dev-'

export const subHeading = "accessMedicalRecords"

export const showStaticCard = true

export const slackDevChannel = 'https://hooks.slack.com/services/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'

export const hospitalWebsiteLink = ''