import React, {useState, useEffect} from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/dist/style.css';
import {validateFormInput} from 'components/src/commonutil/CommonUtil';
import CustomToolTip from './CustomToolTip';
import useStyles from './Style';

function PhoneInputComponent(props) {
  const classes = useStyles();

  let phoneNumberContainerStyle = {
    color: 'black',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 55,
    textAlign: 'left',
    marginTop: '6%',
    marginBottom: '6%',
  };
  let phoneNumberInputStyle = {
    height: 55,
    fontFamily: 'OpenSans-Regular',
    fontSize: '20px',
    textAlign: 'left',
  };

  const [phoneNo, setPhoneNo] = useState();
  const [mobileNo, setMobileNo] = useState();
  const [countryCode, setCountryCode] = useState();
  const [phoneNoErr, setPhoneNoErr] = useState();

  useEffect(() => {
    if (props.defaultValue) {
      setPhoneNo(`${props.value.countryCode}${props.value.mobileNumber}`);
    } else {
      setPhoneNo('');
      props.onChange(undefined);
    }
  }, [props.defaultValue]);

  const handleOnChange = (countryCd, mobileNum) => {
    setCountryCode(countryCd);
    setMobileNo(mobileNum);
    setPhoneNoErr('');
    props.onChange(countryCd, mobileNum);
  };

  const handleOnBlur = rawPhoneNo => {
    if (rawPhoneNo) {
      let error = validateFormInput('mobileNumber', rawPhoneNo);
      setPhoneNoErr(error);
    }
  };

  return (
    <div>
      <CustomToolTip open={phoneNoErr ? true : false} message={phoneNoErr}>
        <PhoneInput
          disable={props.disabled}
          readOnly={true}
          defaultCountry={'in'}
          disableAreaCodes={true}
          countryCodeEditable={false}
          inputExtraProps={{
            autoFocus: true,
            required: true,
            disabled: props.disabled,
          }}
          containerStyle={
            props.containerStyle
              ? props.containerStyle
              : phoneNumberContainerStyle
          }
          inputStyle={
            props.inputStyle ? props.inputStyle : phoneNumberInputStyle
          }
          buttonStyle={{left: '0'}}
          onChange={(value, data, event) => {
            setPhoneNo(value);

            let rawPhoneNo = value
              .replace(/[^0-9]+/g, '')
              .slice(data.dialCode.length);
            handleOnChange(data.dialCode, rawPhoneNo);
            if (rawPhoneNo && rawPhoneNo.length) {
              handleOnBlur(rawPhoneNo);
            }
          }}
          value={phoneNo}
          onBlur={(value, data, event) => {
            handleOnBlur(mobileNo);
          }}
          isValid={() => {
            return phoneNoErr ? false : true;
          }}
          disableAreaCodes={true}
        />
      </CustomToolTip>
    </div>
  );
}

export default PhoneInputComponent;
