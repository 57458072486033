import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {getPatientProfileDetails} from 'components/src/redux/patientprofilereducer/PatientProfileAction';
import {
  getSubscribedUserList,
  verifyUser,
} from 'components/src/redux/subscriptionreducer/SubscriptionAction';
import {resetAddAction} from 'components/src/redux/homescreenreducer/HomeScreenAction';
import {saveUserProfile} from '../localstorage/LocalStorage';

const useListPatientHook = (
  patientList,
  setPatientList,
  setProgress,
  cancel,
  hospitalPatientIDData,
  setHospitalPatientIDData,
  regYrPart,
  setRegYrPart,
  patientCdPart,
  setPatientCdPart,
  hasVerifiedUser,
  setHasVerifiedUser,
  hasUnVerifiedUser,
  setHasUnVerifiedUser,
  verifyUserIndex,
  setVerifyUserIndex,
  verifyUserData,
  setVerifyUserData,
  history,
  loginFlow,
) => {
  const dispatch = useDispatch();
  const userProfileData = useSelector(state => state.profile.data);
  const userListInit = useSelector(
    state => state.subscription.subscribedUserRequestInitiated,
  );
  const userList = useSelector(state => state.subscription.data);

  const userVerificationRequestStatus = useSelector(
    state => state.subscription.userVerificationRequestStatus,
  );

  const profileRequestInit = useSelector(
    state => state.profile.profileRequestInit,
  );

  useEffect(() => {
    if (
      userVerificationRequestStatus &&
      userVerificationRequestStatus === 'SUCCESS'
    ) {
      setHospitalPatientIDData([]);
      setPatientCdPart([]);
      setRegYrPart(initialiseRegYrPart(userList));
      dispatch(getSubscribedUserList());
      if (
        !profileRequestInit &&
        (!userProfileData || Object.keys(userProfileData).length === 0 || userProfileData.appPatientId == 0 )
      ) {
        dispatch(getPatientProfileDetails());
      }
    }
  }, [userVerificationRequestStatus]);

  useEffect(() => {
    if (!profileRequestInit && Object.keys(userProfileData).length > 0) {
      saveUserProfile(userProfileData);
    }
  }, [profileRequestInit]);

  useEffect(() => {
    dispatch(getSubscribedUserList());
    dispatch(getPatientProfileDetails());
  }, []);

  useEffect(() => {
    if (!userListInit && userList && userList.length > 0) {
      setPatientList(userList);
      setProgress(false);
      userList.forEach(user => {
        if (user.appPatientId !== 0) {
          if (!hasVerifiedUser) {
            setHasVerifiedUser(true);
          }
        } else {
          if (!hasUnVerifiedUser) {
            setHasUnVerifiedUser(true);
          }
        }
      });
      setRegYrPart(initialiseRegYrPart(userList));
    }
  }, [userListInit]);

  useEffect(() => {
    if (cancel) {
      dispatch(resetAddAction());
    }
  }, [cancel]);

  useEffect(() => {
    if (verifyUserData && Object.keys(verifyUserData).length) {
      dispatch(verifyUser(verifyUserData));
      setVerifyUserData({});
    }
  }, [verifyUserData]);
};

const initialiseRegYrPart = ptList => {
  let regYrSelected = [];
  for (let i = 0; i < ptList.length; i++) {
    regYrSelected[i] = new Date().getFullYear();
  }
  return regYrSelected;
};

export default useListPatientHook;
