import { getUserAuthToken } from '../axiosApi';
import { subscriptionConstant } from './../ReduxConstant';

const initialState = {
  data: [],
  billingPackage: [],
  hasSubscribedUsers: false, // added this flag to avoid flicker in homescreen page when there is no associated patients against the user
  subscribedUserRequestStatus: '',
  subscribedUserRequestInitiated: false,
  saveSubscriptionInitiated: false,
  billingPackageRequestInitiated: false,
  paymentDetails: {},
  paymentDetailsRequestInitiated: false,
  webSubcriptionInitiated: false,
  webSubscriptionError: false,
  error: {},
  userVerificationInitiated: false,
  userVerificationSuccess: false,
  userVerificationRequestStatus: '',
};

export default function subscriptionDetails(state = initialState, action) {
  switch (action.type) {
    case subscriptionConstant.SUBSCRIBED_USER_REQUEST_INITIATED:
      return {
        ...state,
        subscribedUserRequestInitiated: true,
        subscribedUserRequestStatus: 'INITIATED',
        error: {},
      };
    case subscriptionConstant.SUBSCRIBED_USER_LIST_RESET:
      return {
        ...state,
        data: [],
      };
    case subscriptionConstant.SUBSCRIBED_USER_REQUEST_SUCCESS: {
      let subscribedUsersFlag = false;
      if (action.data.length > 0) {
        subscribedUsersFlag = true;
      }
      return {
        ...state,
        subscribedUserRequestStatus: 'SUCCESS',
        data: action.data,
        hasSubscribedUsers: subscribedUsersFlag,
        subscribedUserRequestInitiated: false,
        error: {},
      };
    }
    case subscriptionConstant.SUBSCRIBED_USER_REQUEST_FAILURE:
      return {
        ...state,
        data: [],
        subscribedUserRequestInitiated: false,
        error: action.data,
        subscribedUserRequestStatus: 'FAILED',
      };
    case subscriptionConstant.USER_VERIFICATION_INITIATED:
      return {
        ...state,
        userVerificationInitiated: true,
        userVerificationSuccess: false,
        userVerificationRequestStatus: 'INITIATED',
        error: {},
      };
    case subscriptionConstant.USER_VERIFICATION_SUCCESS:
      return {
        ...state,
        userVerificationInitiated: false,
        userVerificationSuccess: true,
        userVerificationRequestStatus: 'SUCCESS',
        error: {},
      };

    case subscriptionConstant.USER_VERIFICATION_FAILURE:
      return {
        ...state,
        userVerificationInitiated: false,
        userVerificationSuccess: false,
        userVerificationRequestStatus: 'FAILED',
        error: {},
      };

    case subscriptionConstant.SAVE_SUBSCRIPTION_REQUEST_INITIATED:
      return {
        ...state,
        saveSubscriptionInitiated: true,
        error: {},
      };
    case subscriptionConstant.SAVE_SUBSCRIPTION_REQUEST_SUCCESS:
      return {
        ...state,
        saveSubscriptionInitiated: false,
        error: {},
      };
    case subscriptionConstant.SAVE_SUBSCRIPTION_REQUEST_FAILURE:
      return {
        ...state,
        saveSubscriptionInitiated: false,
        error: action.data,
      };
    case subscriptionConstant.BILLING_PACKAGE_REQUEST_INITIATED:
      return {
        ...state,
        billingPackageRequestInitiated: true,
        error: {},
      };
    case subscriptionConstant.BILLING_PACKAGE_REQUEST_SUCCESS:
      return {
        ...state,
        billingPackage: action.data.data,
        billingPackageRequestInitiated: false,
        error: {},
      };
    case subscriptionConstant.BILLING_PACKAGE_REQUEST_FAILURE:
      return {
        ...state,
        billingPackageRequestInitiated: false,
        error: action.data,
      };
    case subscriptionConstant.PAYMENT_DETAILS_SAVE_INTITATED:
      return {
        ...state,
        paymentDetailsRequestInitiated: true,
        error: {},
      };
    case subscriptionConstant.PAYMENT_DETAILS_SAVE_SUCCESS:
      return {
        ...state,
        paymentDetails: action.data.data,
        paymentDetailsRequestInitiated: false,
        error: {},
      };
    case subscriptionConstant.PAYMENT_DETAILS_SAVE_FAILURE:
      return {
        ...state,
        paymentDetailsRequestInitiated: false,
        error: action.data,
      };

    case subscriptionConstant.WEB_SUBSCRIBE_REQUEST_INITIATED:
      return {
        ...state,
        webSubcriptionInitiated: true,
        webSubscriptionError: false,
        subcriptionRequestObject: action.data,
      };
    case subscriptionConstant.WEB_SUBSCRIBE_REQUEST_ERROR:
      return {
        ...state,
        webSubcriptionInitiated: false,
        webSubscriptionError: true,
        subcriptionRequestObject: undefined,
      };

    case subscriptionConstant.WEB_SUBSCRIBE_REQUEST_SUCCESS: {
      return {
        ...state,
        webSubcriptionInitiated: false,
        webSubscriptionError: false,
        webSubscribedData: {
          request: { ...state.subcriptionRequestObject },
          response: action.data,
        },
        subcriptionRequestObject: undefined,
      };
    }

    default:
      return state;
  }
}
