import {tabCdList} from '../../../../../../components/src/constants/tabConstant';
import {getHospitalByCode} from '../../../../../../components/src/redux/hospitalreducer/Selector';
import {getEventDetailsForPatientByTabCd} from '../../../../../../components/src/redux/patienttabeventreducer/PatientTabEventAction';

import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {refreshHomescreen} from 'components/src/redux/homescreenreducer/Selector';
export default function useAppointmentTabHook(
  setAppointmentEvent,
  userId,
  setProgress,
  pullRefresh,
  setPullRefresh,
  setHelpLineNumber,
  // user,
  setSelectedUser,
  setLang,
  setUserId,
  // setUser,
) {
  const dispatch = useDispatch();
  const patientTabEventInit = useSelector(
    state => state.patientTabEvent.patientTabEventInit,
  );
  const patientTabEventDetails = useSelector(
    state => state.patientTabEvent.patientTabEvents,
  );
  const selUser = useSelector(state => state.home.selectedUser);

  const isRefreshHomescreen = refreshHomescreen(useSelector(state => state));
  const refreshTab = useSelector(state => state.notification.refreshTab);

  useEffect(() => {
    if (pullRefresh && userId) {
      setPullRefresh(false);
      dispatch(
        getEventDetailsForPatientByTabCd(userId, tabCdList?.myappt?.code),
      );
    }
  }, [pullRefresh]);
  useEffect(() => {
    setProgress(patientTabEventInit);

    if (!patientTabEventInit) {
      setAppointmentEvent([]);
    }
    if (!patientTabEventInit && patientTabEventDetails.length) {
      setAppointmentEvent(patientTabEventDetails);
    }
  }, [patientTabEventInit, patientTabEventDetails]);

  useEffect(() => {
    if (selUser) {
      setSelectedUser(selUser);
      setLang(selUser?.lang ? selUser.lang : 'eng');
      setUserId(
        selUser && Object.keys(selUser).length ? selUser.appPatientId : 0,
      );
    }
  }, [selUser]);

  useEffect(() => {
    if (userId) {
      dispatch(
        getEventDetailsForPatientByTabCd(userId, tabCdList?.myappt?.code),
      );
    }
  }, [userId]);

  useEffect(() => {
    if (refreshTab && refreshTab?.code?.includes('MYAPPT')) {
      setPullRefresh(true);
    }
  }, [refreshTab]);
  useEffect(() => {
    if (isRefreshHomescreen) {
      dispatch(setHomeScreenRefreshFlag(false));
      if (userId && !pullRefresh) {
        setPullRefresh(isRefreshHomescreen);
      }
    }
  }, [isRefreshHomescreen]);
}
