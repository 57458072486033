import React, {useState} from 'react';
import PatientAddress from '../PatientAddress';
import Skeleton from 'react-loading-skeleton';
import useStyles from '../Style';
import useCompleteAddressHook from './UseCompleteAddressHook';
import {
  Grid,
  FormControlLabel,
  Button,
  Typography,
  Container,
} from '@material-ui/core';
import {CustomCheckbox} from '../../common/CustomCheckbox';
import { AppStyle } from 'components/AppStyle';
import { languages } from 'components/src/constants/languages';

export default function CompleteAddress(props) {
  const classes = useStyles();

  const [address, setAddress] = useState();
  const [progess, setProgress] = useState(false);
  const [userAddress, setUserAddress] = useState();
  const [useMyDetails, setUseMyDetails] = useState();
  const [formAddress, setFormAddress] = useState();
  const [error, setError] = useState();
  const [branches,setBranches] = useState();

  useCompleteAddressHook(
    setUserAddress,
    userAddress,
    address,
    setAddress,
    setProgress,
    props.setSuccess,
    setBranches
  );

  const handleMyDetailsChange = value => {
    setUseMyDetails(value);
  };

  const handleOnClick = () => {
    if (useMyDetails) {
      userAddress.appPatientId = props.appPatientId;
      setAddress(userAddress);
    } else {
      if (!formAddress.addresLineOne ) {
        setError('addresLineOne');
      }else if(!formAddress.zipCode){
        setError('zipcode');
      } else {
        const contactDetails = {};
        contactDetails.appPatientId = props.appPatientId;
        contactDetails.patientAddrLine1 = formAddress.addresLineOne;
        contactDetails.cityName = formAddress.cityName;
        contactDetails.stateName = formAddress.stateName;
        contactDetails.countryName = formAddress.countryName;
        contactDetails.zipCode = formAddress.zipCode;
        setAddress(contactDetails);
      }
    }
  };

  if (progess || !userAddress) {
    return <Skeleton count={3} height={5} />;
  }
  return (
    <Container maxWidth="sm">
      <Grid container spacing={3} className={classes.registrationGrid}>
        <Grid item xs={5}>
          <FormControlLabel
            control={
              <CustomCheckbox
                onChange={event => {
                  handleMyDetailsChange(event.target.checked);
                }}
                value="myaddr"
              />
            }
            label={languages[props.lang].useMyDetails}
            style={{fontSize: '15px', fontFamily: 'OpenSans-Regular'}}
          />
        </Grid>
        {useMyDetails ? (
          <Grid item xs={12}>
            <Typography>{userAddress.patientAddrLine1}</Typography>
            <Typography>{`${userAddress.cityName},${userAddress.stateName},${userAddress.countryName},${userAddress.zipCode}`}</Typography>
          </Grid>
        ) : (
          <PatientAddress branches={branches} setAddress={setFormAddress} error={error} lang={props.lang} />
        )}

        <Grid item xs={12}>
          <Button
            type="button"
            variant="contained"
            style={{
              margin: 'auto',
              backgroundColor: AppStyle.primaryColor,
              color: '#ffffff',
            }}
            onClick={e => handleOnClick()}>
            {languages[props.lang].save}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}
