export const COMMON_ERROR = 'COMMON_ERROR';

export const NEW_MESSAGE = 'NEW_MESSAGE';

export const platformConstant = {
  SET_PLATFORM_DETAILS: 'SET_PLATFORM_DETAILS',
};

export const userChats = {
  UNREAD_CHATS_SUCCESS: 'UNREAD_CHATS_SUCCESS',
};

export const hospitalConstant = {
  GET_HOSPITAL_DETAILS_INIT: 'GET_HOSPITAL_DETAILS_INIT',
  GET_HOSPITAL_DETAILS_SUCCESS: 'GET_HOSPITAL_DETAILS_SUCCESS',
  GET_HOSPITAL_DETAILS_FAILURE: 'GET_HOSPITAL_DETAILS_FAILURE',
  GET_HOSPITAL_APPURL_INIT: 'GET_HOSPITAL_APPURL_INIT',
  GET_HOSPITAL_APPURL_SUCCESS: 'GET_HOSPITAL_APPURL_SUCCESS',
  GET_HOSPITAL_APPURL_FAILURE: 'GET_HOSPITAL_APPURL_FAILURE',
  GET_ALLHOSPITAL_INIT: 'GET_ALLHOSPITAL_INIT',
  GET_ALLHOSPITAL_SUCCESS: 'GET_ALLHOSPITAL_SUCCESS',
  GET_ALLHOSPITAL_FAILURE: 'GET_ALLHOSPITAL_FAILURE',
};

export const deviceInfoConstant = {
  UPDATE_DEVICE_INFO_INIT: 'UPDATE_DEVICE_INFO_INIT',
  UPDATE_DEVICE_INFO_SUCCESS: 'UPDATE_DEVICE_INFO_SUCCESS',
  UPDATE_DEVICE_INFO_FAILURE: 'UPDATE_DEVICE_INFO_FAILURE',
};

export const signupConstant = {
  SIGNUP_REQUEST_INITIATED: 'SIGNUP_REQUEST_INITIATED',
  SIGNUP_RESPONSE_SUCCESS: 'SIGNUP_RESPONSE_SUCCESS',
  SIGNUP_RESPONSE_FAILURE: 'SIGNUP_RESPONSE_FAILURE',
  AUTH_REQUEST_INTIATED: 'AUTH_REQUEST_INTIATED',
  AUTH_REQUEST_SUCCESS: 'AUTH_REQUEST_SUCCESS',
  AUTH_REQUEST_FAILURE: 'AUTH_REQUEST_FAILURE',
  USER_AUTH_REQUEST_INTIATED: 'USER_AUTH_REQUEST_INTIATED',
  USER_AUTH_REQUEST_SUCCESS: 'USER_AUTH_REQUEST_SUCCESS',
  USER_AUTH_REQUEST_FAILURE: 'USER_AUTH_REQUEST_FAILURE',
  LOAD_USER_CONTACT_DETAILS_TO_REDUX_SUCCESS:
    'LOAD_USER_CONTACT_DETAILS_TO_REDUX_SUCCESS',
  LOGOUT: 'LOGOUT',
  LANGUAGE: 'LANGUAGE',
};

export const fcmConfigurationConstant = {
  FCM_CONFIG_REQUEST_INITIATED: 'FCM_CONFIG_REQUEST_INITIATED',
  FCM_CONFIG_RESPONSE_SUCCESS: 'FCM_CONFIG_RESPONSE_SUCCESS',
  FCM_CONFIG_RESPONSE_FAILURE: 'FCM_CONFIG_RESPONSE_FAILURE',
};

export const otpConstant = {
  OTP_REQUEST_INTIATED: 'OTP_REQUEST_INTIATED',
  OTP_RESPONSE_SUCCESS: 'OTP_RESPONSE_SUCCESS',
  OTP_RESPONSE_FAILURE: 'OTP_RESPONSE_FAILURE',
  VERIFY_OTP_REQUEST_INITIATED: 'VERIFY_OTP_REQUEST_INITIATED',
  VERIFY_OTP_REQUEST_SUCCESS: 'VERIFY_OTP_REQUEST_SUCCESS',
  VERIFY_OTP_REQUEST_FAILURE: 'VERIFY_OTP_REQUEST_FAILURE',
  RESET_VERIFY_OTP: 'RESET_VERIFY_OTP',
};

export const registrationConstant = {
  REGISTRAION_REQUEST_INTIATED: 'REGISTRAION_REQUEST_INTIATED',
  REGISTRAION_RESPONSE_SUCCESS: 'REGISTRAION_RESPONSE_SUCCESS',
  REGISTRATION_RESPONSE_FAILURE: 'REGISTRATION_RESPONSE_FAILURE',
  VALIDATE_REGISTRATION_INTIATED: 'VALIDATE_REGISTRATION_INTIATED',
  VALIDATE_REGISTRATION_RESPONSE_SUCCESS:
    'VALIDATE_REGISTRATION_RESPONSE_SUCCESS',
  VALIDATE_REGISTRATION_RESPONSE_FAILURE:
    'VALIDATE_REGISTRATION_RESPONSE_FAILURE',
  RESET_PATIENT_REGISTRATION_APP_DATA: 'RESET_PATIENT_REGISTRATION_APP_DATA',
  CHECK_USER_EXISTS_INTITATED: 'CHECK_USER_EXISTS_INTITATE',
  CHECK_USER_EXISTS_RESPONSE_SUCCESS: 'CHECK_USER_EXISTS_RESPONSE_SUCCESS',
  CHECK_USER_EXISTS_RESPONSE_FAILURE: 'CHECK_USER_EXISTS_RESPONSE_FAILURE',
  SAVE_PATIENT_REGISTRATION_DATA_TO_REDUX_INTIATED:
    'SAVE_PATIENT_REGISTRATION_DATA_TO_REDUX_INTIATED',
  SAVE_PATIENT_REGISTRATION_DATA_TO_REDUX_SUCCESS:
    'SAVE_PATIENT_REGISTRATION_DATA_TO_REDUX_SUCCESS',
  UPDATE_PROFILE_REQUEST_INITIATED: 'UPDATE_PROFILE_REQUEST_INITIATED',
  UPDATE_PROFILE_REQUEST_SUCCESS: 'UPDATE_PROFILE_REQUEST_SUCCESS',
  UPDATE_PROFILE_REQUEST_FAILURE: 'UPDATE_PROFILE_REQUEST_FAILURE',
  RESET_REGISTRATION_RESPONSE: 'RESET_REGISTRATION_RESPONSE',
};

export const subscriptionConstant = {
  SUBSCRIBED_USER_REQUEST_INITIATED: 'SUBSCRIBED_USER_REQUEST_INITIATED',
  SUBSCRIBED_USER_REQUEST_SUCCESS: 'SUBSCRIBED_USER_REQUEST_SUCCESS',
  SUBSCRIBED_USER_REQUEST_FAILURE: 'SUBSCRIBED_USER_REQUEST_FAILURE',
  SAVE_SUBSCRIPTION_REQUEST_INITIATED: 'SAVE_SUBSCRIPTION_REQUEST_INITIATED',
  SAVE_SUBSCRIPTION_REQUEST_SUCCESS: 'SAVE_SUBSCRIPTION_REQUEST_SUCCESS',
  SAVE_SUBSCRIPTION_REQUEST_FAILURE: 'SAVE_SUBSCRIPTION_REQUEST_FAILURE',
  BILLING_PACKAGE_REQUEST_INITIATED: 'BILLING_PACKAGE_REQUEST_INITIATED',
  BILLING_PACKAGE_REQUEST_SUCCESS: 'BILLING_PACKAGE_REQUEST_SUCCESS',
  BILLING_PACKAGE_REQUEST_FAILURE: 'BILLING_PACKAGE_REQUEST_FAILURE',
  WEB_SUBSCRIBE_REQUEST_INITIATED: 'WEB_SUBSCRIBE_REQUEST_INITIATED',
  WEB_SUBSCRIBE_REQUEST_SUCCESS: 'WEB_SUBSCRIBE_REQUEST_SUCCESS',
  WEB_SUBSCRIBE_REQUEST_ERROR: 'WEB_SUBSCRIBE_REQUEST_ERROR',
  USER_VERIFICATION_INITIATED: 'USER_VERIFICATION_INITIATED',
  USER_VERIFICATION_SUCCESS: 'USER_VERIFICATION_SUCCESS',
  USER_VERIFICATION_FAILURE: 'USER_VERIFICATION_FAILURE',
  SUBSCRIBED_USER_LIST_RESET: 'SUBSCRIBED_USER_LIST_RESET',
};

export const paymentConstant = {
  PAYMENT_DETAILS_SAVE_INTITATED: 'PAYMENT_DETAILS_SAVE_INTITATED',
  PAYMENT_DETAILS_SAVE_SUCCESS: 'PAYMENT_DETAILS_SAVE_SUCCESS',
  PAYMENT_DETAILS_SAVE_FAILURE: 'PAYMENT_DETAILS_SAVE_FAILURE',
  ORDER_CREATE_INITIATED: 'ORDER_CREATE_INITIATED',
  ORDER_CREATE_SUCCESS: 'ORDER_CREATE_SUCCESS',
  ORDER_CREATE_FAILURE: 'ORDER_CREATE_FAILURE',
};

export const homescreenConstant = {
  HOME_EVENT_REQUEST_INITIATED: 'HOME_EVENT_REQUEST_INITIATED',
  HOME_EVENT_REQUEST_SUCCESS: 'HOME_EVENT_REQUEST_SUCCESS',
  HOME_EVENT_REQUEST_FAILURE: 'HOME_EVENT_REQUEST_FAILURE',
  HOME_EVENT_REALM_DATA_LOAD: 'HOME_EVENT_REALM_DATA_LOAD',
  HOME_LATEST_CLAIM_REQUEST_INITIATED: 'HOME_LATEST_CLAIM_REQUEST_INITIATED',
  HOME_LATEST_CLAIM_EVENT_REQUEST_SUCCESS:
    'HOME_LATEST_CLAIM_EVENT_REQUEST_SUCCESS',
  HOME_LATEST_CLAIM_EVENT_REQUEST_FAILURE:
    'HOME_LATEST_CLAIM_EVENT_REQUEST_FAILURE',
  SAVE_SELECTED_USER: 'SAVE_SELECTED_USER',
  RESET_SELECTED_USER: 'RESET_SELECTED_USER',
  SAVE_SELECTED_EVENT: 'SAVE_SELECTED_EVENT',
  RESET_SELECTED_EVENT: 'RESET_SELECTED_EVENT',
  SAVE_ADD_ACTION: 'SAVE_ADD_ACTION',
  RESET_ADD_ACTION: 'RESET_ADD_ACTION',
  HOME_SELECTED_PATIENT: 'HOME_SELECTED_PATIENT',
  RELOAD_PATIENT_LIST: 'RELOAD_PATIENT_LIST',
  RESET_RELOAD: 'RESET_RELOAD',
  DATA_SYNC_INIT: 'DATA_SYNC_INIT',
  DATA_SYNC_SUCCESS: 'DATA_SYNC_SUCCESS',
  DATA_SYNC_FAILURE: 'DATA_SYNC_FAILURE',
  RESET_ON_LOGOUT: 'RESET_ON_LOGOUT',
  HOME_SCREEN_REFRESH: 'HOME_SCREEN_REFRESH',
  HOME_EVENT_SHOW_PAY_BUTTON: 'HOME_EVENT_SHOW_PAY_BUTTON',
  VOIP_MEETING_DATA: 'VOIP_MEETING_DATA',
  CURRENT_SCREEN: 'CURRENT_SCREEN',
};

export const navigationConstant = {
  NAV_NEXT_SCREEN: 'NAV_NEXT_SCREEN',
  SAVE_NAV_PROPS: 'SAVE_NAV_PROPS',
  LOGOUT_PRESSED: 'LOGOUT_PRESSED',
};

export const patientprofileConstant = {
  PATIENT_PROFILE_EVENT_REQUEST_INITIATED: 'PATIENT_PROFILE_REQUEST_INITIATED',
  PATIENT_PROFILE_EVENT_REQUEST_SUCCESS: 'PATIENT_PROFILE_REQUEST_SUCCESS',
  PATIENT_PROFILE_EVENT_REQUEST_FAILURE: 'PATIENT_PROFILE_REQUEST_FAILURE',
  
  PATIENT_PROFILE_DATA_RESET: 'PATIENT_PROFILE_DATA_RESET',
};

export const vitalConstant = {
  ADD_VITAL_INIT: 'ADD_VITAL_INIT',
  ADD_VITAL_SUCCESS: 'ADD_VITAL_SUCCESS',
  ADD_VITAL_FAILURE: 'ADD_VITAL_FAILURE',
  GET_VITAL_FOR_HOSPITAL_INIT: 'GET_VITAL_FOR_HOSPITAL_INIT',
  GET_VITAL_FOR_HOSPITAL_SUCCESS: 'GET_VITAL_FOR_HOSPITAL_SUCCESS',
  GET_VITAL_FOR_HOSPITAL_FAILURE: 'GET_VITAL_FOR_HOSPITAL_FAILURE',
};

export const medicationConstant = {
  CURRENT_MED_INIT: 'CURRENT_MED_INIT',
  CURRENT_MED_SUCCESS: 'CURRENT_MED_SUCCESS',
  CURRENT_MED_FAILURE: 'CURRENT_MED_FAILURE',
  PAST_MED_INTI: 'PAST_MED_INTI',
  PAST_MED_SUCCESS: 'PAST_MED_SUCCESS',
  PAST_MED_FAILURE: 'PAST_MED_FAILURE',
  PRESCRIPTION_HISTORY_INIT: 'PRESCRIPTION_HISTORY_INIT',
  PRESCRIPTION_HISTORY_SUCCESS: 'PRESCRIPTION_HISTORY_SUCCESS',
  PRESCRIPTION_HISTORY_FAILURE: 'PRESCRIPTION_HISTORY_FAILURE',
};

export const kinesisConstant = {
  KINESIS_INIT: 'KINESIS_INIT',
  KINESIS_SUCCESS: 'KINESIS_SUCCESS',
  KINESIS_FAIL: 'KINESIS_FAIL',
  KINESIS_FINISH: 'KINESIS_FINISH',
};

export const consultationConstant = {
  GET_CONSULTATION_ITEMS_INIT: 'GET_CONSULTATION_ITEMS_INIT',
  GET_CONSULTATION_ITEMS_SUCCESS: 'GET_CONSULTATION_ITEMS_SUCCESS',
  GET_CONSULTATION_ITEMS_FAILURE: 'GET_CONSULTATION_ITEMS_FAILURE',
  CREATE_CONSULTATION_ITEM_INIT: 'CREATE_CONSULTATION_ITEM_INIT',
  CREATE_CONSULTATION_ITEM_SUCCESS: 'CREATE_CONSULTATION_ITEM_SUCCESS',
  CREATE_CONSULTATION_ITEM_FAILURE: 'CREATE_CONSULTATION_ITEM_FAILURE',
};

export const credentialConstant = {
  CREDENTIAL_INIT: 'CREDENTIAL_INIT',
  CREDENTIAL_SUCCESS: 'CREDENTIAL_SUCCESS',
  CREDENTIAL_FAILURE: 'CREDENTIAL_FAILURE',
};

export const contentConstant = {
  GET_PUBLIC_CONTENT_INIT: 'GET_PUBLIC_CONTENT_INIT',
  GET_PUBLIC_CONTENT_SUCCESS: 'GET_PUBLIC_CONTENT_SUCCESS',
  GET_PUBLIC_CONTENT_FAILURE: 'GET_PUBLIC_CONTENT_FAILURE',
  GET_PUBLIC_CONTENT_BY_TAG_INIT: 'GET_PUBLIC_CONTENT_BY_TAG_INIT',
  GET_PUBLIC_CONTENT_BY_TAG_SUCCESS: 'GET_PUBLIC_CONTENT_BY_TAG_SUCCESS',
  GET_PUBLIC_CONTENT_BY_TAG_FAILURE: 'GET_PUBLIC_CONTENT_BY_TAG_FAILURE',
  VIEW_ANALYTICS_INIT: 'VIEW_ANALYTICS_INIT',
  VIEW_ANALYTICS_SUCCESS: 'VIEW_ANALYTICS_SUCCESS',
  VIEW_ANALYTICS_FAILURE: 'VIEW_ANALYTICS_FAILURE',
};

export const patientTabEventConstant = {
  PATIENT_TAB_EVENT_INIT: 'PATIENT_TAB_EVENT_INIT',
  PATIENT_TAB_EVENT_SUCCESS: 'PATIENT_TAB_EVENT_SUCCESS',
  PATIENT_TAB_EVENT_FAILURE: 'PATIENT_TAB_EVENT_FAILURE',
};

export const QuickEventConstant = {
  QUICK_EVENT_INIT: 'QUICK_EVENT_INIT',
  QUICK_EVENT_SUCCESS: 'QUICK_EVENT_SUCCESS',
  QUICK_EVENT_FAILURE: 'QUICK_EVENT_FAILURE',
};

export const notificationConstant = {
  TAB_NOTIFICATION_INIT: 'TAB_NOTIFICATION_INIT',
  TAB_NOTIFICATION_RECEIVED: 'TAB_NOTIFICATION_RECEIVED',
  TAB_NOTIFICATION_CLEAR: 'TAB_NOTIFICATION_CLEAR',
  TAB_REFRESH: 'TAB_REFRESH',
  TAB_REFRESH_RESET: 'TAB_REFRESH_RESET',
  TAB_NOTIFICATION_ERROR: 'TAB_NOTIFICATION_ERROR',
};
