import React from 'react';
import Tab from '@material-ui/core/Tab';
import Lock from '@material-ui/icons/Lock';
import Tooltip from '@material-ui/core/Tooltip';
import homeStyles from '../Style';
import { languages } from 'components/src/constants/languages';

export default function CustomTab(props) {
  const classes = homeStyles();

  const tabItem = (
    <Tab
    className={classes.tabsRoot}
    style={{color:props.color}}
      component="a"
      onClick={event => {
        event.preventDefault();
      }}
      {...props}
      icon={<>{props.lock ? <Lock className = {classes.lockstyle} /> : ''}</>}
      disabled={props.lock}
    />
  );
  if (props.lock) {
    return (
      <Tooltip title={languages[props.lang].subscribeToUnlock}>
        <span style={{flex: 1}}>{tabItem}</span>
      </Tooltip>
    );
  }
  return tabItem;
}
