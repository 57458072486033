import React, {useEffect, useState} from 'react';
// import {languages} from '../components/src/constants/languages';
import useAppointmentTabHook from './useAppointmentTabHook';
import Card from '@material-ui/core/Card';
import EventCardHeader from '../../eventcard/EventCardHeader';
import homeStyles from '../../Style';
import {CardContent, Container, Divider, Typography} from '@material-ui/core';
import EventCardDetails from '../../eventcard/EventCardDetails';
import moment from 'moment';
import {statusIcon} from '../../eventcard/EventCardConst';
import {languages} from 'components/src/constants/languages';
import Skeleton from 'react-loading-skeleton';

export default function Appointment(props) {
  const classes = homeStyles();
  const [appointmentEvents, setAppointmentEvent] = useState([]);
  const [progress, setProgress] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [lang, setLang] = useState('');
  const [userId, setUserId] = useState('');
  const [HelpLineNumber, setHelpLineNumber] = useState('');
  const [pullRefresh, setPullRefresh] = useState(false);
  useAppointmentTabHook(
    setAppointmentEvent,
    userId,
    setProgress,
    pullRefresh,
    setPullRefresh,
    setHelpLineNumber,
    setSelectedUser,
    setLang,
    setUserId,
  );
  if (progress) {
    return (
      <Container maxWidth="md">
        <Skeleton count={10} height={6} />
      </Container>
    );
  }
  if (userId === 0) {
    return (
      <Typography>
        {languages[props.lang].selectUserToViewAppointment}
      </Typography>
    );
  }

  return (
    <>
      {appointmentEvents.length
        ? appointmentEvents.map((element, index) => {
            return (
              <div className={classes.cardWrap}>
                <Card className={classes.card} key={index}>
                  <EventCardHeader
                    title={element?.description?.title}
                    time={true}
                    name={element?.description?.patientName}
                  />
                  <Divider />
                  <CardContent>
                    <br />
                    <EventCardDetails event={element} lang={props.lang} />
                  </CardContent>
                </Card>
              </div>
            );
          })
        : null}
    </>
  );
}
