import React, {useState} from 'react';
import {FormControl, InputLabel, ButtonGroup, Button} from '@material-ui/core';
import useStyles from './Style';

function CustomButtonGroup(props) {
  const classes = useStyles();
  const [selectedOption, setSelectedOption] = useState(props.items[0]);

  const handleSelect = value => {
    setSelectedOption(value);
    props.handleSelect(value);
  };

  return (
    // <Grid item className={props.containerStyle}>
    <FormControl>
      <InputLabel
        shrink
        required
        style={{fontFamily: 'OpenSans-Regular', fontSize: '15px'}}
        error={props.error}
        className={props.labelStyle}>
        {props.buttonGroupLabel}
      </InputLabel>
      <ButtonGroup
        fullWidth
        className={props.buttonGroupStyle}
        aria-label={props.buttonGroupName}>
        {props.items.map(d => (
          <Button
            color={'primary'}
            key={d}
            variant={selectedOption === d ? 'contained' : 'outlined'}
            onClick={e => {
              handleSelect(d);
            }}
            className={[props.error ? classes.buttonGroupError : {}]}>
            {d}
          </Button>
        ))}
      </ButtonGroup>
    </FormControl>
    // </Grid>
  );
}
export default CustomButtonGroup;
