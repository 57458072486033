import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import homeStyles from '../../Style';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {dateFormat} from 'components/src/commonutil/CommonUtil';
import {languages} from 'components/src/constants/languages';

export default function MedicationTable(props) {
  const classes = homeStyles();

  return (
    <Table className={classes.medicationTable} aria-label="medication-table">
      <TableHead>
        <TableRow>
          <TableCell>{languages[props.lang].formNameStrength}</TableCell>
          <TableCell align="center">
            {languages[props.lang].frequency}
          </TableCell>
          <TableCell align="center">{languages[props.lang].from}</TableCell>
          <TableCell align="center">{languages[props.lang].to}</TableCell>
          <TableCell align="center">
            {languages[props.lang].specialInstruction}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.rows ? (
          props.rows.map(row => (
            <TableRow key={row.brand}>
              <TableCell component="th" scope="row">
                {row.brand}
              </TableCell>
              <TableCell align="center">{row.frequency}</TableCell>
              <TableCell align="center">
                {moment(row.startDt).format(dateFormat)}
              </TableCell>
              <TableCell align="center">
                {row.endDt ? moment(row.endDt).format(dateFormat) : '-'}
              </TableCell>
              <TableCell align="center">{row.comments}</TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={5} align="center">
              {languages[props.lang].noMedications}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
