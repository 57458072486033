import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PatientRegistration from './component/registerpatient/PatientRegistration';
import Signup from './component/signup/Signup';
import { isUserAthenticated } from 'components/src/redux/signupreducer/Selector';
import {
  getSavedUserAuthToken,
  clearLocalStorage,
  getPreferedLanguage,
  saveRefernce
} from '../src/component/localstorage/LocalStorage';
import { setUserAuthToken } from 'components/src/redux/axiosApi';
import Home from './component/home/Home';
import SwitchScreen from './component/switchscreen/SwitchScreen';
import moment from 'moment';
import { setLang } from 'components/src/redux/signupreducer/SignUpAction';
import ListPatient from './component/registerpatient/ListPatient';
import WebAnalyticsUtils from './WebAnalytics'

export const prefix = ''; //change this to empty in local
const StepCodePath = `${prefix}/chat/:workFlow/:stepCode`;
const initStepPath = `${prefix}/chat/:workFlow`

// export const saveStepCodeAndWorkflow = (path, props) => {
//   let searchParamsObj = {};
//   // let search_params;
//   if (path === StepCodePath || path === initStepPath) {
//     if (props.location.search) {
//       const search = new URLSearchParams(props.location.search);
//       //searchParamsObj = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
//       searchParamsObj = Object.fromEntries(search.entries());
//     }
//     console.log('temp searchparams', searchParamsObj);
//     const params = props.match.params;
//     const SC = 'init'
//     if (typeof window !== 'undefined') {
//       window.localStorage.setItem('STEPCODE', params.stepCode || SC);
//       window.localStorage.setItem('WORKFLOW', params.workFlow);
//       window.localStorage.setItem("WORKFLOW_INITIAL_ARGS", JSON.stringify(searchParamsObj));
//     }
//   }
// };

export const saveStepCodeAndWorkflow = (path, props) => {
  let searchParamsObj = {};
  // let search_params;
  if (path === StepCodePath || path === initStepPath) {
    if (props.location.search) {
      const search = new URLSearchParams(props.location.search);
      // searchParamsObj = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
      // searchParamsObj = Object.fromEntries(search.entries());
      // searchParamsObj = Object.fromEntries(search.entries());
       searchParamsObj = Array.from(search.entries()).reduce((acc, [key, val]) => Object.assign(acc, {[key]: val}), {});
      if(WebAnalyticsUtils.getCampaiganDetails(searchParamsObj)){
        WebAnalyticsUtils.trackCampaiganDetails(WebAnalyticsUtils.getCampaiganDetails(searchParamsObj))
      }

      if(WebAnalyticsUtils.getReferenceLink(searchParamsObj)){
        saveRefernce(WebAnalyticsUtils.getReferenceLink(searchParamsObj))
      }
    }

    console.log('temp searchparams', searchParamsObj);
    const params = props.match.params;
    const SC = 'init'
    if (typeof window !== 'undefined') {
      window.localStorage.setItem('STEPCODE', params.stepCode || SC);
      window.localStorage.setItem('WORKFLOW', params.workFlow);
      window.localStorage.setItem("WORKFLOW_INITIAL_ARGS", JSON.stringify(searchParamsObj));
    }
  }
}

const PrivateRoute = ({ authenticated, lang, langSelected, ...rest }) => {
  if (!authenticated) {
    return (
      <Route
        {...rest}
        render={(props) => {
          saveStepCodeAndWorkflow(rest.path, props);
          return <Signup {...props} lang={lang} langSelected={langSelected} />;
        }}
      />
    );
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        saveStepCodeAndWorkflow(rest.path, props);
        return rest.render(props);
      }}
    />
  );
};

class AppNavigator extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  checkIfUserIsAuthenticated = () => {
    const { isUserAthenticated } = this.props;
    const savedUserAuthToken = getSavedUserAuthToken();

    if (!savedUserAuthToken) {
      this.setState({ authenticated: false });
    } else {
      let todaydate = moment().toDate();
      let expirydate = savedUserAuthToken.expiryTime;
      if (todaydate <= expirydate) {
        this.setState({ authenticated: true });
        // console.log('======came to appNavigator.js===>')
        clearLocalStorage();
      } else if (!isUserAthenticated) {
        setUserAuthToken(savedUserAuthToken);
        this.setState({ authenticated: true });
      }
    }
  };

  componentDidMount() {
    const preferredLang = getPreferedLanguage();
    if (preferredLang) {
      this.props.setLanguage(preferredLang);
    }
    this.checkIfUserIsAuthenticated();
  }

  render() {
    const { authenticated } = this.state;


    return (
      <Router>
        <Switch>
          <PrivateRoute
            lang={this.props.lang}
            langSelected={this.props.langSelected}
            authenticated={authenticated}
            path={`${prefix}/`}
            render={(props) => (
              <SwitchScreen lang={this.props.lang} {...props} />
            )}
            exact
          />

          <PrivateRoute
            lang={this.props.lang}
            langSelected={this.props.langSelected}
            authenticated={authenticated}
            path={`${prefix}/home`}
            render={(props) => <Home lang={this.props.lang} {...props} />}
            exact
          />

          <PrivateRoute
            lang={this.props.lang}
            langSelected={this.props.langSelected}
            authenticated={authenticated}
            path={`${prefix}/listPatient`}
            render={(props) => (
              <ListPatient lang={this.props.lang} {...props} />
            )}
            exact
          />

          <PrivateRoute
            lang={this.props.lang}
            langSelected={this.props.langSelected}
            authenticated={authenticated}
            path={`${prefix}/profile`}
            render={(props) => (
              <PatientRegistration lang={this.props.lang} {...props} />
            )}
            exact
          />

          <PrivateRoute
            lang={this.props.lang}
            langSelected={this.props.langSelected}
            authenticated={authenticated}
            path={`${prefix}/register`}
            render={(props) => (
              <PatientRegistration lang={this.props.lang} {...props} />
            )}
            exact
          />

          <PrivateRoute
            lang={this.props.lang}
            langSelected={this.props.langSelected}
            authenticated={authenticated}
            path={StepCodePath}
            render={(props) => <SwitchScreen lang={this.props.lang} {...props} />}
            exact
          />

          <PrivateRoute
            lang={this.props.lang}
            langSelected={this.props.langSelected}
            authenticated={authenticated}
            path={initStepPath}
            render={(props) => <SwitchScreen lang={this.props.lang} {...props} />}
            exact
          />
        </Switch>
      </Router>
    );
  }
}
function mapStateToProps(state) {
  return {
    isAthenticated: isUserAthenticated(state),
    lang: state.signup.lang,
    langSelected: state.signup.langSelected,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLanguage: data => {
      dispatch(setLang(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppNavigator);
