import React from 'react';
import otp_help_image from 'components/assets/otp_help_image.png';
import useStyles from './Style';

function OtpHelpImage(props) {
  const classes = useStyles();

  return (
    <div className={classes.paper}>
      <img src={otp_help_image} alt="otp_help_image" className={props.style}></img>
    </div>
  );
}

export default OtpHelpImage;
