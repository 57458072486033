import React from 'react';
import Hs from 'components/assets/footer_icon.png';
import { Typography, Link, Box, Container } from '@material-ui/core';
import useStyles from './Style';
import Copyright from './Copyright';
import { languages } from 'components/src/constants/languages';
import { showPoweredBy } from 'components/AppStyle';
function Footer(props) {
  const classes = useStyles();
  return (
    <Container maxWidth="xs" className={classes.footerLocation}>
      {showPoweredBy ? (<>
        <Typography
          variant="body2"
          className={classes.footerText}
          style={props.style}>
          {languages[props.lang].poweredby}
          <img src={Hs} alt="hs" className={classes.hsIconStyle}></img>
          <Link
            color="inherit"
            href="http://thehealthscore.com/"
            className={classes.linkStyle}
            style={props.style}
            target="_blank">
            HealthScore
          </Link>
        </Typography>
        <Box m={2}>
          <Copyright style={props.style} />
        </Box>
      </>) : null}
    </Container>
  );
}

export default Footer;
