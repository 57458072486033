import {makeStyles} from '@material-ui/core/styles';
import {AppStyle} from 'components/AppStyle';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  /* title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  }, */
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  divImageHeader: {
    textAlign: 'left',
    flex: '1',
    display: 'flex',
    /*  [theme.breakpoints.up('sm')]: {
      display: 'none',
    }, */
  },
  LogoText: {
    paddingLeft: '20px',
  },
  homeScreenHeaderImage: {
    alignSelf: 'center',
    fontSize: '20px',
    height: '40px',
  },
  menuBar: {
    background: AppStyle.bodybackground,
  },
  title: {
    flexGrow: 1,
  },
  menu: {
    top: '30px !important',
  },
  modalWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  modalClose: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    color: 'white',
    backgroundColor: 'transparent',
    border: 0,
    outline: 0,
    cursor: 'pointer',
  },
  modalCloseIcon: {
    fontSize: 40,
  }
}));

export default useStyles;
