import React, {useState, useEffect} from 'react';
import useSwitchScreenHook from './useSwitchScreenHook';
import switchScreenStyle from './Style';
import {Grid, CircularProgress} from '@material-ui/core';
import {Redirect} from 'react-router-dom';

function SwitchScreen(props) {
  const classes = switchScreenStyle();
  const [redirectUrl, setRedirectUrl] = useState();
  useSwitchScreenHook(setRedirectUrl);

  if (redirectUrl) {
    return <Redirect to={redirectUrl} />;
  }

  return (
    <Grid
      container
      justifyContent="center"
      container
      spacing={0}
      alignItems="center"
      className={classes.GridStyle}>
      <CircularProgress />
    </Grid>
  );
}
export default SwitchScreen;
