import React, {useState} from 'react';
import useStyles from './Style';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

function CustomDatepicker(props) {
  const classes = useStyles();

  const [dob, setDob] = useState(new Date());
  const [error, setError] = useState();
  const handleBlur = () => {
    if (!dob) {
      setError(true);
    } else {
      setError(false);
      props.handleDateChange(dob);
    }
  };
  return (
    // <Grid item xs={6}>
    <MuiPickersUtilsProvider utils={DateFnsUtils} error={props.error}>
      <KeyboardDatePicker
        width="50"
        disableToolbar
        error={error}
        // variant="inline"
        format="dd/MM/yyyy"
        margin="normal"
        id="date-picker-inline"
        className={classes.datePickerStyle}
        label={
          <span>
            {props.label}
            <span className={classes.dobStyle}>(DD/MM/YYYY)</span>*
          </span>
        }
        value={dob}
        onChange={e => {
          setDob(e);
          props.handleDateChange(e);
        }}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        onBlur={() => handleBlur()}
        helperText={error ? 'Please enter valid date' : ''}
      />
    </MuiPickersUtilsProvider>
    // </Grid>
  );
}
export default CustomDatepicker;
