import React, {useState, useEffect} from 'react';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import homeStyles from '../Style';
import usePatientListHook from './UsePatientListHook';
import CustomAvatar from '../../common/CustomAvatar';
import moment from 'moment';
import {dateFormat} from 'components/src/commonutil/CommonUtil';
import Skeleton from 'react-loading-skeleton';
import CustomTypography from '../../common/CustomTypography';
import { languages } from 'components/src/constants/languages';
import WebAnalyticsUtils from '../../../WebAnalytics';
import { eventConstants } from 'components/src/constants/firebaseAnalyticsEventConstant';
import { getHospitalCodeFromParams } from '../../localstorage/LocalStorage';

export default function PatientList(props) {
  const classes = homeStyles();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [userList, setUserList] = useState();
  const [selectedUser, setSelectedUser] = useState({});
  const [progress, setProgress] = useState(false);
  const [defaultSelect, setDefaultSelect] = useState(false);

  usePatientListHook(setUserList, selectedUser, setProgress, setSelectedIndex, setSelectedUser, props);

  
  useEffect(() => {
  if(userList&& userList.length !== 0){
    let selBranch = getHospitalCodeFromParams();
    userList.forEach((us,ind) => {
      if(us.hospitalCode == selBranch){
       setSelectedIndex(ind)
      }
    })
  }
  },[userList])


  const handleListItemClick = (event, index) => {
    const buttonList = document.querySelectorAll('#HSMeet button');
    // if chat bot is close, in buttonList array have only one button whose button is for chat bot open .
    //  If chat bot is open, in buttonList arrya have three button [firstButton=> for "ACTION" in chat bot UI , secondButton => for "CHAT" in chat bot UI, thirdButton => for "ClOSE" in chat bot UI]. So the "buttonList.length-1" means last button.
    // this if call only when chatbot is open
    if(buttonList.length > 1) {
      buttonList[buttonList.length-1].click()
    }
    WebAnalyticsUtils.trackEvent(eventConstants.CLICKED_ALL_MEMBER, {});
    setSelectedIndex(index);
    if (userList && index !== -1) {
      let analyticsUser = {...userList[index] }
    WebAnalyticsUtils.trackEvent(eventConstants.CLICKED_SELECTED_MEMBER, {analyticsUser});
      setSelectedUser(userList[index]);
      props.setUserSelected(true);
    } else {
      setSelectedUser({});
      props.setUserSelected(false);
    }
  };

  if (!userList || progress) {
    return <Skeleton count={15} height={60} />;
  }

  return (
    <List className={classes.patientList}>
      <ListItem
        alignItems="flex-start"
        selected={selectedIndex === -1}
        onClick={event => handleListItemClick(event, -1)}
        key={-1}
        className={classes.listItem}>
        <ListItemAvatar>
          <CustomAvatar class={classes.avatar} username={true} value={'ALL'} />
        </ListItemAvatar>
        <ListItemText
          className={classes.listTextItem}
          primary="ALL"
          secondary={
            <CustomTypography class={classes.primaryListItemText}>
              {languages[props.lang].allMembers}
            </CustomTypography>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      {userList.map((element, index) => (
        <>
       { element.appPatientId!==0?<>
          <ListItem
            alignItems="flex-start"
            selected={selectedIndex === index}
            onClick={event => handleListItemClick(event, index)}
            key={element.appPatientId}
            className={classes.listItem}
            key={index}>
            <ListItemAvatar>
              <CustomAvatar
                class={classes.avatar}
                username={true}
                value={element.patientName}
              />
            </ListItemAvatar>
            <ListItemText
              primary={
                <CustomTypography class={classes.primaryListItemText}>
                  {`${element.patientName}`}
                  <br />
                  {`(${element.hospitalPatientCode})`}
                  <br />
                  {element.packageName ? `(${element.packageName})` : ''}
                </CustomTypography>
              }
              secondary={
                <CustomTypography class={classes.secondaryListItemText}>
                  {element.packageCode && element.effectiveFrom
                    ? `${moment(element.effectiveFrom).format(
                        dateFormat,
                      )} to ${moment(element.effectiveTo).format(dateFormat)}`
                    : !element.effectiveFrom && !element.packageCode
                    ? languages[props.lang].notSubscribed
                    : languages[props.lang].processingPayment}
                </CustomTypography>
              }
            />
          </ListItem>
          {index == userList.length - 1 ? null : (
            <Divider variant="inset" component="li" />
          )}
        </>:null}</>
      ))}
    </List>
  );
}
