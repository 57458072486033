import {COMMON_ERROR} from './../ReduxConstant';
import { NEW_MESSAGE } from './../ReduxConstant'

const initialState = {
  key: 0,
  info: undefined, //{"message": "Network error occured,please check your internet connection or try again later", "statusCode": 503}
};

export default function navigationDetails(state = initialState, action) {
  switch (action.type) {
    case COMMON_ERROR:
      return {
        ...state,
        info: action.data,
        key: state.key + 1,
      };
      case NEW_MESSAGE:
        return {
          ...state,
          latestChat:action.data
        }
    default:
      return state;
  }
}

export function showErrorToast(data) {
  return {
    type: COMMON_ERROR,
    data: data,
  };
}


export function newChat(data){
  return {
    type: NEW_MESSAGE,
    data: data,
  };
}

export const getErrorMsg = state => state.error;
