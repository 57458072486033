import {tabCdList} from 'components/src/constants/tabConstant';
import {refreshHomescreen} from 'components/src/redux/homescreenreducer/Selector';
import {getPreviousMedication} from 'components/src/redux/medicationreducer/MedicationAction';
import {getEventDetailsForPatientByTabCd} from 'components/src/redux/patienttabeventreducer/PatientTabEventAction';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

export default function UseRecordTabHook(
  setPreviousMedication,
  userId,
  setProgress,
  pullRefresh,
  setPullRefresh,
  setClinicalEvents,
  setAwsCredentials,
  setSelectedUser,
  setLang,
  setUserId,
) {
  const dispatch = useDispatch();

  const previousMedInit = useSelector(
    state => state.medication.previousMedInit,
  );
  const previousMed = useSelector(state => state.medication.previousMed);
  const patientTabEventInit = useSelector(
    state => state.patientTabEvent.PatientTabEventInit,
  );
  const patientTabEventDetails = useSelector(
    state => state.patientTabEvent.patientTabEvents,
  );
  const isRefreshHomescreen = refreshHomescreen(useSelector(state => state));
  const refreshTab = useSelector(state => state.notification.refreshTab);

  const selUser = useSelector(state => state.home.selectedUser);
  useEffect(() => {
    if (patientTabEventDetails && patientTabEventDetails.length) {
      setPreviousMedication(patientTabEventDetails);
    }
  }, [patientTabEventDetails]);

  useEffect(() => {
    if (selUser) {
      setSelectedUser(selUser);
      setLang(selUser?.lang ? selUser.lang : 'eng');
      setUserId(
        selUser && Object.keys(selUser).length ? selUser.appPatientId : 0,
      );
    }
  }, [selUser]);
  useEffect(() => {
    if (userId) {
      dispatch(
        getEventDetailsForPatientByTabCd(userId, tabCdList?.myhealth?.code),
      );
      dispatch(getPreviousMedication(userId));
    }
  }, [userId]);

  useEffect(() => {
    setProgress(patientTabEventInit);
    if (patientTabEventInit) {
      setClinicalEvents([]);
    }
    if (!patientTabEventInit && patientTabEventDetails.length) {
      setClinicalEvents(patientTabEventDetails);
    }
  }, [patientTabEventInit, patientTabEventDetails]);

  useEffect(() => {
    setProgress(previousMedInit);
    if (!previousMedInit) {
      setPreviousMedication([]);
    }
    if (!previousMedInit && previousMed.length) {
      setPreviousMedication(createRows(previousMed));
    }
  }, [previousMedInit]);
  useEffect(() => {
    if (pullRefresh && userId) {
      setPullRefresh(false);

      dispatch(
        getEventDetailsForPatientByTabCd(userId, tabCdList?.myhealth?.code),
      );
    }
  }, [pullRefresh]);

  useEffect(() => {
    if (refreshTab && refreshTab?.code?.includes('MYHEALTH')) {
      setPullRefresh(true);
    }
  }, [refreshTab]);

  useEffect(() => {
    if (isRefreshHomescreen) {
      dispatch(setHomeScreenRefreshFlag(false));
      if (userId && !pullRefresh) {
        setPullRefresh(isRefreshHomescreen);
      }
    }
  }, [isRefreshHomescreen]);

  const createRows = currentMed => {
    const rows = [];
    currentMed.forEach(element => {
      let row = createData(
        element.pharmaBrandNm,
        element.frequency,
        element.startDt,
        element.endDt,
        element.comments,
      );
      rows.push(row);
    });
    return rows;
  };

  const createData = (brand, frequency, startDt, endDt, comments) => {
    return {
      brand,
      frequency,
      startDt,
      endDt,
      comments,
    };
  };
}
