import {signupConstant, fcmConfigurationConstant} from './../ReduxConstant';
import {onLoadToken} from '../../../../components/src/redux/axiosApi';

const initialState = {
  data: {},
  authRequestInitiated: false,
  signupRequestInitiated: false,
  authToken: {},
  userAuthToken: {},
  error: {},
  contactDetails: {},
  lang: 'eng',
  langSelected: false,
  fcmConfiguration:{},
};
/*
{
    access_token: '8056b92a-1b73-4831-b564-0d3c4e91db4a',
    token_type: 'bearer',
    refresh_token: '3feaaee6-4aab-447c-8940-ee69418d5fa3',
    expires_in: 2433893,
    scope: 'read write',
  }*/

export default function signUpDetails(state = initialState, action) {
  switch (action.type) {
    case signupConstant.AUTH_REQUEST_INTIATED:
      return {
        ...state,
        authToken: {},
        authRequestInitiated: true,
        error: {},
      };
    case signupConstant.AUTH_REQUEST_SUCCESS:
      return {
        ...state,
        authToken: action.data,
        authRequestInitiated: false,
        error: {},
      };
    case signupConstant.AUTH_REQUEST_FAILURE:
      return {
        ...state,
        authToken: {},
        authRequestInitiated: false,
        error: action.data,
      };
    case signupConstant.USER_AUTH_REQUEST_INTIATED:
      return {
        ...state,
        userAuthToken: {},
        signupRequestInitiated: true,
        error: {},
      };
    case signupConstant.USER_AUTH_REQUEST_SUCCESS:
      return {
        ...state,
        userAuthToken: action.data,
        signupRequestInitiated: false,
        error: {},
      };
    case signupConstant.LOGOUT:
      return {
        ...state,
        userAuthToken: '',
      };
    case signupConstant.USER_AUTH_REQUEST_FAILURE:
      return {
        ...state,
        userAuthToken: {},
        signupRequestInitiated: false,
        error: action.data,
      };
    case signupConstant.LOAD_USER_CONTACT_DETAILS_TO_REDUX_SUCCESS:
      return {
        ...state,
        contactDetails: action.data,
      };
    case signupConstant.SIGNUP_REQUEST_INITIATED:
      return {
        ...state,
        data: {},
        signupRequestInitiated: true,
        error: {},
      };
    case signupConstant.SIGNUP_RESPONSE_SUCCESS:
      return {
        ...state,
        data: action.data.data,
        signupRequestInitiated: false,
        error: {},
      };
    case signupConstant.SIGNUP_RESPONSE_FAILURE:
      return {
        ...state,
        data: {},
        signupRequestInitiated: false,
        error: action.data,
      };

    case signupConstant.LANGUAGE:
      return {
        ...state,
        lang: action.data,
        langSelected: true
      };

      case fcmConfigurationConstant.FCM_CONFIG_REQUEST_INITIATED:
      return {
        ...state,
      };
    case fcmConfigurationConstant.FCM_CONFIG_RESPONSE_SUCCESS:
      return {
        ...state,
        fcmConfiguration:action.data,
      };

    case fcmConfigurationConstant.FCM_CONFIG_RESPONSE_FAILURE:
      return {
        ...state,
        error: action.data,
      };

    default:
      return state;
  }
}
