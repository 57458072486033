import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => {
  return {
    containerStyle: {
      background: theme.palette.primary.main,
      flexGrow: 1,
      overflow: 'auto'
    },

    backdrop: {
      zIndex: `${theme.zIndex.drawer + 1} !important`,
      color: '#fff',
    },
    errors: {
      backgroundColor: '#f44336 !important',
    },
    infos: {
      backgroundColor: '#2196f3 !important',
    },
    warnings: { backgroundColor: '#ff9800 !important' },
    successs: { backgroundColor: '#4caf50 !important' },
    optErrMsg: {
      color: 'red',
      fontFamily: 'openSans-Bold',
      fontSize: '12px',
      marginTop: '-4px',
    },

    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: theme.palette.common.white,
      marginBottom: theme.spacing(4),
      width: '100%',
      textAlign: 'center',

      '& img': {
        marginTop: 50,
      },
      '& h1': { marginTop: theme.spacing(4) },
    },

    imageStyle: {
      width: '12%',
    },

    title: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: theme.palette.common.white,
      marginBottom: theme.spacing(2),
      width: '100%',
      textAlign: 'center',
    },

    headerText: {
      fontFamily: 'OpenSans-Regular',
      [theme.breakpoints.down('sm')]: {
        fontSize: "1.2rem"
      },
    },

    subHeaderText: {
      fontFamily: 'OpenSans-Regular',
      fontSize: '12px',
      marginTop: '5px',
    },

    parentIconDiv: {
      position: 'relative',
    },

    divIconStyle: {
      borderRadius: '100%',
      position: 'absolute',
      marginTop: '-5px',
      left: '40%',
      width: '52px',
      background: '#fff',
      textAlign: 'center',
      height: '52px',
    },

    textField: {
      width: '100%',
      textAlign: 'left',
      marginTop: '30px',
      marginBottom: '15px',
    },

    largeIcon: {
      fontSize: '2em',
      color: 'black',
      marginTop: '14px',
    },

    dobStyle: {
      display: 'inline-block',
      fontSize: '10px',
      fontFamily: 'OpenSans-Bold',
    },

    dobSection: {
      width: `calc(100% - ${235}px)`,
      '&.error ': {
        color: red,
        borderColor: red,
      },
      '&.error label': {
        color: red,
        borderColor: red,
      },
    },

    resendOtpDiv: {
      display: 'flex',
    },

    resendOtpButton: {
      borderRadius: '15px !important',
      /*
      padding: '5px !important',
      width: '45% !important',
      textAlign: 'center !important',
      background: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.primary.contrastText} !important`,
      */
    },

    resendOtp: {
      ['@media (max-width:420px)']: {
        display: 'block',
        justifyContent: 'center',
        textAlign: 'center',
        '& a': {
          display: 'inline-block',
          clear: 'both',
        },
      },
      display: 'flex',
      justifyContent: 'center',
    },
    phoneNumberContainerStyle: {
      color: 'black',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 55,
      textAlign: 'left',
      marginTop: '25px',

    },
    resendOTPContainerStyle: {
      color: 'black',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 55,
      textAlign: 'left',
      marginTop: '25px',
      marginRight: '10px',
    },
    sendVoiceOTPContainerStyle: {
      color: 'black',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 55,
      textAlign: 'left',
      marginTop: '25px',
      marginLeft: '10px',
    },
    phoneNumberInputStyle: {
      height: 55,
      fontFamily: 'OpenSans-Regular',
      fontSize: '20px',
      textAlign: 'left',
    },

    otpInputStyle: {
      fontFamily: 'OpenSans-Regular',
      fontSize: '22px',
      textAlign: 'left',
      marginLeft: '6px',
      height: '32px',
      width: "32px !important",
      padding: 0,
      ['@media (max-width:420px)']: {
        fontSize: '22px !important',
      }
    },

    error: {
      // background: '#ff0000',
      background: '#d61515bd',
      color: '#fff',
    },

    success: {
      background: '#00ff00',
    },

    warning: {
      background: '#ffff00',
    },

    footerText: {
      fontSize: '12px !important',
      marginTop: '10px !important',
      textAlign: 'center !important',
      // color: 'white !important',
    },

    copyRightText: {
      color: 'white',
      fontSize: '12px !important',
    },

    hsIconStyle: {
      width: '10px',
      position: 'absolute',
      marginTop: '4px',
      marginLeft: '5px',
    },

    linkStyle: {
      fontFamily: 'OpenSans-Bold !important',
      // color: 'white !important',
      marginLeft: '20px !important',
    },

    formControl: {
      width: '100%',
    },

    formSaveButton: {
      [theme.breakpoints.up('sm')]: {
        margin: '2% 0% 5% 56% !important'
      },
      [theme.breakpoints.down('xs')]: {
        marginLeft: "38%",
        marginTop: "2%"
      }
    },

    formResetButton: {
      [theme.breakpoints.up('sm')]: {
        margin: '2% 0% 5% 20% !important'
      },
      [theme.breakpoints.down('xs')]: {
        margin: "0%",
        marginTop: "2%"
      }
    },
    // color: '#ffffff !important',

    activeButton: {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,

      '&:hover': {
        background: theme.palette.primary.main,
      },
    },

    buttonGroupError: {
      borderColor: 'red !important',
    },

    customTextField: {
      fontSize: `${theme.spacing(1)}px`,
      background: '#ffffff',
    },

    linkButtonStyle: {
      padding: '8px !important',
    },

    footerLocation: {
      position: 'relative',
      bottom: '0px',
      width: '300px',
      marginTop: '5%',
    },

    appLogos: {
      width: '150px',
      maxWidth: '100%',
      maxHeight: '200px'
    },

    appleLogo: {
      width: '150px',
      maxWidth: '100%',
      maxHeight: '200px',
      padding: '3%'
    },
    datePickerStyle: {
      fontFamily: 'OpenSans-Regular',
      fontSize: '15px',
    },
  };
});

export default useStyles;
