import React from 'react';
import mobile_logo from 'components/assets/mobile_logo.png';
import useStyles from './Style';

function HospitalLogo(props) {
  const classes = useStyles();

  return (
    <div className={classes.paper}>
      <img src={mobile_logo} alt="mobile_logo" className={props.style}></img>
    </div>
  );
}

export default HospitalLogo;
