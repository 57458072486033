import React from 'react';
import Typography from '@material-ui/core/Typography';

export default function CustomTypography(props) {
  return (
    <Typography
      component="span"
      variant="body2"
      className={props.class}
      color="textPrimary">
      {props.children}
    </Typography>
  );
}
