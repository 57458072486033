import React from 'react';
import {connect} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import {Typography} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Pay from '../../../packagelist/Pay';
import {latestClaim} from 'components/src/redux/homescreenreducer/Selector';
import {selectedUser} from 'components/src/redux/homescreenreducer/Selector';
import CustomTextfield from './../../../common/CustomTextfield';
import {languages} from 'components/src/constants/languages';

class MakePaymentCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {loading, user, latestClaimFeeds, classes} = this.props;
    if (loading) {
      return (
        <Grid
          justifyContent="center"
          spacing={0}
          container
          className={classes.gridContent}>
          <CircularProgress />
        </Grid>
      );
    }

    return (
      <div style={{marginTop: '15px', border: '1px'}}>
        <Grid container spacing={1} justify={'space-around'}>
          {Object.keys(user).length ? (
            <Typography
              style={{
                padding: '6px',
                fontWeight: 'bold',
              }}>{languages[this.props.lang].makePayment}</Typography>
          ) : null}
        </Grid>

        <Grid container spacing={1} justify={'space-around'}>
          <Grid item xs={12} style={{padding: '10px'}}>
            <CustomTextfield
              type={'number'}
              name={languages[this.props.lang].amount}
              id={'amount'}
              onError={e => {
                this.setState({error: e});
              }}
              onTextBlur={t => {
                this.setState({billAmt: t});
              }}
            />
          </Grid>

          <Grid
            container
            item
            lg={12}
            alignItems="center"
            alignContent="center"
            justify="center">
            <Pay
              disabled={this.state.billAmt && !this.state.error ? false : true}
              billAmt={this.state.billAmt}
              selectedEvent={user}
              onSucess={this.props.onSucess}
              paymentBackgroundColor={'grey'}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    latestClaimFeeds: latestClaim(state),
    user: selectedUser(state),
    lang: state.signup.lang,
  };
};

// const PackageSet = withStyles(styles)(PackageList);

export default connect(
  mapStateToProps,
  null,
)(MakePaymentCard);
