import React from 'react';
import moment from 'moment';
import { languages } from '../constants/languages';

export const formInput = {
  mobileNumber: {
    errorMsg: 'Please enter valid 10 digit mobile number',
    regex: /^[0-9]{10}$/,
    regex: /^[0-9]+$/,
  },
  countryCode: {
    errorMsg: 'Please select a country code',
    regex: /^[0-9]+$/,
  },
  name: {
    errorMsg:
      'Please enter name with only alphabets, space and dot(.) with 2 to 120 characters length. No other special characters are allowed.',
    regex: /^[a-z,A-Z,., ]{2,120}$/,
  },
  motherName: {
    errorMsg:
      'Please enter mother name with only alphabets, space and dot(.) not beyond 100 characters length. No other special characters are allowed.',
    regex: /^[a-z,A-Z,., ]{1,100}$/,
  },
  gender: {
    errorMsg: 'Please select a gender',
    regex: /^[Male,Female,Other]+$/,
  },
  dob: {
    errorMsg: 'Please Select date of birth',
    regex: /[^]*$/,
  },
  address: {
    errorMsg: 'Please enter valid address with minimum of 5 characters.',
    regex: /[^]{5,}$/,
  },
  patientAddressLine1: {
    errorMsg: 'Address Line 1 should be 5 to 200 characters long',
    regex: /[^]{5,200}$/,
  },
  patientAddressLine2: {
    errorMsg: 'Address Line 2 should not exceed 200 characters',
    regex: /[^]{0,200}$/,
  },
  cityName: {
    errorMsg: 'Please Enter Valid City name',
    regex: /^[a-z,A-Z,. ]+$/,
  },
  stateName: {
    errorMsg: 'Please Enter Valid State name',
    regex: /^[a-z,A-Z,. ]+$/,
  },
  countryName: {
    errorMsg: 'Please Enter Valid country name',
    regex: /^[a-z,A-Z,. ]+$/,
  },
  zipCode: {
    errorMsg: 'Please Enter Valid zipcode',
    regex: /^[a-z,A-Z,0-9,.,+,_]+$/,
  },
  relation: {
    errorMsg: 'Please select a relation',
    regex: /^[a-z,A-Z]+$/,
  },
  amount: {
    errorMsg: 'Please enter valid amount with maximum two decimal places',
    regex: /^[1-9]\d*(\.\d\d?)?$/,
  },
  vital: {
    errorMsg: 'Cannot save vital values with more than three digits.',
    regex: /^[0-9]\d?\d?(\.\d\d?)?$/,
  },
  hospitalCode: {
    errorMsg: 'Please select a Hospital Branch',
    regex: /^[a-z,A-Z,0-9]+$/,
  },
  ptCode: {
    errorMsg: 'Only numbers are allowed.',
    regex: /^[0-9]+$/,
  },
};

export function allowDigitsAndDecimal(text, allowDecimalFlg) {
  let value = 0;
  if (allowDecimalFlg) {
    value = text.replace(/[^0-9|.]/g, '');
  } else {
    value = text.replace(/[^0-9]/g, '');
  }
  return value;
}

export function validateFormInput(fieldId, fieldValue) {
  const referenceFormField = formInput[fieldId];
  if (fieldId == 'dob') {
    if (fieldValue && isValidDob(fieldValue)) {
      return '';
    } else if (fieldValue && !isValidDob(fieldValue)) {
      return languages['eng'].invalidDob;
    } else {
      return referenceFormField.errorMsg;
    }
  } else if (fieldValue && fieldValue.match(referenceFormField.regex)) {
    if (fieldId == 'mobileNumber') {
      if (
        (fieldValue.match(new RegExp(fieldValue[0], 'g')) || []).length == 10
      ) {
        return referenceFormField.errorMsg;
      }
    }
    return '';
  } else {
    return referenceFormField.errorMsg;
  }
}

export const patientRelation = [
  'Mother',
  'Father',
  'Brother',
  'Friend',
  'Spouse',
  'Son',
  'Daughter',
  'GrandMother',
  'GrandFather',
  'Other',
];

export const eventList = [
  { eventCode: 'MED', eventName: 'Medication' },
  { eventCode: 'APPT', eventName: 'Appointment' },
  { eventCode: 'LAB', eventName: 'Lab Tests' },
  { eventCode: 'DOC', eventName: 'Doctor Advice' },
  { eventCode: 'BIL', eventName: 'Bill' },
];

export const genderList = ['Male', 'Female', 'Other'];
export const dateFormat = 'DD-MMM-YYYY';
export const fullDateFormat = 'DD MMMM YYYY';
export const dateTimeFormat = 'DD-MMM-YYYY hh:mm a z';
export const dateHourFormat = 'DD-MMM-YYYY h a z';

export function userNamePlaceHolder(name) {
  return name
    ? name[0].toLocaleUpperCase() + name[name.length - 1].toLocaleUpperCase()
    : '-';
}

export function findResourceType(link) {
  if (link.includes('.PDF') || link.includes('.pdf')) {
    return 'pdf';
  } else if (
    link.includes('.jpg') ||
    link.includes('.JPG') ||
    link.includes('.png') ||
    link.includes('.PNG') ||
    link.includes('.jpeg') ||
    link.includes('.JPEG')
  ) {
    return 'jpg';
  } else if (link.includes('drive.google.com')) {
    return 'googleDriveLink';
  }
  return 'link';
}

export function regYearDropDown() {
  const year = 2006;
  const curYear = new Date().getFullYear();
  const yrsArray = Array.from(
    new Array(curYear - year + 1),
    (val, index) => index + year,
  );
  return yrsArray;
}


// export const postMessageType = {json : 'JSON'}
export const postWindowMessage = (data, type, webViewRef) => {
  try {
    data = JSON.stringify({ type: `${type}`, data })

    if (webViewRef && webViewRef.current) {
      const clientResponseCode = `
    window.postMessage('${data}', "*");
    true;
  `;
      webViewRef.current.injectJavaScript(clientResponseCode);
    }
  } catch (e) {
    console.log('postwindow message====>', e)
  }
}

export const recivedMessageTypes = {
  unreadMessage: 'unreadMessage',
  resetUserMessageCount: "resetUserMessageCount",
  razorPay: "razorPay",
  externalUrlOpen: "externalUrlOpen",
  fireBaseEvents: 'fireBaseEvents'
}

export const postMessageTypes = {
  unreadMessage: 'unreadMessage',
  razorPaySuccessResponse: "razorPaySuccessResponse",
  razorPayFailureResponse: "razorPayFailureResponse"
}

export const isValidDob = (date) => {
  try {
    const selectedDobString = moment(date).format('DD/MM/YYYY');
    const today = moment(new Date()).format('DD/MM/YYYY');
    return selectedDobString !== today;
  } catch (e) {
    return true;
  }
}

export const getHospitalAdd = (selectedHospital) => {
  let hospitalAdd = `${selectedHospital.hospitalAddrLine1} ${selectedHospital.hospitalAddrLine2} ${selectedHospital.hospitalAddrCityNm} ${selectedHospital.hospitalAddrStateNm} ${selectedHospital.hospitalAddrCountryNm}  ${selectedHospital.zipcode}`;
return hospitalAdd;
}

export const getpatientAdd = (patientContactDetails) => {
  let patientAdd = `${patientContactDetails.patientAddrLine1} ${patientContactDetails.patientAddrLine2} ${patientContactDetails.cityName} ${patientContactDetails.stateName} ${patientContactDetails.countryName}  ${patientContactDetails.zipCode}`;
return patientAdd;
  
}
export const getHospitalName = (selectedHospital) => {
let hospitalName =  selectedHospital.hospitalNm;
  return hospitalName
}