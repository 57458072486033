import React from 'react';
import {useState} from 'react';
import useDataSyncHook from './UseDataSyncHook';
import CustomSnackbar from '../../common/CustomSnackbar';
import { languages } from 'components/src/constants/languages';

export default function DataSync(props) {
  const [update, setUpdate] = useState(0);

  useDataSyncHook(setUpdate);

  const handleClose = () => {
    location.reload();
  };

  return (
    <CustomSnackbar
      open={update > 0}
      action={handleClose}
      message={`${languages[props.lang].updatesAreAvailable}(${update}),${languages[props.lang].refreshToSeeTheChanges}`}
      type={'warning'}
    />
  );
}
