import React, {useState} from 'react';
import Otp from '../../common/Otp';
import Skeleton from 'react-loading-skeleton';
import Typography from '@material-ui/core/Typography';
import useVerifyUserHook from './UseVerifyUserHook';

export default function VerifyUser(props) {
  const [errorMsg, setErrorMsg] = useState();
  const [sendOtp, setSendOtp] = useState(true);
  const [otp, setOtp] = useState();
  const [progress, setProgress] = useState(false);
 const [resendOtp, setResendOtp] = useState(false);

  const handleResendOtp = () => {
    setSendOtp(true);
  };

  const sendVoiceOtp = () => {
    setResendOtp(true);
  };

  useVerifyUserHook(
    props.countryCode,
    props.phoneNo,
    sendOtp,
    setSendOtp,
    setErrorMsg,
    otp,
    setProgress,
    props.associationId,
    props.setSuccess,
    resendOtp, setResendOtp
  );

  if (progress) {
    return <Skeleton count={1} height={5} />;
  }

  return (
    <>
      <Typography>{`We have sent an otp to ${props.countryCode}-${props.phoneNo}`}</Typography>
      <Otp
        errorMsg={errorMsg}
        disabled={false}
        resendOtp={handleResendOtp}
        setOtp={setOtp}
        sendVoiceOtp={sendVoiceOtp}
        isFromSignup={false}
      />
    </>
  );
}
