import {AppStyle} from 'components/AppStyle';

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: '0 10px',
  },

  cardHeader: {
    marginTop: '20px',
    padding: '0px',
    color: '#fff',
  },

  closeButton: {
    backgroundColor: '#ffffff',
    margin: '5px',
  },

  parentCardDiv: {
    padding: '0px',
    fontFamily: 'OpenSans-Bold',
  },

  packageDescDiv: {
    padding: '0px !important',
    fontSize: '18px !important',
    //fontFamily: 'OpenSans-Bold',
  },

  packageContainer: {
    transition: 'transform 200ms',
    '&:hover': {
      // transform: 'scale(1.05)',
    },
  },

  cardDivStyle: {
    flex: '1',
    background: 'rgba(255,255,255,0.1)',
    paddingBottom: '10px',
    textTransform: 'uppercase',
    paddingTop: '10px',
    paddingLeft: '15px',
  },

  cardContentStyle: {
    fontSize: '14.625px',
  },

  gridContainerStyle: {
    paddingLeft: '20px',
    //fontFamily: 'OpenSans-Bold',
  },
  renewContainerStyle: {
    justifyContent: 'center',
    paddingLeft: '0px',
  },

  cardContentDiv: {
    '& button': {
      margin: '5px auto',
      display: 'block',
    },
  },

  packageTextHeader: {
    backgroundColor: AppStyle.primaryColor,
    color: '#ffffff',
    fontFamily: 'OpenSans-Bold',
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: '5px 10px !important',

    '& button': {
      position: 'relative',
      zIndex: 9,
    },
  },

  gridContent: {
    alignItems: 'center',
    minHeight: '50vh',
  },

  divStyle: {
    // marginLeft: '30px',
    textAlign: 'center',

    marginTop: '10px',
  },

  spanHeaderStyle: {
    fontSize: '14px',
    fontFamily: 'OpenSans-Bold',
  },

  spanAmountText: {
    fontFamily: 'OpenSans-Bold',
  },

  divSubHeaderText: {
    fontSize: '14px',
    fontFamily: 'OpenSans-Bold',
  },

  spanRenewalText: {
    fontFamily: 'OpenSans-Bold',
  },

  convenienceText: {
    fontSize: '12px',
    fontFamily: 'OpenSans-Bold',
  },

  payButtonStyle: {
    backgroundColor: 'white',
    color: '#000',
  },
});

export default styles;
