import {makeStyles} from '@material-ui/core/styles';
import {AppStyle} from 'components/AppStyle';
import {red} from '@material-ui/core/colors';

const subscriptionStyle = makeStyles(theme => ({
  container: {
    maxWidth: '500px',
    margin: '0 auto',
    // marginTop: '20px',
    marginBottom: '20px',
    color: '#fff',
    flexGrow: 1,
  },
  registrationContainer: {
    marginTop: '56px',
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      marginTop: '48px',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: '64px',
    },
  },
  registerButton: {
    margin: 'auto',
    color: AppStyle.bodybackground,
  },

  dobSpanStyle: {
    fontSize: '10px',
    fontWeight: 'bold',
  },
  registerstyle: {
    marginLeft: '60%',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '33%',
    },
    [theme.breakpoints.between('sm', 'sm')]: {
      marginLeft: '40%',
    },
  },
  imageHeader: {
    textAlign: 'center',
    display: 'block',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    width: '10%',
  },

  useMytext: {
    color: 'black',
  },

  textHeader: {
    textAlign: 'center',
    fontFamily: 'OpenSans-Regular',
    marginTop: 10,
  },

  textSubHeader: {
    fontSize: '14px',
    textAlign: 'center',
    marginTop: 5,
  },

  errorText: {
    textAlign: 'center',
    color: 'red',
  },

  dobStyle: {
    display: 'inline-block',
    fontSize: '10px',
    fontFamily: 'OpenSans-Bold',
  },

  formControl: {
    width: '100%',
    minWidth: '120px',
  },
  form: {
    background: '#fff',
    padding: '25px 20px',
    marginTop: '20px',
    textAlign: 'left',
    position: 'relative',
    borderRadius: '15px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 0px',
    },
  },
  formtextstyle: {
    fontSize: '15px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '8px',
    },
  },

  buttonContainer: {
    textAlign: 'center',
    width: '100%',
    marginTop: '25px',
  },

  buttonGroup: {
    marginTop: theme.spacing(1) * 2.5 + 'px',
    fontFamily: 'OpenSans-Regular',
    fontSize: '20px',
  },

  buttonGroupError: {
    borderColor: 'red',
  },

  dobSection: {
    width: `calc(100% - ${235}px)`,
    '&.error ': {
      color: red,
      borderColor: red,
    },
    '&.error label': {
      color: red,
      borderColor: red,
    },
  },

  genderSection: {
    width: '235px',
  },

  genderLabel: {
    marginTop: 5,
    fontSize: `${theme.spacing(1) * 1.5}px`,
  },

  activeButton: {
    background: '#ddd',
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },

  progressBarContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    top: '0',
    left: '0',
    textAlign: 'center',
    // backgroundColor: 'rgba(113, 109, 109, 0.83)',
    zIndex: 1,
    '& > :first-child': {
      padding: '20px',
    },

    divContainer: {
      background: AppStyle.bodybackground,
      flexGrow: 1,
    },
  },

  avatar: {
    margin: 'auto',
    backgroundColor: AppStyle.primaryColor,
  },
  signupCard: {
    minWidth: 275,
  },
  registrationTitleColor: {
    color: AppStyle.primaryColor,
  },
  registrationGrid: {
    paddingBottom: '10px',
    background: '#eee',
  },
  patientList: {
    paddingTop: '5px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0px',
    },
  },
  primaryListItemText: {
    display: 'inline',
    fontFamily: 'OpenSans-Regular',
    fontSize: '0.85rem',
    marginRight: '1%',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  headerDiv: {fontWeight: 'bold', textAlign: 'center', padding: '30px'},
  verifyPatientDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  fieldDiv: {
    flex: 1,
  },
  ptNameDiv: {
    fontWeight: 'bold',
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    paddingTop: 10,
  },
  backButton: {
    textAlign: 'center',
    margin: 10,
  },
  verifiedButtonStyle: {
    justifyContent: 'center',
    marginStart: 10,
    height: 32,
    borderRadius: 5,
    borderColor: 'grey',
  },
  verifyButtonStyle: {
    justifyContent: 'center',
    marginStart: 10,
    height: 32,
    borderRadius: 5,
    backgroundColor: '#f04343',
    borderColor: 'white',
  },
  colPtCodeView: {
    flexDirection: 'column',
    paddingLeft: '10px',
  },
  ptCodeView: {
    flexDirection: 'row',
    border: '1px solid #000',
    borderRadius: 5,
    padding: 10,
    margin: "10px auto",
    display: 'flex',
    maxWidth: '350px',
  },
  hospCodeText: {marginTop: 14, fontSize: 14},
  slashText: {marginTop: 6, fontSize: 25},
  inputPtCode: {
    flexDirection: 'row',
    fontSize: 16,
    flex: 0,
    backgroundColor: 'white',
    borderColor: '#d3d3d3',
    borderWidth: 1,
    borderRadius: 5,
    height: 40,
    marginTop: 8,
    paddingTop: 10,
    width: 90,
  },
  inputPtCodeDiv: {
    display: 'flex',
    flexDirection: 'column',
    margin: '20px 0',
  },
  flexDiv: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px',
    justifyContent: 'space-between',
  },
  flex2: {
    flex: 2,
    display: 'flex',
    flexDirection: 'row',
  },
  flex1: {
    flex: 1,
  },
  flexPadd1: {
    flex: 1,
    paddingTop: '6px',
  },
  cardItemBorder: {
    borderRadius: 0,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    backgroundColor: '#efefef',
    display:'flex',
    flexDirection:"column"
  },
  flexDiv2: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px',
    justifyContent: 'space-between',
  },
  rightDiv: {
    textAlign: 'right',
  },
  valueStyle: {
    color: '#000',
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'right',
  },
  labelStyle: {
    color: '#454545',
    fontSize: 14,
  },
  loginContainerStyle: {
    marginTop: 60,
    maxWidth: '600px',
  },
  containerStyle: {
    marginTop: 0,
    maxWidth: '600px',
  },
  warningText: {
    ['@media (max-width:420px)']: {
      marginLeft:"0",
    },
    marginLeft:"20%",
    fontWeight:"800",
    color:"#FE6200"
  }

}));

export default subscriptionStyle;
