import { homescreenConstant } from './../ReduxConstant';
import { authorizedHttp } from './../axiosApi';
import { showErrorToast } from '../errorhandler/ErrorHandleReducer';
import moment from 'moment';

export function initiateFetchEventRequest() {
  return {
    type: homescreenConstant.HOME_EVENT_REQUEST_INITIATED,
  };
}
export function resetonLogout() {
  return {
    type: homescreenConstant.RESET_ON_LOGOUT,
  };
}

export function setHomeScreenRefreshFlag(data) {
  return {
    type: homescreenConstant.HOME_SCREEN_REFRESH,
    data
  };
}

export function fetchEventSuccess(data) {
  return {
    type: homescreenConstant.HOME_EVENT_REQUEST_SUCCESS,
    data,
  };
}

export function fetchEventFailure(data) {
  return {
    type: homescreenConstant.HOME_EVENT_REQUEST_FAILURE,
    data,
  };
}

export function initiateLatestClaimFetchEventRequest() {
  return {
    type: homescreenConstant.HOME_LATEST_CLAIM_REQUEST_INITIATED,
  };
}

export function fetchLatestClaimEventSuccess(data) {
  return {
    type: homescreenConstant.HOME_LATEST_CLAIM_EVENT_REQUEST_SUCCESS,
    data,
  };
}

export function fetchLatestClaimEventFailure(data) {
  return {
    type: homescreenConstant.HOME_LATEST_CLAIM_EVENT_REQUEST_FAILURE,
    data,
  };
}

export function getHomescreenEvents(pageNo = 0, eventCode = '') {
  return dispatch => {
    dispatch(initiateFetchEventRequest());
    
    authorizedHttp
      .get(
        `api/getEventDetailsForUsername?pageNo=${pageNo}&eventCode=${eventCode}`,
      )
      .then(response => {
        if (response.data.status) {
        
          dispatch(fetchEventSuccess(response.data));
        } else {
          dispatch(fetchEventFailure(response.data.error));
        }
      })
      .catch(error => {
        dispatch(fetchEventFailure(error));
        dispatch(showErrorToast(error));
      });
  };
}

export function filterHomescreenEvents(
  appPatientId,
  pageNo = 0,
  eventCode = '',
) {

  let url = ''
  if (eventCode && eventCode != 'SUB') {
    url = `api/getEventDetailsForPatientId?appPatientId=${appPatientId}&pageNo=${pageNo}&eventCode=${eventCode}`;
  } else {
    url = `api/getEventDetailsForPatientId?appPatientId=${appPatientId}&pageNo=${pageNo}`;
  }

  return dispatch => {
    dispatch(initiateFetchEventRequest());
    authorizedHttp
      .get(url)
     
      .then(response => {
        if (response.data.status) {
          dispatch(fetchEventSuccess(response.data));
        } else {
          dispatch(fetchEventFailure(response.data.error));
        }
      })
      .catch(error => {
        dispatch(fetchEventFailure(error));
        dispatch(showErrorToast(error));
      });
  };
}


export function filterSubscriptionEvents(
  appPatientId,
  pageNo = 0,
) {

  let url = ''

  url = `api/getEventDetailsForPatientId?appPatientId=${appPatientId}&pageNo=${pageNo}&eventCode=SUB,REG`;


  return dispatch => {
    dispatch(initiateFetchEventRequest());
    authorizedHttp
      .get(url)
    
      .then(response => {
        if (response.data.status) {
          dispatch(fetchEventSuccess(response.data));
        } else {
          dispatch(fetchEventFailure(response.data.error));
        }
      })
      .catch(error => {
        dispatch(fetchEventFailure(error));
        dispatch(showErrorToast(error));
      });
  };
}


export function getLatestBillDetailsOfPatient(appPatientId) {
  return dispatch => {
    dispatch(initiateLatestClaimFetchEventRequest());
    authorizedHttp
      .get(
        `api/getLatestClaimBillDetailForPatientId?appPatientId=${appPatientId}`,
      )
      .then(response => {
        if (response.data.status) {

          dispatch(fetchLatestClaimEventSuccess(response.data));
        } else {
          dispatch(fetchLatestClaimEventFailure(response.data.error));
        }
      })
      .catch(error => {
        dispatch(fetchLatestClaimEventFailure(error));
        dispatch(showErrorToast(error));
      });
  };
}

export function loadRealmDataToEventsProps(data) {
  return {
    type: homescreenConstant.HOME_EVENT_REALM_DATA_LOAD,
    data,
  };
}
export function loadHomescreenEventsFromRealm(data) {
  return dispatch => {
    dispatch(loadRealmDataToEventsProps(data));
  };
}

export function setShowPayButtonProps(data) {
  return {
    type: homescreenConstant.HOME_EVENT_SHOW_PAY_BUTTON,
    data,
  };
}

export function showPayButton(data) {
  return dispatch => {
    dispatch(setShowPayButtonProps(data));
  };
}

export function saveSelectedUser(data) {
  return {
    type: homescreenConstant.SAVE_SELECTED_USER,
    data,
  };
}

export function resetSelectedUser() {
  return {
    type: homescreenConstant.RESET_SELECTED_USER,
  };
}

export function saveSelectedEvent(data) {
  return {
    type: homescreenConstant.SAVE_SELECTED_EVENT,
    data,
  };
}

export function resetSelectedEvent() {
  return {
    type: homeFilterConstant.RESET_SELECTED_EVENT,
  };
}

export function saveAddAction(data) {
  return {
    type: homescreenConstant.SAVE_ADD_ACTION,
    data,
  };
}

export function resetAddAction() {
  return {
    type: homescreenConstant.RESET_ADD_ACTION,
  };
}

export function reloadPatientList() {
  return {
    type: homescreenConstant.RELOAD_PATIENT_LIST,
  };
}

export function resetReload() {
  return {
    type: homescreenConstant.RESET_RELOAD,
  };
}

export function dataSyncInit() {
  return {
    type: homescreenConstant.DATA_SYNC_INIT,
  };
}

export function dataSyncSuccess(data) {
  return {
    type: homescreenConstant.DATA_SYNC_SUCCESS,
    data,
  };
}

export function dataSyncFailure(data) {
  return {
    type: homescreenConstant.DATA_SYNC_FAILURE,
    data,
  };
}

export function checkForUpdates() {
  return (dispatch, getState) => {
    const lastSyncTime = getState().home.dataSync;
    const reqData =
      Object.keys(lastSyncTime).length == 0
        ? {
          lastSynced: moment()
            .utc()
            .toDate(),
        }
        : lastSyncTime;

    dispatch(dataSyncInit());
    authorizedHttp
      .post(`api/checkForEventUpdates`, reqData)
      .then(response => {
        dispatch(dataSyncSuccess(response.data));
      })
      .catch(error => {
        dispatch(dataSyncFailure(error));
        dispatch(showErrorToast(error));
      });
  };
}


export function updateVoipMeetingData(data) {
  return {
    type: homescreenConstant.VOIP_MEETING_DATA,
    data,
  };
}

export function updateCurrentScreen(data) {
  return {
    type: homescreenConstant.CURRENT_SCREEN,
    data,
  };
}