import { Card } from '@material-ui/core';
import React from 'react'
import HaveQuestionImg from '../../../../../components/assets/hv_question.png'
import BookApptImg from '../../../../../components/assets/Book-an-appointment.jpg'
import homeStyles from '../Style';
import { HSMEETCHATBOT, LoadChatbot } from '../../../useAppHooks';
import { showStaticCard } from 'components/src/redux/config';

export const StaticCard = ({ selectedUser }) => {
    const classes = homeStyles();

    const data = [
        {
            title: 'Have a question?',
            subTitle: 'Send us a voice, video or text message',
            type: 'CHATS',
            img: HaveQuestionImg,

        },
        {
            title: 'Book an Appointment Today',
            subTitle: 'Consult our experts & start your recovery journey',
            type: 'bookAppointment',
            img: BookApptImg,
        },

    ];

    const onClickHandler = item => {
        selectedUser.tabToOpen = item;
        LoadChatbot(selectedUser);
        HSMEETCHATBOT.visibleBot();
    }

    return (
        showStaticCard ?
            data.map((c,i)=> {
                return (
                    <Card key={i} className={classes.staticCard} onClick={() => onClickHandler(c.type)}>
                        <p>{c.title} </p>
                        <img className={classes.staticCardImg} src={c.img}></img>
                        <p>{c.subTitle} </p>
                    </Card>
                )
            })
            :
            <Card className={classes.staticCard} onClick={() => onClickHandler(data[0].type)}>
                <p>{data[0].title} </p>
                <img className={classes.staticCardImg} src={data[0].img}></img>
                <p>{data[0].subTitle} </p>
            </Card>

    )
}
