import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {saveAddAction} from 'components/src/redux/homescreenreducer/HomeScreenAction';

export default function useHomeTabsHook(setAction, action, userSelected) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (action) {
      dispatch(saveAddAction(action));
    }
  }, [action]);
}
