import React from 'react';
import {Typography, Link} from '@material-ui/core';
import useStyles from './Style';

function Copyright(props) {
  const classes = useStyles();
  return (
    <Typography
      variant="body2"
      align="center"
      className={classes.copyRightText}
      style={props.style}>
      {'All rights reserved © '}
      <Link color="inherit" href="http://www.csoft-tech.com/" target="_blank">
        cSoft Technologies Pvt Ltd {}
      </Link>
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default Copyright;
