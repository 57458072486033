export const eventConstants = {
  SEND_OTP: 'Send_OTP',
  ENTER_OTP: 'OTP_Enter',
  RESEND_OTP: 'Resend_OTP',
  SELECT_HOSPITAL_BRANCH: 'Select_Hospital_Branch',
  KYC: 'KYC',
  KYC_FAILED: 'KYC_Failed',
  CLICKED_ALL_MEMBER: 'Clicked_All_Member',
  CLICKED_SELECTED_MEMBER: 'Clicked_Selected_Member',
  NEW_REGISTATION_PATIENT: 'REG_button_clicked',
  NEW_SUBSCRIBE_PATIENT: 'Subscribe_button_clicked',
  ALL_PATIENT_LIST: 'ListPatient_button_clicked',
  USE_MY_NUMBER: 'UseMyNumber',
  USE_NEW_NUMBER: 'Use_New_Mob_Num_Register',
  REGISTATION_NEW_PATIENT: 'Register_New_Patient',
  REGISTATION_FAILED: 'Register_Failed',
  REGISTATION_CANCLE: 'Register_Cancel',
  ALLREADY_REGISTAR_USER_CLICK_ON_CONTINUE:
    'allready_reg_user_click_on_continue',
  ALLREADY_REGISTAR_USER_CLICK_ON_CANCLE: 'allready_reg_user_click_on_cancle',
  VERIFY_NEW_PATIENT: 'verify_new_patient',
  QRScan: 'QRScan',
  TERMSANDCONDITIONS: 'terms_conditions',
  FIRST_TIME_REGISTRATION: 'first_time_registration',
  REG_CURRENTLOCATION: 'reg_current_location',
  REG_ADD_ANOTHER_PATIENT: 'reg_addAnotherPatient',
  REGISTRATION: 'registration',
  OPEN_SELECT_PATIENT: 'open_select_patient_dropdown',
  OPEN_MENU: 'open_menu',
  MENU_DASHBOARD: 'menu_dashboard',
  MENU_PURCHAGSE_CARE: 'menu_purchasecare',
  MENU_MYPROFILE: 'menu_myprofile',
  MENU_REGISTER_PATIENT: 'menu_register_patient',
  MENU_LINK_DEPENDENTS: 'menu_myprofile',
  MENU_CHANGE_LANGUAGE: 'menu_change_language',
  MENU_TERMS_USE: 'menu_terms_use',
  MENU_PRIVACY_POLICY: 'menu_privacy_policy',
  MENU_HELP: 'menu_help',
  MENU_SHARE: 'menu_share',
  MENU_RATE_US: 'menu_rate_us',
  MENU_LOGOUT: 'menu_logout',
  MENU_CONTENT_LIBRARY: 'menu_content_library',
  MENU_OPEN_VITALS: 'menu_open_vitals',
  MENU_OPEN_CHATBOT: 'menu_open_chatbot',
  MENU_OPEN_MEDICATION: 'menu_openMedications',
  MENU_SCAN_PAY: 'menu_scan_pay',
  CARD_CLICK_JOIN_CALL: 'card_join_call_click',
  CARD_CLICK_BILL_PAY: 'card_click_bill_pay',
  CARD_CLICK_MYPROFILE: 'card_click_myprofile',
  CARD_CLICK_FILE_VIEWER: 'card_click_file_view',
  CARD_CALL_FOR_HELP: 'card_call_for_help',
  PAYMENT_INITIATED: 'Payment_Initiated',
  PAYMENT_SUCCESS: 'Payment_Success',
  PAYMENT_FAILUER: 'Payment_Failure',
  LOGOUT: 'logout',
  PULLTOREFRESHPREFIX: 'pull_to_refresh',
  STATICCARDPREFIX: 'st_',
  LOGOCLICK: 'logoClick',
  CLOSE_CHATBOT: 'close_chatbot',

  // Promotion Section
  PROMOTION_VIDEO_PLAYED: 'promotion_video_played',
  PROMOTION_VIDEO_PAUSED: 'propotion_video_paused',
  PROMOTION_VIDEO_MUTED: 'promotion_video_muted',
  PROMOTION_VIDEO_UNMUTED: 'promotion_video_unmuted',
  PROMOTION_VIDEO_ENDED: 'promotion_video_ended',
  PROMOTION_VIDEO_THUMBNAIL_PRESSED: 'promotion_video_thumbnail_pressed',
  RECEVIED_NOTIFICATION: 'received_notification_in_mobile_app',
  USER_CLICK_ON_NOTIFICATION: 'user_clicked_on_notification',
};
