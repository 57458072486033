import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import {useTheme} from '@material-ui/core/styles';

export default function CustomDrawer(props) {
  const theme = useTheme();

  return (
    <>
      <Hidden smDown implementation="css">
        <Drawer
          className={props.desktopClass}
          variant="permanent"
          classes={props.classes}>
          {props.children}
        </Drawer>
      </Hidden>
      <Hidden smUp implementation="css">
        <Drawer
          container={props.container}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={props.open}
          onClose={props.onClose}
          classes={props.classes}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}>
          {props.children}
        </Drawer>
      </Hidden>
    </>
  );
}
