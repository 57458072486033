import React, {useEffect, useState} from 'react';
import EventCard from './../../eventcard/EventCard';
import HomeTabPanel from './../HomeTabPanel';
import ActionCard from './../ActionCard';
import MakePaymentCard from './MakePaymentCard';
import Grid from '@material-ui/core/Grid';
import homeStyles from '../../Style';
import useBillDetailHook from './UseBillDetailHook';
import {
  Card,
  CardContent,
  Container,
  Divider,
  Typography,
} from '@material-ui/core';
import EventCardHeader from '../../eventcard/EventCardHeader';
import EventCardDetails from '../../eventcard/EventCardDetails';
import moment from 'moment';
import {statusIcon} from '../../eventcard/EventCardConst';
import {languages} from 'components/src/constants/languages';
import Skeleton from 'react-loading-skeleton';
import CustomAvatar from '../../../common/CustomAvatar';
// import { selectedUser } from 'components/src/redux/homescreenreducer/Selector';

export default function BillTab(props) {
  const classes = homeStyles();
  const [billEvents, setBillEvent] = useState([]);
  const [progress, setProgress] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [lang, setLang] = useState('');
  const [userId, setUserId] = useState('');
  const [billCards, setBillCards] = useState('');
  const [showpayNow, setShowpayNow] = useState([]);
  const [subscriptionRenewalEvent, setSubscriptionRenewalEvent] = useState('');
  const [patientName, setPatientName] = useState('');
  const [user, setUser] = useState('');
  const [pullRefresh, setPullRefresh] = useState(false);
  useBillDetailHook(
    billCards,
    setBillCards,
    userId,
    setProgress,
    setShowpayNow,
    setPullRefresh,
    pullRefresh,
    subscriptionRenewalEvent,
    setSubscriptionRenewalEvent,
    setUserId,
    setSelectedUser,
    setLang,
    setPatientName,
    setBillEvent,
  );
  if (progress) {
    return (
      <Container maxWidth="md">
        <Skeleton count={12} height={6} />
      </Container>
    );
  }
  if (userId === 0) {
    return (
      <Typography>{languages[props.lang].selectUserToViewBill}</Typography>
    );
  }
  return (
    <>
      <div className={classes.billcard}>
        {billEvents.length?
      <Grid item xs={12} lg={10}>
          <HomeTabPanel index={props.index} value={props.value}>
            <ActionCard display={true} >
            
              {userId !== 0 ? <MakePaymentCard /> : null}
            </ActionCard>
          </HomeTabPanel> 
        </Grid>
        : null }
        <div className={classes.billevent}>
          {billEvents.length
            ? billEvents.map((element, index) => {
                return (
                  <Grid item xs={12} lg={10}>
                    <br />
                    <Card className={classes.card} key={index}>
                      <EventCardHeader
                        key={index}
                        title={element?.description?.title}
                        name={
                          element.description.name ||
                          element.description.patientName
                        }
                        event={element?.eventType}
                        time={true}
                        status={element?.description?.paymentStatus}
                      />
                      <Divider />
                      <CardContent className={classes.subscriptionCard}>
                        {element?.description?.paymentStatus
                          ? statusIcon[(element?.description?.paymentStatus)]
                          : null}
                        {element?.eventType === 'BIL' &&
                        element?.description?.updateCard &&
                        element?.description?.billedTs &&
                        moment(element?.description?.billedTs).diff(
                          moment(),
                          'hours',
                        ) < 24
                          ? statusIcon['U']
                          : null}

                        <br />

                        <EventCardDetails
                          event={element}
                          events={billEvents}
                          user={selectedUser}
                          lang={props.lang}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })
            : null}
        </div>
      </div>
    </>
  );
}
