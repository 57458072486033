import {platformConstant} from './../ReduxConstant';

const initialState = {
  web: true,
  deviceInfo: {},
};

export default function platformDetails(state = initialState, action) {
  if (
    action.type === platformConstant.SET_PLATFORM_DETAILS &&
    !action.data.web
  ) {
    return {
      ...state,
      web: false,
      deviceInfo: action.data.deviceInfo,
    };
  } else {
    return state;
  }
}
