import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  sendOTPReq,
  verifyOTPOfAssociatedUser,
  sendOTPToAssociatedPatient,
  resendOTPToAssociatedPatient
} from 'components/src/redux/otpreducer/OTPAction';
import WebAnalyticsUtils from '../../../WebAnalytics';
import { eventConstants } from '../../../../../components/src/constants/firebaseAnalyticsEventConstant';

export default function useVerifyUserHook(
  countryCode,
  phoneNo,
  sendOtp,
  setSendOtp,
  setErrorMsg,
  otp,
  openBackdrop,
  associationId,
  setSuccess,
  resendOtp, setResendOtp
) {
  // const [verifyUser, setVerifyUser] = useState(true);
  const dispatch = useDispatch();
  const otpError = useSelector(state => state.otp.error);

  const otpInit = useSelector(state => state.otp.otpRequestInitiated);

  const verifyOtpInit = useSelector(state => state.otp.verifyOtpReqInitiated);

  const isOtpVerified = useSelector(function (state) {
    return state.otp.verifyOtpData === true ? true : false;
  });

  useEffect(() => {
    if (sendOtp) {
      WebAnalyticsUtils.trackEvent(eventConstants.VERIFY_ASSOCIATED_USER_SEND_OTP, {
        phoneNumber: phoneNo || '',
        countryCode: countryCode || ''
      })
    }
  }, []);


  useEffect(() => {
    if (sendOtp) {
      const data = {};
      data.mobileNumber = phoneNo;
      data.countryCode = countryCode;
      dispatch(sendOTPToAssociatedPatient(data));
      setSendOtp(false);
    }
  }, [sendOtp]);

  useEffect(() => {
    if (resendOtp) {
      const data = {};
      data.mobileNumber = phoneNo;
      data.countryCode = countryCode;
      dispatch(resendOTPToAssociatedPatient(data));
      setResendOtp(false);
    }
  }, [resendOtp]);

  useEffect(() => {
    if (Object.keys(otpError).length > 0) {
      setErrorMsg(otpError.message);
    }
  }, [otpError]);

  useEffect(() => {
    openBackdrop(otpInit);
  }, [otpInit]);

  useEffect(() => {
    openBackdrop(verifyOtpInit);
  }, [verifyOtpInit]);

  useEffect(() => {
    if (isOtpVerified && otp && otp.length === 5) {
      setSuccess(true);
    }
  }, [isOtpVerified]);

  useEffect(() => {
    /* verifyUser  */
    if (otp && otp.length == 5) {
      const reqData = constructRquestData();
      dispatch(verifyOTPOfAssociatedUser(reqData));
    }
  }, [otp]);

  const constructRquestData = () => {
    let request = {};
    request.associationId = associationId;
    let phoneNumber = {};
    phoneNumber.countryCode = countryCode;
    phoneNumber.mobileNumber = phoneNo;
    phoneNumber.otp = otp;
    request.phoneNumber = phoneNumber;
    return request;
  };
}
