import { setLang } from 'components/src/redux/signupreducer/SignUpAction';
import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';

export default function useSelectLanguageHook(
  selectlang,
) {

  const dispatch = useDispatch();

  useEffect(() => {
    if(selectlang) {
      dispatch(setLang(selectlang))
    }
  }, [selectlang]);
}
