import React, {useEffect, useState} from 'react';
import homeStyles from '../../Style';
import {
  Card,
  CardContent,
  Container,
  Divider,
  Typography,
} from '@material-ui/core';
import EventCardHeader from '../../eventcard/EventCardHeader';
import EventCardDetails from '../../eventcard/EventCardDetails';
import {languages} from 'components/src/constants/languages';
import UseRecordTabHook from './UseRecordTabHook';
import Skeleton from 'react-loading-skeleton';

export default function RecordTab(props) {
  const classes = homeStyles();
  const [previousMedication, setPreviousMedication] = useState([]);
  const [progress, setProgress] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [lang, setLang] = useState('');
  const [userId, setUserId] = useState('');
  const [clinicalEvents, setClinicalEvents] = useState('');
  const [awsCredentials, setAwsCredentials] = useState('');
  const [pullRefresh, setPullRefresh] = useState(false);

  UseRecordTabHook(
    setPreviousMedication,
    userId,
    setProgress,
    pullRefresh,
    setPullRefresh,
    setClinicalEvents,
    setAwsCredentials,
    setSelectedUser,
    setLang,
    setUserId,
  );

  {
    if (progress) {
      return (
        <Container maxWidth="md">
          <Skeleton count={10} height={6} />
        </Container>
      );
    }
    if (userId === 0) {
      return (
        <Typography>{languages[props.lang].selectUserToViewRecord}</Typography>
      );
    }

    return (
      <>
        {previousMedication.length
          ? previousMedication.map((element, index) => {
              return (
                <div className={classes.cardWrap}>
                  <Card
                    className={classes.card}
                    key={index}>
                    <EventCardHeader
                      title={element?.description?.title}
                      time={true}
                      event={element?.eventType}
                      name={
                        element?.description?.name ||
                        element?.description?.patientName
                      }
                    />
                    <Divider />
                    <CardContent>
                      <br />
                      <EventCardDetails event={element} lang={props.lang} />
                    </CardContent>
                  </Card>
                </div>
              );
            })
          : null}
      </>
    );
  }
}
