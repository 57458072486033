import React from 'react';
import Zoom from '@material-ui/core/Zoom';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import homeStyles from '../../Style';
import {useState} from 'react';
import useTabFabHook from './UseTabFabHook';

export default function TabFab(props) {
  const classes = homeStyles();
  const theme = useTheme();
  const [actionCode, setActionCode] = useState(props.action);
  useTabFabHook(actionCode, setActionCode);

  const handleOnClick = code => {
    setActionCode(code);
  };

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const fabs = [
    {
      color: 'primary',
      className: classes.fab,
      icon: <AddIcon />,
      label: 'Add',
      code: 'VTL',
    },
  ];

  return (
    <>
      <Zoom
        key={fabs[0].color}
        in={props.value === 1}
        timeout={transitionDuration}
        style={{
          transitionDelay: `${
            props.value === 1 ? transitionDuration.exit : 0
          }ms`,
        }}
        unmountOnExit>
        <Fab
          aria-label={fabs[0].label}
          className={fabs[0].className}
          color={fabs[0].color}
          onClick={() => handleOnClick(fabs[0].code)}
          disabled={actionCode === 'VTL' ? true : false}>
          {fabs[0].icon}
        </Fab>
      </Zoom>
    </>
  );
}
