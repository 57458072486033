import {consultationConstant} from '../ReduxConstant';

const initialState = {
  consultationDoctorDetails: [],
  getConsultationDoctorInit: false,
  addConsultationDoctorInit: false,
  error: {},
};

export default function consultationDetails(state = initialState, action) {
  switch (action.type) {
    case consultationConstant.GET_CONSULTATION_ITEMS_INIT:
      return {
        ...state,
        consultationDoctorDetails: [],
        getConsultationDoctorInit: true,
        error: {},
      };
    case consultationConstant.GET_CONSULTATION_ITEMS_SUCCESS:
      return {
        ...state,
        consultationDoctorDetails: action.data.data,
        getConsultationDoctorInit: false,
        error: {},
      };
    case consultationConstant.GET_CONSULTATION_ITEMS_FAILURE:
      return {
        ...state,
        consultationDoctorDetails: [],
        getConsultationDoctorInit: false,
        error: action.data,
      };
    case consultationConstant.CREATE_CONSULTATION_ITEM_INIT:
      return {
        ...state,
        addConsultationDoctorInit: true,
        error: {},
      };
    case consultationConstant.CREATE_CONSULTATION_ITEM_SUCCESS:
      return {
        ...state,
        addConsultationDoctorInit: false,
        error: {},
      };
    case consultationConstant.CREATE_CONSULTATION_ITEM_FAILURE:
      return {
        ...state,
        addConsultationDoctorInit: false,
        error: action.data,
      };

    default:
      return state;
  }
}
