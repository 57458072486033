import {hospitalConstant, userChats} from '../ReduxConstant';
import {authorizedHttp} from '../axiosApi';
import {http} from './../axiosApi';
export function getHospitalDetails(data) {
  return {
    // Types of actions to emit before and after
    types: [
      hospitalConstant.GET_HOSPITAL_DETAILS_INIT,
      hospitalConstant.GET_HOSPITAL_DETAILS_SUCCESS,
      hospitalConstant.GET_HOSPITAL_DETAILS_FAILURE,
    ],

    callAPI: () => authorizedHttp.get(`api/getAllHospitalDetails`),
  };
}

export function getHospitalAppUrl() {
  return (dispatch, getState) => {
    const platform = getState().platform;
    const request = platform && platform.web ? http : authorizedHttp;

    // console.log(platform , "=sd-as=d-=as-d=as=d-=a")
    dispatch({
      // Types of actions to emit before and after
      types: [
        hospitalConstant.GET_HOSPITAL_APPURL_INIT,
        hospitalConstant.GET_HOSPITAL_APPURL_SUCCESS,
        hospitalConstant.GET_HOSPITAL_APPURL_FAILURE,
      ],

      callAPI: () => request.get(`api/getAppDetails`),
    });
  };
  //   return {
  //     // Types of actions to emit before and after
  //     types: [
  //       hospitalConstant.GET_HOSPITAL_APPURL_INIT,
  //       hospitalConstant.GET_HOSPITAL_APPURL_SUCCESS,
  //       hospitalConstant.GET_HOSPITAL_APPURL_FAILURE,
  //     ],

  //     callAPI: () => authorizedHttp.get(`api/getAppDetails`),
  //   };
  //   callAPI: () => http.get(`api/getAppDetails`),
  // };
}

export function getAllHospitalDetails() {
  return {
    // Types of actions to emit before and after
    types: [
      hospitalConstant.GET_ALLHOSPITAL_INIT,
      hospitalConstant.GET_ALLHOSPITAL_SUCCESS,
      hospitalConstant.GET_ALLHOSPITAL_FAILURE,
    ],

    callAPI: () => authorizedHttp.get(`api/getAllHospitalDetails`),
  };
}

export function setUnreadMessageCount(patientCode, data) {
  return {
    type: userChats.UNREAD_CHATS_SUCCESS,
    data: data,
  };
}

export function saveHospitalDetails(data) {
  return {
    type: hospitalConstant.GET_ALLHOSPITAL_SUCCESS,
    data: {data: data},
  };
}
