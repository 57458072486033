import { makeStyles } from '@material-ui/core/styles';
import { AppStyle } from 'components/AppStyle';

const commonStyle = makeStyles(theme => ({
  footerText: {
    fontSize: 12,
    marginTop: 10,
    textAlign: 'center',
    color: 'white',
  },

  copyRightText: {
    color: 'white',
    fontSize: '12px',
  },

  hsIconStyle: {
    width: '10px',
    position: 'absolute',
    marginTop: '4px',
    marginLeft: '5px',
  },

  linkStyle: {
    fontWeight: 'bold',
    color: 'white',
    marginLeft: '20px',
  },

  langWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%'
  },
  langBtnWrap: {
    margin: '3px',
    borderRadius: 5,
    width: '30%',
    maxWidth: '100px',
    minWidth: '85px'
  },
  selectedLanguageCard: {
    // minWidth: 100,
    minHeight: 100,
    // display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    background: AppStyle.secondaryColor,
    cursor: 'pointer',
    outline: 'none',
    border: 0,
    borderRadius: 5,
    width: '100%'
  },
  languageCard: {
    minHeight: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    outline: 'none',
    border: 0,
    borderRadius: 5,
    width: '100%'
  },
  langContent: {
    fontWeight: 'bold',
    padding: '16px 0'
  },
  policyContent: {
    fontSize: '5px',
    padding: '2px 0'
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    width: '100%',
    textAlign: 'center',

    '& img': {
      marginTop: 50,
    },
    '& .MuiCard-root': {
      overflow: 'unset',
    },
    '& h1': { marginTop: theme.spacing(2) },
  },
}));

export default commonStyle;
