import { makeStyles } from '@material-ui/core/styles';
import { AppStyle, appThemeColorShades } from 'components/AppStyle';
import { deepOrange, deepPurple, green, red } from '@material-ui/core/colors';

const drawerWidth = 240;

const sideSheetWidth = 300;

const homeStyles = makeStyles(theme => ({
  root: {
    display: 'flex',

  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  joinCall: {
    background: 'red',
    color: '#fff',
    textDecoration: 'none',
    padding: theme.spacing(1),
    borderRadius: '5px',
    marginLeft: '20px',
    height: 'fit-content',
  },
  testCall: {
    background: '#fff',
    color: appThemeColorShades.darker,
    textDecoration: 'none',
    padding: theme.spacing(1),
    borderRadius: '5px',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: appThemeColorShades.darker,
    marginLeft: '20px',
    height: 'fit-content',
  },
  addToCalender: {
    background: '#fff',
    textDecoration: 'none',
    borderColor: appThemeColorShades.darker,
    borderStyle: 'solid',
    borderTopStyle: 'none',
    borderLeftStyle: 'none',
    borderRightStyle: 'none',
  },
  apptCardWrap: {
    fontSize: '0.875rem',
    height: 'auto',
    "& .react-add-to-calendar":{
      display: 'none',
    },
    "& span": {
      display: "inline-block",
      borderBottom: "1px solid #3e3e3e",
      cursor: "pointer"
    }
  },
  callBtnWrap: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '20px'
  },
  close: {
    padding: theme.spacing(0.5),
  },

  snackBarContentStyle: {
    backgroundColor: '#ff8b0e !important',
  },
  subscriptionCard: {
    paddingTop: '0px',
    paddingBottom: '55px !important',
  },
  navDrawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },

  sideSheet: {
    [theme.breakpoints.up('md')]: {
      width: sideSheetWidth,
      flexShrink: 0,
    },
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: AppStyle.bodybackground,
    /*  [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    }, */
  },

  toolbar: theme.mixins.toolbar,

  sideSheetClearance: {
    marginTop: '65px',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },

  contentClearance: {
    marginTop: '56px',
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      marginTop: '48px',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: '64px',
    },
  },
  congratulationCardText: {
    fontFamily: 'OpenSans-Bold',
  },

  drawerPaper: {
    background: AppStyle.bodybackground,
    color: '#ffffff',
    width: drawerWidth,
  },

  sideSheetPaper: {
    width: sideSheetWidth,
  },

  modelDrawerPaper: {
    width: `calc(100% - ${drawerWidth}px)`,
    flexShrink: 0,
  },

  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      // marginTop: '25px',
      marginLeft: sideSheetWidth * 2,
    },
  },
  divImageHeader: {
    textAlign: 'left',
    flex: '1',
  },
  homeScreenHeaderImage: {
    width: '200px',
    alignSelf: 'center',
    height: '60px',
    fontSize: '20px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  secondaryListItemText: {
    display: 'inline',
    fontFamily: 'OpenSans-Regular',
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  primaryListItemText: {
    display: 'inline',
    fontFamily: 'OpenSans-Regular',
    fontSize: '0.85rem',
    marginRight: '1%',
  },
  chip: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  chipColor: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  listTextItem: {
    fontFamily: 'OpenSans-Bold',
  },
  listItem: {
    paddingTop: '4px',
    paddingBottom: '4px',
  },

  listItemSelected: {
    paddingTop: '4px',
    paddingBottom: '4px',
    backgroundColor: AppStyle.secondaryColor || '#00c3c2',
    color: '#ffffff',
  },

  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: '#ffffff',
    backgroundColor: AppStyle.primaryColor,
    fontSize: 14,
    fontFamily: 'OpenSans-Bold',
  },

  patientList: {
    paddingTop: '69px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0px',
    },
  },

  tabRoot: {
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    height: 'calc(100vh - 64px)',
    display: 'flex',
    // minWidth: "200px",
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      marginLeft: '-300px',
      // minWidth: "160px"
    },
  },
  lockstyle: {
    fontSize: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
  },

  //   MuiTabroot : {
  //     minWidth: "200px"
  // },

  tabsRoot: {
    fontSize: '14px !important',
    minWidth: '160px',
    fontFamily: 'OpenSans-Bold !important',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px !important',
      minWidth: '110px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '6px !important',
      minWidth: '70px',
    },

  },

  fab: {
    position: 'absolute',
    bottom: '20%',
    right: '10%',
  },

  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[600],
    },
  },
  registerButton: {
    color: theme.palette.common.white,
    margin: 'auto',
    marginTop: '2%',
    marginLeft: '5%',
    // backgroundColor: '#e6d72A',
    backgroundColor: AppStyle.primaryColor,
    '&:hover': {
      backgroundColor: AppStyle.secondaryColor,
    },
    [theme.breakpoints.up('md')]: {
      width: '74px',
      height: '74px',
      borderRadius: '60px',
    },
  },

  sideSheetIcon: {
    [theme.breakpoints.up('sm')]: {
      fontSize: 40,
    },
  },

  actionCardPaper: {
    // margin: theme.spacing(1),
    margin: '0px',
    [theme.breakpoints.up('sm')]: {
      width: '75%',
      margin: 'auto',
    },
  },

  packageCardPaper: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      width: '100%',
      margin: 'auto',
    },
  },

  swipeView: {
    backgroundColor: '#f3f7f7',
  },

  SwipeableViewsDiv: {
    flex: '1',
    overflow: 'auto',
  },

  appBarStyle: {
    backgroundColor: '#ffffff',
  },
  cardWrap: {
    paddingBottom: '15px',
  },
  actioncard: {

    height: '100%',
  },
  paymentcard: {
    width: "100%",


  },
  billcard: {
    display: "flex",
    width: "100%",
  },
  billevent: {
    width: "100%",
    paddingLeft: "3rem"
  },
  card: {
    maxWidth: " 450px",
    maxHeight: 'auto',
    marginTop: '2%',
    margin: 'auto',
    minHeight: '100px'

  },

  staticCard: {
    maxWidth: " 450px",
    maxHeight: 'fit-content',
    marginTop: '2%',
    margin: 'auto',
    minHeight: '100px',
    display: 'flex',
    flexDirection:'column',
    alignItems: 'center'

  },

  staticCardImg: {
    width: '100px',
    height: '100px'
  },

  // expand: {
  //   transform: 'rotate(0deg)',
  //   marginLeft: 'auto',
  //   transition: theme.transitions.create('transform', {
  //     duration: theme.transitions.duration.shortest,
  //   }),
  // },
  // expandOpen: {
  //   transform: 'rotate(180deg)',
  // },
  cardAvatar: {
    backgroundColor: red[500],
  },
  stepperRoot: {
    width: '100%',
  },

  resetContainer: {
    padding: theme.spacing(3),
  },

  vitalContainer: { paddingBottom: '3%', paddingTop: '3%' },
  vitalButton: {
    marginLeft: '60%',
    backgroundColor: AppStyle.primaryColor,
    color: '#ffffff',
  },

  medicationTable: {
    minWidth: 620,

    '& th': {
      fontFamily: 'OpenSans-Bold',
      paddingTop: '5px !important',
      paddingBottom: '5px !important',
    },
    '& td': {
      paddingTop: '5px !important',
      paddingBottom: '5px !important',
    },
    '&.MuiTableCell-root': {
      padding: '5px !important',
    },
  },
  mediheadstyle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '0.85rem',
    },
  },
  medicontstyle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '0.8rem',
    },
  },

  // Indent card styles
  indentCardBody: {
    padding: 0
  },

  refNoText: {
    textAlign: 'center'
  },

  indentCardItemsTableHeader: {
    display: 'flex', 
    justifyContent: 'space-between', 
    alignItems: 'center'
  },

  indentItemsTableHeaderText: {
    flex: 1, 
    textAlign: 'center'
  },

  seperatorContainer: {
    display: 'flex', 
    justifyContent: 'center',
  },

  seperatorLine: {
    width: '90%', 
    height: 0.5, 
    background: '#000',
  },

  indentItemContainer: {
    paddingLeft: 10,
    paddingRight: 10,
    display: 'flex',
    flexDirection: 'row',
    marginTop: 3,
  },

  indentItemItemName: {
    flex: 1, 
    marginBottom: 10
  },

  indentItemItemQty: {
    flex: 1, 
    textAlign: 'center', 
    marginBottom: 10
  },

  cardFooter: {
    display: 'flex', 
    justifyContent: 'center', 
    padding: 0, 
    marginTop: 10
  },

  loadMoreButtonContainer: {
    width: '100%', 
    display: 'flex', 
    justifyContent: 'center'
  },

  loadMoreButton: {
    width: '50%', 
    cursor: 'pointer'
  },

  loadMoreText: {
    textAlign: 'center', 
    lineHeight: 0.5, 
    color: '#000',
  },
  apptCardWrapParrent: {
    position: 'relative',
    fontSize: '0.875rem'
  }
}));

export default homeStyles;
