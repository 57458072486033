import axios from 'axios';
import {loginConstants} from './config';
import {errorHandler} from './errorhandler/ErrorHandler';

const timeOut = 20000;

let authToken = ''; // client auth token

let userAuthToken = ''; // user auth token after they sign up

export function setUserAuthToken(token) {
  userAuthToken = token;
}

export function getUserAuthToken() {
  if(!userAuthToken){
    return  ''
  }


  return `${userAuthToken.token_type} ${userAuthToken.access_token}`;
}

export function setToken(token) {
  authToken = token;
}

const authorizedHttp = axios.create({
  baseURL: loginConstants.MOBILE_PATIENT_APP_BASE_URL,
  timeout: timeOut,
  headers: {'Content-Type': 'application/json'},
});

authorizedHttp.interceptors.request.use(
  async config => {
    config.headers.Authorization = `${userAuthToken.token_type} ${userAuthToken.access_token}`;
    return config;
  },
  error => Promise.reject(error),
);

authorizedHttp.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(errorHandler(error));
  },
);

const http = axios.create({
  baseURL: loginConstants.MOBILE_PATIENT_APP_BASE_URL,
  timeout: timeOut,
  headers: {'Content-Type': 'application/json'},
});

http.interceptors.request.use(
  async config => {
    config.headers.Authorization = `${authToken.token_type} ${authToken.access_token}`;
    return config;
  },
  error => Promise.reject(error),
);

http.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(errorHandler(error));
  },
);

const login = axios.create({
  baseURL: loginConstants.MOBILE_PATIENT_APP_BASE_URL,
  timeout: timeOut,
  headers: {'Content-Type': 'application/x-www-form-urlencoded'},
});

login.interceptors.request.use(request => {
  return request;
});

login.interceptors.request.use(
  async config => {
    return config;
  },
  error => Promise.reject(error),
);
login.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(errorHandler(error));
  },
);

export {http, login, authorizedHttp};
