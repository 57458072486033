import { homescreenConstant } from './../ReduxConstant';

const initialState = {
  events: [],
  eventsRequestInitiated: false,
  error: {},
  hasMore: true,
  selectedUser: {},
  selectedEvent: '',
  addAction: '',
  selectedPatient: 0,
  reloadData: false,
  latestBillDetails: {},
  latestBillDetailsRequestedInitiated: false,
  latestBillDetailError: {},
  dataSyncInit: false,
  dataSync: {},
  dataSyncError: {},
  showPayButton: true,
  voipMeetingData: '',
  currentScreen: '',
  isRefreshHomeScreen: false,
};

export default function homescreenDetails(state = initialState, action) {
  switch (action.type) {
    case homescreenConstant.HOME_EVENT_REQUEST_INITIATED:
      return {
        ...state,
        eventsRequestInitiated: true,
        showPayButton: false,
        error: {},
      };
    case homescreenConstant.HOME_SCREEN_REFRESH:
      return {
        ...state,
        isRefreshHomeScreen: action.data
      };
    case homescreenConstant.HOME_EVENT_REQUEST_SUCCESS:
      return {
        ...state,
        events: action.data.data,
        eventsRequestInitiated: false,
        showPayButton: true,
        //hasMore: action.data.data.length == 0 ? false : true,
        error: {},
      };
    case homescreenConstant.HOME_EVENT_REQUEST_FAILURE:
      return {
        ...state,
        eventsRequestInitiated: false,
        error: action.data,
        showPayButton: false,
      };

    case homescreenConstant.HOME_LATEST_CLAIM_REQUEST_INITIATED:
      return {
        ...state,
        latestBillDetails: {},
        latestBillDetailsRequestedInitiated: true,
        latestBillDetailError: {},
      };

    case homescreenConstant.HOME_LATEST_CLAIM_EVENT_REQUEST_SUCCESS:
      return {
        ...state,
        latestBillDetails: action.data.data,
        latestBillDetailsRequestedInitiated: false,
        //hasMore: action.data.data.length == 0 ? false : true,
        latestBillDetailError: {},
      };

    case homescreenConstant.HOME_LATEST_CLAIM_EVENT_REQUEST_FAILURE:
      return {
        ...state,
        latestBillDetails: {},
        latestBillDetailsRequestedInitiated: false,
        latestBillDetailError: action.data,
      };

    case homescreenConstant.HOME_EVENT_REALM_DATA_LOAD:
      return {
        ...state,
        events: action.data,
        showPayButton: false,
        error: {},
      };

    case homescreenConstant.HOME_EVENT_SHOW_PAY_BUTTON: {
      return {
        ...state,
        showPayButton: false,
      };
    }

    case homescreenConstant.SAVE_SELECTED_USER:
      return {
        ...state,
        selectedUser: action.data,
      };
    case homescreenConstant.SAVE_SELECTED_EVENT:
      return {
        ...state,
        selectedEvent: action.data,
      };
    case homescreenConstant.RESET_SELECTED_USER:
      return {
        ...state,
        selectedUser: {},
      };
    case homescreenConstant.SAVE_ADD_ACTION:
      return {
        ...state,
        addAction: action.data,
      };
    case homescreenConstant.RESET_ADD_ACTION:
      return {
        ...state,
        addAction: '',
      };
    case homescreenConstant.RELOAD_PATIENT_LIST:
      return {
        ...state,
        addAction: '',
        selectedUser: {},
        selectedPatient: 0,
        reloadData: true,
      };
    case homescreenConstant.RESET_RELOAD:
      return {
        ...state,
        reloadData: false,
      };

    case homescreenConstant.HOME_SELECTED_PATIENT:
      return {
        ...state,
        selectedPatient: action.data,
      };
    case homescreenConstant.DATA_SYNC_INIT:
      return {
        ...state,
        dataSyncInit: true,
        // dataSync: {},
        dataSyncError: {},
      };
    case homescreenConstant.DATA_SYNC_SUCCESS:
      return {
        ...state,
        dataSyncInit: false,
        dataSync: action.data.data,
      };
    case homescreenConstant.DATA_SYNC_FAILURE:
      return {
        ...state,
        dataSyncInit: false,
        dataSyncError: action.data,
      };

    case homescreenConstant.VOIP_MEETING_DATA:
      return {
        ...state,
        voipMeetingData: action.data,
      };

    case homescreenConstant.CURRENT_SCREEN: {
      return {
        ...state,
        currentScreen: action.data,
      };
    }
    default:
      return state;
  }
}
