import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { prefix } from '../../AppNavigator';
import { getSubscribedUserList } from 'components/src/redux/subscriptionreducer/SubscriptionAction';
import { getPatientProfileDetails } from 'components/src/redux/patientprofilereducer/PatientProfileAction';
import { getApplicationCredentials } from 'components/src/redux/credentialsreducer/CredentialAction';
import {
  saveUserProfile,
  getSavedUserAuthToken,
  saveApplicationCredentials,
  clearLocalStorage,
} from '../localstorage/LocalStorage';
import { getHospitalDetails } from 'components/src/redux/hospitalreducer/HospitalAction';

const useSwitchScreenHook = setRedirectUrl => {
  const dispatch = useDispatch();

  const userProfileData = useSelector(state => state.profile.data);

  const userAuthToken = useSelector(state => state.signup.userAuthToken);

  const globalError = useSelector(state => state.error);

  const appCredentials = useSelector(state => state.credentials.credentials);
  const hospital = useSelector(state => state.hospital.hospital);

  const savedUserAuthToken = getSavedUserAuthToken();

  const profileRequestInit = useSelector(
    state => state.profile.profileRequestInit,
  );

  const userListInit = useSelector(
    state => state.subscription.subscribedUserRequestInitiated,
  );
  const userList = useSelector(state => state.subscription.data);

  useEffect(() => {
    if (
      !profileRequestInit &&
      (Object.keys(userAuthToken).length ||
        Object.keys(savedUserAuthToken).length) &&
      Object.keys(userProfileData).length === 0
    ) {
      dispatch(getPatientProfileDetails());
      dispatch(getSubscribedUserList());
      dispatch(getApplicationCredentials());
      if (!hospital) {
        dispatch(getHospitalDetails());
      }
    }
  }, [Object.keys(userAuthToken).length]);

  useEffect(() => {
    if (!profileRequestInit && Object.keys(userProfileData).length > 0) {
      saveUserProfile(userProfileData);
      if (
        !userListInit &&
        userList.length === 0 &&
        userProfileData.patientNm == null
      ) {
        setRedirectUrl(`${prefix}/profile`);
      } else if (!userListInit) {
        if (userList && (userList.length > 1 || (userList.length == 1 && !userList[0].appPatientId))) {
          setRedirectUrl(`${prefix}/listPatient`);
        } else {
          setRedirectUrl(`${prefix}/home`);
        }
      }
    }
  }, [userListInit, userList, profileRequestInit]);

  useEffect(() => {
    if (globalError.info) {
      if (globalError.info.statusCode == 401) {
        // console.log('======came to useSwitchScreenHook.js===>')
        clearLocalStorage();
      }
    }
  }, [globalError.key, globalError.info]);

  useEffect(() => {
    if (Object.keys(appCredentials).length) {
      saveApplicationCredentials(appCredentials);
    }
  }, [Object.keys(appCredentials).length]);
};

export default useSwitchScreenHook;
