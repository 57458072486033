import { patientTabEventConstant } from '../ReduxConstant';

const initialState = {
  patientTabEvents: [],
  PatientTabEventInit: false,
  error: {},
};

export default function patientTabEventDetails(state = initialState, action) {
  switch (action.type) {
    case patientTabEventConstant.PATIENT_TAB_EVENT_INIT:
      return {
        ...state,
        patientTabEvents: [],
        PatientTabEventInit: true,
        error: {},
      };
    case patientTabEventConstant.PATIENT_TAB_EVENT_SUCCESS:
      return {
        ...state,
        patientTabEvents: action.data.data,
        PatientTabEventInit: false,
        error: {},
      };
    case patientTabEventConstant.PATIENT_TAB_EVENT_FAILURE:
      return {
        ...state,
        patientTabEvents: [],
        PatientTabEventInit: false,
        error: action.data,
      };
    default:
      return state;
  }
}
