import React from 'react';
import CardHeader from '@material-ui/core/CardHeader';
import CustomAvatar from '../../common/CustomAvatar';
import moment from 'moment-timezone';
import homeStyles from '../Style';
import {dateTimeFormat} from 'components/src/commonutil/CommonUtil';
import {cardHeaders} from './EventCardConst';

export default function EventCardHeader(props) {
  const classes = homeStyles();
  const cardHeader = cardHeaders[props.event];
  const title = props?.title || cardHeader?.title ;
  let subheader = '';

  if (props.time) {
    subheader = moment(props.createdTs)
     // .tz('Asia/Manila')
      .format(dateTimeFormat);
  } else {
    subheader = props.createdTs;
  }
  return (
    <CardHeader
      avatar={
        <CustomAvatar
          class={classes.cardAvatar}
          username={true}
          value={props.name}
        />
      }
      title={title}
      subheader={subheader}
    />
  );
}
