import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { AppStyle } from 'components/AppStyle';

export const CustomCheckbox = withStyles({
  root: {
    color: AppStyle.primaryColor,
    '&$checked': {
      color: AppStyle.primaryColor,
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);
