import React from 'react';
import {Button} from '@material-ui/core';
import {
  getPatienDetailsForAppPatientId,
  relatedPatientList,
} from 'components/src/redux/subscriptionreducer/Selector';
import {connect} from 'react-redux';
import {
  createOrder,
  addPaymentDetails,
} from 'components/src/redux/paymentreducer/PaymentAction';
import {
  loadingOrderRequest,
  getOrderDetails,
  paymentIntiated,
  orderRequestedItemCode,
} from 'components/src/redux/paymentreducer/Selector';
import {reloadPatientList} from 'components/src/redux/homescreenreducer/HomeScreenAction';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from '@material-ui/core/styles';
import styles from './Style';
import paymentHandler from './PaymentHandler';
import {languages} from 'components/src/constants/languages';

import {HSMEETCHATBOT} from '../../useAppHooks';
import WebAnalyticsUtils from '../../WebAnalytics';
import {eventConstants} from 'components/src/constants/firebaseAnalyticsEventConstant';

class Pay extends React.Component {
  componentDidMount() {
   
    if (this.props.paymentMode === 'auto') {
      this.onPayClick();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      loadingOrderRequest,
      orderDetails,
      paymentIntiated,
      data,
    } = this.props;

    if (
      prevProps.loadingOrderRequest === true &&
      loadingOrderRequest === false &&
      orderDetails.orderUUID
    ) {
      this.openPaymentGateWay();
    }

    if (paymentIntiated === false && prevProps.paymentIntiated === true) {
      this.props.reloadHome();
    }
  }

  openPaymentGateWay = () => {
    const {
      orderDetails,
      selectedEvent,
      relatedPatientList,
      paymentBackgroundColor,
    } = this.props;

    const user = getPatienDetailsForAppPatientId(
      relatedPatientList,
      selectedEvent.appPatientId,
    );

    let orderDesc = '';
    if (this.props.paymentMode === 'auto') {
      orderDesc = orderDetails.billItemNm;
    } else {
      orderDesc = selectedEvent.packageName;
    }

    paymentHandler(orderDetails.totalAmt, this.paymentSucess, {
      name: user?.patientName,
      description: orderDesc,
      order_id: orderDetails?.paymentGatewayOrderId,
      contact: `${user?.phoneNumber?.countryCode}${
        user?.phoneNumber?.mobileNumber
      }`,
      paymentBackgroundColor: paymentBackgroundColor,
      imageurl: orderDetails?.hospitalLogo,
      currencyCode: orderDetails?.currencyCode || 'INR',
    });
  };

  paymentSucess = response => {
    const {selectedEvent, orderDetails, savePaymentDetails} = this.props;

    if (response) {
      if (HSMEETCHATBOT && HSMEETCHATBOT.paymentSuccess) {
        HSMEETCHATBOT.paymentSuccess();
      }
    } else {
      let anaylaticData = {};
      anaylaticData.appPatientId = selectedEvent.appPatientId;
      anaylaticData.patientOrderId = orderDetails.patientOrderId;
      anaylaticData = JSON.stringify(anaylaticData);
      WebAnalyticsUtils.trackEvent(eventConstants.PAYMENT_FAILUER, {
        data: anaylaticData,
      });
    }
    let reqData = {};
    reqData.onlinePymCd = response ? response.razorpay_payment_id : '';
    reqData.paymentStatusFlg = response ? true : false;
    reqData.appPatientId = selectedEvent.appPatientId;
    reqData.patientOrderId = orderDetails.patientOrderId;
    reqData.paymentErrorMsg = response ? '' : 'Cancelled';
    reqData.razorPaySignature = reqData.paymentStatusFlg
      ? response.razorpay_signature
      : '';

    savePaymentDetails(reqData);
    if (response) {
      let analyticsData = JSON.stringify(reqData);
      WebAnalyticsUtils.trackEvent(eventConstants.PAYMENT_SUCCESS, {
        data: analyticsData,
      });
    }
  };

  onPayClick = () => {
    const {
      selectedEvent,
      placeOrder,
      data,
      billAmt,
      qty,
      billData,
    } = this.props;

    const reqData = {};
    if (data) {
      reqData.billItemCd = data.packageCode;
      reqData.quantity = qty;
      reqData.subscriptionPckFlg = true;
      reqData.appBillFlg = true;
      reqData.hospitalCd = data.hospitalCode;
    } else if (billData) {
      reqData.billItemCd = billData.appBillCode || billData.billCode;
      reqData.billItemNm = billData.appBillName || billData.billCode;
      reqData.subscriptionPckFlg = false;
      reqData.appBillFlg = true;
      reqData.billAmt = billAmt;
    } else {
      reqData.billItemCd = 'Bill Payment';
      reqData.billItemNm = 'Bill Payment';
      reqData.subscriptionPckFlg = false;
      reqData.appBillFlg = false;
      reqData.billAmt = billAmt;
    }

    reqData.appPatientId = selectedEvent.appPatientId;
    placeOrder(reqData);
    let anaylaticData = JSON.stringify(reqData);
    WebAnalyticsUtils.trackEvent(eventConstants.PAYMENT_INITIATED, {
      data: anaylaticData,
    });
  };

  render() {
    const {
      loadingOrderRequest,
      paymentIntiated,
      // css = {},
      requestedItemCode,
      data,
      classes,
    } = this.props;

    const progress =
      data &&
      requestedItemCode === data.packageCode &&
      (loadingOrderRequest || paymentIntiated);
    const diableButton = loadingOrderRequest || paymentIntiated;

    if (this.props.paymentMode === 'auto') {
      return null;
    }

    return (
      <Button
        disabled={diableButton}
        variant="contained"
        onClick={this.onPayClick}
        className={classes.payButtonStyle}>
        {progress ? <CircularProgress color="inherit" size={16} /> : null}
        {languages[this.props.lang].payNow}
      </Button>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    relatedPatientList: relatedPatientList(state),
    loadingOrderRequest: loadingOrderRequest(state),
    orderDetails: getOrderDetails(state),
    paymentIntiated: paymentIntiated(state),
    requestedItemCode: orderRequestedItemCode(state),
    lang: state.signup.lang,
    //orderError: getOrderErrorDetails(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    placeOrder: data => dispatch(createOrder(data)),
    savePaymentDetails: data => {
      dispatch(addPaymentDetails(data));
    },
    reloadHome: () => dispatch(reloadPatientList()),
  };
};

const Payment = withStyles(styles)(Pay);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Payment);
