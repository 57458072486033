import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getUserProfile } from '../../localstorage/LocalStorage';
import { updateProfile } from 'components/src/redux/registrationreducer/RegistrationAction';
import {getAllHospitalDetails} from 'components/src/redux/hospitalreducer/HospitalAction';
export default function useCompleteAddressHook(
  setUserAddress,
  userAddress,
  address,
  setAddress,
  setProgress,
  setSuccess,
  setBranches
) {
  const userProfile = getUserProfile();
  const dispatch = useDispatch();

  const profileUpdateInit = useSelector(
    state => state.registration.updateProfileDataRequestInitiated,
  );

  const profileUpdateError = useSelector(state => state.registration.error);

  const profileUpdateSuccess = useSelector(
    state => state.registration.profileData,
  );

  const hospitalBranches = useSelector(state => state.hospital.branches);

  useEffect(() => {
    if (!hospitalBranches) {
      dispatch(getAllHospitalDetails());
    }
  }, []);

  useEffect(() => {
    setBranches(hospitalBranches);
  }, [hospitalBranches]);

  useEffect(() => {
    if (!userAddress) {
      setUserAddress(userProfile.contactDetails);
    }
  }, [userAddress]);
  useEffect(() => {
    if (address && !profileUpdateInit) {
      dispatch(updateProfile(address));
    }
  }, [address]);

  useEffect(() => {
    setProgress(profileUpdateInit);
  }, [profileUpdateInit]);

  useEffect(() => {
    if (!profileUpdateInit && Object.keys(profileUpdateSuccess).length) {
      setAddress('');
      setSuccess(true);
    }
  }, [Object.keys(profileUpdateSuccess).length]);
}
