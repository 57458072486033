import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  saveSelectedEvent,
  getLatestBillDetailsOfPatient,
} from 'components/src/redux/homescreenreducer/HomeScreenAction';
import { showMedicationTab } from 'components/AppStyle';

export default function useHomeTabsHook(
  setAddAction,
  setSelectedUser,
  setLockTab,
  setValue,
  tabCode,
  setClaimDetails,
  setProgress,
  setTabs,
  tabList,
  setMedTabPresent
) {
  const dispatch = useDispatch();
  const savedAction = useSelector(state => state.home.addAction);
  const claimDetails = useSelector(state => state.home.latestBillDetails);

  useEffect(() => {
    setAddAction(savedAction);

    if (savedAction === 'REG' || savedAction === 'SUB' || savedAction === 'LISTPATIENT') {
      setValue(0);
    }
  }, [savedAction]);

  useEffect(() => {
    let tabs = tabList.filter(i => i.code !== 'MED' || showMedicationTab);
    setMedTabPresent(tabs.find(e => e.code === 'MED') !== undefined)
    setTabs(tabs);
  }, []);


  useEffect(() => {
    setClaimDetails(claimDetails.latestClaimDetails);
  }, [claimDetails]);

  const selectedUserId = useSelector(state => {
    const savedUser = state.home.selectedUser;
    if (Object.keys(savedUser).length) {
      return savedUser.appPatientId;
    }
    return 0;
  });

  const selectedUser = useSelector(state => state.home.selectedUser);

  useEffect(() => {
    setSelectedUser(selectedUser);
    if (selectedUserId && selectedUser.packageCode == null) {
      setLockTab(true);
      setValue(0);
    } else {
      if (tabCode === 'BIL' && selectedUserId > 0) {
        dispatch(getLatestBillDetailsOfPatient(selectedUserId));
      }
      setLockTab(false);
    }
  }, [selectedUserId]);

  useEffect(() => {
    if (tabCode === 'BIL' && selectedUserId > 0) {
      dispatch(getLatestBillDetailsOfPatient(selectedUserId));
    }
  }, [selectedUser, tabCode]);

  useEffect(() => {
    dispatch(saveSelectedEvent(tabCode || 'SUB'));
  }, [tabCode]);
}
