import React, {useState} from 'react';
import {Container, CircularProgress} from '@material-ui/core';
import CustomContainer from '../common/CustomContainer';
import MenuBar from '../menubar/MenuBar';
import {registrationContainerColor} from './ConstStyle';
import useStyles from './Style';
import useRegisterHook from './UseRegisterHook';
import Profile from './Profile';
import Register from './Register';
import useMyDetailsHook from './UseMyDetailsHook';
import Skeleton from 'react-loading-skeleton';
import { AppStyle } from 'components/AppStyle';
import { languages } from 'components/src/constants/languages';

function PatientRegistration(props) {
  const classes = useStyles();
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const [userData, setUserData] = useState();
  const [isProfile, setIsProfile] = useState(
    props.location.pathname.includes('profile'),
  );
  const [userNumber, setUserNumber] = useState();
  const [cancel, setCancel] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [branches , setBranches] = useState(null)

  useMyDetailsHook(setUserNumber, userNumber);

  useRegisterHook(
    userData,
    setUserData,
    setOpenBackdrop,
    setErrorMsg,
    props.history,
    cancel,
    setBranches
  );

  const validateAddress = address => {
    if (
      address &&
      address.addresLineOne &&
      address.zipCode &&
      address.cityName &&
      address.stateName &&
      address.countryName
    ) {
      return true;
    } else {
      if(!address.addresLineOne){
setAddressError('addresLineOne');
      }else{
        setAddressError('zipcode');
      }
      
      
      return false;
    }
  };

  const registerUser = formData => {
    if (isProfile) {
      formData.patientMobileNo = userNumber;
      formData.relation = 'Self';
    }
    if (
      !formData?.patientMobileNo ||
      !formData?.dob ||
      !formData?.relation ||
      !formData?.patientName ||
      !validateAddress(formData?.address) ||
      !formData?.gender
    ) {
      setErrorMsg(languages[props.lang].enterAllFields);
      return;
    }
    setUserData(formData);
  };

  if (!userNumber) {
    return <CircularProgress />;
  }

  if (openBackdrop) {
    return (
      <Container maxWidth="sm">
        <Skeleton count={6} height={10} />
      </Container>
    );
  }
  if (isProfile) {
    return (
      <>
        <MenuBar history={props.history} lang={props.lang} />
        <CustomContainer
          openBackdrop={false}
          displayDialog={errorMsg ? true : false}
          dialogTitle={'Error'}
          dialogMessage={errorMsg}
          dialogType={'error'}
          style={registrationContainerColor}
          footerStyle={{color: AppStyle.primaryColor}}>
          <Container maxWidth="sm" className={classes.registrationContainer}>
            <Profile
              registerUser={registerUser}
              userNumber={userNumber}
              setErrorMsg={setErrorMsg}
              addressError={addressError}
              lang={props.lang}
              branches={branches}
            />
          </Container>
        </CustomContainer>
      </>
    );
  } else {
    return (
      <CustomContainer
        openBackdrop={false}
        displayDialog={errorMsg ? true : false}
        dialogTitle={'Error'}
        dialogMessage={errorMsg}
        dialogType={'error'}
        style={registrationContainerColor}
        footerStyle={{color: AppStyle.primaryColor}}>
        <Container maxWidth="sm">
          <Register
            registerUser={registerUser}
            userNumber={userNumber}
            setErrorMsg={setErrorMsg}
            close={setCancel}
            addressError={addressError}
            lang={props.lang}
            branches={branches}
          />
        </Container>
      </CustomContainer>
    );
  }
}
export default PatientRegistration;
