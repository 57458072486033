import React, { useState , useEffect } from 'react';
import useStyles from './Style';
import {
  Grid,
  FormControlLabel,
  Button,
  Typography,
  TextField,
  FormHelperText,
} from '@material-ui/core';
import Title from '../common/Title';
import PhoneInputComponent from '../common/PhoneInputComponent';
import {
  patientRelation,
  genderList,
  isValidDob,
} from 'components/src/commonutil/CommonUtil';
import PatientName from './PatientName';
import PatientAddress from './PatientAddress';
import CustomButtonGroup from '../common/CustomButtonGroup';
import CustomDatepicker from '../common/CustomDatepicker';
import {
  phoneNumberContainerStyle,
  phoneNumberInputStyle,
  registrationTitleColor,
} from './ConstStyle';
import { CustomCheckbox } from '../common/CustomCheckbox';
import CustomSelect from '../common/CustomSelect';
import { AppStyle } from 'components/AppStyle';
import { languages } from 'components/src/constants/languages';
import { FormControl, Select, InputLabel, MenuItem } from '@material-ui/core';
import WebAnalyticsUtils from '../../WebAnalytics';
import { eventConstants } from 'components/src/constants/firebaseAnalyticsEventConstant';
import { getHospitalCodeFromParams } from '../localstorage/LocalStorage';
function Register(props) {
  const classes = useStyles();
  const [patientMobileNo, setPatientMobileNo] = useState();
  const [dob, setDob] = useState(new Date());
  const [gender, setGender] = useState(genderList[0]);
  const [relation, setRelation] = useState(patientRelation[0]);
  const [patientName, setPatientName] = useState();
  const [hospitalCd, setHospitalCd] = useState('Select Hospital Branch');
  const [address, setAddress] = useState();
  const [myNumber, setMyNumber] = useState();
  const [showBranchSelection, setShowBranchSelection] = useState(false);
  const [showBranchSelectionError, setShowBranchSelectionError] = useState(
    null,
  );
const [showDOBSelectionError, setShowDOBSelectionError] = useState(
    false
  );
  
  useEffect(() => {
    if(props.branches && props.branches.length > 1){
      let selBranch = getHospitalCodeFromParams();
      if(selBranch){
      setHospitalCd(selBranch);
      }
      let defaultH = props.branches.find(d => d.defaultHospitalRegistrationFlg)
      if(!defaultH){
        defaultH = props.branches[0]
      }
      setShowBranchSelection(true)
    }else if(props.branches && props.branches.length==1){
      setHospitalCd(props.branches[0].hospitalCd)
    }
  },[props.branches])

  const handleMyNumberChange = value => {
    if (value && document.getElementById('firstName')) {
      document.getElementById('firstName').focus();
    }
    WebAnalyticsUtils.trackEvent(eventConstants.USE_MY_NUMBER, {Mob_Num: props.userNumber})
    setMyNumber(value);
  };

  const handlePhoneNumberChange = (countryCode, mobileNo) => {
    WebAnalyticsUtils.trackEvent(eventConstants.USE_NEW_NUMBER, {Mob_Num: `${countryCode}-${mobileNo}`})
    setPatientMobileNo({
      countryCode: countryCode,
      mobileNumber: mobileNo,
    });
  };

  const handleDateChange=(selectedDob)=>{
    setDob(selectedDob);
    if(showDOBSelectionError){
      setShowDOBSelectionError(false)
    }
  }

 

  const handleOnClick = () => {
    if (
      dob && isValidDob(dob) &&
      gender &&
      patientName &&
      address &&
      relation &&
      patientMobileNo &&
      hospitalCd !== 'Select Hospital Branch'
    ) {
      const formData = {
        dob,
        gender,
        patientName,
        patientMobileNo: myNumber ? props.userNumber : patientMobileNo,
        address,
        relation,
      };
        formData.hospitalCode = hospitalCd
        let analyticsProfileData = JSON.stringify(formData)
        WebAnalyticsUtils.trackEvent(eventConstants.REGISTATION_NEW_PATIENT, {user_details:analyticsProfileData})
        props.registerUser(formData);
    } else if(dob && !isValidDob(dob)){
      if(!showDOBSelectionError){
      setShowDOBSelectionError(true);
      }
    }else {
      props.setErrorMsg(
        languages[props.lang].needInfoToProvideBestCare,
      );
      if(hospitalCd === 'Select Hospital Branch'){
      setShowBranchSelectionError(true);
      }
      WebAnalyticsUtils.trackEvent(eventConstants.REGISTATION_FAILED, {errorMsg: languages[props.lang].enterAllFields})
    }
  };

  const handleClose = () => {
    WebAnalyticsUtils.trackEvent(eventConstants.REGISTATION_CANCLE, {})
    props.close(true);
  };

  return (
    <>
      <Title
        header={languages[props.lang].registerPatient}
        subHeader={languages[props.lang].patientDetails}
        style={registrationTitleColor}
      />
      <form noValidate className={classes.form}>
        <Grid container spacing={3} className={classes.registrationGrid}>
          {showBranchSelection ? (
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <InputLabel required >Select Branch</InputLabel>
                <Select 
                 value={hospitalCd}
                 onChange = { (e) => {
                   setHospitalCd(e.target.value)
                 }}>
                   <MenuItem value="Select Hospital Branch">
                    Select Hospital Branch
                  </MenuItem>
                  {props.branches.map(d => (
                    <MenuItem value={d.hospitalCd} key={d}>
                      {d.hospitalNm}
                    </MenuItem>
                  ))}
                </Select>
                {showBranchSelectionError ? (
                  <FormHelperText style={{color: 'red'}}>
                    Hospital Branch is Required .
                  </FormHelperText>
                ) : null}
              </FormControl>
            </Grid>) : null}

          <Grid item xs={5}>
            <FormControlLabel
              control={
                <CustomCheckbox
                  onChange={event => {
                    handleMyNumberChange(event.target.checked);
                  }}
                  value="mynum"
                />
              }
              label={languages[props.lang].useMyNumber}
              style={{ fontSize: '15px', fontFamily: 'OpenSans-Regular' }}
            />
          </Grid>
          <Grid item xs={7}>
            {myNumber ? (
              <TextField
                id="mobileNo"
                label={languages[props.lang].phoneNumber}
                defaultValue={`+${props.userNumber.countryCode}-${props.userNumber.mobileNumber}`}
                InputProps={{
                  readOnly: true,
                }}
              />
            ) : (
              <PhoneInputComponent
                onChange={handlePhoneNumberChange}
                inputStyle={phoneNumberInputStyle}
                containerStyle={phoneNumberContainerStyle}
              />
            )}
          </Grid>
          <PatientName setPatientName={setPatientName} lang={props.lang} />
          <Grid item container spacing={3}>
            <Grid item xs={12} lg={6}>
              <CustomDatepicker
                label={languages[props.lang].dob}
                handleDateChange={handleDateChange}
              />
              {showDOBSelectionError ? (
                  <FormHelperText style={{color: 'red'}}>
                    {languages[props.lang].invalidDob}
                  </FormHelperText>
                ) : null}
            </Grid>
            <Grid item xs={12} lg={6}>
              <CustomButtonGroup
                containerStyle={classes.genderSection}
                labelStyle={classes.genderLabel}
                buttonGroupLabel={languages[props.lang].gender}
                buttonGroupStyle={classes.buttonGroup}
                buttonGroupName={languages[props.lang].genderSelection}
                items={genderList}
                handleSelect={setGender}
              />
            </Grid>
          </Grid>
          <PatientAddress branches={props.branches} selectedBranch={hospitalCd} setAddress={setAddress} error={props.addressError} lang={props.lang} />
          <Grid item xs={12}>
            <CustomSelect
              selectLabel={languages[props.lang].relation}
              items={patientRelation}
              onChange={setRelation}
            />
          </Grid>
          <Grid container xs={12}>
            <Grid item xs={6}>
              <Button
                type="button"
                variant="contained"
                style={{
                  marginLeft: '30%',
                  backgroundColor: AppStyle.primaryColor,
                  color: '#ffffff',
                }}
                onClick={e => handleOnClick()}>
                {languages[props.lang].registerCap}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                type="button"
                variant="contained"
                style={{
                   marginLeft: '30%',
                  backgroundColor: AppStyle.primaryColor,
                  color: '#ffffff',
                }}
                onClick={e => handleClose()}>
                {languages[props.lang].cancel}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
export default Register;
