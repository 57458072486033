import React, {useState} from 'react';
import useStyles from './Style';
import Button from '@material-ui/core/Button';
import OtpInput from 'react-otp-input';
import CustomToolTip from './CustomToolTip';
import WebAnalyticsUtils from '../../WebAnalytics';
import { eventConstants } from 'components/src/constants/firebaseAnalyticsEventConstant';
import { getKinesisWebEventVO } from '../../utils/KinesisWebUtil';
import { kinesisUIEventBeforeLogin } from 'components/src/redux/kinesisReducer/kinesisActions';
import { useDispatch } from 'react-redux';
import { actionType, eventScreenName, eventType } from 'components/src/constants/KinesisConstants';

function Otp(props) {
  const classes = useStyles();
  const [otp, setOtp] = useState();
  const dispatch = useDispatch();

  const handleOtpChange = value => {
    setOtp(value);
    if (value.length === 5) {
      let eventVO = getKinesisWebEventVO(eventScreenName.patientSignupScreen, eventType.loginAttempt, "button_verifyOtp", '' , actionType.onAutoSelect, '', '');
      dispatch(kinesisUIEventBeforeLogin(eventVO, 'web'));
      WebAnalyticsUtils.trackEvent(props.isFromSignup?eventConstants.ENTER_OTP:eventConstants.VERIFY_ASSOCIATED_USER_ENTER_OTP, {})
      props.setOtp(value);
    }
  };

  return (
    <div className={classes.parentIconDiv}>
      <div>
        <CustomToolTip
          open={props.errorMsg ? true : false}
          message={
            props.errorMsg ? (
              <div style={{marginTop: '-15px'}}>{props.errorMsg}</div>
            ) : null
          }
          type={'error'}>
          <OtpInput
            onChange={value => {
              handleOtpChange(value);
            }}
            value={otp}
            numInputs={5}
            containerStyle={classes.phoneNumberContainerStyle}
            inputStyle={classes.otpInputStyle}
            shouldAutoFocus={true}
          />
        </CustomToolTip>
      </div>
      <div  className={classes.resendOtp}>
      <div className={classes.resendOTPContainerStyle}>
        <Button
           type="button"
           variant="contained"
           color="primary"
          className={classes.resendOtpButton}
          onClick={() => {
            WebAnalyticsUtils.trackEvent(props.isFromSignup?eventConstants.RESEND_OTP:eventConstants.VERIFY_ASSOCIATED_USER_RESEND_OTP,{})
            props.resendOtp()
            let eventVO = getKinesisWebEventVO(eventScreenName.patientSignupScreen, eventType.loginAttempt, "button_resendOtp", '' , actionType.onClick, '', '');
            dispatch(kinesisUIEventBeforeLogin(eventVO, 'web'));
          }}
          disabled={props.disabled}>
          Resend OTP
        </Button>
      </div>
       <div className={classes.sendVoiceOTPContainerStyle}>
        <Button
           type="button"
           variant="contained"
           color="primary"
          className={classes.resendOtpButton}
          onClick={() => {
            WebAnalyticsUtils.trackEvent(props.isFromSignup?eventConstants.SEND_VOICE_OTP:eventConstants.VERIFY_ASSOCIATED_USER_SEND_VOICE_OTP,{})
            props.sendVoiceOtp()
            let eventVO = getKinesisWebEventVO(eventScreenName.patientSignupScreen, eventType.loginAttempt, "button_getOtpByCall", '' , actionType.onClick, '', '');
            dispatch(kinesisUIEventBeforeLogin(eventVO, 'web'));

          }}
          disabled={props.disabled}>
          Get OTP by voice call
        </Button>
      </div>
      </div>
    </div>
  );
}
export default Otp;
