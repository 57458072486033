export const eventType = {
    login: "login",
    appointment: "appointment",
    download: "download",
    loginAttempt: "loginAttempt",
    newUserLogin: "newUserLogin"
}

export const eventScreenName = {
    patientListMenu: "patientList_menu",
    patientSignupScreen: "patientSignupScreen",
    patientProfileScreen: "patientProfileScreen",
    patientListPatienScreen: "patientListPatienScreen"
}

export const actionType = {
    onClick: "onClick",
    onAutoSelect: "onAutoSelect",
    onLoad: "onLoad"
}
