import React, {useEffect} from 'react';
import HomeTabPanel from './../HomeTabPanel';
import ActionCard from './../ActionCard';
import MedicationTable from './MedicationTable';
import {Typography, Container} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import EventCardHeader from '../../eventcard/EventCardHeader';
import Divider from '@material-ui/core/Divider';
import useMedicationTabHook from './UseMedicationTabHook';
import {useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import {languages} from 'components/src/constants/languages';

export default function MedicationTab(props) {
  const selectedUser = props.selectedUser;
  const userId =
    selectedUser && Object.keys(selectedUser).length
      ? selectedUser.appPatientId
      : 0;
  const [currentMedication, setCurrentMedication] = useState();
  const [previousMedication, setPreviousMedication] = useState();
  const [progress, setProgress] = useState();

  useMedicationTabHook(
    setCurrentMedication,
    setPreviousMedication,
    userId,
    setProgress,
  );

  if (progress) {
    return (
      <Container maxWidth="md">
        <Skeleton count={5} height={6} />
      </Container>
    );
  }
  if (userId == 0) {
    return (
      <Typography>
        {languages[props.lang].selectUserToViewMedication}
      </Typography>
    );
  }

  return (
    <>
      {currentMedication ? (
        <HomeTabPanel value={props.value} index={props.index} dir={props.dir}>
          <ActionCard display={true}>
            <Card style={{overflowX: 'scroll'}}>
              <EventCardHeader
                name={selectedUser.patientName}
                event={'CURRENTMED'}
              />
              <Divider />
              <CardContent>
                <MedicationTable rows={currentMedication} lang={props.lang} />
              </CardContent>
            </Card>
          </ActionCard>
        </HomeTabPanel>
      ) : null}
      {previousMedication ? (
        <HomeTabPanel value={props.value} index={props.index} dir={props.dir}>
          <ActionCard display={true}>
            <Card style={{overflowX: 'scroll'}}>
              <EventCardHeader
                name={selectedUser.patientName}
                event={'PREVMED'}
              />
              <Divider />
              <CardContent>
                <MedicationTable rows={previousMedication} lang={props.lang} />
              </CardContent>
            </Card>
          </ActionCard>
        </HomeTabPanel>
      ) : null}
    </>
  );
}
