import React, {useEffect} from 'react';
import SwipeableViews from 'react-swipeable-views';
import {useTheme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import EventCard from '../eventcard/EventCard';
import homeStyles from '../Style';
import HomeTabPanel from './HomeTabPanel';
import TabFab from './tabfab/TabFab';
import ActionCard from './ActionCard';
import useHomeTabsHook from './UseHomeTabsHook';
import {useState} from 'react';
import SubscriptionTab from './SubscriptionTab';
import BillTab from './billtab/BillTab';
import CustomTab from './CustomTab';
import {withRouter} from 'react-router-dom';
import MedicationTab from './medicationtab/MedicationTab';
import {languages} from 'components/src/constants/languages';
import WebAnalyticsUtils from '../../../WebAnalytics';

import Appointment from './appointmenttab/Appointment';
import RecordTab from './Records/RecordTab';

function a11yProps(index) {
  return {
    id: `action-tab-${index}`,
    'aria-controls': `action-tabpanel-${index}`,
  };
}

const tabList = [
  {
    title: 'HOME',
    code: 'SUB',
    addLock: false,
    ref: '/home/general',
    color: '#6fc062',
    translationKey: 'webHome',
  },
  {
    title: 'APPOINTMENTS',
    code: 'myappt',
    addLock: true,
    ref: '/home/appointments',
    color: '#6fc062',
    translationKey: 'webAppointments',
  },
  {
    title: 'MEDICATIONS',
    code: 'MED',
    addLock: true,
    ref: '/home/medication',
    color: '#6fc062',
    translationKey: 'webMedications',
  },
  // {
  //   title: 'LAB TESTS',
  //   code: 'INVSTR',
  //   addLock: true,
  //   ref: '/home/lab',
  //   color: '#6fc062',
  //   translationKey: 'webLabTests',
  // },
  {
    title: 'RECORDS',
    code: 'myhealth',
    addLock: true,
    ref: '/home/docnote',
    color: '#6fc062',
    translationKey: 'webRecords',
  },
  {
    title: 'BILLS & PAYMENTS',
    code: 'mybill',
    addLock: true,
    ref: '/bill',
    color: '#6fc062',
    translationKey: 'webBills',
  },
];

function HomeTabs(props) {
  const classes = homeStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [addAction, setAddAction] = useState('');
  const [selectedUser, setSelectedUser] = useState();
  const [claimDetails, setClaimDetails] = useState();
  const [lockTab, setLockTab] = useState(false);
  const [tabCode, setTabCode] = useState();
  const [progress, setProgress] = useState(false);
  const [tabs, setTabs] = useState(tabList);
  const [medTabPresent, setMedTabPresent] = useState(true);

  // if (userId === 0) {
  //   return (
  //     <Typography>
  //       {languages[props.lang].selectUserToViewBills}
  //     </Typography>
  //   );
  // }

  useHomeTabsHook(
    setAddAction,
    setSelectedUser,
    setLockTab,
    setValue,
    tabCode,
    setClaimDetails,
    setProgress,
    setTabs,
    tabList,
    setMedTabPresent,
  );

  const handleChange = (event, newValue) => {
    WebAnalyticsUtils.trackEvent(tabs[newValue].title, {
      details: tabs[newValue],
    });
    setValue(newValue);
    setTabCode(tabs[newValue].code);
  };

  const handleChangeIndex = index => {
    setValue(index);
    setTabCode(tabs[index].code);
  };

  if (!medTabPresent) {
    return (
      <div className={classes.tabRoot}>
        {addAction !== 'REG' && addAction !== 'LISTPATIENT' ? (
          <AppBar position="static" className={classes.appBarStyle}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              aria-label="action tabs example">
              {tabs.map((tab, index) => (
                <CustomTab
                  className={classes.tabsRoot}
                  key={index}
                  label={languages[props.lang][tab.translationKey]}
                  href={tab.ref}
                  lock={lockTab && tab.addLock}
                  color={tab.color}
                  lang={props.lang}
                  {...a11yProps(index)}
                />
              ))}
            </Tabs>
          </AppBar>
        ) : null}

        <div className={classes.SwipeableViewsDiv}>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
            className={classes.swipeView}>
            {value == 0 ? (
              <SubscriptionTab
                value={value}
                index={0}
                dir={theme.direction}
                action={addAction}
                tabCode={tabs[0].code}
                location={props.location}
                selectedUser={selectedUser}
                lang={props.lang}
              />
            ) : null}
            {tabCode === 'myappt' ? (
              <Appointment
                value={value}
                index={value}
                dir={theme?.direction}
                action={addAction}
                tabCode={'myappt'}
                location={props?.location}
                selectedUser={selectedUser}
                lang={props?.lang}
              />
            ) : null}

            {tabCode === 'myhealth' ? (
              <RecordTab
                value={value}
                index={value}
                dir={theme.direction}
                action={addAction}
                tabCode={tabCode}
                location={props.location}
                selectedUser={selectedUser}
                lang={props.lang}
              />
            ) : null}

            {tabCode === 'mybill' ? (
              <BillTab
                value={value}
                claimDetails={claimDetails}
                index={value}
                dir={theme.direction}
                action={addAction}
                tabCode={tabCode}
                location={props.location}
                selectedUser={selectedUser}
                lang={props.lang}
              />
            ) : null}
          </SwipeableViews>
        </div>
        {!addAction &&
        selectedUser &&
        Object.keys(selectedUser).length &&
        tabs[value].code !== 'myappt' ? (
          <TabFab value={value} action={addAction} />
        ) : null}
      </div>
    );
  } else {
    return (
      <div className={classes.tabRoot}>
        {addAction !== 'REG' && addAction !== 'LISTPATIENT' ? (
          <AppBar position="static" className={classes.appBarStyle}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              aria-label="action tabs example">
              {tabs.map((tab, index) => (
                <CustomTab
                  className={classes.tabsRoot}
                  key={index}
                  label={languages[props.lang][tab.translationKey]}
                  href={tab.ref}
                  lock={lockTab && tab.addLock}
                  color={tab.color}
                  lang={props.lang}
                  {...a11yProps(index)}
                />
              ))}
            </Tabs>
          </AppBar>
        ) : null}

        <div className={classes.SwipeableViewsDiv}>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
            className={classes.swipeView}>
            {value == 0 ? (
              <SubscriptionTab
                value={value}
                index={0}
                dir={theme.direction}
                action={addAction}
                tabCode={tabs[0].code}
                location={props.location}
                selectedUser={selectedUser}
                lang={props.lang}
              />
            ) : null}
            {tabCode === 'myappt' ? (
              <Appointment
                value={value}
                index={value}
                dir={theme?.direction}
                action={addAction}
                tabCode={'myappt'}
                location={props?.location}
                selectedUser={selectedUser}
                lang={props?.lang}
              />
            ) : null}
            {tabCode === 'MED' ? (
              <MedicationTab
                value={value}
                index={value}
                dir={theme?.direction}
                action={addAction}
                tabCode={'MED'}
                location={props?.location}
                selectedUser={selectedUser}
                lang={props?.lang}
              />
            ) : null}
            {tabCode === 'myhealth' ? (
              <RecordTab
                value={value}
                index={value}
                dir={theme.direction}
                action={addAction}
                tabCode={tabCode}
                location={props.location}
                selectedUser={selectedUser}
                lang={props.lang}
              />
            ) : null}

            {tabCode === 'mybill' ? (
              <BillTab
                value={value}
                claimDetails={claimDetails}
                index={value}
                dir={theme.direction}
                action={addAction}
                tabCode={tabCode}
                location={props.location}
                selectedUser={selectedUser}
                lang={props.lang}
              />
            ) : null}
          </SwipeableViews>
        </div>
        {!addAction &&
        selectedUser &&
        Object.keys(selectedUser).length &&
        tabs[value].code !== 'myappt' ? (
          <TabFab value={value} action={addAction} />
        ) : null}
      </div>
    );
  }
}

export default withRouter(HomeTabs);
