import {hospitalConstant, userChats} from '../ReduxConstant';

const initialState = {
  hospital: undefined,
  getHospitalDetailsInit: false,
  userChats: {},
  error: {},
};

export default function hospitalDetails(state = initialState, action) {
  switch (action.type) {
    case hospitalConstant.GET_HOSPITAL_DETAILS_INIT:
      return {
        ...state,
        getHospitalDetailsInit: true,
        error: {},
      };
    case hospitalConstant.GET_HOSPITAL_DETAILS_SUCCESS:
      return {
        ...state,
        hospital: action.data.data,
        getHospitalDetailsInit: false,
        error: {},
      };
    case hospitalConstant.GET_HOSPITAL_DETAILS_FAILURE:
      return {
        ...state,
        hospital: undefined,
        getHospitalDetailsInit: false,
        error: action.data,
      };
    case hospitalConstant.GET_HOSPITAL_APPURL_INIT:
      return {
        ...state,
        appUrl: undefined,
        error: {},
      };
    case hospitalConstant.GET_HOSPITAL_APPURL_SUCCESS:
      return {
        ...state,
        appUrl: action.data.data,
        error: {},
      };
    case hospitalConstant.GET_HOSPITAL_APPURL_FAILURE:
      return {
        ...state,
        appUrl: undefined,
        error: action.data,
      };
    case hospitalConstant.GET_ALLHOSPITAL_INIT:
      return {
        ...state,
        branches: undefined,
        error: {},
      };
    case hospitalConstant.GET_ALLHOSPITAL_SUCCESS:
      return {
        ...state,
        branches: action.data.data,
        error: {},
      };
    case hospitalConstant.GET_ALLHOSPITAL_FAILURE:
      return {
        ...state,
        branches: undefined,
        error: action.data,
      };
    case userChats.UNREAD_CHATS_SUCCESS:
      let staten = {...state};
      if (staten.userChats) {
        staten.userChats[action.data.patientCode] = action.data.value;
      }
      return {
        ...staten,
      };

    default:
      return state;
  }
}
