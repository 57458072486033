import React from 'react';
import {prefix} from '../../AppNavigator';
import moment from 'moment';

const keys = {
  languageSelected: '@csoft:languageSelected',
  language: '@csoft:language',
}

export function getAppAuthToken() {
  if (window && window.localStorage) {
    return JSON.parse(window.localStorage.getItem('appAuthToken'));
  }
}

export function saveUserAuthToken(token) {
  if (window && window.localStorage) {
    token.expiryTime = moment()
      .add(token.expires_in, 'seconds')
      .toDate();

    window.localStorage.setItem('userAuthToken', JSON.stringify(token));
  }
}

export function getSavedUserAuthToken() {
  if (window && window.localStorage) {
    return JSON.parse(window.localStorage.getItem('userAuthToken'));
  }
}

export function saveRefernce(refrence){
  if (window && window.localStorage && refrence) {
    window.localStorage.setItem('refrence', refrence.replace(/[^0-9a-z]/gi, ''));
  }
}

export function getRefernce(){
  if (window && window.localStorage) {
   return  window.localStorage.getItem('refrence');
  }
}


export function saveUserProfile(userProfile) {
  if (window && window.localStorage) {
    window.localStorage.setItem('userProfile', JSON.stringify(userProfile));
  }
}

export function getUserProfile() {
  let userProfile = {};
  if (
    window &&
    window.localStorage &&
    window.localStorage.getItem('userProfile')
  ) {
    userProfile = JSON.parse(window.localStorage.getItem('userProfile'));
  }
  return userProfile;
}

export function clearLocalStorage() {
  if (window && window.localStorage) {
    window.localStorage.removeItem('userAuthToken');
    window.localStorage.removeItem('userProfile');
    window.localStorage.removeItem('appAuthToken');
    window.localStorage.removeItem('credentials');
    window.location.href = prefix + '/';
  }
}

export function saveApplicationCredentials(credentials) {
  if (window && window.localStorage) {
    window.localStorage.setItem('credentials', JSON.stringify(credentials));
  }
}

export function getApplicationCredentials() {
  if (window && window.localStorage) {
    return JSON.parse(window.localStorage.getItem('credentials'));
  }
}

export function saveIsLanguageSelected(data) {
  if (window && window.localStorage) {
    window.localStorage.setItem(keys.languageSelected, JSON.stringify(data));
  }
}

export function getIsLanguageSelected() {
  if (window && window.localStorage) {
    return JSON.parse(window.localStorage.getItem(keys.languageSelected));
  }
}


export function savePreferedLanguage(data) {
  if (window && window.localStorage) {
    window.localStorage.setItem(keys.language, JSON.stringify(data));
  }
}

export function getPreferedLanguage() {
  if (window && window.localStorage) {
    return JSON.parse(window.localStorage.getItem(keys.language));
  }
}


export function getHospitalCodeFromParams (){
  if(window && window.localStorage.WORKFLOW_INITIAL_ARGS){
    let selBranch = JSON.parse(window?.localStorage?.WORKFLOW_INITIAL_ARGS);
      selBranch = selBranch?.hospitalCode;
      return selBranch;
  }
}

