import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import useEventCardHook from './UseEventCardHook';
import Error from '@material-ui/icons/Error';
import moment from 'moment-timezone';
import Divider from '@material-ui/core/Divider';
import homeStyles from '../Style';
import EventCardHeader from './EventCardHeader';
import Skeleton from 'react-loading-skeleton';
import {statusIcon} from './EventCardConst';
import {dateTimeFormat} from 'components/src/commonutil/CommonUtil';

import EventCardDetails from './EventCardDetails';

export default function EventCard(props) {
  const classes = homeStyles();
  const [pageNo, setPageNo] = React.useState(0);
  const [events, setEvents] = React.useState([]);
  const [progress, setProgress] = React.useState(false);
  const [user, setUser] = React.useState(null)
const [selectedHospital, setSelectedHospital] = React.useState(null)
const [patientContactDetails, setPatientContactDetails] = React.useState(null)
  useEventCardHook(pageNo, setPageNo, setEvents, setProgress, setUser, props.code, setSelectedHospital, setPatientContactDetails);

  if (progress) {
    return (
      <Card className={classes.card}>
        <CardHeader>
          <Skeleton count={1} height={2} />
        </CardHeader>
        <CardContent>
          <Skeleton count={5} height={1} />
        </CardContent>
      </Card>
    );
  }


  return (
    <>
      {events.map((element, index) => (
        <Card className={classes.card} key={index}>
          
          <EventCardHeader
            title={element.description.title}
            name={element.description.name || element.description.patientName}
            event={element.eventType}
            time={true}
            createdTs={
              element.eventType === 'CLNI'
                ? element?.description?.noteCreatedTs
                : element?.eventType === 'VTL'
                ? element?.description?.recordedOn
                : element?.createdTs
            }
            status={element?.description?.paymentStatus}
          />
          <Divider />

          <CardContent 
            className={classes.subscriptionCard}
          >
            {element?.description?.paymentStatus ? statusIcon[element?.description?.paymentStatus] : null}
            { element?.eventType === 'BIL' && 
              element?.description?.updateCard && 
              element?.description?.billedTs && 
              moment(element?.description?.billedTs).diff(moment(), 'hours') < 24 ? 
              statusIcon['U'] : null
            }
            
            <br />
            
            <EventCardDetails event={element} events={events} user={user} selectedHospital={selectedHospital} lang={props.lang}  patientContactDetails={patientContactDetails}/>
          </CardContent>
        </Card>
      ))}
    </>
  );
}
