import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
  saveAddAction,
  resetAddAction,
} from 'components/src/redux/homescreenreducer/HomeScreenAction';

export default function useSideSheetHook(setAction, action, userSelected) {
  const dispatch = useDispatch();
  const addAction = useSelector(state => state.home.addAction);

  useEffect(() => {
    setAction(addAction);
  }, [addAction]);

  useEffect(() => {
    dispatch(saveAddAction(action));
  }, [action]);

  useEffect(() => {
    // if (userSelected) {
    dispatch(resetAddAction());
    //}
  }, [userSelected]);
}
