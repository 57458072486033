import { fcmConfigurationConstant, signupConstant } from './../ReduxConstant';
import { http, login, setToken, setUserAuthToken } from './../axiosApi';
import { loginConstants } from './../config';
import { showErrorToast } from '../errorhandler/ErrorHandleReducer';
import Base64 from '../../commonutil/Base64';
import { actionType, eventScreenName, eventType } from '../../constants/KinesisConstants';
import { getKinesisWebEventVO } from '../../../../web/src/utils/KinesisWebUtil';
import { kinesisUIEventBeforeLogin } from '../kinesisReducer/kinesisActions';

let clientSecret = null;

export function initiateSaveUser() {
  return {
    type: signupConstant.SIGNUP_REQUEST_INITIATED,
  };
}

export function saveUserSuccess(data) {
  return {
    type: signupConstant.SIGNUP_RESPONSE_SUCCESS,
    data,
  };
}

export function saveUserFailure(data) {
  return {
    type: signupConstant.SIGNUP_RESPONSE_FAILURE,
    data,
  };
}

export function initiateAuthRequest() {
  return {
    type: signupConstant.AUTH_REQUEST_INTIATED,
  };
}

export function authSuccess(data) {
  return {
    type: signupConstant.AUTH_REQUEST_SUCCESS,
    data,
  };
}

export function authFailure(data) {
  return {
    type: signupConstant.AUTH_REQUEST_FAILURE,
    data,
  };
}

export function initiateUserAuthRequest() {
  return {
    type: signupConstant.USER_AUTH_REQUEST_INTIATED,
  };
}

export function userAuthSuccess(data) {
  return {
    type: signupConstant.USER_AUTH_REQUEST_SUCCESS,
    data,
  };
}

export function userAuthFailure(data) {
  return {
    type: signupConstant.USER_AUTH_REQUEST_FAILURE,
    data,
  };
}

export function loadUserConatctDetailsSuccess(data) {
  return {
    type: signupConstant.LOAD_USER_CONTACT_DETAILS_TO_REDUX_SUCCESS,
    data,
  };
}

export function loadUserContactDetails(data) {
  return (dispatch) => {
    dispatch(loadUserConatctDetailsSuccess(JSON.parse(data)));
  };
}

export function setLang(data) {
  return {
    type: signupConstant.LANGUAGE,
    data,
  };
}
export function initiatefcmConfiguration() {
  return {
    type: fcmConfigurationConstant.FCM_CONFIG_REQUEST_INITIATED,
  };
}

export function fcmConfigurationSuccess(data) {
  if (data && (typeof data === 'string' || data instanceof String)) {
    try {
      data = JSON.parse(data)
    } catch (e) {
      console.log('fcmConfigurationSuccess====>', e)
    }
  }
  return {
    type: fcmConfigurationConstant.FCM_CONFIG_RESPONSE_SUCCESS,
    data,
  };
}

export function fcmConfigurationFailure(data) {
  return {
    type: fcmConfigurationConstant.FCM_CONFIG_RESPONSE_FAILURE,
    data,
  };
}

export function getAuthToken(isWeb = true) {
  return (dispatch) => {
    dispatch(initiateAuthRequest());
    dispatch(initiatefcmConfiguration());
    let data = { clientId: loginConstants.CLIENT_ID };
    let parameter = `client_id=${loginConstants.CLIENT_ID}&grant_type=client_credentials`;
    if (!isWeb) {
      data = {
        clientId: loginConstants.MOBILE_CLIENT_ID,
        bundleId: loginConstants.BUNDLE_ID,
      };
      parameter = `client_id=${loginConstants.MOBILE_CLIENT_ID}&grant_type=client_credentials`;
    }

    http
      .post('client/v2/getToken', data)
      .then((rep) => {
        dispatch(fcmConfigurationSuccess(rep.data.data.fcmConfiguration));
        clientSecret = Base64.atob(rep.data.data.clientSecret);
        login
          .post(`oauth/token?${parameter}&client_secret=${clientSecret}`)
          .then((response) => {
            setToken(response.data);
            dispatch(authSuccess(response.data));
            let eventVO = getKinesisWebEventVO(eventScreenName.patientSignupScreen, eventType.loginAttempt, '', '' , actionType.onLoad, '', '');
             dispatch(kinesisUIEventBeforeLogin(eventVO, isWeb?'web':'mobile'));
          })
          .catch((error) => {
            dispatch(authFailure(error));
            dispatch(showErrorToast(error));

          });
      })
      .catch((error) => {
        dispatch(authFailure(error));
        dispatch(showErrorToast(error));
        dispatch(fcmConfigurationFailure(error));

      });
  };
}

export function saveUserDetails(data) {
  return (dispatch) => {
    dispatch(initiateSaveUser());

    http
      .post('api/saveUserDetails', data)
      .then((response) => {
        dispatch(saveUserSuccess(response.data));
      })
      .catch((error) => {
        dispatch(saveUserFailure(error));
        dispatch(showErrorToast(error));
      });
  };
}

export function logout(history, redirectUrl = '/') {
  return (dispatch) => {
    dispatch({
      type: signupConstant.LOGOUT,
    });
  };
}

export function getUserAuthToken(data) {
  return (dispatch, getState) => {
    const platform = getState().platform;
    dispatch(initiateUserAuthRequest());


    let parameter = `client_id=${loginConstants.CLIENT_ID}&grant_type=password&client_secret=${clientSecret}`;
    if (!platform.web) {
      parameter = `client_id=${loginConstants.MOBILE_CLIENT_ID}&grant_type=password&client_secret=${clientSecret}`;
    }

    login
      .post(
        `oauth/token?username=${data.username}&password=${data.password}&${parameter}`,
      )
      .then((response) => {
        if (response.status === 200 && response.data.token_type === 'bearer') {
          setUserAuthToken(response.data);
          dispatch(userAuthSuccess(response.data));
        }
      })
      .catch((error) => {
        console.log('errorerrorerrorerrorerrorerror ===>>>>>');
        dispatch(userAuthFailure(error));
        dispatch(showErrorToast(error));
      });
  };
}

export function refreshAuthToken(refreshToken, isWeb = true) {
  return (dispatch) => {

    dispatch(initiateUserAuthRequest());
    let data = { clientId: loginConstants.CLIENT_ID };
    let parameter = `client_id=${loginConstants.CLIENT_ID}&grant_type=refresh_token`;
    if (!isWeb) {
      data = {
        clientId: loginConstants.MOBILE_CLIENT_ID,
        bundleId: loginConstants.BUNDLE_ID,
      };
      parameter = `client_id=${loginConstants.MOBILE_CLIENT_ID}&grant_type=refresh_token`;
    }
    http
      .post('client/v2/getToken', data)
      .then((rep) => {
        dispatch(fcmConfigurationSuccess(rep.data.data.fcmConfiguration));
        clientSecret = Base64.atob(rep.data.data.clientSecret);
        login
          .post(`oauth/token?refresh_token=${refreshToken}&${parameter}&client_secret=${clientSecret}`)
          .then((response) => {
            if (response.status === 200 && response.data.token_type === 'bearer') {
              setUserAuthToken(response.data);
              dispatch(userAuthSuccess(response.data));
            }
          })

          .catch((error) => {
            dispatch(userAuthFailure(error));
            dispatch(showErrorToast(error));
          });
      })
      .catch((error) => {
        dispatch(authFailure(error));
        dispatch(showErrorToast(error));
      });
  };
}