import React, {useState} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';

import {useTheme} from '@material-ui/core/styles';
import MenuBar from '../menubar/MenuBar';

import homeStyles from './Style';
import SideSheet from './sidesheet/SideSheet';

import HomeTabs from './hometab/HomeTabs';
import DataSync from './datasync/DataSync';

function Home(props) {
  const classes = homeStyles();
  const [navDrawerOpen, setNavDrawerOepn] = useState(false);
  const [sideSheetOpen, setSideSheetOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState();

  /* const handleDrawerToggle = () => {
    setNavDrawerOepn(!navDrawerOpen);
  }; */

  const handleSideSheetToggle = () => {
    setSideSheetOpen(!sideSheetOpen);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <MenuBar
        class={classes.appBar}
        //  handleDrawerToggle={handleDrawerToggle}
        handleSideSheetToggle={handleSideSheetToggle}
        home={true}
        history={props.history}
        lang={props.lang}
      />

      <SideSheet
        handleDrawerToggle={handleSideSheetToggle}
        mobileOpen={sideSheetOpen}
        lang={props.lang}
      />
      {/*  <HomeScreenNavigation
        setSelectedMenu={setSelectedMenu}
        handleDrawerToggle={handleDrawerToggle}
        mobileOpen={navDrawerOpen}
      /> */}
      <main className={classes.content}>
        <div className={classes.contentClearance} />
        <DataSync lang={props.lang} />
        <HomeTabs location={props.location} history={props.history} lang={props.lang} />
      </main>
    </div>
  );
}

export default Home;
