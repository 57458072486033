import {notificationConstant} from '../ReduxConstant';

const initialState = {
  tabNotifications: {},
  intiCall: false,
  refreshTab: {},
  error: false,
};

export default function notificationDetails(state = initialState, action) {
  switch (action.type) {
    case notificationConstant.TAB_NOTIFICATION_RECEIVED:
      return {
        ...state,
        tabNotifications: action.data,
        intiCall: false,
        error: false,
      };

    case notificationConstant.TAB_NOTIFICATION_CLEAR:
      return {
        ...state,
        tabNotifications: {},
        intiCall: false,
        error: false,
      };

    case notificationConstant.TAB_NOTIFICATION_INIT:
      return {
        ...state,
        tabNotifications: {},
        intiCall: true,
        error: false,
      };

    case notificationConstant.TAB_NOTIFICATION_ERROR:
      return {
        ...state,
        tabNotifications: {},
        intiCall: false,
        error: true,
      };

    case notificationConstant.TAB_REFRESH:
      return {
        ...state,
        refreshTab: action.data,
      };

    case notificationConstant.TAB_REFRESH_RESET:
      return {
        ...state,
        refreshTab: {},
      };

    default:
      return state;
  }
}
