import React from 'react';

export const homescreenFeeds = state =>
  state.home.events ? state.home.events : [];

export const getShowPayButton = state =>
  state.home.showPayButton ? state.home.showPayButton : false;


export const refreshHomescreen = state =>
  state.home.isRefreshHomeScreen

export const subscriptionFeeds = state =>
  state.home.events.filter(e => {
    return e.eventType === 'SUB';
  });

export const latestClaim = state =>
  state.home.latestBillDetails ? state.home.latestBillDetails : null;

export const homescreenFeedsLoading = state =>
  state.home.eventsRequestInitiated;

export const latestClaimLoading = state =>
  state.home.latestBillDetailsRequestedInitiated;

export const subscriptionRenewalFeeds = state =>
  state.home.events.filter(e => {
    return e.eventType === 'SUBR';
  });

export const getSelctedPatientId = state => {
  return state.home.selectedPatient;
};

export const selectedUser = state => {
  return state.home.selectedUser;
};
export const outstandingBalanceFeeds = state =>
  state.home.events.filter(e => {
    return e.eventType === 'OUTBAL' && e.description.paymentStatus !== 'P';
  });
