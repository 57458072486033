import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CompleteAddress from '../../registerpatient/completeaddress/CompleteAddress';
import VerifyUser from '../../registerpatient/verifyuser/VerifyUser';
import PackageSet from '../../packagelist/Plan';
import {useEffect} from 'react';
import homeStyles from '../Style';
import { languages } from 'components/src/constants/languages';

export default function CompleteProfileStepper(props) {
  const classes = homeStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  useEffect(() => {
    setActiveStep(0);
  }, [props.appPatientId]);

  const handleStepComplete = () => {
    setActiveStep(activeStep + 1);
  };

  const completeProfileSteps = [
    {
      name: 'Complete Verification',
      content: (
        <VerifyUser
          countryCode={props.countryCode}
          phoneNo={props.phoneNo}
          associationId={props.associationId}
          setSuccess={handleStepComplete}
        />
      ),
    },
    {
      name: 'Complete Profile',
      content: (
        <CompleteAddress
          appPatientId={props.appPatientId}
          setSuccess={handleStepComplete}
          lang={props.lang}
        />
      ),
    },
    {
      name: 'Select Plan',
      content: <PackageSet hideClose={true} />,
    },
  ];

  let steps = completeProfileSteps;

  if (props.verified && !props.profileComplete) {
    steps = completeProfileSteps.filter(
      item => item.name !== 'Complete Verification',
    );
  } else if (!props.verified && props.profileComplete) {
    steps = completeProfileSteps.filter(
      item => item.name !== 'Complete Profile',
    );
  }

  return (
    <div className={classes.stepperRoot}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((value, index) => (
          <Step key={value.name}>
            <StepLabel>{value.name}</StepLabel>
            <StepContent>{value.content}</StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>{languages[props.lang].subscriptionSuccessful}</Typography>
        </Paper>
      )}
    </div>
  );
}
