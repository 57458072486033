import { patientTabEventConstant } from '../ReduxConstant';
import { authorizedHttp } from '../axiosApi';

export function getEventDetailsForPatientByTabCd(appPatientId, tabCode) {
  return {
    // Types of actions to emit before and after
    types: [
      patientTabEventConstant.PATIENT_TAB_EVENT_INIT,
      patientTabEventConstant.PATIENT_TAB_EVENT_SUCCESS,
      patientTabEventConstant.PATIENT_TAB_EVENT_FAILURE,
    ],

    callAPI: () =>
      authorizedHttp.get(`api/getEventDetailsForPatientByTabCode?appPatientId=${appPatientId}&tabCode=${tabCode}`),
  };
}

