import React from 'react';
import {connect} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import {
  Card,
  CardContent,
  Typography,
  Button,
  CardHeader,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Pay from './Pay';
import {getBillingPackages} from 'components/src/redux/subscriptionreducer/SubscriptionAction';
import {
  billingPackage,
  loadingBillingPackage,
} from 'components/src/redux/subscriptionreducer/Selector';
import {resetAddAction} from 'components/src/redux/homescreenreducer/HomeScreenAction';
import {selectedUser} from 'components/src/redux/homescreenreducer/Selector';
import {withStyles} from '@material-ui/core/styles';
import styles from './Style';
import CustomSelect from '../common/CustomSelect';
import {languages} from 'components/src/constants/languages';
import {getSubscribedUserList} from 'components/src/redux/subscriptionreducer/SubscriptionAction';
import CheckforSubscription from './CheckforSubscription';

const cardcolor = ['#709f0d', '#797979', '#1076dc', '#dc8b12'];

const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

class PackageList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      months: 1,
      componentSelect: null,
    };
  }

  componentDidMount() {
    const {user, branches} = this.props;
    if (branches?.hospital?.length > 0 && !user.hospitalPatientCode) {
      const selectedHospital = branches.hospital.find(
        d => d?.hospitalCd == user?.hospitalCode,
      );
      if (selectedHospital?.autoSubscribe) {
        this.props.getSubscribedUserList();
        this.setState({componentSelect: 'refresh'});
      } else {
        if (!this.props.packages || this.props.packages.length == 0) {
          this.props.getPackages('DEFAULT', this.props.user);
        }
        this.setState({componentSelect: 'plans'});
      }
    } else {
      if (!this.props.packages || this.props.packages.length == 0) {
        this.props.getPackages('DEFAULT', this.props.user);
      }
      this.setState({componentSelect: 'plans'});
    }
  }

  handleMonthChange = value => {
    this.setState({months: value});
  };

  packageCard = (item, user, i, packageDesc) => {
    const backgroundColor = cardcolor[i % cardcolor.length];

    const {classes} = this.props;

    const createMarkup = htmlString => ({__html: htmlString});
    const html = item.packageDesc;
    const num = html.replace(new RegExp('"', 'g'), '');

    return (
      <Grid xs={12} sm={12} md={3} item className={classes.packageContainer}>
        <Card className={classes.cardHeader} style={{backgroundColor}}>
          <div className={classes.parentCardDiv}>
            <div className={classes.cardDivStyle}>{item.packageName}</div>
          </div>

          <CardContent className={classes.packageDescDiv}>
            <Grid container className={classes.gridContainerStyle}>
              <Grid item xs={6} sm={12}>
                <div dangerouslySetInnerHTML={createMarkup(num)} />
              </Grid>
            </Grid>

            <div className={classes.cardContentDiv}>
              <Grid container className={classes.gridContainerStyle}>
                {this.props.hideClose && item.renewalAmt > 0 ? (
                  <Grid item xs={4}>
                    <CustomSelect
                      selectLabel={'Month'}
                      items={months}
                      onChange={this.handleMonthChange}
                    />
                  </Grid>
                ) : null}

                {this.props.hideClose && item.renewalAmt >= 0 ? (
                  <Grid item xs={8}>
                    <Pay
                      data={item}
                      selectedEvent={user}
                      onSucess={this.props.onSucess}
                      paymentBackgroundColor={backgroundColor}
                      className={classes.payButtonStyle}
                      qty={item.renewalAmt == 0 ? 2 : this.state.months}
                    />
                  </Grid>
                ) : null}
              </Grid>

              <div className={classes.divStyle}>
                <div>
                  <span className={classes.spanHeaderStyle}>
                    {languages[this.props.lang].oneTimeMembership}:
                  </span>
                  <span className={classes.spanAmountText}>
                    {`${languages[this.props.lang].rs}. ${item.packageAmount}`}
                  </span>
                </div>
                <div className={classes.divSubHeaderText}>
                  {languages[this.props.lang].monthlyFee}:{' '}
                  <span className={classes.spanRenewalText}>
                    {languages[this.props.lang].rs}.{item.renewalAmt}
                  </span>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  render() {
    const {loading, packages, user, classes} = this.props;
    const {componentSelect} = this.state;

    if (loading || !componentSelect) {
      return (
        <Grid
          container
          justifyContent="center"
          spacing={0}
          className={classes.gridContent}>
          <CircularProgress />
        </Grid>
      );
    }
    if ('refresh' == componentSelect) {
      return <CheckforSubscription />;
    }

    if (packages.length === 0) {
      return <div>{languages[this.props.lang].noPackage}</div>;
    }

    return (
      <div>
        <div className={classes.packageTextHeader}>
          {this.props.hideClose ? null : (
            <Button
              // className={classes.closeButton}
              type="button"
              variant="contained"
              color="default"
              onClick={() => this.props.closePackageDisplay()}>
              {languages[this.props.lang].close}
            </Button>
          )}
          {Object.keys(user).length ? (
            <Typography
              style={{
                padding: '6px',
                flex: 1,
              }}>{`Choose Care Package for ${user.patientName}`}</Typography>
          ) : null}
        </div>

        <Grid container className={classes.root} spacing={1}>
          {packages.map((d, i) => this.packageCard(d, user, i))}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    packages: billingPackage(state),
    loading: loadingBillingPackage(state),
    user: selectedUser(state),
    lang: state.signup.lang,
    branches: state.hospital,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getPackages: (rateCategory, user) => {
      dispatch(getBillingPackages(rateCategory, user));
    },
    closePackageDisplay: () => {
      dispatch(resetAddAction());
    },
    getSubscribedUserList: () => dispatch(getSubscribedUserList()),
  };
}

const PackageSet = withStyles(styles)(PackageList);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PackageSet);
