import {credentialConstant} from '../ReduxConstant';

const initialState = {
  credentials: {},
  credentialInit: false,
  error: {},
};

export default function credentialDetails(state = initialState, action) {
  switch (action.type) {
    case credentialConstant.CREDENTIAL_INIT:
      return {
        ...state,
        credentials: {},
        credentialInit: true,
        error: {},
      };
    case credentialConstant.CREDENTIAL_SUCCESS:
      return {
        ...state,
        credentials: action.data.data,
        credentialInit: false,
        error: {},
      };
    case credentialConstant.CREDENTIAL_FAILURE:
      return {
        ...state,
        credentials: {},
        credentialInit: false,
        error: action.data,
      };

    default:
      return state;
  }
}
