import { otpConstant } from './../ReduxConstant';
import { http } from './../axiosApi';
import { authorizedHttp } from './../axiosApi';
import { errorHandler } from 'components/src/redux/errorhandler/ErrorHandler';

export function initiateOTP() {
  return {
    type: otpConstant.OTP_REQUEST_INTIATED,
  };
}

export function otpSuccess(data) {
  return {
    type: otpConstant.OTP_RESPONSE_SUCCESS,
    data,
  };
}

export function otpFailure(data) {
  return {
    type: otpConstant.OTP_RESPONSE_FAILURE,
    data,
  };
}

export function initiateVerfiyOTP() {
  return {
    type: otpConstant.VERIFY_OTP_REQUEST_INITIATED,
  };
}

export function verifyOTPSuccess(data) {
  return {
    type: otpConstant.VERIFY_OTP_REQUEST_SUCCESS,
    data,
  };
}

export function verifyOTPFailure(data) {
  return {
    type: otpConstant.VERIFY_OTP_REQUEST_FAILURE,
    data,
  };
}

export function sendOTPReq(countryCode, mobileNumber) {
  const data = {};
  data.mobileNumber = mobileNumber;
  data.countryCode = countryCode;
  return dispatch => {
    dispatch(initiateOTP());
    http
      .post('api/sendOTP', data)
      .then(response => {
        dispatch(otpSuccess(response.data));
      })
      .catch(error => {
        dispatch(otpFailure(error));
      });
  };
}

export function resendOTPReq(countryCode, mobileNumber) {
  const data = {};
  data.mobileNumber = mobileNumber;
  data.countryCode = countryCode;
  return dispatch => {
    dispatch(initiateOTP());
    http
      .post('api/resendOTP', data)
      .then(response => {
        dispatch(otpSuccess(response.data));
      })
      .catch(error => {
        dispatch(otpFailure(error));
      });
  };
}

export function verifyOTPReq(countryCode, mobileNumber, otp) {
  const data = {};
  const phoneNumber = {};
  phoneNumber.mobileNumber = mobileNumber;
  phoneNumber.countryCode = countryCode;
  phoneNumber.otp = otp;
  data.phoneNumber = phoneNumber;
  data.deviceInfo = {};
  return dispatch => {
    dispatch(initiateVerfiyOTP());
    http
      .post('api/verifyOTP', data)
      .then(response => {
        if (response.data.status) {
          dispatch(verifyOTPSuccess(response.data));
        } else {
          dispatch(verifyOTPFailure({ message: 'Invalid OTP', statusCode: 400 }));
        }
      })
      .catch(error => {
        dispatch(verifyOTPFailure(error));
      });
  };
}

export function sendOTP(data) {
  return dispatch => {
    dispatch(initiateOTP());
    http
      .post('api/sendOTP', data)
      .then(response => {
        dispatch(otpSuccess(response.data));
      })
      .catch(error => {
        dispatch(otpFailure(error));
      });
  };
}
export function verifyOTP(data) {
  return dispatch => {
    dispatch(initiateVerfiyOTP());
    http
      .post('api/verifyOTP', data)
      .then(response => {
        if (response.data.status) {
          dispatch(verifyOTPSuccess(response.data));
        } else {
          dispatch(verifyOTPFailure(response.data));
        }
      })
      .catch(error => {
        dispatch(verifyOTPFailure(error));
      });
  };
}

export function resendOTP(data) {
  return dispatch => {
    dispatch(initiateOTP());
    http
      .post('api/resendOTP', data)
      .then(response => {
        dispatch(otpSuccess(response.data));
      })
      .catch(error => {
        dispatch(otpFailure(error));
      });
  };
}

export function sendOTPToAssociatedPatient(data) {
  return dispatch => {
    dispatch(initiateOTP());
    authorizedHttp
      .post('api/sendOTP', data)
      .then(response => {
        dispatch(otpSuccess(response.data));
      })
      .catch(error => {
        dispatch(otpFailure(error));
      });
  };
}

export function resendOTPToAssociatedPatient(data) {
  return dispatch => {
    dispatch(initiateOTP());
    authorizedHttp
      .post('api/resendOTP', data)
      .then(response => {
        dispatch(otpSuccess(response.data));
      })
      .catch(error => {
        dispatch(otpFailure(error));
      });
  };
}

export function verifyOTPOfAssociatedUser(data) {
  return dispatch => {
    dispatch(initiateVerfiyOTP());
    authorizedHttp
      .post('api/verifyOTPOfAssociatedUser', data)
      .then(response => {
        if (response.data.status) {
          dispatch(verifyOTPSuccess(response.data));
        } else {
          dispatch(verifyOTPFailure({ message: 'Invalid OTP', statusCode: 400 }));
        }
      })
      .catch(error => {
        dispatch(verifyOTPFailure(error));
      });
  };
}

export function resetVerifyOtpData() {
  return dispatch => {
    dispatch({
      type: otpConstant.RESET_VERIFY_OTP,
    });
  };
}
