import React, {useState} from 'react';
import Viewer from 'react-viewer';
import useCustomFileViewerHook from './UseCustomFileViewerHook';
import {
  findResourceType
} from 'components/src/commonutil/CommonUtil';

export default function CustomFileViewer(props) {
  const [awsCredentials, setAwsCredentials] = useState();
  const [viewerSrc, setViewerSrc] = useState();
  const [visible, setVisible] = useState(false);
   const lowerCaseFileTypeNm = (props.filePath).toLowerCase();
  const [resourceType,setResourceType] = useState(findResourceType(lowerCaseFileTypeNm));
   
 
  useCustomFileViewerHook(
    awsCredentials,
    setAwsCredentials,
    setVisible,
    props.filePath,
    props.bucketName,
    setViewerSrc,
    props.fileType,
    props.fileName,
    props.close,
    resourceType,
  );


  return (
    <div>
      {viewerSrc &&  (resourceType==='jpg')  ? (
        <Viewer
          visible={visible}
          onClose={() => {
            setVisible(false);
            props.close();
          }}
          images={[{src: viewerSrc, alt: ''}]}
        />
      ) : null}
      {/*    {viewerSrc && props.fileType === 'pdf' ? (
        <PDFViewer
          document={{
            url: viewerSrc,
          }}
        />
      ) : null} */}
    </div>
  );
}
