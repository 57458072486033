import { makeStyles } from '@material-ui/core/styles';
import { AppStyle } from 'components/AppStyle';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
      width: '100%',
    },
  },

  containerStyle: {
    background: AppStyle.bodybackground,
    flexGrow: 1,
    minHeight:
      '700px' /* when contry drop down opens background was not covered because its absolute position thats why added minimum height */,
  },

  appUrlContainer: {
    ['@media (max-width:420px)']: {
      display: 'block',
      justifyContent: 'center',
      textAlign: 'center',
      '& a': {
        display: 'inline-block',
        clear: 'both',
      },
    },
    display: 'flex',
    justifyContent: 'center',
  },
  hyperLinkWhite: {
    color: 'white',
  },
  policyContainer: {
    ['@media (max-width:420px)']: {
      display: 'block',
      justifyContent: 'center',
      textAlign: 'center',
      '& a': {
        display: 'inline-block',
        clear: 'both',
      },
    },
    display: 'flex',
    justifyContent: 'center',
    fontSize: '8px',
    color: 'white'
  },
  appleLogo: {
    width: '150px',
    maxWidth: '100%',
    maxHeight: '200px',
    margin: '5px',
    ['@media (max-width:420px)']: {
      display: 'block',
      margin: '5px auto',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    width: '100%',
    textAlign: 'center',

    '& img': {
      marginTop: 50,
    },
    '& .MuiCard-root': {
      overflow: 'unset',
    },
    '& h1': { marginTop: theme.spacing(2) },
  },

  divClassContainer: {
    marginTop: '20px',
    fontFamily: 'OpenSans-Regular',
    fontWeight: '100',
  },

  imageStyle: {
    width: '150px',
    maxWidth: '100%',
    maxHeight: '200px',
  },
  otpImageStyle: {
    maxWidth: '100%',
    position: 'absolute',
    left: '102%',
    top: '65px',
    '@media (max-width: 800px)': {
      display: 'none',
    },
  },
  textField: {
    width: '100%',
    textAlign: 'left',
    marginTop: '30px',
    marginBottom: '15px',
  },

  largeIcon: {
    fontSize: '2em',
    color: 'black',
    marginTop: '14px',
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: '20px',
    maxWidth: '400px',
    border: '10px',
    backgroundColor: 'white',
    borderRadius: '10px',
    padding: '25px 35px',
    fontFamily: 'OpenSans-Regular',
    position: 'relative',
  },

  headerText: {
    fontFamily: 'OpenSans-Regular',
  },

  subHeaderText: {
    fontFamily: 'OpenSans-Regular',
    fontSize: '14px',
    marginTop: '5px',
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
  },

  parentIconDiv: {
    position: 'relative',
  },

  divIconStyle: {
    borderRadius: '100%',
    position: 'absolute',
    marginTop: '-5px',
    left: '40%',
    width: '52px',
    background: '#fff',
    textAlign: 'center',
    height: '52px',
  },

  phoneInputStyle: {
    color: 'black',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 55,
    fontFamily: 'OpenSans-Regular',
    fontSize: '20px',
    textAlign: 'left',
    left: '0',
  },

  captchaDivStyle: {
    width: '304px',
    margin: '0 auto',
  },

  verifyOtpDiv: {
    position: 'absolute',
    width: '100%',
    left: '0px',
    top: '0px',
    background: '#ddd',
    borderRadius: '10px 10px  0px 0px',
    height: '115px',
  },

  parentVerifyOtpDiv: {
    paddingBottom: 20,
  },

  formLabelColor: {
    color: 'red',
  },

  // phoneInputContainerStyle:{
  //     color: 'black',
  //     display: 'flex',
  //     justifyContent: 'center',
  //     alignItems: 'center',
  //     height: 55,
  //   },

  //   phoneInputStyle:{
  //     height: 55,
  //     fontFamily: 'OpenSans-Regular',
  //     fontSize: '20px',
  //     textAlign: 'left',
  //   },

  resendOtpButton: {
    borderRadius: '15px',
    padding: '5px',
    margin: '10px 0 0 80px',
    width: '45%',
    textAlign: 'center',
  },
  sendOtpButton: {
    borderRadius: '15px',
    padding: '5px',
    width: '45%',
    textAlign: 'center',
  },
  resendOtpDiv: {
    display: 'flex',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  avatar: {
    margin: 'auto',
    background: AppStyle.bodybackground,
    sizes: '30px',
    // },

    // position:'absolute',
    // top:'100px'
  },
  signupCard: {
    minWidth: 275,
    flexDirection: 'row',
    position: 'relative',
  },
  logoDiv: {
    height: '230px',
  },
}));
export default useStyles;
