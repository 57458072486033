import React, {useState} from 'react';
import Otp from '../common/Otp';
import useSignupOtpHook from './UseSignupOtpHook';

export default function SignUpOtp(props) {
  const [errorMsg, setErrorMsg] = useState();
  const [sendOTP, setSendOtp] = useState(true);
  const [resendOtp, setResendOtp] = useState(false);
  const [otp, setOtp] = useState();
  const handleResendOtp = () => {
   setSendOtp(true);
  };

  const sendVoiceOtp = ()=>{
    setResendOtp(true)
  }

  useSignupOtpHook(
    props.countryCode,
    props.phoneNo,
    sendOTP,
    setSendOtp,
    setErrorMsg,
    otp,
    setOtp,
    props.openBackdrop,
    resendOtp,
    setResendOtp
  );

  return (
    <Otp
      errorMsg={errorMsg}
      disabled={false}
      resendOtp={handleResendOtp}
      setOtp={setOtp}
      sendVoiceOtp={sendVoiceOtp}
      isFromSignup={true}
    />
  );
}
