import {registrationConstant} from './../ReduxConstant';

const initialState = {
  registerData: {},
  registerPatientIntiated: false,
  verifyPatientIntiated: false,
  profileData: {},
  matchingProfileData: {},
  updateProfileDataRequestInitiated: false,
  error: {},
  isValidationMethodCalled: false,
};

export default function registrationDetails(state = initialState, action) {
  switch (action.type) {
    case registrationConstant.REGISTRAION_REQUEST_INTIATED:
      return {
        ...state,
        registerData: {},
        registerPatientIntiated: true,
        error: {},
      };
    case registrationConstant.REGISTRAION_RESPONSE_SUCCESS:
      return {
        ...state,
        registerData: action.data,
        registerPatientIntiated: false,
        error: {},
      };
    case registrationConstant.REGISTRATION_RESPONSE_FAILURE:
      return {
        ...state,
        registerData: {},

        registerPatientIntiated: false,
        error: action.data,
      };

    case registrationConstant.VALIDATE_REGISTRATION_INTIATED:
      return {
        ...state,
        verifyPatientIntiated: true,
        error: {},
      };
    case registrationConstant.VALIDATE_REGISTRATION_RESPONSE_SUCCESS:
      return {
        ...state,
        verifyPatientIntiated: false,
        error: {},
      };
    case registrationConstant.VALIDATE_REGISTRATION_RESPONSE_FAILURE:
      return {
        ...state,
        verifyPatientIntiated: false,
        error: action.data,
      };
    case registrationConstant.SAVE_PATIENT_REGISTRATION_DATA_TO_REDUX_INTIATED: {
      return {
        ...state,
        patientRegistrationAppDataSaveInitiated: true,
        patientRegistrationAppDataSaveCompleted: false,
        error: {},
      };
    }
    case registrationConstant.SAVE_PATIENT_REGISTRATION_DATA_TO_REDUX_SUCCESS: {
      return {
        ...state,
        patientRegistrationAppData: action.data,
        patientRegistrationAppDataSaveInitiated: false,
        patientRegistrationAppDataSaveCompleted: true,
        error: {},
      };
    }
    case registrationConstant.CHECK_USER_EXISTS_INTITATED:
      return {
        ...state,
        matchingProfileData: {},
        verifyPatientIntiated: true,
        error: {},
      };
    case registrationConstant.CHECK_USER_EXISTS_RESPONSE_SUCCESS:
      return {
        ...state,
        matchingProfileData: action.data,
        verifyPatientIntiated: false,
        error: {},
      };
    case registrationConstant.CHECK_USER_EXISTS_RESPONSE_FAILURE:
      return {
        ...state,
        matchingProfileData: {},
        verifyPatientIntiated: false,
        error: action.data,
      };
    case registrationConstant.UPDATE_PROFILE_REQUEST_INITIATED:
      return {
        ...state,
        updateProfileDataRequestInitiated: true,
        error: {},
      };
    case registrationConstant.UPDATE_PROFILE_REQUEST_SUCCESS:
      return {
        ...state,
        profileData: action.data,
        updateProfileDataRequestInitiated: false,
        error: {},
      };
    case registrationConstant.UPDATE_PROFILE_REQUEST_FAILURE:
      return {
        ...state,
        profileData: {},
        updateProfileDataRequestInitiated: false,
        error: action.data,
      };
    case registrationConstant.RESET_REGISTRATION_RESPONSE:
      return {
        ...state,
        registerData: {},
        registerPatientIntiated: false,
        error: {},
      };

    default:
      return state;
  }
}
