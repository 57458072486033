import React, {useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import useStyles from './Style';
import {validateFormInput} from 'components/src/commonutil/CommonUtil';

function CustomTextfield(props) {
  const classes = useStyles();
  const [textValue, setTextValue] = useState();
  const [error, setError] = useState();

  const handleBlur = id => {
    let fieldId = id;
    if (id === 'firstName' || id === 'lastName') {
      fieldId = 'name';
    }
    if (id === 'location') {
      fieldId = 'address';
    }
    let errorMsg = validateFormInput(fieldId, textValue);
    setError(errorMsg);
    if (props.onError) {
      props.onError(errorMsg ? true : false);
    }
    if (!errorMsg || props.updateOnError) {
      props.onTextBlur(textValue);
    }
  };

  useEffect(()=>{
    if(props.value != textValue){
      setTextValue(props.value)
    }
  },[props.value])

  return (
    <TextField
      required
      error={error ? true : false}
      value={textValue || ''}
      id={props.id}
      name={props.id}
      label={props.name}
      type={props.type || 'text'}
      fullWidth
      ref={props.forwardedRef}
      onChange={e => {
        setTextValue(e.target.value);
      }}
      onBlur={e => {
        handleBlur(props.id);
      }}
      maxLength={props.maxLength ? props.maxLength : '12'}
      style={{background: '#ffffff', fontSize: '15px'}}
      className={props.className ? props.className : classes.customTextField}
      size="small"
      helperText={error ? error : ''}
    />
  );
}
export default CustomTextfield;
