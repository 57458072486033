import React from 'react';
import {useState} from 'react';
import {Link} from '@material-ui/core';
import CustomFileViewer from '../fileviewer/CustomFileViewer';
import useStyles from './Style';

export default function FileAttachment(props) {
  const classes = useStyles();
  const [openFileView, setOpenFileView] = useState(false);
  const closefileViewer = () => {
    setOpenFileView(false);
  };
  return (
    <>
      <Link
        component="button"
        variant="body2"
        onClick={() => {
          setOpenFileView(true);
        }}
        className={classes.linkButtonStyle}
        style={{padding: '8px'}}>
        {props.attachment.fileName}
      </Link>

      {openFileView ? (
        <CustomFileViewer
          close={closefileViewer}
          filePath={props.attachment.filePath}
          bucketName={props.attachment.bucketName}
          fileType={props.attachment.fileType}
          fileName={props.attachment.fileName}
        />
      ) : null}
    </>
  );
}
