/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */
import React, { useEffect, useState } from 'react';
import { Provider as StoreProvider, useSelector } from 'react-redux';
import store from 'components/src/redux/store/Store';
import AppNavigator from './AppNavigator';
import { createMuiTheme } from '@material-ui/core/styles';
// import { createTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles';
import { AppStyle } from 'components/AppStyle';
import { connect } from 'react-redux';
import { getErrorMsg } from 'components/src/redux/errorhandler/ErrorHandleReducer';
import CustomDialog from './component/common/CustomDialog';
import { googleTrackingId } from 'components/src/redux/config';
import ttiPolyfill from 'tti-polyfill';
import { createBrowserHistory } from 'history';
import useAppHooks from './useAppHooks';
import './index.css';
import ErrorBoundary from "./ErrorBoundry"
const history = createBrowserHistory();

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'OpenSans-Regular',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    button: {
      textTransform: 'none',
    },
  },

  palette: {
    primary: { main: AppStyle.primaryColor, contrastText: '#fff' },
    secondary: { main: AppStyle.secondaryColor, contrastText: '#fff' },
    error: { light: '#e57373', main: '#f44336', dark: '#d32f2f' },
  },
});


ttiPolyfill.getFirstConsistentlyInteractive().then(tti => {

});

const callback = list => {
  list.getEntries().forEach(entry => {

  });
};

var observer = new PerformanceObserver(callback);
observer.observe({
  entryTypes: [
    'navigation',
    'paint',
    'resource',
    'mark',
    'measure',
    'frame',
    'longtask',
  ],
});

history.listen(location => {
  if (location.pathname.includes('/HSPatient')) {
    let rootURL = location.pathname.split('/')[1];
    let userPage = location.pathname.split('/')[3];

    let pageHit = `/${rootURL}/${userPage}`;
  }
});

const App = () => {
  return (
    <ErrorBoundary>
        <StoreProvider store={store}>
          <ThemeProvider theme={theme}>
            <Wrapper />
          </ThemeProvider>
        </StoreProvider>
    </ErrorBoundary>
    
  );
};

function ParentWrapper(props) {
  const [error, setError] = useState({});
  const [user, setUser] = React.useState(null);
  const [paymentGatewayJsx, setPaymentGatewayJsx] = useState(null);
  const [selectedLang, setSelectedLang] = useState(null);

  useAppHooks(
    user,
    setUser,
    setPaymentGatewayJsx,
    setError,
    selectedLang,
    setSelectedLang,
    props,
  );

  return (
    <div id="app">
      {Object.keys(error).length && Object.keys(error.info).length ? (
        <CustomDialog
          type="error"
          title="Error"
          content={error.info.message || error.info.code}
        />
      ) : null}
      <div id="modal-root" />
      <div id="paymentAuto">{paymentGatewayJsx}</div>
      <AppNavigator />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    error: getErrorMsg(state),
  };
}

const Wrapper = connect(mapStateToProps)(ParentWrapper);

export default App;
