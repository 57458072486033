import { useSelector, useDispatch } from 'react-redux';
import { loginConstants } from 'components/src/redux/config';

export function getKinesisWebEventVO(eventScreenName, eventType, actionOnComponentName, actionOnComponentValue, actionType, userHospitalCode, userPatientCode) {
    let referenceURL = '';
    if(window){
        referenceURL = window.location.href;
    }
    let event = {};
    try {
        event = {
            appId: loginConstants.CLIENT_ID,
            appIdType: "url",
            eventScreenName: eventScreenName || '', // name of the screen displayed to the user
            eventType: eventType,
            actionOnComponentName: actionOnComponentName,
            actionOnComponentValue: actionOnComponentValue,
            actionType: actionType,
            userHospitalCode: userHospitalCode,
            userPatientCode: userPatientCode,
            additionalParam :{
                "baseApplicationUrl":referenceURL
            }
        };
        return event;
    } catch (e) {
        console.log('Kinesis error====>', e)
    }
}