import React from 'react';

const errorHandler = error => {
  const errorObj = {};
  if (error.message === 'Network Error') {
    errorObj.statusCode = 503;
    errorObj.message =
      'Network error occured,please check your internet connection or try again later';
    return errorObj;
  } else if (!error.response) {
    errorObj.statusCode = 500;
    errorObj.message =
      'We are not able to process your request right now,Please contact the administrator';
    return errorObj;
  } else if (
    error.response.status === 400 &&
    error.response.data &&
    error.response.data.error == 'invalid_grant'
  ) {
    errorObj.statusCode = 401;
    errorObj.message = 'Your session has expired. Please login again.';
    return errorObj;
  } else if (error.response.status === 400) {
    errorObj.statusCode = error.response.status;
    errorObj.message = 'Request sent is not in the right format';
    return errorObj;
  } else if (error.response.status === 401) {
    errorObj.statusCode = error.response.status;
    errorObj.message = 'Your session has expired. Please login again.';
    return errorObj;
  } else if (error.response.status === 403 || error.response.status === 409) {
    errorObj.statusCode = error.response.status;
    errorObj.message = "You don't have permission to perform this action";
    return errorObj;
  } else {
    errorObj.statusCode = error.response.status;
    errorObj.message = 'unexpected error occured,please try later';
    return errorObj;
  }
};

export { errorHandler };
