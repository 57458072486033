import {otpConstant} from './../ReduxConstant';

const initialState = {
  data: {},
  otpRequestInitiated: false,
  verifyOtpReqInitiated: false,
  otpVarificationFailed: false,
  verifyRequestSuccess: false,
  verifyOtpData: {},
  error: {},
};

export default function otpDetails(state = initialState, action) {
  switch (action.type) {
    case otpConstant.OTP_REQUEST_INTIATED:
      return {
        ...state,
        data: {},
        otpRequestInitiated: true,
        error: {},
      };
    case otpConstant.OTP_RESPONSE_SUCCESS:
      return {
        ...state,
        data: action.data,
        otpRequestInitiated: false,
        error: {},
      };
    case otpConstant.OTP_RESPONSE_FAILURE:
      return {
        ...state,
        data: {},
        otpRequestInitiated: false,
        error: action.data,
      };
    case otpConstant.VERIFY_OTP_REQUEST_INITIATED:
      return {
        ...state,
        verifyOtpData: {},
        verifyOtpReqInitiated: true,
        otpVarificationFailed: false,
        error: {},
      };
    case otpConstant.VERIFY_OTP_REQUEST_SUCCESS:
      return {
        ...state,
        verifyOtpData: action.data.data,
        verifyRequestSuccess: true,
        verifyOtpReqInitiated: false,
        error: {},
      };
    case otpConstant.VERIFY_OTP_REQUEST_FAILURE:
      return {
        ...state,
        verifyOtpData: {},
        verifyOtpReqInitiated: false,
        otpVarificationFailed: true,
        error: action.data,
      };

    case otpConstant.RESET_VERIFY_OTP:
      return {
        ...state,
        verifyOtpData: {},
        verifyOtpReqInitiated: false,
        otpVarificationFailed: false,
        error: {},
      };

    default:
      return state;
  }
}
