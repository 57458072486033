import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import {userNamePlaceHolder} from 'components/src/commonutil/CommonUtil';

export default function(props) {
  return (
    <Avatar className={props.class ? props.class : ''}>
      {props.username ? userNamePlaceHolder(props.value) : props.value}
    </Avatar>
  );
}
