import React from 'react';

export const subscriptionDataLoading = state =>
  state.subscription.subscribedUserRequestInitiated;

export const unSubscribedPatient = subscription =>
  subscription.filter(e => {
    return !e.paymentFlg;
  });

export const billingPackage = state => state.subscription.billingPackage;
export const loadingBillingPackage = state =>
  state.subscription.billingPackageRequestInitiated;

export const findBillingPackageByCode = (billingPackage, packageCode) => {
  let item = null;
  if (billingPackage && packageCode) {
    item = billingPackage.find(d => d.packageCode === packageCode);
  }
  return item;
};

export const relatedPatientList = state => state.subscription.data;
export const loadingRelatedPatientDetails = state =>
  state.subscription.subscribedUserRequestInitiated;

export const getPatienDetailsForAppPatientId = (patientList, appPatienId) => {
  return patientList.find(d => d.appPatientId == appPatienId);
};

export const primaryDependents = state =>
  state.subscription.data.filter(e => {
    return e.primaryDependent;
  });
