import {registrationConstant, homescreenConstant} from './../ReduxConstant';
import {authorizedHttp} from './../axiosApi';
import {showErrorToast} from '../errorhandler/ErrorHandleReducer';

export function initiateUserExistsCheck() {
  return {
    type: registrationConstant.CHECK_USER_EXISTS_INTITATED,
  };
}

export function userExistsCheckSuccess(data) {
  return {
    type: registrationConstant.CHECK_USER_EXISTS_RESPONSE_SUCCESS,
    data,
  };
}

export function userExistsCheckFailure(data) {
  return {
    type: registrationConstant.CHECK_USER_EXISTS_RESPONSE_FAILURE,
    data,
  };
}

export function intiateRegisterUser() {
  return {
    type: registrationConstant.REGISTRAION_REQUEST_INTIATED,
  };
}

export function registerUserSuccess(data) {
  return {
    type: registrationConstant.REGISTRAION_RESPONSE_SUCCESS,
    data,
  };
}

export function registerUserFailue(data) {
  return {
    type: registrationConstant.REGISTRATION_RESPONSE_FAILURE,
    data,
  };
}

export function intiatePatientValidation() {
  return {
    type: registrationConstant.VALIDATE_REGISTRATION_INTIATED,
  };
}

export function patientValidationSuccess(data) {
  return {
    type: registrationConstant.VALIDATE_REGISTRATION_RESPONSE_SUCCESS,
    data,
  };
}

export function patientValidationFailure(data) {
  return {
    type: registrationConstant.VALIDATE_REGISTRATION_RESPONSE_FAILURE,
    data,
  };
}

export function checkIfUserExists() {
  return dispatch => {
    dispatch(initiateUserExistsCheck());
    authorizedHttp
      .get('api/checkIfUserDetailsExists')
      .then(response => {
        dispatch(userExistsCheckSuccess(response.data));
      })
      .catch(error => {
        dispatch(userExistsCheckFailure(error));
        dispatch(showErrorToast(error));
      });
  };
}

export function saveUserDetails(data) {
  return dispatch => {
    dispatch(intiateRegisterUser());
    authorizedHttp
      .post('api/saveUserDetails', data)
      .then(response => {
        if (response.data.status) {
          dispatch(registerUserSuccess(response.data));
        } else {
          const errMsg =
            response.data.errors && response.data.error !== null
              ? response.data.errors
              : {
                  error:
                    'Unable to register patient(s). Please try after some time.',
                };
          dispatch(registerUserFailue(errMsg));
          let errorValue = Object.keys(errMsg)[0];
          dispatch(showErrorToast({message: errMsg[errorValue]}));
        }
      })
      .catch(error => {
        dispatch(registerUserFailue(error));
        dispatch(showErrorToast(error));
      });
  };
}

export function validateUserDetails(data) {
  return dispatch => {
    dispatch(intiatePatientValidation());
    authorizedHttp
      .post('api/checkIfPatientDetailsExists', data)
      .then(response => {
        dispatch(patientValidationSuccess(response.data));
      })
      .catch(error => {
        dispatch(patientValidationFailure(error));
        dispatch(showErrorToast(error));
      });
  };
}

export function initiateUpdateProfileRequest() {
  return {
    type: registrationConstant.UPDATE_PROFILE_REQUEST_INITIATED,
  };
}

export function updateProfileSuccess(data) {
  return {
    type: registrationConstant.UPDATE_PROFILE_REQUEST_SUCCESS,
    data,
  };
}

export function updateProfileFailure(data) {
  return {
    type: registrationConstant.UPDATE_PROFILE_REQUEST_FAILURE,
    data,
  };
}

export function updateProfile(data) {
  return dispatch => {
    dispatch(initiateUpdateProfileRequest());
    authorizedHttp
      .post('api/updateProfileOfPatient', data)
      .then(response => {
        dispatch(updateProfileSuccess(response.data));
      })
      .catch(error => {
        dispatch(updateProfileFailure(error));
        dispatch(showErrorToast(error));
      });
  };
}

export function resetEvents() {
    return {
        type: homescreenConstant.HOME_EVENT_REQUEST_SUCCESS,
        data: {
            data: []
        }
    }
}

export function resetRegistrationResponse() {
  return dispatch => {
    dispatch({
      type: registrationConstant.RESET_REGISTRATION_RESPONSE,
    });
  };
}
