export const getEventsByType = (events, type) => {
    const typeEvents = [] 
    events && events.length && events.map((element, index) => {
      
        if(element.eventType === type) {
            typeEvents.push(element)
        }
    })

    return typeEvents
}

export const getMeetingUrl = async (url) => {
    let json;
    let response = await fetch(url);

    if (response.ok) { 
      json = await response.json();
    } else {
      alert("HTTP-Error: " + response.status);
    }
    
    return json
}