import {patientprofileConstant} from './../ReduxConstant';

const initialState = {
  data: {},
  profileRequestInit: false,
  error: {},
};

export default function patientProfileDetails(state = initialState, action) {
  switch (action.type) {
    case patientprofileConstant.PATIENT_PROFILE_EVENT_REQUEST_INITIATED:
      return {
        ...state,
        profileRequestInit: true,
        profileDataRequested: true,
        error: {},
      };
    case patientprofileConstant.PATIENT_PROFILE_EVENT_REQUEST_SUCCESS:
      return {
        ...state,
        data: action.data.data,
        profileRequestInit: false,
        error: {},
      };
    case patientprofileConstant.PATIENT_PROFILE_EVENT_REQUEST_FAILURE:
      return {
        ...state,
        profileRequestInit: false,
        error: action.data,
      };

    case patientprofileConstant.PATIENT_PROFILE_DATA_RESET:
      return {
        ...state,
        data: {},
      };

    default:
      return state;
  }
}
