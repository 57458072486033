import { navigationConstant } from './../ReduxConstant';

const initialState = {
  nextScreen: {},
  navProps: undefined,
  logoutPressed: false,
};

export default function navigationDetails(state = initialState, action) {
  switch (action.type) {
    case navigationConstant.NAV_NEXT_SCREEN:
      return {
        ...state,
        nextScreen: action.data,
      };
    case navigationConstant.SAVE_NAV_PROPS:
      return {
        ...state,
        navProps: action.data,
      };
    case navigationConstant.LOGOUT_PRESSED:
      return {
        ...state,
        logoutPressed: action.data,
      };
    default:
      return state;
  }
}

export const getNavigationProps = state => { return state.navigation.navProps; }
export const getLogoutPressed = state => { return state.navigation.logoutPressed; }

