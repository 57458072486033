import React, {useEffect} from 'react';
import {getUserProfile} from '../localstorage/LocalStorage';
const useMyDetailsHook = (setPatientMobileNo, patientMobileNo) => {
  const userProfile = getUserProfile();
  useEffect(() => {
    if (!patientMobileNo) {
      setPatientMobileNo(userProfile?.contactDetails?.patientMobileNo);
    }
  }, [patientMobileNo]);
};

export default useMyDetailsHook;
