import { AppStyle } from 'components/AppStyle';
import React from 'react';
export const phoneNumberContainerStyle = {
  color: 'black',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 43,
  textAlign: 'left',
  margin: 'auto',
  width: '100%',
};
export const phoneNumberInputStyle = {
  height: 43,
  fontFamily: 'OpenSans-Regular',
  fontSize: '15px',
  textAlign: 'left',
  width: '100%',
};

export const registrationTitleColor = {color: AppStyle.primaryColor};

export const registrationContainerColor = {background: '#ffffff'};

export const avatar = {
  margin: 'auto',
  backgroundColor: AppStyle.primaryColor,
};
