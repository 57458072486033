import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getHomescreenEvents,
  filterHomescreenEvents,
} from 'components/src/redux/homescreenreducer/HomeScreenAction';
import {getQuickActionDetailsForPatient} from 'components/src/redux/quickeventsreducer/QuickEventsAction'
export default function useEventCardHook(
  pageNo,
  setPageNo,
  setEvents,
  setProgress,
  setUser,
  selectedEvent,
  setSelectedHospital,
  setPatientContactDetails
) {
  const dispatch = useDispatch();
  const selectedUserId = useSelector(state => {
    const savedUser = state.home.selectedUser;
    if (Object.keys(savedUser).length) {
      return savedUser.appPatientId;
    }
    return 0;
  });

  //const selectedEvent = useSelector(state => state.home.selectedEvent);

  const eventListInit = useSelector(state => state.home.eventsRequestInitiated);

  const events = useSelector(state => state.quickEvents.quickEvents);

  const selectedUser = useSelector(state => state.home.selectedUser);
  const hospitalDetails = useSelector(state => state.hospital.hospital);
  const patientContactDetails = useSelector(state =>  state.profile.data.contactDetails);

  useEffect(() => {
    if(hospitalDetails){
      const selectedHospital = hospitalDetails.find(d => d?.hospitalCd == selectedUser?.hospitalCode);
    setSelectedHospital(selectedHospital)
    }
    
  }, [selectedUser])

  useEffect(() => {
    if(patientContactDetails){
      setPatientContactDetails(patientContactDetails)
    }
    
  }, [patientContactDetails])
  

  useEffect(() => {
    setProgress(eventListInit);
    if (!eventListInit && events.length) {
      setEvents(events);
    }
  }, [eventListInit]);

  useEffect(() => {
    if (selectedEvent) {
      if (selectedUserId && selectedUserId > 0) {
        dispatch(filterHomescreenEvents(selectedUserId, pageNo, selectedEvent));
      }
    }
  }, [pageNo, selectedUserId, selectedEvent]);

  useEffect(() => {
    setEvents([]);
  }, [selectedEvent]);

  useEffect(() => {
    setEvents([]);
  }, [selectedUserId]);

  useEffect(() => {
    if (selectedUser) {
      setUser(selectedUser)
    }
  }, [selectedUser])

  useEffect(() => {
    if (selectedUserId) {
      dispatch(getQuickActionDetailsForPatient(selectedUserId));
    }
  }, [selectedUserId]);
}
