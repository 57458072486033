import React from 'react';

export const paymentIntiated = state =>
  state.payment.paymentDetailsRequestInitiated;

export const loadingOrderRequest = state => state.payment.orderRequestInitiated;

export const orderRequestedItemCode = state =>
  state.payment.requestedBillItemCode;

export const getOrderDetails = state => state.payment.order;

export const getOrderErrorDetails = state => state.payment.error;
