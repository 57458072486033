import React from 'react';
import PatientRegistration from '../../registerpatient/PatientRegistration';
import homeStyles from '../Style';
import EventCard from '../eventcard/EventCard';
import HomeTabPanel from './HomeTabPanel';
import ActionCard from './ActionCard';
import CompleteProfileStepper from '../completeprofile/CompleteProfileStepper';
import PackageSet from '../../packagelist/Plan';
import ListPatient from '../../registerpatient/ListPatient';
import { StaticCard } from './StaticCard';

export default function SubscriptionTab(props) {
  const classes = homeStyles();

  if (props.action === 'REG') {
    return (
      <HomeTabPanel value={props.value} index={props.index} dir={props.dir}>
        <ActionCard display={true}>
          <PatientRegistration location={props.location} lang={props.lang} />
        </ActionCard>
      </HomeTabPanel>
    );
  }
if (props.action === 'LISTPATIENT') {
    return (
      <HomeTabPanel value={props.value} index={props.index} dir={props.dir}>
        <ActionCard display={true}>
          <ListPatient location={props.location} lang={props.lang} />
        </ActionCard>
      </HomeTabPanel>
    );
  }

  if (
    !props.action &&
    props.selectedUser &&
    Object.keys(props.selectedUser).length &&
    props.selectedUser.packageCode == null
  ) {
    if (!props.selectedUser.verified || !props.selectedUser.profileComplete) {
      return (
        <HomeTabPanel value={props.value} index={props.index} dir={props.dir}>
          <ActionCard display={true} class={classes.packageCardPaper}>
            <CompleteProfileStepper
              verified={props?.selectedUser?.verified}
              profileComplete={props?.selectedUser?.profileComplete}
              countryCode={props?.selectedUser?.phoneNumber?.countryCode}
              phoneNo={props?.selectedUser?.phoneNumber?.mobileNumber}
              associationId={props?.selectedUser?.associationId}
              appPatientId={props?.selectedUser?.appPatientId}
              lang={props.lang}
            />
          </ActionCard>
        </HomeTabPanel>
      );
    } else {
      return (
        <HomeTabPanel value={props.value} index={props.index} dir={props.dir}>
          <ActionCard display={true} class={classes.packageCardPaper}>
            <PackageSet hideClose={true} />
          </ActionCard>
        </HomeTabPanel>
      );
    }
  } else if (props.action === 'SUB') {
    return (
      <HomeTabPanel value={props.value} index={props.index} dir={props.dir}>
        <ActionCard display={true} class={classes.packageCardPaper}>
          <PackageSet hideClose={false} />
        </ActionCard>
      </HomeTabPanel>
    );
  }

  return (
    <HomeTabPanel value={props.value} index={props.index} dir={props.dir}>
      <EventCard homePage={true} code={props.tabCode} lang={props.lang} />
      <StaticCard selectedUser={props?.selectedUser}/>
    </HomeTabPanel>
  );
}
