import { AppStyle } from 'components/AppStyle';
import favicon from 'components/assets/favicon.ico';
import {
  App_Title,
  chatbotBaseUrl,
  envPrefix,
  hsChatbotUrl,
} from 'components/src/redux/config';
import React, { useEffect, createRef } from 'react';
import { BackHandler } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import {
  clearLocalStorage,
  getPreferedLanguage,
  getSavedUserAuthToken,
  getApplicationCredentials,
} from './component/localstorage/LocalStorage';
import Pay from './component/packagelist/Pay';
import { getHospitalDetails } from 'components/src/redux/hospitalreducer/HospitalAction';
import { getUserAuthToken } from 'components/src/redux/axiosApi';
import { paymentConstants } from 'components/src/redux/config';
import WebAnalyticsUtils from './WebAnalytics';
import { recivedMessageTypes } from 'components/src/commonutil/CommonUtil';

let selectedusedGlobal = null;
let paymentOnsucess = null;
let setPaymentGatewayJsxGlobal = null;
let hospitalData = null;

const counterStorageKey = 'BOTCOUNTER';
const stepCodeStorageKey = 'STEPCODE';
const workflowIdStorageKey = 'WORKFLOW';

export let HSMEETCHATBOT;
export const LoadChatbot = (user, selectedLang, hospital, awsCredentials, fcmConfiguration) => {
  const data = {
    base_url: chatbotBaseUrl,
    key: '',
    theme: AppStyle?.primaryColor,
    verticalPosition: 'bottom',
    horizontalPosition: 'right',
    patientId: user?.hospitalPatientCode,
    hospitalId: user?.hospitalCode,
    platform: 'web',
    appType: 'patient',
    hideChat: false,
    lang: selectedLang,
    chatbotApiVersion: WebAnalyticsUtils.getChatbotApiVersion() || '',
    currencyCode: hospital?.currencyCode || 'INR',
    currencySymbol: hospital?.currencySymbol,
    s3AccessKey: awsCredentials ? awsCredentials?.accessKey : '',
    s3SecretKey: awsCredentials ? awsCredentials?.secretKey : '',
    s3BucketName: envPrefix + user?.hospitalCode?.toLowerCase(),
    storagePath: 'chatbot',
    userName: user?.patientName,
    tabToOpen: user?.tabToOpen,
    fcmConfiguration: fcmConfiguration ? JSON.stringify(fcmConfiguration) : null
  };

  HSMEETCHATBOT = new hsChat(data);
  HSMEETCHATBOT.openBot(getBotExtraArgs());
  return HSMEETCHATBOT;
};
export const getBotExtraArgs = () => {
  const data = {
    token: getUserAuthToken(),
    paymentGatewayKey: paymentConstants.PAYMENT_GATEWAY_KEY,
    themeColor: AppStyle.headerBackgroundColor,
  };

  return JSON.stringify(data);
};

const f = function (e) {
  {
    try {
      /**Case listening for chatbot book appt flow */
      const msg = e.data;
      if (
        msg &&
        typeof msg === 'string' &&
        msg.startsWith('{')
      ) {

        const chatbotData = JSON.parse(msg);
        if (chatbotData.type == recivedMessageTypes.fireBaseEvents) {
          WebAnalyticsUtils.trackEvent(chatbotData?.data?.event, chatbotData?.data?.params)
        } else if (chatbotData.paid == false) {
          RedirectToPaymentGateway(chatbotData);
        }
      }
      if (typeof msg === 'string') {
        switch (msg) {
          case 'bot_visible_open':
            document.getElementById('app').classList.add('blur');
            break;
          case 'bot_visible_close':
            document.getElementById('app').classList.remove('blur');
            break;
        }
      }
    } catch (e) {
      console.log('function====>', e);
    }
  }
};

const RedirectToPaymentGateway = chatbotData => {
  /**Constuct a payment object */
  const data = {
    itemCode: chatbotData.packageCode,
    appBill: true,
    billItemNm: chatbotData.billName,
    billAmt: chatbotData.amount,
    billData: {
      appBillCode: chatbotData.packageCode,
      appBillName: chatbotData.billName,
    },
    currencySymbol: hospitalData.currencySymbol,
    currencyCode: hospitalData?.currencyCode || 'INR',
  };

  if (
    data &&
    selectedusedGlobal &&
    Object.keys(selectedusedGlobal).length > 0
  ) {
    const _paymentGateway = (
      <Pay
        billData={data.billData}
        billAmt={data.billAmt}
        selectedEvent={selectedusedGlobal}
        onSucess={paymentOnsucess}
        paymentBackgroundColor={'grey'}
        paymentMode={'auto'}
        currencyCode={hospitalData?.currencyCode || 'INR'}
      />
    );

    setPaymentGatewayJsxGlobal(_paymentGateway);
  }
};

export default function useAppHooks(
  user,
  setUser,
  setPaymentGatewayJsx,
  setError,
  selectedLang,
  setSelectedLang,
  props,
) {
  const dispatch = useDispatch();
  /**Redux state */
  const selectedUser = useSelector(state => state.home.selectedUser);
  const awsCredentials = getApplicationCredentials();
  const getAllUser = useSelector(
    state => state.subscription.data
  );
  const hospital = useSelector(state =>state.hospital.hospital);
  const saveAuthToken = getSavedUserAuthToken();
  const fcmConfiguration = useSelector((state) => state.signup.fcmConfiguration);
  const selectedUserLang = useSelector(state => state.signup.lang);


  useEffect(() => {
    if (fcmConfiguration?.apiKey) {
      // console.log(fcmConfiguration)
      WebAnalyticsUtils.intializeAnalytics(fcmConfiguration)
    }
  }, [fcmConfiguration])

  /**fcmConfiguration */
  /**Hooks */
  useEffect(() => {
    /**Respective hospital title */
    document.title = App_Title;

    /**Respective hospital favicon */
    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = favicon;

    /**Load the HS chatbot */
    const script = document.createElement('script');

    script.src = hsChatbotUrl;
    script.async = true;

    document.body.appendChild(script);

    const preferredLang = getPreferedLanguage();
    setSelectedLang(preferredLang);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (selectedUser) {
      if (saveAuthToken && (!hospital || Object.keys(hospital).length === 0)) {
        dispatch(getHospitalDetails());
      }
      setUser(selectedUser);
    }
  }, [selectedUser]);

  const xx = () => {
    const HSMEETCHATBOT = LoadChatbot(user, selectedUserLang, hospital, awsCredentials, fcmConfiguration);
    selectedusedGlobal = user;
    paymentOnsucess = props.onSucess;
    setPaymentGatewayJsxGlobal = setPaymentGatewayJsx;
    hospitalData = hospital;
    windowPostMessageListener();
    openIfStepCodeAndWorkflowAvailable(HSMEETCHATBOT);
  };

  let chatBotSetTimeout;

  function myFunction() {
    chatBotSetTimeout = setTimeout(function () {
      if (document.querySelector('#HSMeet') && WebAnalyticsUtils.getChatbotApiVersion()) {
        xx();
      } else {
        myFunction();
      }
    }, 1000);
  }

  function myStopFunction() {
    if (chatBotSetTimeout) {
      clearTimeout(chatBotSetTimeout);
    }
  }

  useEffect(() => {
    if (
      user &&
      Object.keys(user).length > 0 &&
      user.hospitalPatientCode &&
      user.hospitalCode &&
      user.patientName &&
      hospital &&
      Object.keys(hospital).length > 0
    ) {
      /**Invoke the chatbot here*/
      if (document.querySelector('#HSMeet') && WebAnalyticsUtils.getChatbotApiVersion()) {
        xx();
        myStopFunction();
      } else {
        myFunction();
      }
    }
  }, [user, document.querySelector('#HSMeet'), selectedUserLang]);

  useEffect(() => {
    if (props?.error?.info) {
      if (props.error.info.statusCode == 401) {
        // console.log('======came to UseApphook.js===>')
        clearLocalStorage();
      } else {
        setError(props.error);
      }
    }
  }, [props?.error?.key, props?.error?.info]);

  const windowPostMessageListener = () => {
    window.removeEventListener('message', f, false);
    window.addEventListener('message', f);
  };

  const openIfStepCodeAndWorkflowAvailable = async HSMEETCHATBOT => {
    await new Promise(resolve => setTimeout(resolve, 500));
    const stepCode = localStorage.getItem(stepCodeStorageKey);
    const workFlow = localStorage.getItem(workflowIdStorageKey);

    // console.log('====temp========', workFlow);
    if (workFlow) {
      // const botCounterKey = stepCode + '_' + workFlow;
      //   let counterData = JSON.parse(
      //     localStorage.getItem(counterStorageKey) || JSON.stringify({}),
      //   );
      //   if (counterData[botCounterKey] === undefined) {
      //     counterData = {
      //       [botCounterKey]: true,
      //     };
      // console.log('temp stepdata web2', stepCode, workFlow);
      const workflowArgs = window.localStorage.getItem('WORKFLOW_INITIAL_ARGS');
      let workflowArgsParsedObj = {};
      let workflowArgsParsed = {};
      if (workflowArgs) {
      workflowArgsParsedObj = JSON.parse(workflowArgs);
     let selHospitalCode = getAllUser.find(h => workflowArgsParsedObj?.hospitalCode == h?.hospitalCode)
       workflowArgsParsed = workflowArgsParsedObj;
      if(!selHospitalCode){
        workflowArgsParsed.hospitalCode = user.hospitalCode;
      }
      }
      // // console.log('======came to visible bot before========', stepCode,
      // workFlow,
      //   workflowArgsParsed,
      //   getBotExtraArgs())
      HSMEETCHATBOT.visibleBot(
        stepCode,
        workFlow,
        workflowArgsParsed,
        getBotExtraArgs(),
      );
      // console.log('======came to visible Bot========')
      //}
      // localStorage.setItem(
      //   counterStorageKey,
      //   JSON.stringify({...counterData}),
      // );
    }
    // console.log('======came to remove storage disabled========')
    localStorage.removeItem(stepCodeStorageKey);
    localStorage.removeItem(workflowIdStorageKey);
    localStorage.removeItem('WORKFLOW_INITIAL_ARGS');
  };

  
}
