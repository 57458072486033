import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import PatientRegistration from '../../registerpatient/PatientRegistration';
import homeStyles from '../Style';
import {useState} from 'react';

export default function ActionCard(props) {
  const classes = homeStyles();
  const [displayCard, setDisplayCard] = useState(props.display);

  if (!displayCard) {
    return null;
  }
  return (
    <Grow
      in={displayCard}
      style={{transformOrigin: '0 0 0'}}
      {...(displayCard ? {timeout: 1000} : {})}>
      <Paper
        elevation={4}
        className={props.class ? props.class : classes.actionCardPaper}>
        {props.children}
      </Paper>
    </Grow>
  );
}
