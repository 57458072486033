import {medicationConstant} from '../ReduxConstant';

const initialState = {
  currentMed: [],
  previousMed: [],
  prescriptionHistoryData: [],
  currentMedInit: false,
  previousMedInit: false,
  prescriptionHistoryInit: false,
  error: {},
};

export default function medicationDetails(state = initialState, action) {
  switch (action.type) {
    case medicationConstant.CURRENT_MED_INIT:
      return {
        ...state,
        currentMed: [],
        currentMedInit: true,
        error: {},
      };
    case medicationConstant.CURRENT_MED_SUCCESS:
      return {
        ...state,
        currentMed: action.data.data,
        currentMedInit: false,
        error: {},
      };
    case medicationConstant.CURRENT_MED_FAILURE:
      return {
        ...state,
        currentMed: [],
        currentMedInit: false,
        error: action.data,
      };
    case medicationConstant.PRESCRIPTION_HISTORY_INIT:
      return {
        ...state,
        prescriptionHistoryData: [],
        prescriptionHistoryInit: true,
        error: {},
      };
    case medicationConstant.PRESCRIPTION_HISTORY_SUCCESS:
      return {
        ...state,
        prescriptionHistoryData: action.data.data,
        prescriptionHistoryInit: false,
        error: {},
      };
    case medicationConstant.PRESCRIPTION_HISTORY_FAILURE:
      return {
        ...state,
        prescriptionHistoryData: [],
        prescriptionHistoryInit: false,
        error: action.data,
      };
    case medicationConstant.PAST_MED_INTI:
      return {
        ...state,
        previousMed: [],
        previousMedInit: true,
        error: {},
      };
    case medicationConstant.PAST_MED_SUCCESS:
      return {
        ...state,
        previousMed: action.data.data,
        previousMedInit: false,
        error: {},
      };
    case medicationConstant.PAST_MED_FAILURE:
      return {
        ...state,
        previousMed: [],
        previousMedInit: false,
        error: action.data,
      };
    default:
      return state;
  }
}
