import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  sendOTPReq,
  verifyOTPReq,
  resendOTPReq
} from 'components/src/redux/otpreducer/OTPAction';

export default function useSignupOtpHook(
  countryCode,
  phoneNo,
  sendOtp,
  setSendOtp,
  setErrorMsg,
  otp,
  setOtp,
  openBackdrop,
  resendOtp,
  setResendOtp
) {
  const [signup, setSignup] = useState(true);
  const dispatch = useDispatch();
  const otpError = useSelector(state => state.otp.error);

  const otpInit = useSelector(state => state.otp.otpRequestInitiated);

  const verifyOtpInit = useSelector(state => state.otp.verifyOtpReqInitiated);

  useEffect(() => {
    if (sendOtp) {
      dispatch(sendOTPReq(countryCode, phoneNo));
      setSendOtp(false);
    }
  }, [sendOtp]);

  useEffect(() => {
    if (resendOtp) {
      dispatch(resendOTPReq(countryCode, phoneNo));
      setSendOtp(false);
    }
  }, [resendOtp]);

  useEffect(() => {
    if (Object.keys(otpError).length > 0) {
      /*  if (otpError.statusCode === 503 || otpError.statusCode === 500) {
        handleError(otpError.message);
      } else { */
      setErrorMsg(otpError.message);
      setOtp('');
      setSignup(true);
      // }
    }
  }, [otpError]);

  useEffect(() => {
    openBackdrop(otpInit);
  }, [otpInit]);

  useEffect(() => {
    openBackdrop(verifyOtpInit);
  }, [verifyOtpInit]);

  useEffect(() => {
    if (signup && otp && otp.length == 5) {
      dispatch(verifyOTPReq(countryCode, phoneNo, otp));
      setSignup(false);
    }
  }, [otp]);
}
