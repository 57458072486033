import React from 'react';
import {Grid, TextField , Button,CircularProgress} from '@material-ui/core';
import CustomTextfield from '../common/CustomTextfield';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';


import MyLocation from '@material-ui/icons/MyLocation';
import { languages } from 'components/src/constants/languages';

function constructAddress(results){
  let addressParts = results[0].address_components;
  const addressDetails = {};
  addressDetails.formatted_address = results[0].formatted_address
  let addresLineOne =  addressDetails.formatted_address 
  addressParts.forEach(element => {
    let type = element.types;
    if (type.includes('postal_code')) {
      addressDetails.zipCode = (element.long_name).replace(/[^0-9,.]+/g, "");
      addresLineOne = addresLineOne.replace(`${addressDetails.zipCode}`,'')
    } else if (type.includes('locality')) {
      addressDetails.cityName = element.long_name;
      addresLineOne = addresLineOne.replace(`${addressDetails.cityName}`,'')
    }else if(type.includes('administrative_area_level_2') && !addressDetails.cityName){
      addressDetails.cityName = element.long_name;
      addresLineOne = addresLineOne.replace(`${addressDetails.cityName}`,'')
    } else if (type.includes('administrative_area_level_1')) {
      addressDetails.stateName = element.long_name;
      addresLineOne = addresLineOne.replace(`${addressDetails.stateName}`,'')
    } else if (type.includes('country')) {
      addressDetails.countryName = element.long_name;
      addresLineOne = addresLineOne.replace(`${addressDetails.countryName}`,'')
    }
    addressDetails.formatted_address =  addressDetails.formatted_address.replace(',', '')
    addresLineOne =  addresLineOne.replace(',', '')

  });
  return { addressDetails , addresLineOne};
}
class PatientAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {address: '', addressLineOne: '', loadingCurrentLocation : false,addressDetails:{}};
  }

  

  handleAddressLineChange = value => {
    let addr={...this.state.addressDetails}
    this.setState({addressLineOne: value});
    addr.addresLineOne = value;
    this.setState({addressDetails:addr})
    this.props.setAddress(addr);
  };

  handleChange = address => {
    this.setState({address});
  };

 

 getGeoCode = (latlng) =>  {
     const geocoder = new google.maps.Geocoder();
     const $this = this 
     geocoder.geocode( { 'location': latlng}, function(results, status) {
      if (status == google.maps.GeocoderStatus.OK) {
        let {addressDetails ,addresLineOne } =  constructAddress(results)
        addressDetails.latitude = latlng.lat
        addressDetails.longitude = latlng.lng
        if(addresLineOne && addresLineOne.trim()!==''){
        $this.handleAddressLineChange(addresLineOne)
      addressDetails.addresLineOne=addresLineOne  
      }else{
        addressDetails.addresLineOne=$this.state.addressLineOne;
      }
        addressDetails.address=addressDetails.zipCode  
        $this.setState({address: addressDetails.zipCode, loadingCurrentLocation : false,addressDetails:addressDetails});
        addressDetails =  $this.validateAddress(addressDetails);
        $this.props.setAddress(addressDetails);
      }
    });
  }

  getCurrentPosition = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const $this = this 
     navigator.geolocation.getCurrentPosition(
    (position) => {
      $this.getGeoCode({ 
            lat: parseFloat(position.coords.latitude), 
            lng: parseFloat(position.coords.longitude)
         })
    },
     (e) => {
       $this.setState({loadingCurrentLocation : false})
       console.error("Unable to get Location",e)
     },{timeout:10000});
     $this.setState({loadingCurrentLocation : true})
  }

  validateAddress = (addressDetails)=>{
    if(this.props.branches && this.props.branches.length>0){ 
       addressDetails.addresLineOne=addressDetails.addresLineOne 
       addressDetails.cityName=addressDetails.cityName ||  this.props.branches[0].hospitalAddrCityNm
       addressDetails.stateName=addressDetails.stateName ||  this.props.branches[0].hospitalAddrStateNm
       addressDetails.countryName=addressDetails.countryName ||  this.props.branches[0].hospitalAddrCountryNm
       addressDetails.zipCode=addressDetails.zipCode ||  this.props.branches[0].zipcode
    
       if(!this.state.addressLineOne || this.state.addressLineOne.trim()===''){
        this.handleAddressLineChange(addressDetails.addresLineOne)
      }else{
        addressDetails.addresLineOne=this.state.addressLineOne;
      }
    }
    return addressDetails;
  }

  handleSelect = address => {
    geocodeByAddress(address)
      .then(results => {
       getLatLng(results[0])
       let {addressDetails  } =  constructAddress(results)
       addressDetails.address=addressDetails.formatted_address
       addressDetails.addresLineOne=this.state.addressLineOne
      addressDetails = this.validateAddress(addressDetails);
        this.props.setAddress(addressDetails);
        this.setState({address: addressDetails.formatted_address,addressDetails:addressDetails});
      })
      .then(latLng => console.log('Success', latLng))
      .catch(error => console.error('Error', error));
  };

  render() {
    const { loadingCurrentLocation } = this.state
    return (
      <>
        <Grid item xs={12}>
          <CustomTextfield
            name={languages[this.props.lang].addressLine1}
            id={'address'}
            onTextBlur={this.handleAddressLineChange}
            value={this.state.addressLineOne}
            updateOnError={true}
          />
        </Grid>
        <Grid item xs={12}>
          <PlacesAutocomplete
            value={this.state.address}
            onChange={this.handleChange}
            onSelect={this.handleSelect}>
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div>
                <div style={{display:'flex'}}>
                <TextField
                  required
                  fullWidth
                  error={this.props.error && this.props.error==='zipcode'}
                  name="zipcode"
                  label={languages[this.props.lang].zipcode}
                  {...getInputProps({
                    placeholder: languages[this.props.lang].searchByZipcode,
                    className: 'location-search-input',
                  })}
                  style={{background: '#ffffff', fontSize: '15px',paddingRight:'25px'}}
                  helperText={
                    this.props.error && this.props.error==='zipcode' ? languages[this.props.lang].selectValidAddress : ''
                  }
                  autocomplete="new-password"
                />
                <Button  onClick={this.getCurrentPosition}>
                  {loadingCurrentLocation ? <CircularProgress /> : <MyLocation />}</Button>
                </div>
                <div className="autocomplete-dropdown-container">
                  {loading && <div>Loading...</div>}
                  {suggestions.map(suggestion => {
                    if (suggestion.types.includes('postal_code')) {
                      const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item';
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? {
                            backgroundColor: '#fafafa',
                            cursor: 'pointer',
                          }
                        : {
                            backgroundColor: '#ffffff',
                            cursor: 'pointer',
                          };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}>
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
          
        </Grid>
      </>
    );
  }
}
export default PatientAddress;
