import { QuickEventConstant } from '../ReduxConstant';

const initialState = {
  quickEvents: [],
  quickEventsInit: false,
  error: {},
};

export default function patientTabEventDetails(state = initialState, action) {
  switch (action.type) {
    case QuickEventConstant.QUICK_EVENT_INIT:
      return {
        ...state,
        quickEvents: [],
        quickEventsInit: true,
        error: {},
      };
    case QuickEventConstant.QUICK_EVENT_SUCCESS:
      return {
        ...state,
        quickEvents: action.data.data,
        quickEventsInit: false,
        error: {},
      };
    case QuickEventConstant.QUICK_EVENT_FAILURE:
      return {
        ...state,
        quickEvents: [],
        quickEventsInit: false,
        error: action.data,
      };
    default:
      return state;
  }
}
