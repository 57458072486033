import React, {useState} from 'react';
import useStyles from './Style';
import {FormControl, Select, InputLabel, MenuItem} from '@material-ui/core';

function CustomSelect(props) {
  const classes = useStyles();
  const [selectValue, setSelectValue] = useState(
    props.defaultValue ? props.defaultValue : props.items[0],
  );
  const [error, setError] = useState();

  const selectId = `${props.selectLabel}-sel-id`;
  const inputLabelId = `${props.selectLabel}-label-id`;

  const handleOnChange = value => {
    setSelectValue(value);
    props.onChange(value);
  };
  return (
    <FormControl className={classes.formControl} error={props.error}>
      <InputLabel required id={inputLabelId}>
        {props.selectLabel}
      </InputLabel>
      <Select
        id={selectId}
        error={error ? true : false}
        value={selectValue}
        onChange={e => {
          handleOnChange(e.target.value);
        }}
        required={true}>
        {props.items.map(d => (
          <MenuItem value={d} key={d}>
            {d}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
export default CustomSelect;
