import React from 'react';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import homeStyles from '../Style';
import PatientList from './PatientList';
import Button from '@material-ui/core/Button';
import {useTheme} from '@material-ui/core/styles';
import PersonAdd from '@material-ui/icons/PersonAdd';
import Link from '@material-ui/icons/Link';
import Subscriptions from '@material-ui/icons/Subscriptions';
import {useState} from 'react';
import useSideSheetHook from './UseSideSheetHook';
import CustomDrawer from '../../common/CustomDrawer';
import Footer from '../../common/Footer';
import WebAnalyticsUtils  from '../../../WebAnalytics';
import { eventConstants } from 'components/src/constants/firebaseAnalyticsEventConstant';

function SideSheet(props) {
  const classes = homeStyles();
  const {container} = props;

  const [action, setAction] = useState('');
  const [userSelected, setUserSelected] = useState(false);

  useSideSheetHook(setAction, action, userSelected);

  const handleOnClick = event => {
    let parameter = {}
    if(event == "REG"){
      WebAnalyticsUtils.trackEvent(eventConstants.NEW_REGISTATION_PATIENT, {})
    }else if(event == "SUB"){
      WebAnalyticsUtils.trackEvent(eventConstants.NEW_SUBSCRIBE_PATIENT, {})
    }else if(event == "LISTPATIENT"){
      WebAnalyticsUtils.trackEvent(eventConstants.ALL_PATIENT_LIST, {})
    }
    setAction(event);
  };

  return (
    <CustomDrawer
      desktopClas={classes.sideSheet}
      classes={{
        paper: classes.sideSheetPaper,
      }}
      open={props.mobileOpen}
      onClose={props.handleDrawerToggle}
      container={container}>
      <div className={classes.sideSheetClearance} />
      <Divider />
      <PatientList setUserSelected={setUserSelected} lang={props.lang} />

      <div>
        <Button
          aria-label={'register'}
          className={classes.registerButton}
          onClick={() => handleOnClick('REG')}
          variant="contained"
          disabled={action === 'REG'}>
          <PersonAdd className={classes.sideSheetIcon} />
        </Button>
        <Button
          aria-label={'subscribe'}
          className={classes.registerButton}
          onClick={() => handleOnClick('SUB')}
          variant="contained"
          disabled={action === 'SUB'}>
          <Subscriptions className={classes.sideSheetIcon} />
        </Button>
        <Button
          aria-label={'listPatient'}
          className={classes.registerButton}
          onClick={() => handleOnClick('LISTPATIENT')}
          variant="contained"
          disabled={action === 'LISTPATIENT'}>
          <Link className={classes.sideSheetIcon} />
        </Button>
      </div>
      <Footer lang={props.lang} />
    </CustomDrawer>
  );
}
export default SideSheet;
