import {patientprofileConstant} from './../ReduxConstant';
import {authorizedHttp} from './../axiosApi';

export function getPatientProfileDetails() {
  return {
    // Types of actions to emit before and after
    types: [
      patientprofileConstant.PATIENT_PROFILE_EVENT_REQUEST_INITIATED,
      patientprofileConstant.PATIENT_PROFILE_EVENT_REQUEST_SUCCESS,
      patientprofileConstant.PATIENT_PROFILE_EVENT_REQUEST_FAILURE,
    ],
    callAPI: () => authorizedHttp.get(`api/getUserProfileDetails`),
  };
}

export function resetProfileData() {
  return {
    type: patientprofileConstant.PATIENT_PROFILE_DATA_RESET
  };
}