import {paymentConstant} from './../ReduxConstant';

const initialState = {
  order: {},
  orderRequestInitiated: false,
  paymentDetails: {},
  paymentDetailsRequestInitiated: false,
  error: {},
};

export default function paymentDetails(state = initialState, action) {
  switch (action.type) {
    case paymentConstant.ORDER_CREATE_INITIATED:
      return {
        ...state,
        orderRequestInitiated: true,
        requestedBillItemCode:action.payload,
        error: {},
        order: {},
      };
    case paymentConstant.ORDER_CREATE_SUCCESS: {
      return {
        ...state,
        order: action.data.data,
        orderRequestInitiated: false,
        error: {},
      };
    }
    case paymentConstant.ORDER_CREATE_FAILURE:
      return {
        ...state,
        order: {},
        orderRequestInitiated: false,
        error: action.data,
      };

    case paymentConstant.PAYMENT_DETAILS_SAVE_INTITATED:
      return {
        ...state,
        paymentDetailsRequestInitiated: true,
        error: {},
      };
    case paymentConstant.PAYMENT_DETAILS_SAVE_SUCCESS:
      return {
        ...state,
        paymentDetails: action.data.data,
        paymentDetailsRequestInitiated: false,
        error: {},
      };
    case paymentConstant.PAYMENT_DETAILS_SAVE_FAILURE:
      return {
        ...state,
        paymentDetailsRequestInitiated: false,
        error: action.data,
      };

    default:
      return state;
  }
}
