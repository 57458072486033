import {combineReducers} from 'redux';
import signUpReducer from 'components/src/redux/signupreducer/SignUpReducer';
import otpReducer from 'components/src/redux/otpreducer/OTPReducer';
import navigationReducer from 'components/src/redux/navigationreducer/NavigationReducer';
import registrationReducer from 'components/src/redux/registrationreducer/RegistrationReducer';
import subscriptionReducer from 'components/src/redux/subscriptionreducer/SubscriptionReducer';
import errorHandleReducer from 'components/src/redux/errorhandler/ErrorHandleReducer';
import paymentReducer from 'components/src/redux/paymentreducer/PaymentReducer';
import homescreenReducer from 'components/src/redux/homescreenreducer/HomeScreenReducer';
import platformReducer from 'components/src/redux/platformreducer/PlatformReducer';
import profile from 'components/src/redux/patientprofilereducer/PatientProfileReducer';
import vital from 'components/src/redux/vitalreducer/VitalReducer';
import medication from 'components/src/redux/medicationreducer/MedicationReducer';
import consultation from 'components/src/redux/consultaionreducer/ConsultationReducer';
import credentials from 'components/src/redux/credentialsreducer/CredentialReducer';
import hospitalReducer from 'components/src/redux/hospitalreducer/HospitalReducer';
import contentReducer from 'components/src/redux/contentreducer/contentReducer';
import patientTabEventReducer from 'components/src/redux/patienttabeventreducer/PatientTabEventReducer';
import quickEventsReducer from 'components/src/redux/quickeventsreducer/QuickEventsReducer';
import notificationReducer from 'components/src/redux/notificationreducer/NotificationReducer';

const appReducer = combineReducers({
  signup: signUpReducer,
  otp: otpReducer,
  navigation: navigationReducer,
  registration: registrationReducer,
  subscription: subscriptionReducer,
  payment: paymentReducer,
  error: errorHandleReducer,
  home: homescreenReducer,
  platform: platformReducer,
  profile: profile,
  vital: vital,
  medication: medication,
  consultation: consultation,
  credentials: credentials,
  hospital: hospitalReducer,
  content: contentReducer,
  patientTabEvent: patientTabEventReducer,
  quickEvents: quickEventsReducer,
  notification: notificationReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_ON_LOGOUT') {
    let {navigation, platform} = state;
    state = {
      navigation,
      platform,
    };
  }
  return appReducer(state, action);
};

export default rootReducer;
