import {medicationConstant} from '../ReduxConstant';
import {authorizedHttp} from '../axiosApi';

export function getCurrentMedication(appPatientId) {
  return {
    // Types of actions to emit before and after
    types: [
      medicationConstant.CURRENT_MED_INIT,
      medicationConstant.CURRENT_MED_SUCCESS,
      medicationConstant.CURRENT_MED_FAILURE,
    ],

    callAPI: () =>
      authorizedHttp.get(`api/getCurrentMedications/${appPatientId}`),
  };
}

export function getPreviousMedication(appPatientId) {
  return {
    // Types of actions to emit before and after
    types: [
      medicationConstant.PAST_MED_INTI,
      medicationConstant.PAST_MED_SUCCESS,
      medicationConstant.PAST_MED_FAILURE,
    ],
    callAPI: () =>
      authorizedHttp.get(`api/getPreviousMedications/${appPatientId}`),
  };
}
export function getPrescriptionHistory(appPatientId) {
  return {
    // Types of actions to emit before and after
    types: [
      medicationConstant.PRESCRIPTION_HISTORY_INIT,
      medicationConstant.PRESCRIPTION_HISTORY_SUCCESS,
      medicationConstant.PRESCRIPTION_HISTORY_FAILURE,
    ],
    callAPI: () =>
      authorizedHttp.get(`api/getPrescriptionHistory/${appPatientId}`),
  };
}
