import React from 'react';
import Typography from '@material-ui/core/Typography';
import useStyles from './Style';

function Title(props) {
  const classes = useStyles();
  return (
    <div className={classes.paper}>
      <Typography
        component="h1"
        variant="h4"
        className={classes.headerText}
        style={props.style}>
        {props.header}
      </Typography>
      <Typography
        component="h5"
        className={classes.subHeaderText}
        style={props.style}>
        {props.subHeader}
      </Typography>
    </div>
  );
}

export default Title;
